import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import WatchedItemContainer from '../utils/WatchedItemContainer.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import ContactData from '../addressbook/ContactData.js'
import '../css/icons.css'

class MyAccount extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item : null,
      tab: 'props',
    }
    this.container = new WatchedItemContainer(this, 'com.optimsys.costra.kordex.users.UserContainer')
    this.data = new EditData(this, 'item.')
  }

  componentDidMount() {
    this.container.load(this.props.id)
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.container.load(this.props.id)
    }
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.FetchUser',
      id: this.props.id,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'myaccount.myAccount.loadError'})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.UpdateUser',
      item: this.state.item,
    }).then((result) => {
      if (this.props.id === result.item._id.$oid) {
        result.editOpen = false
        this.setState(result)
      }
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }
  
  checkPassword(password){
    const regex = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{10,}$")
    return regex.test(password)
  }

  render() {
    if (!this.state.item) {
      return <div className="mainPanel withTabs">
        <LoadingPlaceholder/>
      </div>
    }

    return <div className="mainPanel">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig user"/>
          <h1>{this.state.item.login}</h1>
          <div className="values">
            <div className="icons">
              {this.state.item.extensions.kordex.processing.missingSignature &&
                <div className="icon left iconBig missingSignature" title={Translate.get('myaccount.info.missingSignature')}/>
              }
              {this.state.item.extensions.kordex.processing.weakPassword &&
                <div className="icon left iconBig weakPassword" title={Translate.get('myaccount.info.weakPassword')}/>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          <div className="section spacedTop">
            <div className="buttons">
              {this.state.allowEdit && <EditButtons component={this}/>}
            </div>
            <div className="subsection">
              <div className="header-row">
                <div className="icon iconSection user"/>
                <h3>{Translate.get('myaccount.title')}</h3>
              </div>
              <div className="content">
                <Form.Text data={this.data}
                           disabled={!this.state.editOpen}
                           path="displayName"
                           title='myaccount.displayName'/>
                <Form.Text data={this.data}
                           disabled={!this.state.editOpen}
                           path="extensions.kordex.localLine"
                           title='myaccount.localLine'/>
                <Form.Password data={this.data}
                               disabled={!this.state.editOpen}
                               path='password'
                               placeholder='myaccount.password'
                               title='myaccount.password'
                               check={(this.state.item.password &&
                                (this.state.item.password !== this.state.item.passwordCopy || !this.checkPassword(this.state.item.password))
                               ) ? ' invalid' : ''}/>
                {this.state.item.password &&
                  <Form.Password data={this.data}
                                 disabled={!this.state.editOpen}
                                 path='passwordCopy'
                                 placeholder='myaccount.passwordCopy'
                                 title='myaccount.passwordCopy'
                                 check={(this.state.item.password &&
                                  (this.state.item.password !== this.state.item.passwordCopy || !this.checkPassword(this.state.item.password))
                                 ) ? ' invalid' : ''}/>
                }
              </div>
              {this.state.item.password && !this.checkPassword(this.state.item.password) &&
                <div>
                  <div className="left icon alert"/>
                  {Translate.get('myaccount.info.weakPassword')}
                </div>
              }
              {this.state.item.password && this.state.item.password !== this.state.item.passwordCopy &&
                <div>
                  <div className="left icon alert"/>
                  {Translate.get('myaccount.info.passwordMismatch')}
                </div>
              }
            </div>

            <ContactData data={this.data}
                          path="extensions.kordex.contact"
                          disabled={!this.state.editOpen}
                          title='myaccount.contact'
                          noValidation={true}/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default MyAccount
