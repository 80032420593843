class Id {

  static nextId = 0

  static get() {
    Id.nextId += 1
    return Id.nextId + '.' + Math.floor((Math.random() * 1000))
  }
}

export default Id