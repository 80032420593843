import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import '../css/dialogs.css'

class PackagerSelectDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      campaigns : null,
      selectedIndex: 0,
      working: false,
      result: null,
    }
  }

  runPackager(event, packager) {
    this.setState({working : true})
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.exports.RunPackager',
      packager: packager,
    }).then(result => {
      this.setState({working: false, result : result})
    })
  }

  componentDidMount() {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.exports.PackagersList',
    }).then(result => {
      if (!result.campaigns) {
        result.campaigns = []
      }
      this.setState({campaigns: result.campaigns})
    })
  }

  renderContent() {
    if (this.state.result) {
      return <p>{Translate.get('exports.selectPackager.result', null, this.state.result)}</p>
    }
    if (this.state.working || !this.state.campaigns) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="select">
        <select onChange={event => this.setState({selectedIndex: event.target.value})}>
          {this.state.campaigns.map((campaign, index) =>
            <option key={index} value={index}>{campaign.displayName}</option>
          )}
        </select>
      </div>
      <ul className="list spacedTop">
        {this.state.campaigns[this.state.selectedIndex].packagers ?
          this.state.campaigns[this.state.selectedIndex].packagers.map((packager, index) =>
            <li key={index}>
              <div className="row withHover"
                   onClick={(event) => this.runPackager(event, packager)}>
                <div><div className="icon export"/></div>
                <div>{Translate.get(packager._class)}</div>
              </div>
            </li>
          )
          :
          <li><div className="row"><div>{Translate.get('exports.selectPackager.noPackagers')}</div></div></li>
        }
      </ul>
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig export"/>
          <span>{Translate.get('exports.selectPackager.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>

      </div>
    </div>
  }
}

export default PackagerSelectDialog