import React from 'react'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'

import PreparedLines from './PreparedLines.js'

class Sal {

  static partnersImportPrepared(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.partners && data.partners.map(partner =>
          <li key={partner.item.salPartnerId}>
            {PreparedLines.contactDataItem('imports.sal.li.partner', partner.item)}
            {PreparedLines.contactDataItemContacts(partner.item)}
            {PreparedLines.errors(partner)}
          </li>
        )}
      </ul>
    </div>
  }

  static partnersImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        <li>
          <div className="row">
            <div><div className="icon ok"/></div>
            <div>{Translate.get('imports.result.partnerRowsImported')}: {data.partnerRowsImported}</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div><div className="icon cancel"/></div>
            <div>{Translate.get('imports.result.partnerRowSkipped')}: {data.partnerRowIssues}</div>
          </div>
        </li>
      </ul>
    </div>
  }

  static sfCarsImportPrepared(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.cases && data.cases.map(caseRecord =>
          <li key={caseRecord.item._id.$oid}>
            {PreparedLines.caseRecord(caseRecord)}
            {PreparedLines.errors(caseRecord)}
            <div className="spaced">
              <ul className="list">
                <li>
                  {PreparedLines.contactDataItem('imports.sal.li.client', caseRecord.item.client)}
                  {PreparedLines.contactDataItemContacts(caseRecord.item.client)}
                </li>

                {caseRecord.locations && caseRecord.locations.map(caseLocation =>
                  <li key={caseLocation.item._id.$oid}>
                    {PreparedLines.contactDataItem('imports.sal.li.branch', caseLocation.item.client)}
                    {PreparedLines.errors(caseLocation)}
                    {PreparedLines.contactDataItemContacts(caseLocation.item.client)}
                    <div className="spaced">
                      <ul className="list">
                        {caseLocation.objects && caseLocation.objects.map(caseObject =>
                          <li key={caseObject.item._id.$oid}>
                            <div className="row">
                              <div><div className="icon car"/></div>
                              <div className="size2c">{Translate.get('imports.sal.row.object')}</div>
                              <div className="size4c">{Translate.get('imports.sal.row.sfr')} {caseObject.item.sfrNo}</div>
                              <div className="size6c">{Translate.get('imports.sal.row.vin')} {caseObject.item.vin}</div>
                              <div>{caseObject.item.name}</div>
                            </div>
                            {PreparedLines.errors(caseObject)}
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}

              </ul>
            </div>
          </li>
        )}

      </ul>
    </div>
  }

  static sfCarsImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        <li>
          <div className="row">
            <div><div className="icon ok"/></div>
            <div>{Translate.get('imports.result.objectRowsImported')}: {data.carRowsImported}</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div><div className="icon cancel"/></div>
            <div>{Translate.get('imports.result.objectRowsSkipped')}: {data.carRowsSkipped}</div>
          </div>
        </li>
      </ul>
    </div>
  }

  static sfVtpImportPrepared(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.cases && data.cases.map(caseRecord =>
          <li key={caseRecord.item._id.$oid}>
            {PreparedLines.caseRecord(caseRecord)}
            {PreparedLines.errors(caseRecord)}
            <div className="spaced">
              <ul className="list">
                <li>
                  {PreparedLines.contactDataItem('imports.sal.li.client', caseRecord.item.client)}
                  {PreparedLines.contactDataItemContacts(caseRecord.item.client)}
                </li>

                {caseRecord.locations && caseRecord.locations.map(caseLocation =>
                  <li key={caseLocation.item._id.$oid}>
                    {PreparedLines.contactDataItem('imports.sal.li.branch', caseLocation.item.client)}
                    {PreparedLines.errors(caseLocation)}
                    {PreparedLines.contactDataItemContacts(caseLocation.item.client)}
                    <div className="spaced">
                      <ul className="list">
                        {caseLocation.objects && caseLocation.objects.map(caseObject =>
                          <li key={caseObject.item._id.$oid}>
                            <div className="row">
                              <div><div className="icon log"/></div>
                              <div className="size2c">{Translate.get('imports.sal.row.object')}</div>
                              <div className="size4c">{Translate.get('imports.sal.row.sfr')}{caseObject.item.sfrNo}</div>
                              <div className="size6c">{Translate.get('imports.sal.row.vin')} {caseObject.item.vin}</div>
                              <div>{caseObject.item.name}</div>
                            </div>
                            {PreparedLines.errors(caseObject)}
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static sfVtpImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        <li>
          <div className="row">
            <div><div className="icon ok"/></div>
            <div>{Translate.get('Importovaných řádků předmětů')} {data.vtpRowsImported}</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div><div className="icon cancel"/></div>
            <div>{Translate.get('imports.result.objectRowsSkipped')} {data.vtpRowsSkipped}</div>
          </div>
        </li>
      </ul>
    </div>
  }

  static sfE6ImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.e6.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.orvRowsImported', data.orvRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsImported', data.partnerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.objectRowsImported', data.objectRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsImported', data.accessoryRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contractRowsImported', data.contractRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.eventRowsImported', data.eventRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.addressRowsSkipped', data.addressRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.orvRowsSkipped', data.orvRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsSkipped', data.partnerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.objectRowsSkipped', data.objectRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsSkipped', data.accessoryRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contractRowsSkipped', data.contractRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.eventRowsSkipped', data.eventRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static telE6PaymentsPreview(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.payments && data.payments.map((payment, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon coins"/></div>
              <div className="size2c">{Translate.get('imports.preview.payment')}</div>
              <div className="size4c">{payment.eventId}</div>
              <div className="size4c">{payment.received.id}</div>
              <div className="size4c">
                {Formatter.formatAmount(payment.received.amount) + ' ' + payment.received.currency}
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static pisE4Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.exportBatchButton('imports.button.printExport', data.printId)}
          {PreparedLines.exportBatchButton('imports.button.reportExport', data.reportId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.clientRowsImported', data.clientRowsImported)}
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.mailRowsImported', data.mailRowsImported)}
        {PreparedLines.reportLine('sal.pis.result.mailsSent', data.mailsSent, 'email')}
        {PreparedLines.reportLine('sal.pis.result.mailedCases', data.mailedCases, 'email')}
        {PreparedLines.reportLine('imports.result.clientRowsSkipped', data.clientRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.mailRowsSkipped', data.mailRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static pisE6Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.exportBatchButton('imports.button.printExport', data.printId)}
          {PreparedLines.exportBatchButton('imports.button.reportExport', data.reportId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.e6.addressRowsImported', data.addressRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsImported', data.partnerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.objectRowsImported', data.objectRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contractRowsImported', data.contractRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.eventRowsImported', data.eventRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.planRowsImported', data.planRowsImported)}
        {PreparedLines.reportLine('sal.pis.result.mailsSent', data.mailsSent, 'email')}
        {PreparedLines.reportLine('sal.pis.result.mailedCases', data.mailedCases, 'email')}
        {PreparedLines.reportLine('imports.result.e6.addressRowsSkipped', data.addressRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsSkipped', data.partnerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.objectRowsSkipped', data.objectRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contractRowsSkipped', data.contractRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.eventRowsSkipped', data.eventRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.planRowsSkipped', data.planRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static collectionE4Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.clientRowsImported', data.clientRowsImported)}
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.clientRowsSkipped', data.clientRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static collectionE6Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.e6.addressRowsImported', data.addressRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.orvRowsImported', data.orvRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsImported', data.partnerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.insuranceRowsImported', data.insuranceRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.objectRowsImported', data.objectRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsImported', data.accessoryRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contractRowsImported', data.contractRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.eventRowsImported', data.eventRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.securityRowsImported', data.securityRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.employerRowsImported', data.employerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.planRowsImported', data.planRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.addressRowsSkipped', data.addressRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.orvRowsSkipped', data.orvRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsSkipped', data.partnerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.insuranceRowsSkipped', data.insuranceRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.objectRowsSkipped', data.objectRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsSkipped', data.accessoryRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contractRowsSkipped', data.contractRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.eventRowsSkipped', data.eventRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.securityRowsSkipped', data.securityRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.employerRowsSkipped', data.employerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.planRowsSkipped', data.planRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static pisSkResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.exportBatchButton('imports.button.printExport', data.batchId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.clientRowsImported', data.clientRowsImported)}
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.clientRowsSkipped', data.clientRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static pisSkPostPrepared(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.keys && data.keys.map(key =>
          <li key={key.caseNo}>
            <div className="row">
              <div><div className="icon case"/></div>
              <div className="size4c">{Translate.get('imports.sal.row.pisSkPostPrepared')}</div>
              <div className="size4c">{key.evNum}</div>
              <div className="size4c">{key.postageNo}</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static pisSkPostResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {data.importRecordId && PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.exportBatchButton('imports.button.printExport', data.batchId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('sal.pis.sk.postageRows.imported', data.importedRows)}
        {PreparedLines.reportLine('sal.pis.sk.postageRows.skipped', data.skippedRows, 'cancel')}
        {PreparedLines.reportLine('sal.pis.sk.postageRows.notFound', data.notFoundRows, 'cancel')}
      </ul>
    </div>
  }

  static telE4Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.clientRowsImported', data.clientRowsImported)}
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.clientRowsSkipped', data.clientRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static telE6Result(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.e6.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsImported', data.partnerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.objectRowsImported', data.objectRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contractRowsImported', data.contractRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.eventRowsImported', data.eventRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.securityRowsImported', data.securitiesRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.planRowsImported', data.planRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsSkipped', data.partnerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.objectRowsSkipped', data.objectRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contractRowsSkipped', data.contractRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.eventRowsSkipped', data.eventRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.securityRowsSkipped', data.securitiesRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.planRowsSkipped', data.planRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static telPaymentResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.mismatchedPayments', data.mismatchedPayments, 'cancel')}
      </ul>
    </div>
  }
}

export default Sal