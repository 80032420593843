import React, { Component } from 'react';
import Formatter from './Formatter.js';

class DurationInput extends Component {

  static parseStr(dateStr, millis) {
    // TODO parse using format

    var mult = 1
    if (millis) {
      mult = 1000
    }

    // dd:hh:mm:ss.SSS ------
    var matches = null
    var parsed = null
    if (millis) {
      matches = dateStr.match(/(\d+):(\d+):(\d+):(\d+).(\d+)/)
      if (matches && matches.length > 1) {
        matches.shift()
        parsed = matches.map(Number)
        return parsed[4] + (1000 * (parsed[3] + (60 * (parsed[2] + (60 * (parsed[1] + (24 * parsed[0])))))))
      }
    }
    // dd:hh:mm:ss ------
    matches = dateStr.match(/(\d+):(\d+):(\d+):(\d+)/)
    if (matches && matches.length > 1) {
      matches.shift()
      parsed = matches.map(Number)
      return mult * (parsed[3] + (60 * (parsed[2] + (60 * (parsed[1] + (24 * parsed[0]))))))
    }

    // hh:mm:ss.SSS ------
    if (millis) {
      matches = dateStr.match(/(\d+):(\d+):(\d+).(\d+)/)
      if (matches && matches.length > 1) {
        matches.shift()
        parsed = matches.map(Number)
        return parsed[3] + (1000 * (parsed[2] + (60 * (parsed[1] + (60 * parsed[0])))))
      }
    }
    // hh:mm:ss ------
    matches = dateStr.match(/(\d+):(\d+):(\d+)/)
    if (matches && matches.length > 1) {
      matches.shift()
      parsed = matches.map(Number)
      return mult * (parsed[2] + (60 * (parsed[1] + (60 * parsed[0]))))
    }

    // mm:ss.SSS ------
    if (millis) {
      matches = dateStr.match(/(\d+):(\d+).(\d+)/)
      if (matches && matches.length > 1) {
        matches.shift()
        parsed = matches.map(Number)
        return parsed[2] + (1000 * (parsed[1] + (60 * parsed[0])))
      }
    }
    // mm:ss ------
    matches = dateStr.match(/(\d+):(\d+)/)
    if (matches && matches.length > 1) {
      matches.shift()
      parsed = matches.map(Number)
      return mult * (parsed[1] + (60 * (parsed[0])))
    }

    // ss.SSS ------
    if (millis) {
      matches = dateStr.match(/(\d+).(\d+)/)
      if (matches && matches.length > 1) {
        matches.shift()
        parsed = matches.map(Number)
        return parsed[1] + (1000 * parsed[0])
      }
    }
    // ss ------
    matches = dateStr.match(/(\d+)/)
    if (matches && matches.length > 1) {
      matches.shift()
      parsed = matches.map(Number)
      return mult * parsed[0]
    }
    return null
  }

  constructor(props) {
    super(props)
    var value = ""
    if (this.props.value) {
      if (this.props.millis) {
        value = Formatter.formatMillis(this.props.value)
      }
      else {
        value = Formatter.formatSeconds(this.props.value)
      }
    }
    this.state = {
      localChange: false,
      valueStr : value,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.localChange && prevProps && prevProps.value !== this.props.value) {
      var value = ""
      if (this.props.value) {
        if (this.props.millis) {
          value = Formatter.formatMillis(this.props.value)
        }
        else {
          value = Formatter.formatSeconds(this.props.value)
        }
      }
      this.setState({
        valueStr: value,
      })
    }
  }

  finalizeValue(event) {
    this.setState({localChange: false})
    var time = DurationInput.parseStr(this.state.valueStr, this.props.millis)
    if (time === null) {
      this.props.onChange(null)
    }
    else {
      this.props.onChange({$numberLong: time.toString()})
    }
  }

  changeValue(value) {
    this.setState({valueStr: value, localChange: true})
    var time = DurationInput.parseStr(this.state.valueStr, this.props.millis)
    if (time === null) {
      this.props.onChange(null)
    }
    else {
      this.props.onChange({$numberLong: time.toString()})
    }
  }

  render() {
    return <input type="text"
                  className={this.props.className}
                  placeholder={this.props.placeholder}
                  value={this.state.valueStr}
                  readOnly={this.props.disabled}
                  onChange={(event) => this.changeValue(event.target.value)}
                  onBlur={(event) => this.finalizeValue(event)}/>
  }
}

export default DurationInput