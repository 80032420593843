import React, { Component } from 'react'
import Dialogs from './../costra/Dialogs.js'
import Translate from '../costra/Translate';
import WsContainers from '../costra/WsContainers.js'

import ConfirmDialog from '../utils/ConfirmDialog.js'
import Nav from '../utils/Nav.js'

import ViewLustrationDialog from './ViewLustrationDialog';
import './../css/dialogs.css';

class LustrationDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {state: 'Idle'}
    this.aresLustration = this.aresLustration.bind(this)
  }

  personOR(event, item) {    
    let firstName = item.firstName
    let lastName = item.lastName
    Dialogs.close(null)
    return Nav.go(event,
      "https://or.justice.cz/ias/ui/rejstrik-$osoba?p%3A%3Asubmit=x&.%2Frejstrik-%24osoba=&prijmeni=" + lastName +
      "&jmeno=" + firstName + "&jenPlatne=PLATNE", true)
  }

  businessOR(event, item) {    
    let companyId = item.companyId.trim()
    Dialogs.close(null)
    if (companyId.length <= 8) {
      return Nav.go(event,
        "https://or.justice.cz/ias/ui/rejstrik-$firma?ico=" + companyId + "&jenPlatne=VSECHNY", true)
    }
  }

  aresLustration(item) {
    Dialogs.open(
      <ConfirmDialog text={"Chcete odeslat dotaz na registr ARES s IČO " + item.companyId}/>
    ).then(res => {
      if(res) {
        this.setState({state: 'Working'})
        WsContainers.action({
          _class: 'com.optimsys.costra.kordex.lustration.RequestLustration',
          params: {
            _class: 'com.optimsys.costra.kordex.lustration.ares.RequestAresOrLustration',
            companyId: item.companyId,
          }
        }).then((res) => {
          Dialogs.close(null);
          Dialogs.open(<ViewLustrationDialog lustrationResult={res} caseId={this.props.caseId}/>)
        }, () => {
          this.setState({state: 'RequestError'})
        })
      }
    })
  }

  engagements(event, item) {
    let companyId = item.companyId
    Dialogs.close(null)
    if (companyId) {
      return Nav.go(event,
        "https://or.justice.cz/ias/ui/rejstrik-$spolecnici?p%3A%3Asubmit=x&.%2Frejstrik-%24&ico=" + companyId +
        "&jenPlatne=PLATNE", true)
    }
  }

  ares(event, item) {
    let companyId = item.companyId
    Dialogs.close(null)
    if (companyId.length <= 8) {
      return Nav.go(event, "https://wwwinfo.mfcr.cz/cgi-bin/ares/ares_es.cgi?xml=1&ico=" + companyId, true)
    }
  }

  renderSelection() {
    let companyId = this.props.item && this.props.item.companyId
    let personOrAvailable = (this.props.item.firstName && this.props.item.lastName)
    return <ul className="list spacedBottom">
      <li>
        <div className={'row withHover' + (personOrAvailable ? "" : " disabled")}
             onClick={(event) => personOrAvailable && this.personOR(event, this.props.item)}>
          <div className="flex4c">{Translate.get('lustrations.dialog.row.personOR')}</div>
          {!personOrAvailable &&
            <div>{Translate.get('lustrations.dialog.row.personOR.error')}</div>
          }
        </div>
      </li>
      <li>
        <div className={'row withHover' + (companyId ? "" : " disabled")}
             onClick={(event) => companyId && this.businessOR(event, this.props.item)}>
          <div className="flex4c">{Translate.get('lustrations.dialog.row.companyOR')}</div>
          {!companyId &&
            <div>{Translate.get('lustrations.dialog.row.companyOR.error')}</div>
          }
        </div>
      </li>
      <li>
        <div className={'row withHover' + (companyId ? "" : " disabled")}
             onClick={(event) => companyId && this.ares(event, this.props.item)}>
          <div className="flex4c">{Translate.get('lustrations.dialog.row.ares')}</div>
          {!companyId &&
            <div>{Translate.get('lustrations.dialog.row.aresError')}</div>
          }
        </div>
      </li>
      <li>
        <div className={'row withHover' + (companyId ? "" : " disabled")}
             onClick={(event) => companyId && this.engagements(event, this.props.item)}>
          <div className="flex4c">{Translate.get('lustrations.dialog.row.engagements')}</div>
          {!companyId &&
            <div>{Translate.get('lustrations.dialog.row.engagementsError')}</div>
          }
        </div>
      </li>
      <li>
        <div className={'row withHover' + (companyId ? "" : " disabled")}
             onClick={(event) => companyId && this.aresLustration(this.props.item)}>
          <div className="flex4c">{Translate.get('lustrations.dialog.row.downloadAres')}</div>
          {!companyId &&
            <div>{Translate.get('lustrations.dialog.row.downloadAresError')}</div>
          }
        </div>
      </li>
    </ul>
  }

  renderContent() {
    switch(this.state.state) {
      case 'Working':
        return <span>{Translate.get('lustrations.dialog.working')}</span>
      case 'RequestError':
        return <span>{Translate.get('lustrations.dialog.requestError')}</span>
      default:
        return this.renderSelection()
    }
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig law"/>
          <span>{Translate.get('lustrations.dialog.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel" />
          </div>
        </div>
      </div>
    </div>
  }
}

export default LustrationDialog