import Router from '../costra/Router.js'

class Nav {

  static go(event, url, newTab) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    if (newTab || (event && (event.button > 0 || event.shiftKey))) {
      window.open(Router.prefix() + url, '_blank').focus()
    }
    else {
      Router.go(url)
    }
    return true
  }

  static addUrlParam(event, param, value) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    const url = Router.changeOrAddUrlParams({[param] : value})
    Router.go(url)
  }

  static externalUri(event, url, params) {
    if (event) {
      event.stopPropagation()
    }
    var paramsStr = ''
    Object.keys(params).forEach(key => {
      if (paramsStr) {
        paramsStr += '&'
      }
      else {
        paramsStr += '?'
      }
      paramsStr += encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    window.open(Router.prefix() + url + paramsStr, '_blank').focus()
  }

  static openMap(event, lat, lng) {
    if (event) {
      event.stopPropagation()
    }
    window.open('https://www.google.com/maps/place/' + lat + ',' + lng, '_blank').focus()
  }
}

export default Nav