import React, { Component } from 'react'
import Container from '../costra/Container.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import {PageDataContext} from '../PageDataContext.js'
import '../css/list.css'
import '../css/icons.css'

class CommStatistics extends Component {

  constructor(props) {
    super(props)
    this.state = {
      items: null,
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.statistics.CommStatistics')
  }

  load() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.statistics.CommStatistics$Get',
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }

  renderUserName(userId) {
    if (userId.$oid === "000000000000000000000000") {
      return Translate.get('statistics.operators.shared')
    }
    let user = this.context.users[userId.$oid]
    if (!user) {
      return '?'
    }
    return user.displayName
  }

  renderSmsesTable(items) {
    return <table className="list">
      <thead>
        <tr>
          <td><div className="icon sms"/></td>
          <td>{Translate.get('statistics.comms.smsPort')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.inbound')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.outbound')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.errors')}</td>
          <td className="size1c"></td>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) =>
          <tr className="row"
              key={index}>
            <td><div className="icon sms"/></td>
            <td className="size4c">
              {item.port.serviceName ? item.port.serviceName : Translate.get('statistics.comms.allSmses')}
            </td>
            <td className="size2c alignRight">{item.inbound}</td>
            <td className="size2c alignRight">{item.outbound}</td>
            <td className="size2c alignRight">{item.errors}</td>
            <td className="size1c"></td>
          </tr>
        )}
      </tbody>
    </table>
  }

  renderCallsTable(items) {
    return <table className="list">
      <thead>
        <tr>
          <td><div className="icon call"/></td>
          <td>{Translate.get('statistics.comms.phoneNumber')}</td>
          <td>{Translate.get('statistics.comms.users')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.inbound')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.inboundConnected')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.outbound')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.outboundConnected')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.totalLength')}</td>
          <td className="alignRight">{Translate.get('statistics.comms.connectedLength')}</td>
          <td className="size1c"></td>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) =>
          <tr className="row"
              key={index}>
            <td><div className="icon call"/></td>
            <td className="size4c">
              {item.phoneNumber ? item.phoneNumber : Translate.get('statistics.comms.allCalls')}
            </td>
            <td className="size6c">
              {item.userIds && item.userIds.map(id => this.context.users[id.$oid].displayName).join(', ')}
            </td>
            <td className="size2c alignRight">{item.inbound}</td>
            <td className="size2c alignRight">{item.inboundConnected}</td>
            <td className="size2c alignRight">{item.outbound}</td>
            <td className="size2c alignRight">{item.outboundConnected}</td>
            <td className="size3c alignRight">{Formatter.formatSeconds(item.totalLength)}</td>
            <td className="size3c alignRight">{Formatter.formatSeconds(item.connectedLength)}</td>
            <td className="size1c"></td>
          </tr>
        )}
      </tbody>
    </table>
  }

  render() {
    if (!this.state.thisMonthCalls || !this.state.lastMonthCalls) {
      return <LoadingPlaceholder />
    }
    return <>
      <div className="section spacedTop">
        <div className="listHeader">
          <div>
            <div className="icon iconBig statistics"/>
            <h2>{Translate.get('statistics.thisMonth.title')}</h2>
          </div>
        </div>
        {this.renderCallsTable(this.state.thisMonthCalls)}
        {this.renderSmsesTable(this.state.thisMonthSmses)}
      </div>
      <div className="section spacedTop">
        <div className="listHeader">
          <div>
            <div className="icon iconBig statistics"/>
            <h2>{Translate.get('statistics.lastMonth.title')}</h2>
          </div>
        </div>
        {this.renderCallsTable(this.state.lastMonthCalls)}
        {this.renderSmsesTable(this.state.lastMonthSmses)}
      </div>
    </>
  }
}

CommStatistics.contextType = PageDataContext

export default CommStatistics
