import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import '../../../css/form.css'

var vtpTypes = [
  {value: 'Original', title: 'caseobject.sal.sfvtp.vtpTypes.Original'},
  {value: 'Import', title: 'caseobject.sal.sfvtp.vtpTypes.Import'},
  {value: 'NoneNew', title: 'caseobject.sal.sfvtp.vtpTypes.NoneNew'},
  {value: 'SecondFull', title: 'caseobject.sal.sfvtp.vtpTypes.SecondFull'},
  {value: 'SecondLost', title: 'caseobject.sal.sfvtp.vtpTypes.SecondLost'},
  {value: 'CocList', title: 'caseobject.sal.sfvtp.vtpTypes.CocList'},
  {value: 'Foreign', title: 'caseobject.sal.sfvtp.vtpTypes.Foreign'},
]

function SfVtpProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection document"/>
        <h3>{Translate.get('caseobject.properties.title')}</h3>
      </div>

      <div className="content">
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="sfrNo"
                  title='caseobject.sal.sfvtp.sfrNo'
                  validate={Validator.notEmptyString}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="name"
                  title='caseobject.properties.objectName'
                  validate={Validator.notEmptyString}/>
        <Form.Address data={props.component.data}
                      disabled={!props.editOpen}
                      path="address"
                      title='caseobject.properties.address'
                      caseIds={[props.component.data.get("caseId")]}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="vin"
                  title='caseobject.properties.vin'
                  validate={Validator.notEmptyString}
                  styling={Metrics.CheckVin(props.component.data.get("vin"),"Vin")}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="variable"
                  title='caseobject.sal.sfvtp.variable'
                  validate={Validator.notEmptyString}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="vtpNo"
                  title='caseobject.sal.sfvtp.vtpNo'/>
        <Form.Select data={props.component.data}
                    disabled={!props.editOpen}
                    path="vtpType"
                    title='caseobject.sal.sfvtp.vtpType'
                    values={vtpTypes}/>
        <Form.DateTime data={props.component.data}
                      disabled={!props.editOpen}
                      path="expectedDep"
                      title='caseobject.sal.sfvtp.expectedDep'
                      format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.component.data}
                      disabled={!props.editOpen}
                      path="realDep"
                      title='caseobject.sal.sfvtp.realDep'
                      format="%d.%m.%yyyy"/>
      </div>

      <Form.TextArea data={props.component.data}
                    disabled={!props.editOpen}
                    path="exportedNote"
                    styling="textArea big"
                    title='caseobject.properties.exportedNote'/>
    </div>

    {props.data.get('contactData') && props.data.get('contactData').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'contactData.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default SfVtpProperties
