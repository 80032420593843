import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import '../../../css/form.css'

const caseStates = [
  {value: 'Done', title: 'cofidis.collection.caseState.Done'},
  {value: 'Dismissed', title: 'cofidis.collection.caseState.Dismissed'},
  {value: 'Unknown', title: 'cofidis.collection.caseState.Unknown'},
]

const clientTypes = [
  {value: 'Company', title: 'cofidis.collection.clientType.Company'},
  {value: 'SoleTrader', title: 'cofidis.collection.clientType.SoleTrader'},
  {value: 'Individual', title: 'cofidis.collection.clientType.Individual'},
  {value: 'Unknown', title: 'cofidis.collection.clientType.Unknown'}
]

function CollectionProperties(props) {

  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection car"/>
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="caseNo"
                   title="cofidis.collection.caseNo"
                   validate={Validator.notEmptyString}/>

        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="contract.signatureDate"
                       title="cofidis.collection.signatureDate"
                       format="%d.%m.%yyyy"/>
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="contract.payments"
                   title="cofidis.collection.payments"/>
        <Form.Amount data={props.data}
                     disabled={!props.editOpen}
                     path="contract.monthlyPayment"
                     title="cofidis.collection.monthlyPayment"
                     currency="CZK"/>
        <Form.Amount data={props.data}
                     disabled={!props.editOpen}
                     path="contract.totalDebt"
                     title="cofidis.collection.totalDebt"
                     currency="CZK"/>
        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="contract.dueDate"
                       title="cofidis.collection.dueDate"
                       format="%d.%m.%yyyy"/>

        <Form.Select data={props.data}
                     disabled
                     values={caseStates}
                     path="state"
                     title="cofidis.collection.state"
                     className="size2c right"/>

        <Form.Select data={props.data}
                     disabled
                     values={clientTypes}
                     path="clientType"
                     title="cofidis.collection.clientType"
                     className="size2c right"/>      

        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="plannedEnding"
                       title="caserecord.properties.plannedEnding"
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.data}
                       disabled={true}
                       path="processing.created"
                       title='caserecord.processing.created'
                       format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                       disabled={true}
                       path="processing.finished"
                       title='caserecord.processing.finished'
                       format="%d.%m.%yyyy" />
      </div>
    </div>

    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((_, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>

}

export default CollectionProperties