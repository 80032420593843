import React from 'react'
import Translate from '../costra/Translate'

import '../css/icons.css'
import '../css/form.css'

function CaseEditTabs(props) {
  switch(props.item._class) {

    case 'com.optimsys.costra.kordex.caserecord.sal.telephony.TelCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisSKCase':
      return <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caserecord.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caserecord.tabs.events')} </div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caserecord.tabs.files')}</div>
        <div className={'tab' + (props.component.state.tab === 'objects' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'objects'})}>{Translate.get('caserecord.tabs.objects')}</div>
        <div className={'tab' + (props.component.state.tab === 'payments' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'payments'})}>{Translate.get('caserecord.tabs.payments')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caserecord.tabs.log')}</div>
        }
      </div>

    case 'com.optimsys.costra.kordex.caserecord.erl.pred.PredCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.sf.SfCase':
      return <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caserecord.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caserecord.tabs.events')}</div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caserecord.tabs.files')}</div>
        <div className={'tab' + (props.component.state.tab === 'objects' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'objects'})}>{Translate.get('caserecord.tabs.objects')}</div>
        <div className={'tab' + (props.component.state.tab === 'gps' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'gps'})}>{Translate.get('caserecord.tabs.gps')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caserecord.tabs.log')}</div>
        }
      </div>

    case 'com.optimsys.costra.kordex.caserecord.vw.fs.VwFsCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.collection.CollectionCase':
      return <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caserecord.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caserecord.tabs.events')}</div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caserecord.tabs.files')}</div>
        <div className={'tab' + (props.component.state.tab === 'objects' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'objects'})}>{Translate.get('caserecord.tabs.objects')}</div>
        <div className={'tab' + (props.component.state.tab === 'gps' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'gps'})}>{Translate.get('caserecord.tabs.gps')}</div>
        <div className={'tab' + (props.component.state.tab === 'payments' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'payments'})}>{Translate.get('caserecord.tabs.payments')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caserecord.tabs.log')}</div>
        }
      </div>


    case 'com.optimsys.costra.kordex.caserecord.ucb.telephony.TelCase':
    case 'com.optimsys.costra.kordex.caserecord.cofidis.collection.CollectionCase':
      return <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
              onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caserecord.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
              onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caserecord.tabs.events')} </div>
        <div className={'tab' + (props.component.state.tab === 'payments' ? ' active' : '')}
              onClick={(event) => props.component.setState({tab: 'payments'})}>{Translate.get('caserecord.tabs.payments')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
                onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caserecord.tabs.log')}</div>
        }
      </div>

    default:
      return <div>
        {props.item._class}
      </div>

  }
}

export default CaseEditTabs
