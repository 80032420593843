import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import '../css/dialogs.css'
import Translate from '../costra/Translate.js'

class UploadDropFileDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {uploading: false}
  }

  uploadFile(event, stayOpen) {
    this.setState({uploading: true})
    event.preventDefault()
    var data = new FormData(document.getElementById('dropFileUploadForm'))
    fetch('/upload/dropFile', {
      method: 'POST',
      body: data,
    }).then((result) => {
      this.setState({uploading: false})
      if (result.status < 200 || result.status > 202) {
         this.setState({error: 'dropfile.upload.error'})
      }
      else if (!stayOpen) {
        Dialogs.close(null)
      }
    }, (error) => {
      this.setState({uploading: false, error: 'dropfile.upload.error'})
    })
  }

  render() {
    if (this.state.uploading) {
      return <div className="dialogsContainer">
        <div className="dialog small frame">
          <LoadingPlaceholder/>
          <div className="buttons">
            <div className="button"
                 title={Translate.get('close')}
                 onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>
        </div>
      </div>
    }

    return (
      <div className="dialogsContainer">
        <div className="dialog small frame">
          <div className="title">
            <div className="icon iconBig file"/>
            <span>{Translate.get('dropfile.upload.title')}</span>
          </div>

          <form id="dropFileUploadForm"
                onSubmit={(event) => this.uploadFile(event)} encType="multipart/form-data">
            <input type="hidden" name="dropBoxId" value={this.props.dropBoxId}/>
            <input className="wide" type="file" name="dropFile"/>
            <textarea className="wide" name="note" placeholder={Translate.get('dropfile.upload.note.placeholder')}/>
          </form>

          <div className="buttons">
            <div className="button"
                 onClick={(event) => this.uploadFile(event)}
                 title={Translate.get('dropfile.upload.button.upload')}>
              <div className="icon iconBig ok"/>
            </div>
            <div className="button"
                 onClick={(event) => this.uploadFile(event, true)}
                 title={Translate.get('dropfile.upload.button.uploadAndNext')}>
              <div className="icon iconBig add"/>
            </div>
            <div className="button"
                 onClick={(event) => Dialogs.close(null)}
                 title={Translate.get('close')}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UploadDropFileDialog