import React from 'react'
import PreparedLines from './PreparedLines.js'
import Translate from '../costra/Translate.js'

class Cofidis {

  static collectionCaseResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('cofidis.result.caseRowsImported', data.caseRowsImported)}
        {PreparedLines.reportLine('cofidis.result.caseRowsSkipped', data.caseRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static collectionCasePaymentsResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('cofidis.result.paymentRowsImported', data.paymentRowsImported)}
        {PreparedLines.reportLine('cofidis.result.paymentRowsSkipped', data.paymentRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('cofidis.result.mismatchedPayments', data.mismatchedPayments, 'cancel')}
      </ul>
    </div>
  }

}

export default Cofidis