import React from 'react'
import Column from '../costra/Column.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import Metrics from '../utils/Metrics.js'
import StateIcons from '../utils/StateIcons.js'
import Nav from '../utils/Nav.js'

import {PageDataContext} from '../PageDataContext.js'

class CaseRecord {

  static daysHoursMinutes(number){
    const days = Math.floor(number / (24*60*60*1000));
    const daysms = number % (24*60*60*1000);
    const hours = Math.floor(daysms / (60*60*1000));
    const hoursms = number % (60*60*1000);
    const minutes = Math.floor(hoursms / (60*1000));
    const minutesms = number % (60*1000);
    const sec = Math.floor(minutesms / 1000);
    return {days: days,hours: hours,minutes: minutes, seconds: sec}
  }

  static sources = [
    {value: 'E4', title: 'sal.source.E4'},
    {value: 'E5', title: 'sal.source.E5'},
    {value: 'E6', title: 'sal.source.E6'},
    {value: 'SAP', title: 'sal.source.SAP'},
  ]
  
  static countInterval(item){
    const interval = CaseRecord.daysHoursMinutes((item.processing.finished? item.processing.finished : + new Date())  - item.processing.created)
    switch(true){
      case interval.days > 2 : return interval.days + " dnů"
      case interval.days === 1 : return interval.days + " den"
      case interval.days < 1 && interval.hours > 1 : return interval.hours + " hodin"
      case interval.days < 1 && interval.hours < 1 : return "Méně než 1 hodina"
      default: return interval.days
    }
  }

  static go(event, id, newTab) {
    Nav.go(event, '/case/' + id, newTab)
  }

  static click(event, props) {
    if (!props.disabled) {
      if (props.toggleSelected) {
        event.stopPropagation()
        props.toggleSelected(props.item.item, !props.isSelected)
      }
      else {
        CaseRecord.go(event, props.item.item._id.$oid)
      }
    }
  }

  static columns = Column.createMap([
    Column.element('icon', "case", <div className="icon case"/>),
    new Column('campaign', 'Kampaň', item =>
      <PageDataContext.Consumer>
        {pageData => pageData.campaigns[item.item.campaignId.$oid]}
      </PageDataContext.Consumer>,
      true
    ),
    Column.wrappedPrimitive('caseNo', 'Číslo smlouvy', true),
    new Column('stateIcons', 'Stav', (item) => <StateIcons item={item.item} />),
    new Column('processingGroup', Translate.get('processingGroup.short'),
      (item) => Translate.get('processingGroup.short.' + item.item.processing.processingGroup)),
    Column.wrappedDateTime('plannedEnding', 'Konec případu', '%d.%m. %yyyy %HH:%MM', true),
    Column.wrappedPrimitive('client.displayName', 'Klient', true),
    new Column('objects', 'Předmět', (item) => (item.objects && item.objects.length > 0 &&
      (item.objects.length > 1 ? ('Předmětů: ' + item.objects.length.toString()) : item.objects[0].name))),
    new Column('processing.maxDistance', 'Max. rozdíl adres [km]',
      (item) => item.item.processing.maxDistance && Number(item.item.processing.maxDistance).toFixed(2), true),
    new Column('processing.created', 'Začátek případu',
      (item) => item.item.processing.created && Formatter.formatDateTime('%d.%m. %yyyy %HH:%MM',item.item.processing.created), true),
    new Column("interval", "Doba zprávy",
      (item)=> CaseRecord.countInterval(item.item)),
    new Column("processing.payments.0.planned", "Celkem",
      (item)=> item.item.processing.payments &&
        Formatter.formatAmount(item.item.processing.payments[0].planned) + ' ' +  item.item.processing.payments[0].currency,
      true, 'alignRight'),
    new Column("processing.payments.0.missing", "Saldo",
      (item)=> item.item.processing.payments &&
        Formatter.formatAmount(item.item.processing.payments[0].missing) + ' ' + item.item.processing.payments[0].currency,
      true, 'alignRight'),
    new Column("processing.payments.0.payed", "Zaplaceno",
      (item)=> item.item.processing.payments &&
        Formatter.formatAmount(item.item.processing.payments[0].payed) + ' ' + item.item.processing.payments[0].currency,
      true, 'alignRight'),
    new Column("users", "Uživatelé", (item)=>
      item.item.processing.taskedUsers &&
        <PageDataContext.Consumer>
          {pageData =>
            item.item.processing.taskedUsers.map(user =>
              pageData.users[user.$oid] ? pageData.users[user.$oid].displayName : '?'
            ).join(', ')
          }
        </PageDataContext.Consumer>
    ),
  ])

  static defaultLayout = [
    {name: 'icon'},
    {name: 'campaign', extraClass: 'size3c'},
    {name: 'caseNo', extraClass: 'size3c'},
    {name: 'stateIcons', extraClass: 'size2c'},
    {name: 'plannedEnding', extraClass: 'size3c'},
    {name: 'client.displayName', extraClass: 'size6c'},
    {name: 'objects', extraClass: 'size6c'},
  ]

  static scoreColor(item) {
    let result = ''
    switch (item._class) {
      case 'com.optimsys.costra.kordex.caserecord.erl.pred.PredCase':
      case 'com.optimsys.costra.kordex.caserecord.sal.sf.SfCase':
        if(item.processing.score !== undefined)  {
          result = Metrics.ScoreColor(item.processing.score)
        }
        break;
      default: 
    }
    return result
  }

  static Row(props) {
    return <tr className={'row withHover ' + (Metrics.CheckStatus(props.item.state))}
               onClick={(event) => CaseRecord.click(event, props)}>
      <td className={Metrics.CheckStatus(props.item.state)}/>
      <td><div className={"ratingIcon " + CaseRecord.scoreColor(props.item.item? props.item.item : props.item)}/></td>
      {props.toggleSelected &&
        <td>
          <input type="checkbox"
                 disabled={props.disabled}
                 checked={props.isSelected}
                 onChange={(event) => CaseRecord.click(event, props)}/>
        </td>
      }
      {Column.render(CaseRecord.columns, props.layout, props.item)}
      <td >
        <div className="button"
            title="Otevřít v novém okně"
            onClick={(event) => CaseRecord.go(event, props.item.item._id.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      </td>
      
    </tr>
  }
}

export default CaseRecord
