import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import DropBoxExportDialog from './DropBoxExportDialog.js'

class ExportBatchExportRow extends Component {

  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  submit(event) {
    this.formRef.current.submit()
  }

  sendToDropBox(event) {
    event.stopPropagation()
    Dialogs.open(
      <DropBoxExportDialog campaignId={this.props.campaignId} exportData={this.props.exportData}/>
    )
  }

  render() {
    return <div className="row withHover" onClick={(event) => this.submit(event)}>
      <div><div className="icon export"/></div>
      <div className="size4c">
        {Translate.get(this.props.exportData._class)}
      </div>
      <div className="buttons">
        <div className="button"
             title={Translate.get('exports.button.sendToDropBox')}
             onClick={(event) => this.sendToDropBox(event)}>
          <div className="icon dropBox"/>
        </div>
      </div>

      <form action='/download/export'
            method="post"
            encType='multipart/form-data'
            target='_blank'
            ref={this.formRef}>
        {Object.entries(this.props.exportData).map((values, i) => {
          let name = values[0]
          let value = values[1]
          if(name !== this.props.exportData.displayName){
            return <input type='hidden' key={name + i} name={name} value={value.hasOwnProperty("$oid") ? value.$oid : value}/>
          }
          return ""
        })}
      </form>
    </div>
  }

}

export default ExportBatchExportRow