import React, { Component } from 'react';
import Formatter from './Formatter.js';

class DateTimeInput extends Component {

  static parseDateObject(dateStr) {
    // TODO parse using format

    var matches = dateStr.match(/(\d+).[ ]*(\d+).[ ]*(\d+)[ ]+(\d+):(\d+):(\d+)/)
    if (matches && matches.length > 0) {
      return new Date(matches[3], matches[2] - 1, matches[1], matches[4], matches[5], matches[6])
    }
    matches = dateStr.match(/(\d+).[ ]*(\d+).[ ]*(\d+)[ ]+(\d+):(\d+)/)
    if (matches && matches.length > 0) {
      return new Date(matches[3], matches[2] - 1, matches[1], matches[4], matches[5], 0)
    }
    matches = dateStr.match(/(\d+).[ ]*(\d+).[ ]*(\d+)/)
    if (matches && matches.length > 0) {
      return new Date(matches[3], matches[2] - 1, matches[1], 0, 0, 0)
    }
    // default
    return new Date(dateStr)
  }

  constructor(props) {
    super(props)
    var value = ""
    if (this.props.value) {
      value = Formatter.formatDateTime(this.props.format, this.props.value)
    }
    this.state = {
      localChange: false,
      valueStr : value,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.localChange && prevProps && prevProps.value !== this.props.value) {
      var value = ""
      if (this.props.value) {
        value = Formatter.formatDateTime(this.props.format, this.props.value)
      }
      this.setState({
        valueStr: value,
      })
    }
  }

  finalizeValue(event) {
    this.setState({localChange: false})
    var time = DateTimeInput.parseDateObject(this.state.valueStr).getTime()
    if (isNaN(time)) {
      this.props.onChange(null)
    }
    else {
      this.props.onChange({$numberLong: time.toString()})
    }
  }

  changeValue(value) {
    this.setState({valueStr: value, localChange: true})
    var time = DateTimeInput.parseDateObject(this.state.valueStr).getTime()
    if (isNaN(time)) {
      this.props.onChange(null)
    }
    else {
      this.props.onChange({$numberLong: time.toString()})
    }
  }

  render() {
    return <input type="text"
                  className={this.props.className}
                  placeholder={this.props.placeholder}
                  value={this.state.valueStr}
                  onChange={(event) => this.changeValue(event.target.value)}
                  onBlur={(event) => this.finalizeValue(event)}/>
  }
}

export default DateTimeInput