import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import '../../../css/form.css'

function CollectionProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection car" />
        <h3>{Translate.get('caseobject.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="name"
                  title='caseobject.properties.objectName'
                  validate={Validator.notEmptyString}/>
        <Form.Address data={props.component.data}
                      disabled={!props.editOpen}
                      path="address"
                      title='caseobject.properties.address'
                      caseIds={[props.component.data.get("caseId")]}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="manufacturer"
                  title='caseobject.properties.manufacturer'/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="licenseNumber"
                  title="caseobject.properties.licenseNo"/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="vin"
                  title='caseobject.properties.vin'
                  styling={Metrics.CheckVin(props.component.data.get("vin"),"Vin")}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="price"
                  title='caseobject.properties.purchasePrice'
                  validate={Validator.number}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="productionYear"
                  title='caseobject.properties.productionYear'/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="power"
                  title='caseobject.properties.power'/>
      </div>
    </div>

    {props.component.data.get('contactData') && props.component.data.get('contactData').map((contactData, index) =>
      <ContactData key={index}
                   data={props.component.data}
                   path={'contactData.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default CollectionProperties
