import SimpleListContainer from '../costra/SimpleListContainer.js'

class ListContainer extends SimpleListContainer {

  static initialState() {
    var params = SimpleListContainer.initialState()
    delete params.page
    return params
  }

  params() {
    var params = super.params()
    params.sortField = this.component.state.sortField
    params.sortOrder = this.component.state.sortOrder
    params.page = this.component.props.page ?? 1
    return params
  }

  sortBy(field) {
    var params = this.params()
    if (params.sortField === field) {
      params.sortOrder *= -1
    }
    else {
      params.sortOrder = -1
      params.sortField = field
    }
    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params: params,
    })
  }

  reload() {
    this.component.setState({items: null}, () => {
      super.reload()
    })
  }
}

export default ListContainer