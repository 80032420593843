import React, { Component } from 'react';
import EditData from './costra/EditData.js';
import Identity from './costra/Identity.js'
import Translate from './costra/Translate.js'
import WsContainers from './costra/WsContainers.js';

import './css/dialogs.css';
import Form from './utils/Form.js';

class UpdatePasswordDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
    }
    this.data = new EditData(this, '')
  }

  componentDidMount() {
    this.load()
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.FetchUser',
      id: this.props.userId,
    }).then((result) => {
      this.setState(result)
    }, () => {
      this.setState({error : Translate.get('update.password.error.load')})
    })
  }

  save() {
    if (!this.isPasswordOkay()) {
      return
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.UpdateExpiredPassword',
      item: this.state.item,
    }).catch((error) => {
      if(error === 'The new password matches the old one') {
        this.setState({error : Translate.get('update.password.error.match.old')})
      }
    })
  }

  getIssue() {
    if (!this.state.item) return ''
    else if (!this.state.item.password || this.state.item.password === '') return ''    
    else if (!this.testRegex(this.state.item.password)) return Translate.get('update.password.error.weak')
    else if (!this.testMatching(this.state.item.password, this.state.item.passwordCopy)) return Translate.get('update.password.error.match')
    else if (this.state.error) return this.state.error
    
    else return ''
  }

  testMatching(password, passwordCopy) {
    return password === passwordCopy
  }

  testRegex(password) {
    const regex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{10,}$')
    return regex.test(password)
  }

  isPasswordOkay() {
    let pass = this.state.item && this.state.item.password
    let pass2 = this.state.item && this.state.item.passwordCopy
    return this.testMatching(pass, pass2) && this.testRegex(pass) && !this.state.error
  }

  render() {
    return (
      <div className='flex-container'>
        <div className='small-page'>
          <div className='header'>
            <div className='left icon iconBig alert'></div>
            <h1>{Translate.get('update.password.title')}</h1>
          </div>
          <div className='content'>
            <div className='info'>
            {Translate.get('update.password.info')}
              <ul>
                <li><b>{Translate.get('update.password.info.1')}</b></li>
                <li><b>{Translate.get('update.password.info.2')}</b></li>
                <li><b>{Translate.get('update.password.info.3')}</b></li>
                <li><b>{Translate.get('update.password.info.4')}</b></li>
                <li><b>{Translate.get('update.password.info.5')}</b></li>
              </ul>
            </div>
            <Form.Password data={this.data}
              path='item.password'
              title={Translate.get('update.password.password')}
              check={(!this.testRegex(this.data.get('item.password')) && this.data.get('item.password') !== '' ? ' invalid' : '')}
            />
            <Form.Password data={this.data}
              path='item.passwordCopy'
              title={Translate.get('update.password.passwordCopy')}
              check={(!this.isPasswordOkay() && this.data.get('item.passwordCopy') ? ' invalid' : '')}
            />

            {!this.isPasswordOkay() && <div className='issue'>{this.getIssue()}</div>}

            <div className='buttons'>
              <div className='button save' onClick={() => this.save()}>{Translate.get('update.password.save')}</div>
              <div className='button okay' onClick={() => Identity.logout()}>{Translate.get('update.password.log.another')}</div>
            </div> 
          </div>

        </div>
      </div>
    )
  }
}

export default UpdatePasswordDialog