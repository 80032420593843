import React, { Component } from 'react';

class DialogRef {
  constructor(tag, successProc, failedProc) {
    this.tag = tag
    this.successProc = successProc
    this.failedProc = failedProc
  }
}

class Dialogs extends Component {

  static ref = null

  static open(tag) {
    if (!Dialogs.ref) {
      console.log("#Dialog The tag is not mounted.")
      return null
    }
    else {
      return new Promise((success, failed) => {
        Dialogs.ref.state.stack.push(new DialogRef(tag, success, failed))
        Dialogs.ref.setState({
          stack: Dialogs.ref.state.stack
        })
      })
    }
  }

  static close(result) {
    if (!Dialogs.ref) {
      console.log("#Dialog The tag is not mounted.")
    }
    else if (Dialogs.ref.state.stack.length > 0) {
      Dialogs.ref.state.stack[Dialogs.ref.state.stack.length - 1].successProc(result)
      Dialogs.ref.state.stack.splice(Dialogs.ref.state.stack.length - 1, 1)
      Dialogs.ref.setState({
        stack: Dialogs.ref.state.stack
      })
    }
    else {
      console.log("#Dialog There is no dialog to close.")
    }
  }

  static failure(result) {
    if (!Dialogs.ref) {
      console.log("#Dialog The tag is not mounted.")
    }
    else if (Dialogs.ref.state.stack.length > 0) {
      Dialogs.ref.state.stack[Dialogs.ref.state.stack.length - 1].failedProc(result)
      Dialogs.ref.state.stack.splice(Dialogs.ref.state.stack.length - 1, 1)
      Dialogs.ref.setState({
        stack: Dialogs.ref.state.stack
      })
    }
    else {
      console.log("#Dialog There is no dialog to close.")
    }
  }

  constructor() {
    super()
    this.state = {
      stack: [],
    }
  }

  componentDidMount() {
    if (Dialogs.ref) {
      console.log("#Dialog The dialog tag is being mounted multiple times.")
    }
    else {
      Dialogs.ref = this
    }
  }

  componentWillUnmount() {
    if (Dialogs.ref === this) {
      Dialogs.ref = null
    }
  }

  render() {
    /*
    if (this.state.stack.length <= 0) {
      return null
    }
    return this.state.stack[this.state.stack.length - 1].tag
    */
    return <div id='dialogs'>
      {this.state.stack.map((item, index) =>
        <div key={index} className={'dialogLevel' + index}>{item.tag}</div>
      )}
    </div>
  }

}

export default Dialogs