import React from 'react'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'
import Dialogs from '../costra/Dialogs.js'

import Nav from '../utils/Nav.js'
//import SortIcon from '../utils/SortIcon.js'

//import Item from '../addressbook/Item.js'
import CallDialDialog from './CallDialDialog.js'
//import SmsSendDialog from '../message/SmsSendDialog.js'

class CommLog {
  static callRecording(event, id) {
    Nav.go(event, '/download/recording?id=' + id + '&recIndex=0&type=wav', true)
  }

  static smsStates = () => [
    {value: 'Received', title: 'commlog.commlog.smsStates.Received'},
    {value: 'Pending', title: 'commlog.commlog.smsStates.Pending'},
    {value: 'Sent', title: 'commlog.commlog.smsStates.Sent'},
    {value: 'Delivered', title: 'commlog.commlog.smsStates.Delivered'},
    {value: 'Error', title:'commlog.commlog.smsStates.Error'},
  ]

  static mailStates = () => [
    {value: 'Sent', title: 'commlog.commlog.mailStates.Sent'},
    {value: 'Error', title: 'commlog.commlog.mailStates.Error'},
  ]

  static title(item) {
    switch (item._class) {
      case "com.optimsys.costra.sms.db.Sms":
        return 'SMS'
      default:
        return item._class
    }
  }

  static smsState(item) {
    var state = CommLog.smsStates.find(state => state.value === item.state)
    if (state) {
      return state.title
    }
    return item.state
  }

  static mailState(item) {
    var state = CommLog.mailStates.find(state => state.value === item.state)
    if (state) {
      return state.title
    }
    return item.state
  }

  static dialCall(event, dst) {
    event.stopPropagation()
    Dialogs.open(<CallDialDialog dst={dst}/>)
  }

  static activeCallState(state) {
    switch(state) {
      case 'Setup':
        return 'commlog.commlog.activeCallState.Setup'
      case 'Ringing':
        return 'commlog.commlog.activeCallState.Ringing'
      case 'Answered':
        return 'commlog.commlog.activeCallState.Answered'
      case 'Talking':
        return 'commlog.commlog.activeCallState.Talking'
      case 'Disconnected':
        return 'commlog.commlog.activeCallState.Disconnected'
      default:
        return ''
    }
  }

  static renderAnnotation(annotated) {
    if (!annotated || annotated.length <= 0) {
      return null
    }
    return <div className="row">
      <div className="flexRest"/>
      {annotated.map(item =>
        <div className="button right"
                    key={item.id.$oid}
                    title={item.title}
                    onClick={event => Nav.go(event, '/addressBook/' + item.id.$oid)}>
          <div className="icon addressBookItem"/>
          {item.title} ({item.activeCases})
        </div>
      )}
    </div>
  }

  // the comm log is now annotated only with address book items
  /*
  static renderAnnotation(annotated) {
    if (!annotated || annotated.length <= 0) {
      return null
    }
    return <div className="row">
      <div className="flexRest"/>
      {annotated.map(item => {
        switch(item.linkType) {
          case 'AddressBook':
            return <div className="button right"
                        key={item.id.$oid}
                        title={item.title}
                        onClick={event => Nav.go(event, '/addressBook/' + item.id.$oid)}>
              <div className="icon addressBookItem"/>
              {item.title}
            </div>
          case 'CaseRecord':
            return <div className="button right"
                        key={item.id.$oid}
                        title={item.title}
                        onClick={event => Nav.go(event, '/case/' + item.id.$oid)}>
              <div className="icon case"/>
            </div>
          case 'CaseObject':
            return <div className="button right"
                        key={item.id.$oid}
                        title={item.title}
                        onClick={event => Nav.go(event, '/caseObject/' + item.id.$oid)}>
              <div className="icon car"/>
            </div>
          default:
            return <div className="right" key={item.id.$oid}>{item.linkType}</div>
        }
      })}
    </div>
  }
  */

  static renderDialButton(props) {
    if (!props.canDialCall) {
      return null
    }
    return <div className="button right"
         title={Translate.get('task.button.dial')}
         onClick={event =>
          CommLog.dialCall(event, props.item.src.match(/^[0-9]{1,4}$/) ? props.item.dst : props.item.src)
         }>
      <div className="icon call"></div>
    </div>
  }

  static Row(props, expandOrHide) {
    switch (props.item._class) {
      case "com.optimsys.costra.sms.db.Sms":
        return <div className="row multiple-columns">
          <div className="row">
            <div><div className="icon sms" title="SMS"/></div>
            <div className="size3c">
              {Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM:%SS', props.item.time)}
            </div>
            <div className="size5c">
              {Formatter.phoneNumber(props.item.src, '+420')}
            </div>
            <div className="size5c">
              {' -> ' + Formatter.phoneNumber(props.item.dst, '+420')}
            </div>
            <div className="size5c">{Translate.get('sms.state.' + props.item.state)}</div>
            {CommLog.renderDialButton(props)}
          </div>
          {CommLog.renderAnnotation(props.item._annotation)}
          <div className="row">
            <div>
              <i className="shiftedMessageContainer">{props.item.text}</i>
            </div>
          </div>
        </div>

      case 'com.optimsys.costra.call.db.Call':
        return <div className="row multiple-columns">
          <div className="row">
            <div><div className="icon call" title="Hovor"/></div>
            <div className="size3c">
              {Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM:%SS', props.item.time)}
            </div>
            <div className="size5c">
              {Formatter.phoneNumber(props.item.src, '+420')}
            </div>
            <div className="size5c">
              {' -> ' + Formatter.phoneNumber(props.item.dst, '+420')}
            </div>
            <div className="size5c">
              {props.item.connectedTo && (' -> ' + props.item.connectedTo.map(number =>
                Formatter.phoneNumber(number , '+420')
              ).join(', '))}
            </div>
            <div className="size2c">
              {props.item.length.talking > 0 && Formatter.formatSeconds(props.item.length.talking)}
            </div>
            {CommLog.renderDialButton(props)}
            {props.markCallAsHandled && props.item.tags && props.item.tags.missedCall === '' &&
              <div className="button right"
                   title={Translate.get('commlog.item.button.markAsHandled')}
                   onClick={evt => props.markCallAsHandled(props.item)}>
                <div className="icon ok"></div>
              </div>
            }
          </div>
          {CommLog.renderAnnotation(props.item._annotation)}
        </div>

      case 'com.optimsys.costra.mail.db.Mail':
        return <div className='row multiple-columns'>
          <div className="row">
            <div><div className="icon email" title="Mail"/></div>
            <div className="size3c">
              {Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM:%SS', props.item.time)}
            </div>
            <div className="size5c">
              {props.item.src}
            </div>
            <div className="size5c">
              {' -> ' + (props.item.dst ? props.item.dst.join(', ') : '?')}
            </div>
            <div className="size2c">{Translate.get('mail.state.' + props.item.state)}</div>
            <div>{props.item.subject}</div>
            {props.item.attachments && props.item.attachments.map((attachment, index) =>
              <div className="button right"
                   key={index}
                   title={Translate.get('commlog.item.button.openAttachment', null, {name: attachment.info.fileName})}
                   onClick={evt => Nav.go(evt, '/download/mailAttachment?id=' + props.item._id.$oid + '&index=' + index, true)}>
                <div className="icon file"></div>
              </div>
            )}
            <div className="button right"
                 title={Translate.get('commlog.item.button.openText')}
                 onClick={evt => Nav.go(evt, '/download/mail?id=' + props.item._id.$oid, true)}>
              <div className="icon newWindow"></div>
            </div>
          </div>
          {CommLog.renderAnnotation(props.item._annotation)}
        </div>

      default:
        return <div>{props.item._class}</div>
    }
  }
}

export default CommLog