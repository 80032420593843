import React from 'react';
import Translate from '../costra/Translate';

function ErrorPlaceholder(props) {
  return <div className="placeholder">
    <div className='icon alert'/>
    <div className="text">{Translate.get(props.error ? props.error : 'genericLoadError')}</div>
    {props.reload &&
      <button onClick={props.reload}>{Translate.get('reload')}</button>
    }
  </div>
}

export default ErrorPlaceholder