import React, { Component } from 'react'
import Translate from '../costra/Translate'
import Task from '../tasks/Task'
import Flag from '../flags/Flag'

class StateIcons extends Component {

  render() {
    var activeTasks = this.props.item.processing.activeTasks &&
      Object.values(this.props.item.processing.activeTasks.reduce((a,c) => Object.assign(a, {[c.taskClass]:c}),{}))
    if (!this.props.item || !this.props.item.processing ) {
      return null
    }
    return (activeTasks || this.props.item.processing.flags ?
      <div className="icons">
        {activeTasks && activeTasks.map((task, index) =>
          <Task.Icon taskClass={task.taskClass} extraClass={this.props.extraClass} key={index}/>
        )}
        {this.props.item.processing.flags && this.props.item.processing.flags.map((flag, index) =>
          <Flag.Icon flag={flag} extraClass={this.props.extraClass} key={index}/>
        )}
      </div>
      :
      <div className="icons">
        <div className={'icon left ok ' + this.props.extraClass} title={Translate.get('utils.stateIcons.ok')}/>
      </div>
    )
  }
}

export default StateIcons