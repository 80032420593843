import React from 'react';
import Translate from '../costra/Translate';

function TextInputArray(props) {
  var list = props.data.get(props.path)
  return <div className="subsection">
    <div className="header-row">
      <div className={'icon iconSection ' + props.icon}/>
      <h3>{Translate.get(props.title)}</h3>
      {!props.disabled &&
        <div className="right button"
            onClick={(event) => props.data.addToArray(props.path, props.newValue())}>
          <div className="icon left add"/>
          {Translate.get('utils.textInputArray.button.add')}
        </div>
      }
    </div>
    <div className="content">
      {list && Object.keys(list).map((index) =>
        props.disabled ?
          <div key={index} className="formRow">
            <div className="input">{list[index]}</div>
          </div>
          :
          <div key={index} className="formRow">
            <input type="text"
                  className="formInput"
                  value={list[index]}
                  onChange={(event) => props.data.set(props.path + '.' + index, event.target.value)}/>
            <div className="button"
                title={Translate.get('remove')}
                onClick={(event) => props.data.removeFromArray(props.path, index)}>
              <div className="icon remove"/>
            </div>
          </div>
      )}
    </div>
  </div>
}

export default TextInputArray