import React from 'react'
import Formatter from './Formatter.js'
import ObjectHelper from './ObjectHelper.js'

class Column {

  constructor(name, title, fn, sortable, extraClass) {
    this.name = name
    this.title = title
    if (fn) {
      this.fn = fn
    }
    else {
      this.fn = (item) => ObjectHelper.get(item, this.name)
    }
    this.sortable = Boolean(sortable)
    this.extraClass = extraClass || ''
  }

  static primitive(name, title, sortable, extraClass) {
    return new Column(name, title, null, sortable, extraClass)
  }

  static select(name, title, values, extraClass) {
    return new Column(name, title, (item) => {
      var key = ObjectHelper.expand(ObjectHelper.get(item, name))
      if (!values) {
        return key
      }
      var value = values.find((value) => value.value === key)
      if (value && value.title) {
        return value.title
      }
      else return key
    }, extraClass)
  }

  static element(name, title, element, sortable, extraClass) {
    return new Column(name, title, (item) => element, sortable, extraClass)
  }

  static dateTime(name, title, format, sortable, extraClass) {
    return new Column(name, title, (item) => Formatter.formatDateTime(format, ObjectHelper.get(item, name)), sortable, extraClass)
  }

  static wrappedPrimitive(name, title, sortable, extraClass) {
    return new Column(name, title, (item) => ObjectHelper.get(item.item, name), sortable, extraClass)
  }

  static wrappedDateTime(name, title, format, sortable, extraClass) {
    return new Column(name, title, (item) => Formatter.formatDateTime(format, ObjectHelper.get(item.item, name)), sortable, extraClass)
  }

  static createMap(columns) {
    var result = {}
    columns.forEach((column) => {
      result[column.name] = column
    })
    return result
  }

  static render(columnsMap, layout, item) {
    return layout.map((field, i) => {
      let column = columnsMap[field.name]
      if (!column) {
        return null
      }
      return <td key={field.name + '-' + i}
                 className={column.extraClass + (field.extraClass ? ' ' + field.extraClass : '')}>{column.fn(item)}</td>
    })
  }
}

export default Column