import React, { Component } from 'react'
import EditData from '../costra/EditData.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'

import '../css/dialogs.css'
import Task from './Task.js'
import {PageDataContext} from  '../PageDataContext.js'

class TaskEditDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      task : ObjectHelper.copy(this.props.task),
      note: '',
      privateNote: '',
    }
    this.taskData = new EditData(this, 'task.')
    this.noteData = new EditData(this, '')
  }

  updateTask() {
    this.setState({working: true})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.UpdateTask',
      item: this.state.task,
      note: this.state.note,
      privateNote: this.state.privateNote,
    }).then((result) => {
      Dialogs.close(result)
    }, () => {
      this.setState({ error : 'task.taskInfo.updateError' })
    })
  }

  render() {
    if(this.state.working) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <LoadingPlaceholder/>
        </div>
      </div>
    }
    let users = Object.values(this.context.users).sort((a, b) => {
      if (a.isContractor !== b.isContractor) {
        return (a.isContractor ? 1 : -1)
      }
      return a.displayName.localeCompare(b.displayName)
    }).map(user => ({'title' : user.displayName, 'value' : user.id.$oid}))
    let promised = this.taskData.get('promised')
    return <div className="dialogsContainer">
      <div className="dialog wide frame">

        <div className='title'>
          <Task.Icon taskClass={this.props.task._class} extraClass="iconBig"/>
          <span>
            {Translate.get(this.props.task._class) + ' ' +
              Translate.get('task.form.casesCount', null, {count: this.state.task.caseIds ? this.state.task.caseIds.length : 0})}
          </span>
        </div>
        <div>
          <Form.Select data={this.taskData}
                       path="userId"
                       title='task.form.user'
                       enableFirstOption={true}
                       values={users}/>
          {this.taskData.get('address') &&
            <Form.Address data={this.taskData}
                          path="address"
                          title='contactData.address.title'
                          caseIds={this.state.task.caseIds}/>
          }
          {promised && promised.map((promised, i) =>
            <Form.Text key={i}
                       data={this.taskData}
                       path={'promised.' + i + '.amount'}
                       title={promised.currency}/>
          )}
          <Form.DateTime data={this.taskData}
                         path="deadlineAt"
                         title='task.form.deadlineAt'
                         withTime={true}
                         dontAllowClear={true}
                         format="%d.%m.%yyyy %HH:%MM"/>
        </div>

        <div className='title'>
          <div className="icon iconBig edit"/>
          <span>{Translate.get('task.form.notesTitle')}</span>
        </div>
        <div>
          <Form.TextArea data={this.noteData}
                         path="note"
                         title='task.form.note'/>
          <Form.TextArea data={this.noteData}
                         path="privateNote"
                         title='task.form.privateNote'/>
        </div>

        <div className="buttons spacedTop">
          <div className="button"
               title={Translate.get('apply')}
               onClick={() => this.updateTask()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

TaskEditDialog.contextType = PageDataContext

export default TaskEditDialog