import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import Campaign from '../campaigns/Campaign.js'

import MessageTemplate from './MessageTemplate.js'

import '../css/icons.css'
import '../css/form.css'
import Translate from '../costra/Translate.js'

class MessageTemplateEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      placeholders: null,
      editOpen : false,
    }
    this.data = new EditData(this, 'item.')
  }

  loadAdditional() {
    return WsContainers.action({
      _class: 'com.optimsys.costra.kordex.message.GetMessagePlaceholders',
    }).then((result) => {
      this.setState({placeholders: result.placeholders})
      return result
    }, () => {
      this.setState({error: 'message.templateEdit.placeholdersError' })
    })
  }

  create() {
    this.loadAdditional()
    this.setState({item: {
      displayName : '',
      subject : '',
      text : '',
      campaignId : this.props.campaignId,
      asSms : true,
      asMail : true,
    }, editOpen : true})
  }

  load() {
    this.loadAdditional()
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.message.FetchMessageTemplate',
      id : this.props.id,
    }).then((result) => {
      this.setState({item: result.item, editOpen : false})
      return result
    }, () => {
      this.setState({error: 'message.templateEdit.loadError'})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.message.UpdateMessageTemplate',
      item : this.state.item,
    }).then((result) => {
      if (this.props.id === result.item._id.$oid) {
        this.setState({item: result.item, editOpen : false})
      }
      else {
        MessageTemplate.go(null, result.item._id.$oid)
      }
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }

  componentDidMount() {
    if (this.props.id === 'new') {
      this.create()
    }
    else {
      this.load()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  addPlaceholderText(value) {
    var lastChar = ' '
    var text = this.state.item.text
    if (text.length > 0) {
      lastChar = text[text.length - 1]
    }
    if (lastChar !== '\n' && lastChar !== ' ') {
      text += ' '
    }
    text += value
    var item = this.state.item
    item.text = text
    this.setState({item: item})
  }

  remove(event) {
    MessageTemplate.remove(event, this.state.item).then((confirmed) => {
      if (confirmed) {
        Campaign.go(event, this.state.item.campaignId)
      }
    })
  }

  renderProps() {
    if (!this.state.item || !this.state.placeholders) {
      return <LoadingPlaceholder/>
    }
    return <div className="section">
      <div className="listHeader">
        <h2>
          <div className="icon messageTemplate"/>
          {Translate.get('message.templateEdit.h2')}
        </h2>
        {this.state.editOpen && this.props.id !== 'new'  &&
          <div className="button right"
               onClick={(event) => this.remove(event)}
               title={Translate.get('message.templateEdit.button.remove')}>
            <div className="icon left remove"/>
            {Translate.get('message.templateEdit.button.remove')}
          </div>
        }
        <EditButtons component={this}/>
      </div>

      <div className="content">
        <Form.Text data={this.data}
                   disabled={!this.state.editOpen}
                   path="displayName"
                   title='message.templateEdit.form.displayName'/>
        <div className="subSection">
          <Form.Check data={this.data}
                      disabled={!this.state.editOpen}
                      path="asSms"
                      title='message.templateEdit.form.asSms'/>
          <Form.Check data={this.data}
                      disabled={!this.state.editOpen}
                      path="asMail"
                      title='message.templateEdit.form.asMail'/>
        </div>
      </div>

      {this.state.placeholders.map(group =>
        <div className="formRow fullLength">
          {group.placeholders.map(placeholder =>
            <div className="button"
                 key={placeholder.name}
                 onClick={(event) => this.addPlaceholderText(placeholder.value)}>
              <div className="icon edit left"/>
              <span className="left">{placeholder.title}</span>
            </div>
          )}
        </div>
      )}

      <div className="formRow fullLength">
        <textarea placeholder={Translate.get('message.templateEdit.textarea.placeholder')}
                  value={this.state.item.text}
                  onChange={(event) => this.data.set('text', event.target.value)}/>
      </div>
    </div>
  }

  render() {
    return (
      <div className="mainPanel">
        <div className="header">
          <div className="row">
            <div className="left icon iconBig messageTemplate"/>
            {this.props.id === 'new' ?
              <h1>{Translate.get('message.templateEdit.h1.newTemplate')}</h1>
              :
              this.state.item && <h1>{this.state.item.displayName}</h1>
            }
          </div>
        </div>
        <div className="scrollable">
          <div className="content">
            {this.renderProps()}
          </div>
        </div>
      </div>
    )
  }
}

export default MessageTemplateEdit
