import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Table from '../utils/Table.js'

import Task from './Task'
import {PageDataContext} from  '../PageDataContext.js'
import CaseRecord from '../caserecord/CaseRecord.js'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen: false,
      error: null,
      caseLayout: ObjectHelper.copy(CaseRecord.defaultLayout),
    }
    this.data = new EditData(this, 'item.')
    this.toggleCaseSelected = this.toggleCaseSelected.bind(this)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.FetchTask',
      id: this.props.id,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'caserecord.propertiesEdit.loadError' })
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.UpdateTask',
      item: this.state.item,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  toggleCaseSelected(item, selected) {
    this.data.toggleIdInArray('caseIds', item._id, selected)
  }

  toggleSelectAll(selected) {
    if (!selected) {
      this.data.set('caseIds', [])
    }
    else if (this.state.caseRecords) {
      var list = this.state.caseRecords.filter(caseRecord =>
        !caseRecord.item.processing.finished
      ).map(caseRecord =>
        caseRecord.item._id
      )
      this.data.set('caseIds', list)
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="section spacedTop"><LoadingPlaceholder /></div>
    }
    if (this.state.error) {
      return <div className="section spacedTop">{Translate.get(this.state.error)}</div>
    }

    let users = Object.keys(this.context.users).map(id => {
        return {value: id, title: this.context.users[id].displayName}
    }).sort((a, b) => a.title.localeCompare(b.title))

    return <div className="section spacedTop">
      <div className="buttons">
        <EditButtons component={this}/>
      </div>
      <div className="subsection">
        <div className="header-row">
          <Task.Icon taskClass={this.state.item._class} extraClass="iconSection"/>
          <h3>{Translate.get(this.state.item._class)}</h3>
        </div>

        <div className="content">
          <Form.Select data={this.data}
                       disabled={!this.state.editOpen}
                       path="userId"
                       title='task.properties.user'
                       values={users} />
          {this.state.item.address &&
            <Form.Address data={this.data}
                          disabled={!this.state.editOpen}
                          path="address"
                          title='contactData.address.title'
                          caseIds={this.state.item.caseIds}/>
          }
          <Form.DateTime data={this.data}
                         disabled={!this.state.editOpen}
                         path="deadlineAt"
                         withTime={true}
                         title='task.properties.deadlineAt'
                         format="%d.%m.%yyyy %HH:%MM" />
          <Form.DateTime data={this.data}
                         disabled={true}
                         path="createdAt"
                         withTime={true}
                         title='task.properties.createdAt'
                         format="%d.%m.%yyyy %HH:%MM" />
          <Form.DateTime data={this.data}
                         disabled={true}
                         path="discardedAt"
                         withTime={true}
                         title='task.properties.discardedAt'
                         format="%d.%m.%yyyy %HH:%MM" />
        </div>
      </div>

      <div className="subsection">
        <div className="header-row">
          <div className='icon iconSection case'/>
          <h3> {Translate.get('task.properties.relatedCases.title')} </h3>
        </div>
      </div>

      <Table name="taskCasesLayout"
             withSemaphore={true}
             items={this.state.caseRecords}
             layout={this.state.caseLayout}
             columns={CaseRecord.columns}
             updateLayout={(layout) => this.setState({caseLayout : layout})}
             toggleSelectAll={this.toggleSelectAll}
             disabled={!this.state.editOpen}
             itemFn={(item, index, key) =>
                <CaseRecord.Row layout={this.state.caseLayout}
                                item={item}
                                key={key}
                                isSelected={this.data.isIdInArray('caseIds', item.item._id)}
                                toggleSelected={this.toggleCaseSelected}
                                disabled={!this.state.editOpen}/>
             }/>

    </div>
  }
}
Properties.contextType = PageDataContext

export default Properties