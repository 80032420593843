import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import WatchedItemContainer from '../utils/WatchedItemContainer.js'

import EntityChangeLog from '../changelog/EntityChangeLog.js'
import DropFiles from '../dropfile/DropFiles.js'
import { PageDataContext } from '../PageDataContext.js'
import Properties from './Properties.js'

import '../css/icons.css'
import '../css/form.css'

class DropBoxEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item : null,
      tab: 'files',
    }
    this.container = new WatchedItemContainer(this, 'com.optimsys.costra.kordex.dropbox.DropBoxContainer')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.load(this.props.id)
    }
    else {
      this.setState({item: null, tab: 'props'})
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  renderTabs() {
    switch(this.state.tab) {
      case "log":
        return <EntityChangeLog id={this.props.id} item={this.state.item}/>
      case "files":
        return <DropFiles id={this.props.id}/>
      default:
        return <Properties id={this.props.id} campaignId={this.props.campaignId}/>
    }
  }

  render() {
    return <div className="mainPanel withTabs">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig dropBox"/>
          {this.props.id === 'new' &&
            <h1>{Translate.get('dropbox.new.title')}</h1>
          }
          {this.state.item &&
            <h1>{this.state.item.name} ({this.context.campaigns[this.state.item.campaignId.$oid]})</h1>
          }

          {this.state.item && this.state.item.processing &&
            <div className="values">
              {this.state.item.processing.unprocessed}/{this.state.item.processing.files}
            </div>
          }
        </div>
        {this.props.id !== 'new' &&
          <div className="row">
            <div className="tabs">
              <div className={'tab' + (this.state.tab === 'props' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'props'})}>{Translate.get('dropbox.tab.props')}</div>
              <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'files'})}>{Translate.get('dropbox.tab.files')}</div>
              {this.state.item && this.state.item.allowEdit &&
                <div className={'tab' + (this.state.tab === 'log' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'log'})}>{Translate.get('dropbox.tab.log')}</div>
              }
            </div>
          </div>
        }
      </div>
      <div className="scrollable">
        <div className="content">{this.renderTabs()}</div>
      </div>
    </div>
  }

}
DropBoxEdit.contextType = PageDataContext

export default DropBoxEdit
