import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js';
import Router from '../costra/Router.js';
import Formatter from '../costra/Formatter.js';
import Translate from '../costra/Translate.js';

import '../css/dialogs.css';

class NewObjectDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      caseObjects : null,
      error : null,
    }
  }

  componentDidMount() {   
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caseobject.PrepareForCreation' ,
      caseId: this.props.id,
    }).then((result) => {
      this.setState({caseObjects: result.caseObjects})
    }, () => {
      this.setState({error: Translate.get('caseobject.newObject.error')})
    })
  }
  
  addObject(item) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caseobject.UpdateObject',
      item: item,
    }).then((result) => {
      Dialogs.close(Router.go('/caseObject/' + result.item._id.$oid))
    }, () => {
      this.setState({error: Translate.get('caseobject.newObject.error')})
    })
  }

  render() {
    return (
        <div className="dialogsContainer">
          <div className="dialog wide frame">
            <div className="title">
              <div className="icon iconBig car"/>
              <span>{Translate.get('caseobject.preparedObjects')}</span>
            </div>
            <ul className="list spacedBottom">
              {this.state.caseObjects && this.state.caseObjects.map((item, index) => 
                <li key={index}>
                  <div className='row withHover alternative' onClick={() => this.addObject(item)}>
                    <div ><span>{Translate.get('caseobject.objectType')}</span> {Translate.get(item._class)}</div>
                    {item.address &&
                      <div ><span>{Translate.get('contactData.address.title')}</span> {Formatter.address(item.address)}</div>
                    }
                  </div>
                </li>
              )}
            </ul>
            <div className="buttons">              
              <div className="button"
                   title={Translate.get('close')}
                   onClick={(event) => Dialogs.close(null)}>
                <div className="icon iconBig cancel" />
              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default NewObjectDialog