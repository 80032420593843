import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'
import Input from '../utils/Input.js'
import '../css/dialogs.css';

class SmsSendDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dst: props.dst,
      msg: '',
      error: null,
      working: true,
    }
    this.data = new EditData(this, '')
  }

  componentDidMount() {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.comms.GetSmsSenders',
    }).then((result) => {
      result.working = false
      if (result.senders) {
        result.availableSenders = result.senders.map((sender, index) => {
          return {
            title: sender.displayName,
            value: index,
          }
        })
      }
      this.setState(result)
    }, (error) => {
      this.setState({working: false, error: error})
    })
  }

  sendSms() {
    this.setState({working: true})
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.comms.SendSms',
      src: this.state.senders[this.state.senderIndex].port,
      dst: this.state.dst,
      msg: this.state.msg,
    }).then((result) => {
      Dialogs.close(null)
    }, (error) => {
      this.setState({working: false, error: error})
    })
  }

  renderContent() {
    if (this.state.error) {
      return <div>{Translate.get('comms.sendSms.error')}</div>
    }
    if (this.state.working) {
      return <LoadingPlaceholder/>
    }
    return <>
      <Form.Text data={this.data}
                 path="dst"
                 title='comms.sendSms.dst'/>
      <Form.Select data={this.data}
                   path="senderIndex"
                   values={this.state.availableSenders}
                   title='comms.sendSms.src'/>
      <div className="formRow">
        <Input.TextArea data={this.data}
                        path="msg"
                        title='comms.sendSms.msg'/>
      </div>
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig call"/>
          <span>{Translate.get('comms.sendSms.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          {!this.state.working && !this.state.error &&
            <div className="button" onClick={(event) => this.sendSms()}>
              <div className="icon iconBig ok"/>
            </div>
          }
          <div className="button" onClick={(event) => Dialogs.close(false)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default SmsSendDialog
