import React from 'react'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'
import '../css/icons.css'

import CommLogList from './CommLogList.js'

function renderContent(props) {
  switch (props.tab) {
    case 'new':
      return <CommLogList page={props.page} preset="NewOnly"/>
    default:
      return <CommLogList page={props.page}/>
  }
}

function CommLogSection(props) {
  return <div className="mainPanel">
    <div className="header">
      <div className="row">
        <div className="left icon iconBig communication"/>
        <h1>{Translate.get('commlog.header.title')}</h1>
      </div>
    </div>
    <div className="scrollable">
      <div className='subMenu'>
        <div className="row">
          <div className="tabs">
            <div className={'tab' + (!props.tab ? ' active' : '')}
                 onClick={(event) => Nav.go(event, '/commLog')}>{Translate.get('commlog.preset.default')}</div>
            <div className={'tab' + (props.tab === 'new' ? ' active' : '')}
                 onClick={(event) => Nav.go(event, '/commLog/new')}>{Translate.get('commlog.preset.new')}</div>
          </div>
        </div>
      </div>
      <div className="content">
        {renderContent(props)}
      </div>
    </div>
  </div>
}

export default CommLogSection