import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import '../css/list.css'
import '../css/icons.css'
import '../css/form.css'

function Payment(props) {
  return <div className="row">
    <div><div className="icon coins" /></div>
    <div className="size4c">{Formatter.formatDateTime("%d.%m. %yyyy", props.payment.time)}</div>
    <div className="size6c">{props.payment.id}</div>
    <div className="size4c">{Formatter.formatAmount(props.payment.amount) + ' ' + props.payment.currency}</div>
    <div>{props.payment.note}</div>
  </div>
}

class ReceivedPayments extends Component {

  constructor(props) {
    super(props)
    this.state = {
      received: null,
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.paymentplan.GetReceivedPayments',
      planId: this.props.planId,
    }).then((result) => {
      this.setState({
        received: result.received || [],
      })
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.planId !== this.props.planId) {
      this.load()
    }
  }

  toggleItemExpanded(index) {
    var expanded = this.state.expanded
    expanded[index] = !expanded[index]
    this.setState({ expanded: expanded })
  }

  render() {
    if (!this.state.received) {
      return <LoadingPlaceholder />
    }
    return <ul className="list">
      <li>
        <div className="row rowHeader">
          <div className="icon-placeholder" />
          <div className="size4c">{Translate.get('paymentplan.received.date')}</div>
          <div className="size6c">{Translate.get('paymentplan.received.id')}</div>
          <div className="size4c">{Translate.get('paymentplan.received.amount')}</div>
        </div>
      </li>
      {this.state.received.map((payment, index) =>
        <li key={index}><Payment payment={payment}/></li>
      )}
    </ul>
  }
}

export default ReceivedPayments
