import React, { Component } from 'react'
import Formatter from '../costra/Formatter'

class SkWcGuide extends Component {

  constructor(props) {
    super(props)
    this.state = {
      history: ['beginning'],
      callState : 'beginning',
      lang : 'sk'
    }
  }

  prevPage() {
    if(this.state.history.length > 1) {
      this.setState({history: this.state.history.slice(0, -1)}, () => this.setState({callState: this.state.history[this.state.history.length -1]}))
    }
  }

  contactDialog() {
    return <input onChange={(e) => (this.setState({cotact : e.target.value}))}/>
  }

  getContent(callState) {
    switch (callState) {
      case 'beginning' : return this.beginning()
      case 'welcomeSkCheckIdentity' : return this.welcomeSkCheckIdentity()
      case 'welcomeSkNoIdentity' : return this.welcomeSkNoIdentity()
      case 'welcomeSkCheckTime' : return this.welcomeSkCheckTime()
      case 'welcomeSkNoTime' : return this.welcomeSkNoTime()
      case 'welcomeSkNoContract' : return this.welcomeSkNoContract()
      case 'welcomeSkNoContractConfirmed' : return this.welcomeSkNoContractConfirmed()
      case 'welcomeSkCheck1' : return this.welcomeSkCheck1()
      case 'welcomeSkCheck2' : return this.welcomeSkCheck2()
      case 'welcomeSkCheck2Fail' : return this.welcomeSkCheck2Fail()
      case 'welcomeSkCheck3' : return this.welcomeSkCheck3()
      case 'welcomeSkCheck3Fail' : return this.welcomeSkCheck3Fail()
      case 'welcomeSkTravelCard' : return this.welcomeSkTravelCard()
      case 'welcomeSkEnd' : return this.welcomeSkEnd()
      case 'end' : return this.save()

      default : return this.save()
    }
  }

  save() {
    alert('save')
  }

  getAddress() {
    alert('address')
  }

  render() {
    return <div className="guide">
      <div className="guide-header">
        <div className="previous" onClick={(e) => this.prevPage()}>Krok spět</div>
        <div className="language" onClick={(e) => this.setState({lang: this.state.lang === "sk" ? "cz" : "sk"})}>{(this.state.lang === "sk" ? "cz" : "sk").toUpperCase()}</div>
        <div className="actual-page">{this.state.history.length}</div>
      </div>
      <div className="content">{this.getContent(this.state.callState)}</div>
    </div>
  }

  /**********************************************************************/
  beginning() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
           Dobrý deň, <b>(vaše meno)</b>, agentúra KORDEX, v zastúpení spoločnosti sAutoleasing SK.
        </p>
        : <p>
          Dobrý den, <b>(vaše jméno)</b>, agentura KORDEX, v zastoupení společnosti sAutoleasing SK.
        </p>
      }
      {this.state.lang === "sk" ? 
        this.props.item.client.companyId ? <p>
          Dovolal/a som sa do spoločnosti <b> {this.props.item.client.displayName}</b>?
        </p> : <p>
          Hovorím s pánom / pani <b> {this.props.item.client.displayName}</b>?
        </p>
        // CZ
        : this.props.item.client.companyId ? <p>
            Dovolal/a jsem se do společnosti <b> {this.props.item.client.displayName}</b>?
          </p> : <p>
          Hovořím s panem / paní <b> {this.props.item.client.displayName}</b>?
        </p>
      }
      <div className="options">
        {this.props.item.client.personalId && <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheckIdentity', history : [...this.state.history, "welcomeSkCheckIdentity"]})}>Ano</div>}
        {!this.props.item.client.personalId && <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheckTime', history : [...this.state.history, "welcomeSkCheckTime"]})}>Ano</div>}
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeSkNoIdentity', history : [...this.state.history, "welcomeSkNoIdentity"]})}>Ne</div>
      </div>
    </>
  }

  welcomeSkCheckIdentity() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Môžete nám, prosím, pre overenie uviesť rok Vášho narodenia?
        </p>
        : <p>
          Pro ověření vás poprosím o rok vašeho narození.
        </p>
      }

      {this.state.lang === "sk" ? 
        <p>
          <b>Rodné číslo: {this.props.item.client.personalId}  Správna odpoveď? </b>
        </p>
        : <p>
          <b>Rodné číslo: {this.props.item.client.personalId}  Správná odpověď? </b>
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheckTime', history : [...this.state.history, "welcomeSkCheckTime"]})}>Ano</div>
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeSkNoIdentity', history : [...this.state.history, "welcomeSkNoIdentity"]})}>Ne</div>
      </div>
    </>
  }

  welcomeSkNoIdentity() {
    return <>
      <div className="instructions">
        {this.state.lang === "sk" ? 
        <p>
          <b>Hovor nemôže pokračovať bez overenia identity. Je potrebné získať správny kontakt na klienta a naplánovať nový hovor.</b>
        </p>
        : <p>
          <b>Hovor nemůže pokračovat bez ověření identity. Je třeba získat správný kontakt na klienta a naplánovat nové volání.</b>
        </p>
      }
      </div>
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'end', history : [...this.state.history, "end"]})}>Ok</div>
      </div>
    </>
  }

  welcomeSkCheckTime() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Dovoľujeme si Vás kontaktovať vo veci Vašej úverovej zmluvy, ktorú ste uzavreli prostredníctvom spoločnosti
          sAutoleasing SK. Máte prosím čas 2 minúty pre overenie správnosti údajov?
        </p>
        : <p>
          Dovoluji si Vás kontaktovat ve věci Vaší úvěrové smlouvy, kterou jste uzavřel/a prostřednictvím společnosti
          sAutoleasing SK. Máte prosím 2 minuty čas na ověření správnosti vašich údajů?
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheck1', history : [...this.state.history, "welcomeSkCheck1"]})}>Ano</div>
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeSkNoTime', history : [...this.state.history, "welcomeSkNoTime"]})}>Ne</div>
        <div className="c" onClick={(e) => this.setState({callState : 'welcomeSkNoContract', history : [...this.state.history, "welcomeSkNoContract"]})}>Smlouva neexistuje</div>
      </div>
    </>
  }

  welcomeSkNoTime() {
    return <>
      <div className="instructions">
        {this.state.lang === "sk" ? 
        <p>
          <b>Je potrebné naplánovať nový hovor.</b>
        </p>
        : <p>
          <b>Je třeba naplánovat nové volání.</b>
        </p>
      }
      </div>
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'end', history : [...this.state.history, "end"]})}>Konec</div>
      </div>
    </>
  }

  welcomeSkNoContract() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Vážený pán/Vážená pani, chceme Vás upozorniť, že dňa
          <b>{Formatter.formatDateTime('dd.MM. yyyy', this.props.item.signatureDate)}</b>
          bola podpisaná zmluva na kúpu
          <b>{this.props.object.vehicleInfo.name}</b>
          v obstarávacej cene
          <b>{this.props.object.vehicleInfo.purchasePrize} EUR</b>
          u dodávateľa
          <b>
            {this.props.object.distributor.displayName}
            {this.getAddress(this.props.object.distributor).street}
            {this.getAddress(this.props.object.distributor).city}
          </b>.
        </p>
        : <p>
          Vážený Páne/Paní, musím Vás upozornit, že dne
          <b>{Formatter.formatDateTime('dd.MM. yyyy', this.props.item.signatureDate)}</b>
          byla podepsána smlouva na koupi
          <b>{this.props.object.vehicleInfo.name}</b>
          s pořizovací cenou
          <b>{this.props.object.vehicleInfo.purchasePrize} EUR</b>
          u dodavatele
          <b>
            {this.props.object.distributor.displayName}
            {this.getAddress(this.props.object.distributor).street}
            {this.getAddress(this.props.object.distributor).city}
          </b>.
        </p>
      }
      {this.state.lang === "sk" ? 
        <p>
         Skutočne ste túto úverovú zmluvu neuzavreli?
        </p>
        :
        <p>
          Skutečně jste tuto úvěrovou smlouvu neuzavřel/a?
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheck1', history : [...this.state.history, "welcomeSkCheck1"]})}>V pořádku</div>
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeCallCheck2No', state: 'PVK_O6', history : [...this.state.history, "welcomeSkNoContractConfirmed"]})}>Smlouva neexistuje</div>
      </div>
    </>
  }

  welcomeSkNoContractConfirmed() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
         Doporučujeme, aby ste kontaktovali telefónnu linku
          <b>02/48 62 98 00</b>
          a túto skutočnosť oznámili. Na tejto linke Vám poradia ako postupovať ďalej, aby
          Vám nevznikla žiadna škoda. Ďakujeme za Váš čas a prajeme príjemný deň/večer.
        </p>
        :
        <p>
          Doporučím vám, abyste zavolal/a na telefonní číslo
          <b>02/48 62 98 00</b>
          a tuto skutečnost oznámil/a. Na tomto čísle Vám poradí, jak postupovat v dalších krocích tak, aby
          Vám nevznikla nějaká škoda. Děkuji za váš čas a přeji Vám příjemný den/večer.
        </p>
      }
     <div className="instructions">
        {this.state.lang === "sk" ? 
        <p>
          <b>Je potrebné informovať sAutoleasing SK na adrese <a href="mailto:info@sautoleasing.sk">info@sautoleasing.sk</a>
          s upozornením na možný úverový podvod.<br/>
          Stav bol zmenený na PVK_06 - PODOZRENIE NA PODVOD</b>
        </p>
        : 
        <p>
          <b> Je třeba informovat sAutoleasing SK na adrese <a href="mailto:info@sautoleasing.sk">info@sautoleasing.sk</a>
          s upozorněním na možný úvěrový podvod.<br/>
          Stav byl změněn na PVK_06 - PODOZRENIE NA PODVOD</b>
        </p>
      }
      </div>
      <div className="options">
          <div className="a" onClick={(e) => this.setState({callState : 'end', history : [...this.state.history, "end"]})}>Ok</div>
      </div>
    </>
  }

  welcomeSkCheck1() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
         1) Viete mi, prosím, potvrdiť typ vozidla, ktoré ste kúpili?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
        :
        <p>
          1) Můžete mi prosím potvrdit typ vozu, který jste koupil/a?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
      }
      {this.state.lang === "sk" ? 
        <p>
         2) Viete mi uviesť, u ktorého predajcu ste vozidlo kupovali?
            <b>
            ({this.props.object.distributor.displayName})
            ({this.getAddress(this.props.object.distributor).street})
            ({this.getAddress(this.props.object.distributor).city})
            </b>
        </p>
        :
        <p>
          2) U kterého prodejce jste vozidlo pořídil/a?
          <b>
            ({this.props.object.distributor.displayName})
            ({this.getAddress(this.props.object.distributor).street})
            ({this.getAddress(this.props.object.distributor).city})
          </b>
        </p>
      }
      {this.state.lang === "sk" ? 
        <p>
          3) Pamätáte si výšku obstarávacej ceny vozidla?
          <b>({this.props.object.vehicleInfo.purchasePrize} EUR)</b>
        </p>
        :
        <p>
           3) Pamatate si pořizovací cenu vozidla?
           <b>({this.props.object.vehicleInfo.purchasePrize} EUR)</b>
        </p>
      }
      {this.state.lang === "sk" ? 
        <p>

         4) Obdržali ste kópiu úverovej zmluvy a splátkový kalendár?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
        :
        <p>
          4) Obdržel/a jste kopii úvěrové smlouvy a splátkový kalendář?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheck2', history : [...this.state.history, "welcomeSkCheck2"]})}>Další</div>
      </div>
    </>
  }

  welcomeSkCheck2() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Dostali ste už všetky doklady potrebné na prepis vozidla?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
        :
        <p>
          Obdržel/a jste už všechny doklady potřebné pro přepis vozidla?
          <b>({this.props.object.vehicleInfo.name})</b>
        </p>
      }
      {this.state.lang === "sk" ? 
        <ul>
          <li>originál odhláseného Osvedčenia o evidencii vozidla</li>
          <li>výpis z Obchodného registra a splnomocnenie na prepis (len FOP/PO)</li>
        </ul>
        :
        <ul>
          <li>originál odhlášeného Potvrzení o evidenci vozidla</li>
          <li>výpis z Obchodního rejstříku a zplnomocnění pro přepis (jen FOP/PO)</li>
        </ul>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState : 'welcomeSkCheck3', history : [...this.state.history, "welcomeSkCheck3"]})}>Ano</div>
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeSkCheck2Fail', history : [...this.state.history, "welcomeSkCheck2Fail"]})}>Ne</div>
      </div>
    </>
  }

  welcomeSkCheck2Fail() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Kontaktujte, prosím, čo najskôr predajcu, aby Vám nevznikli sankcie kvôli oneskorenému prepisu vozidla.
          Vozidlo musí byť prepísané do 30 dní od podpisu úverovej zmluvy.
        </p>
        :
        <p>
          Kontaktujte prosím co nejrychleji prodejce, aby vám nevznikly sankce kvůli pozdnímu přepisu vozidla.
          Vozidlo musí být přepsáno do 30ti dní od podpisu úvěrové smlouvy.
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({travelCardJmeno: this.props.item.client.displayName,
           travelCardUlice: this.getAddress(this.props.item.client).street,
           travelCardMesto: this.getAddress(this.props.item.client).city,
           travelCardPsc:  this.getAddress(this.props.item.client).postCode,
           callState : 'welcomeSkTravelCard',
           history : [...this.state.history, "welcomeSkTravelCard"]})}>Ano</div>
      </div>
    </>
  }

  welcomeSkCheck3() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Už ste nechali vozidlo prepísať? Pripomínam Vám, že je potrebné poštou alebo emailom zaslať fotokópiu nového Osvedčenia o evidencii
          vozidla časť II. (velký technický preukaz), v ktorom:
        </p>
        :
        <p>
          Už jste nechal/a vozidlo přepsat? Připomenu, že je třeba poštou nebo mailem zaslat fotokopii nového
          Potvrzení o evidenci vozidla část II. (velký technický průkaz), ve kterém:
        </p>
      }
      {this.state.lang === "sk" ? 
        <ul>
          <li>(FON) ste uvedený ako vlastník a držiteľ vozidla</li>
          <li>(FOP/PO) je spoločnosť sAutoleasing SK uvedená ako vlastník a vy ako držiteľ vozidla</li>
        </ul>
        :
        <ul>
          <li>(FON) jste uveden/a jako vlastník a držitel vozidla</li>
          <li>(FOP/PO) je společnost sAutoleasing SK uvedena jako vlastník a vy jako držitel vozidla</li>
        </ul>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({
           travelCardJmeno: this.props.item.client.displayName,
           travelCardUlice: this.getAddress(this.props.item.client).street, // TODO firstAddress?
           travelCardMesto: this.getAddress(this.props.item.client).city,
           travelCardPsc:  this.getAddress(this.props.item.client).postCode,
           callState : 'welcomeSkTravelCard',
           history : [...this.state.history, "welcomeSkTravelCard"]})}>Ano</div>
        <div className="b" onClick={(e) => this.setState({callState : 'welcomeSkCheck3Fail', history : [...this.state.history, "welcomeSkCheck3Fail"]})}>Další...</div>
      </div>
    </>
  }

  welcomeSkCheck3Fail() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Zrealizujte, prosím, prepis vozidla čo najskôr, aby Vám nehrozili sankcie.
        </p>
        :
        <p>
          Proveďte prosím přepis vozidla co nejdříve, aby Vám nehrozily sankce.
        </p>
      }
      <div className="options">
        <div className="a" onClick={(e) => this.setState({
           travelCardJmeno: this.props.item.client.displayName,
           travelCardUlice: this.getAddress(this.props.item.client).street,
           travelCardMesto: this.getAddress(this.props.item.client).city,
           travelCardPsc:  this.getAddress(this.props.item.client).postCode,
           callState : 'welcomeSkTravelCard',
           history : [...this.state.history, "welcomeSkTravelCard"]})}>Ano</div>
      </div>
    </>
  }

  welcomeSkTravelCard() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Chcem Vás tiež informovať, že s uzatvorením úverovej zmluvy ste získali jedinečnú bonusovú kartu
          Travel Card, s ktorou máte nárok na neobmedzený počet ubytovaní na 1-6 nocí pre 2 osoby zdarma,
          podmienkou je len úhrada polpenzie. K dispozícii máte cca 1600 hotelov v 18 krajinách.
          Túto kartu môžete aj darovať a ten kto ju aktivuje, má právo ju používať po celú dobu platnosti Vašej
          úverovej zmluvy.
          Karta Vám bude zaslaná na Vašu adresu:
        </p>
        :
        <p>
          Dovolím si Vás také informovat, že společně s uzavřením úvěrové smlouvy jste získal/a jedinečnou
          bonusovou kartu Travel Card, se kterou máte nárok na neomezený počet ubytování na 1-6
          nocí pro 2 osoby zdarma. Podmínkou je pouze úhrada polopenze. Ubytovat se můžete v jednom z 1600
          hotelů v 18ti zemích. Tuto kartu můžete také darovat jiné osobě a ten, kdo jí aktivuje, má právo jí užívat
          po celou dobu platnosti Vaší úvěrové smlouvy.
          Karta Vám bude zaslaná na Vaší adresu:
        </p>
      }
      <input type="text"
              placeholder="Jméno"
              onChange={(e) => this.setState({travelCardJmeno : e.target.value})}/>
      <input type="text"
              placeholder="Ulice"
              onChange={(e) => this.setState({travelCardUlice : e.target.value})}/>
      <input type="text"
              placeholder="Město"
              onChange={(e) => this.setState({travelCardMesto : e.target.value})}/>
      <input type="text"
              placeholder="PSČ"
              onChange={(e) => this.setState({travelCardPsc : e.target.value})}/>
      {this.state.lang === "sk" ? 
        <p>
          Sú tieto údaje v poriadku?
        </p>
        :
        <p>
          Jsou tyto údaje v pořádku?
        </p>
      }         
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState: 'welcomeSkEnd', history : [...this.state.history, "welcomeSkEnd"]})}>Další</div>
      </div>
    </>
  }

  welcomeSkEnd() {
    return <>
      {this.state.lang === "sk" ? 
        <p>
          Na záver Vám ešte pripomínam, že Vaša splátka je vo výške
          <b> {this.props.item.paymentAmount} EUR</b>
          a termín splatnosti máte stanovený na
          <b> {Formatter.formatDateTime('dd.MM. yyyy', this.props.item.signatureDate)}</b>.
          Bankový príkaz preto nastavte aspoň 3 dni pred dátumom splatnosti. <br/>
          V prípade ďalších otázok môžete kontaktovať spoločnosť sAutoleasing SK poštou, emailom alebo na
          telefónnom čísle, ktoré nájdete v zmluve. <br/>
          Ďakujeme za Váš čas a veríme, že budete so službami spoločnosti sAutoleasing SK spokojný. Prajem Vám príjemný zvyšok dňa.
        </p>
        :
        <p>
          Na závěr Vám jenom připomenu, že Vaše splátky budou ve výši
          <b> {this.props.item.paymentAmount} EUR</b>.
          Termín splatnosti první splátky máte natavený na
          <b> {Formatter.formatDateTime('dd.MM. yyyy', this.props.item.signatureDate)}</b>.
          Bankovní příkaz prosím nastavte alespoň 3 dny před datem splatnosti.<br/>
          V případě dotazů můžete kontaktovat sAutoleasing SK poštou, mailem, nebo na telefonním čísle, které najdete
          na smlouvě.<br/>
          Děkuji za Váš čas a věřím, že budete se službami sAutoleasing SK spokojen/a. Přeji Vám příjemný zbytek dne.
        </p>
      }
      {this.state.lang === "sk" ? 
        <p>
          V prípade nespokojnosti uviesť do poznámky pre zadávateľa dôvod.
        </p>
        :
        <p>
          V případě nespokojenosti uvést do poznámky pro zadavatele důvod.
        </p>
      }
      <input type="textarea"
             placeholder="Poznámka pro zadavatele"
             onChange={(e) => this.setState({noteForContractor : e.target.value})}/>
      <p><b>
        Zvolte výsledek:
      </b></p>
      <select onChange={(e) => this.setState({state : e.target.value})}>
        <option value="PVK_UK">klient: pf potvrdil, úz potvrdil</option>
        <option value="PVK_01">klient: pf potvrdil, ÚZ NEPOTVRDIL</option>
        <option value="PVK_02">klient: PF NEPOTVRDIL,&nbsp; úz potvrdil</option>
        <option value="PVK_03">3.osoba: pf potvrdila,&nbsp; úz potvrdila</option>
        <option value="PVK_04">3.osoba: pf potvrdila,&nbsp; ÚZ NEPOTVRDILA</option>
      </select>
      <div className="options">
        <div className="a" onClick={(e) => this.setState({callState: 'end', history : [...this.state.history, "end"]})}>Konec</div>
      </div>
    </>
  }
}

export default SkWcGuide