import React from 'react'

import DateHelper from '../costra/DateHelper.js'

import Input from './Input.js'
import Translate from '../costra/Translate.js'

class Form {

  static copyProps(props, className) {    
    if (!className) {
      className = 'formInput'
    }
    var newProps = {
      className: className,
    }
    Object.keys(props).map(key =>
      newProps[key] = props[key]
    )
    return newProps
  }

  static Text(props) {
    var tag = Input.Text(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className={"formRow " + (props.styling ? props.styling : "")}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Phone(props) {
    var tag = Input.Phone(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static TextArea(props) {
    var tag = Input.TextArea(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className={"formRow " + (props.styling ? props.styling : "")}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Amount(props) {
    var tag = Input.Amount(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Select(props) {
    var tag = Input.Select(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className={"formRow " + (props.styling ? props.styling : "")}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Check(props) {
    var tag = Input.Check(Form.copyProps(props))
    if (!tag) {
      return null
    }
    
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static ToggleCheck(props) {
    var tag = Input.ToggleCheck(Form.copyProps(props))
    if (!tag) {
      return null
    }
    
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static DateTime(props) {
    var tag = Input.DateTime(Form.copyProps(props, 'shortInput'))
    if (!tag) {
      return null
    }
    return <div className={"formRow " + (props.styling ? props.styling : "")}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Duration(props) {
    var tag = Input.Duration(Form.copyProps(props))
    if (!tag) {
      return null
    }
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Area(props) {
    var tag = Input.Area(Form.copyProps(props, 'shortInput'))
    if (!tag) {
      return null
    }
    return <div className="formRow">
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static TextWithUnit(props) {
    var tag = Input.Text(Form.copyProps(props, 'shortInput'))
    if (!tag) {
      return null
    }
    return <div className={"formRow " + (props.styling ? props.styling : "")}>
      <label>{Translate.get(props.title)}:</label>
      <div className={"value-with-unit" + (props.disabled ? "" :" input")}>
      {tag}
      <Input.Select data={props.data}
                    path={props.unitPath}
                    disabled={props.disabled}
                    values={props.unitValues}
                    className="tinyInput"/>
      </div>
    </div>
  }

  static Password(props) {
    var tag = Input.Password(Form.copyProps(props, 'shortInput'))
    if (!tag) {
      return null
    }
    if (props.disabled) {
      return <></>
    }
    return <div className={"formRow" + (props.check ? props.check : '')}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static Address(props) {
    var tag = Input.Address(Form.copyProps(props, 'shortInput'))
    if (!tag) {
      return null
    }
    return <div className={"formRow" + (props.check ? props.check : '')}>
      <label>{Translate.get(props.title)}:</label>
      {tag}
    </div>
  }

  static _markAsChecked(props) {
    props.data.set(props.path, {
      user: props.currentUser,
      checkTime: DateHelper.now(),
    })
  }

  // defunct, this pattern is no longer used
  /*
  static UserCheck(props) {
    var doDisplay = true
    var checkData = props.data.get(props.path)
    var stateStr = ''

    if (props.flagsPath && props.requiredFlag) {
      var flags = props.data.get(props.flagsPath)
      doDisplay = flags && flags.includes(props.requiredFlag)
    }
    if (checkData) {
      stateStr = props.users[checkData.user['$oid']] + ' ' + Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM', checkData.checkTime)
      doDisplay = true
    }
    if (!doDisplay) {
      return null
    }

    return <div className="formRow center">
      <label>{props.title}:</label>
      {props.disabled || stateStr ?
        <div className="inputReplacement">
          {stateStr}
        </div>
        :
        <button title="Označit jako zkontrolované"
                className="formButton"
                onClick={event => Form._markAsChecked(props)}>
          Označit jako zkontrolované
        </button>
      }
    </div>
  }
  */

  /*


  static filterDateRange(filter, container, path, title, format) {
    var mode = ObjectHelper.get(filter, path + '.mode')

    return <div className="formRow">
      <label>{title}:</label>

       <select className="shortInput"
               value={mode}
               onChange={(event) => container.set(path + '.mode', event.target.value)}>
         <option value="Is">Je</option>
         <option value="Range">Od-do</option>
         <option value="Unset">Nemá hodnotu</option>
       </select>

       {(!mode || mode === 'Is') &&
         <DateTimeInput value={ObjectHelper.get(filter, path + '.from')}
                        onChange={(value) => container.set(path + '.from', value)}
                        className="shortInput"
                        placeholder="Datum..."
                        format="%d.%m.%yyyy"/>
       }

       {mode === 'Range' &&
         <DateTimeInput value={ObjectHelper.get(filter, path + '.from')}
                        onChange={(value) => container.set(path + '.from', value)}
                        className="tinyInput"
                        placeholder="Od..."
                        format="%d.%m.%yyyy"/>
       }

       {mode === 'Range' &&
         <DateTimeInput value={ObjectHelper.get(filter, path + '.to')}
                        onChange={(value) => container.set(path + '.to', value)}
                        className="tinyInput"
                        placeholder="Do..."
                        format="%d.%m.%yyyy"/>
       }
    </div>
  }

  */
}

export default Form
