import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Validator from '../costra/Validator.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CampaignsArray from '../campaigns/CampaignsArray.js'
import ContactData from '../addressbook/ContactData.js'
import User from './User.js'
import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen: false,
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    return WsContainers.action({
      _class: 'com.optimsys.costra.kordex.users.FetchUser',
      id: this.props.id === 'new' ? undefined : this.props.id,
    }).then((result) => {
      result.editOpen = false
      result.simplifiedSmsSenders = []
      if (result.smsSenders) {
        result.smsSenders.forEach(sender => {
          result.simplifiedSmsSenders.push(
            {value: (sender.port.serviceName + '/' + sender.port.port), title: sender.displayName})
        })
      }
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'genericLoadError'})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.UpdateUser',
      item: this.state.item,
    }).then((result) => {
      if (this.props.id === result.item._id.$oid) {
        result.editOpen = false
        this.setState(result)
      }
      else {
        User.go(null, result.item._id.$oid)
      }
    }, () => {
      this.setState({ error: 'genericStoreError'})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  checkPassword(password){
    const regex = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{10,}$")
    return regex.test(password)
  }

  isSuperUser() {
    if (!this.state.item) {
      return false
    }
    if (!this.state.item.rights) {
      return false
    }
    return this.state.item.rights.find(right =>
      right._class === 'com.optimsys.costra.authorization.SuperUserRight'
    )
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder error={this.state.error} reload={() => this.load()}/>
    }
    if (!this.state.item) {
      return <LoadingPlaceholder />
    }
    return <div className="section spacedTop">
      <div className="buttons">
        {this.state.allowEdit && <EditButtons component={this}/>}
      </div>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection user"/>
          <h3>{Translate.get('users.properties.title')}</h3>
        </div>
        <div className="content">
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="login"
                     title='users.properties.login'
                     validate={Validator.notEmptyString}/>
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="displayName"
                     title="users.properties.displayName"/>
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="extensions.kordex.localLine"
                     title='users.properties.localLine'/>
          <Form.Select data={this.data}
                       disabled={!this.state.editOpen}
                       values={this.state.simplifiedSmsSenders}
                       path="extensions.kordex.smsPort"
                       title='users.properties.smsPort'/>
          <Form.DateTime data={this.data}
                         disabled={!this.state.editOpen}
                         path="extensions.kordex.processing.passwordExpirationDate"
                         title='users.properties.passwordExpirationDate'
                         format="%d.%m.%yyyy"/>
          <Form.Password data={this.data}
                         disabled={!this.state.editOpen}
                         path='password'
                         placeholder='users.properties.password.placeholder'
                         title='users.properties.password'
                         check={(
                          this.state.item.password &&
                          (this.state.item.password !== this.state.item.passwordCopy || !this.checkPassword(this.state.item.password))
                         ) ? ' invalid' : ''}/>
          {this.data.get('password') &&
            <Form.Password data={this.data}
                           disabled={!this.state.editOpen}
                           path='passwordCopy'
                           title='users.properties.passwordCopy'
                           check={(
                            this.state.item.passwordCopy &&
                            (this.state.item.password !== this.state.item.passwordCopy || !this.checkPassword(this.state.item.password))
                           ) ? ' invalid' : ''}/>
          }
        </div>
        {this.state.item.password && !this.checkPassword(this.state.item.password) &&
          <div>
            <div className="left icon alert"/>
            {Translate.get('myaccount.info.weakPassword')}
          </div>
        }
        {this.state.item.password && this.state.item.password !== this.state.item.passwordCopy &&
          <div>
            <div className="left icon alert"/>
            {Translate.get('myaccount.info.passwordMismatch')}
          </div>
        }
      </div>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection rights"/>
          <h3>{Translate.get('users.properties.rights')}</h3>
        </div>
        {this.isSuperUser() &&
          <div className="right">
            <div className="icon info left" />
            {Translate.get('users.properties.superUserInfo')}
          </div>
        }
        <div className="content">
          {User.rights.map(right =>
            <Form.ToggleCheck data={this.data}
                              disabled={!this.state.editOpen}
                              path="rights"
                              value={right}
                              title={right._class}/>
          )}
        </div>
      </div>

      <CampaignsArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="extensions.kordex.campaignIds"
                      title='users.properties.campaign'
                      icon="campaign"/>

      <ContactData data={this.data}
                    path="extensions.kordex.contact"
                    disabled={!this.state.editOpen}
                    title='users.properties.contact'
                    noValidation= {true}/>
    </div>
  }
}

export default Properties
