import React, { Component } from 'react'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import ItemsList from './ItemsList.js'
import CasesList from './CasesList'
import LustrationsList from './LustrationsList.js'

import './navPanel.css'

class NavPanel extends Component {

  constructor(props) {
    super(props)
    this.state = {
      casesFilter: "active",
    }
    this.data = new EditData(this, '')
  }

  render() {
    return <div className="navPanel">
      <div className="navPanel--withPadding">
        <select value={this.state.casesFilter}
                onChange={(event) => this.setState({casesFilter : event.target.value})} >
          <option value="all">{Translate.get('nav.activeCases.allCases')}</option>
          <option value="active">{Translate.get('nav.activeCases.activeCases')}</option>
        </select>
        <div className="navPanel--scrollable">
          <div>
            <h3>{Translate.get('nav.addressbook.title')}</h3>
            <ItemsList items={this.props.items} id={this.props.id} caseIndex={this.props.caseIndex}/>

            {this.props.lustrations &&
              <>
                <h3>{Translate.get('nav.lustrations.title')}</h3>
                <LustrationsList lustrations={this.props.lustrations} id={this.props.id}/>
              </>
            }

            <h3>{Translate.get('nav.cases.title')}</h3>
            <CasesList cases={this.props.cases}
                       casesFilter={this.state.casesFilter}
                       id={this.props.id}
                       caseIndex={this.props.caseIndex}
                       objectsIndex={this.props.objectsIndex}/>

          </div>
        </div>
      </div>
      {/*this.state.selectedObjects && this.state.selectedObjects.length > 0 &&
        <div className='activePanel'>   
          <div onClick={() => this.objectAddressDialog()}>
            {Translate.get('nav.button.setObjectAddress')}
          </div>
        </div>
      */}
    </div>
  }
}

export default NavPanel