import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import {PageDataContext} from '../PageDataContext.js'
import ConfirmDialog from '../utils/ConfirmDialog.js'
import Nav from '../utils/Nav.js'

class DropBox {

  static go(event, id, newTab) {
    Nav.go(event, '/dropBox/' + id, newTab)
  }

  static create(event, campaignId) {
    Nav.go(event, '/dropBox/new/' + campaignId)
  }

  static title(item) {
    return item.phoneLine
  }

  static remove(event, item) {
    event.stopPropagation()
    Dialogs.open(
      <ConfirmDialog text={Translate.get('dropbox.remove.prompt', null, {name: item.item.name})}/>
    ).then((confirmed) => {
      if (confirmed) {
        return WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.dropbox.RemoveDropBox',
          id: item.item._id,
        })
      }
    })
  }

  static Row(props) {
    return <div className={'row' + (props.item.allowView ? ' withHover' : '')}
                onClick={(event) => props.item.allowView && DropBox.go(event, props.item.item._id.$oid)}>
      <div><div className="icon dropBox"/></div>
      <div className="size4c">
        <PageDataContext.Consumer>
          {pageData => pageData.campaigns[props.item.item.campaignId.$oid]}
        </PageDataContext.Consumer>
      </div>
      <div className="size6c">
        {props.item.item.name}
      </div>
      <div className="size3c">
        {props.item.item.processing.unprocessed}/{props.item.item.processing.files}
      </div>

      {props.item.allowEdit &&
        <div className="right button"
             title={Translate.get('dropbox.button.remove')}
             onClick={(event) => DropBox.remove(event, props.item)}>
          <div className="icon remove"/>
        </div>
      }
      {props.item.allowView &&
        <div className="right button"
             title={Translate.get('openInNewWindow')}
             onClick={(event) => DropBox.go(event, props.item._id.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      }
    </div>
  }

}

export default DropBox