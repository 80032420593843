import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate  from '../costra/Translate.js'
import ObjectHelper  from '../costra/ObjectHelper.js'

import '../css/dialogs.css'

class TableColumnsDialog extends Component {
  constructor(props) {
    super(props)
    let columns = ObjectHelper.copy(this.props.columns)
    let layout = this.props.layout.map(layoutItem => {
      let col = columns[layoutItem.name]
      if (!col) {
        return null
      }
      col.selected = true
      delete columns[layoutItem.name]
      return col
    }).filter(item => item !== null).concat(Object.values(columns))
    layout.forEach(item => {
      if (!item.selected) {
        item.selected = false
      }
    })
    this.state = {
      layout: layout,
      draggedIndex: -1,
      draggedFrom: -1,
    }
  }

  // Using onDrag instead onDragStart as chrome wasnt responding to it
  dragStart(event, index) {
    this.setState({draggedTo : -1, draggedFrom : index})
  }

  // Keeping drag event alive, if it detects change of the index on a dragged object, updates it
  dragOver(event, index) {
    event.stopPropagation()
    event.preventDefault()
    if(this.state.draggedTo !== index) {
      this.setState({draggedTo : index})
    }
  }

  // Updating the order if the user manages to drop it on a dropzone
  dragEnd(event, index) {
    event.stopPropagation()
    event.preventDefault()
    let layout = this.state.layout
    let dragged = layout[this.state.draggedFrom]
    if (dragged && event.target.classList.contains('dropZone')) {
      layout.splice(this.state.draggedFrom, 1)
      if (this.state.draggedFrom < index) {
        layout.splice(index - 1, 0, dragged)
      }
      else {
        layout.splice(index, 0, dragged)
      }
      this.setState({layout: layout, draggedFrom : -1, draggedTo : -1})
    }
    else {
      this.setState({draggedFrom : -1, draggedTo : -1})
    }
/*
    let originalIndex = this.state.draggedFrom
    let originalDestination = this.state.dragged
    let finalIndex = originalIndex < index ? index -1 : index

    let reorderder = this.state.options.filter(x => x !== originalDestination)
    reorderder.splice(finalIndex, 0, originalDestination)

    let reorderedSelected = this.state.selected.filter(x => x !== originalDestination)
    reorderedSelected.splice(finalIndex, 0, originalDestination)
    
    this.setState({ options : reorderder, selected: reorderedSelected })
    */
  }

  toggleSelected(i, value) {
    let layout = this.state.layout
    layout[i].selected = value
    this.setState({layout: layout})
  }

  apply() {
    let layout = this.state.layout.filter(item =>
      item.selected
    ).map(item => {
      let field = this.props.layout.find(original => original.name === item.name)
      if (field) {
        return field
      }
      return {name: item.name}
    })
    Dialogs.close(layout)
  }

  render() {
    // eventHandlers set on container level so user cannot perform "invalid" drag operation
    return <div className="dialogsContainer checkboxDialog" 
                onDragOver={(event) => this.dragOver(event)}
                onDrop={(event) => this.dragEnd(event)}>

      <div className="dialog small frame">
        <div className='title'>{this.props.title}</div>
        {this.state.layout.map((item, i) =>
          // chrome wasn't responding to dragStart 
          <div key={item.name}
               onDrag={(event) => this.dragStart(event, i)}>
            {this.state.draggedFrom >= 0 &&
              <div className={"dropZone" + (this.state.draggedTo === i ? ' active' : '')}
                   onDrop={(event) => this.dragEnd(event, i)}
                   onDragOver={(event) => this.dragOver(event, i)}>
                {Translate.get("dropZone", null, {index : i})}
              </div>
            }
            <div className={"option" + (this.state.draggedFrom === i ? ' draggster' : '')}
                 onDragOver={(event) => this.dragOver(event, i)}
                 draggable={true}>
              <input type='checkbox'
                     checked={item.selected}
                     onChange={(event) => this.toggleSelected(i, event.target.checked)}/>
              <div className='text'>
                {Translate.get(item.name === 'icon' ? 'caserecord.column.icon' : item.title)}
              </div>
            </div>
          </div>
        )}
        {this.state.draggedFrom >= 0 &&
          <div className={"dropZone" + (this.state.draggedTo === this.state.layout.length ? ' active' : '')}
               onDrop={(event) => this.dragEnd(event, this.state.layout.length)}
               onDragOver={(event) => this.dragOver(event, this.state.layout.length)}>
            {Translate.get("dropZone", null, {index : this.state.layout.length})}
          </div>
        }
        <div className="buttons">
          <div className="button"
               title={Translate.get('apply')}
               onClick={(event) => this.apply()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default TableColumnsDialog