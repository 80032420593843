import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import Dialogs from '../costra/Dialogs.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import DropFile from './DropFile.js'
import UploadDropFileDialog from './UploadDropFileDialog.js'

import '../css/list.css'
import '../css/icons.css'
import Translate from '../costra/Translate.js'

class Container extends SimpleListContainer {

  params() {
    var params = super.params()
    params.dropBoxId = this.component.props.id
    return params
  }
}

class DropFiles extends Component {

  constructor(props) {
    super(props)
    var state = Container.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.dropfile.DropFilesList')
    this.upload = this.upload.bind(this)
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  upload(event) {
    event.stopPropagation()
    Dialogs.open(
      <UploadDropFileDialog dropBoxId={this.props.id}/>
    ).then((confirmed) => {}, () => {
      this.setState({error: 'genericLoadError'})
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
        <div className="right button"
             title={Translate.get('dropfile.button.upload')}
             onClick={this.upload}>
          <div className="icon left import"/>
          <span className="left">{Translate.get('dropfile.button.upload')}</span>
        </div>
      </div>
      <List list={this.state.items} itemFn={(item, index) =>
        <DropFile.Row item={item}
                      imports={this.state.imports}/>
      }/>
    </>
  }

}

export default DropFiles