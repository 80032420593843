import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import Batch from './Batch.js'
import ExportBatchExportRow from './ExportBatchExportRow.js'
import ExportBatchCases from '../caserecord/ExportBatchCases.js'

import {PageDataContext} from  './../PageDataContext.js'

import '../css/list.css'
import '../css/icons.css'

class ExportBatchView extends Component {

  static contextType = PageDataContext

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      exports: null,
      tab: 'cases',
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.exports.FetchExportBatch',
      id: this.props.id,
    }).then((result) => {
      this.setState({item: result.item, exports: result.exports})
    }, () => {
      this.setState({error: 'genericStoreError'})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderTabs() {
    if (!this.state.item) {
      return <div className="spacedTop"><LoadingPlaceholder/></div>
    }
    switch(this.state.tab) {
      case 'download':
        return <div>
          <ul className="list spacedTop">
            {this.state.exports && this.state.exports.map((exportData, index) => {
              return <li key={index}>
                <ExportBatchExportRow campaignId={this.state.item.campaignId} exportData={exportData}/>
              </li>
            })}
          </ul>
        </div>
      default:
        return <ExportBatchCases id={this.props.id}/>
    }
  }

  render() {
    return (
      <div className="mainPanel withTabs">
        <div className="header">
          <div className="row">
            <div className="left icon iconBig export"/>
            {this.state.item && <>
              <h1>{Translate.get(this.state.item._class)} ({this.context.campaigns[this.state.item.campaignId.$oid]})</h1>
              <div className="values">
                <Batch.DetailsText item={this.state.item}/>
              </div>
            </>}
          </div>
          <div className="row">
            <div className="tabs">
              <div className={'tab' + (this.state.tab === 'cases' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'cases'})}>{Translate.get('exportBatch.tab.case')}</div>
              <div className={'tab' + (this.state.tab === 'download' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'download'})}>{Translate.get('exportBatch.tab.download')}</div>
            </div>         
          </div>
        </div>
        <div className="scrollable">
          <div className="content">
            {this.renderTabs()}
          </div>
        </div>
      </div>
    )
  }
}

export default ExportBatchView