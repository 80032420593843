import React, { Component } from 'react'
import EditData from '../costra/EditData.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'

import '../css/dialogs.css'

class CurrencyEditDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item : null,
    }
    this.data = new EditData(this, 'item.')
  }

  componentDidMount() {
    this.load()
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.statistics.GetCurrencySettings',
      id: this.props.id,
    }).then((result) => {
      this.setState({item: result})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.statistics.UpdateCurrencySettings',
      item: this.state.item,
    }).then((result) => {
      Dialogs.close(result)
    })
  }

  render() {
    if(!this.state.item) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <LoadingPlaceholder/>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className='title'>
          <div className="icon iconBig coins"/>
          <span>{Translate.get('statistics.settings.currency')}</span>
        </div>
        <div>
          <Form.Text data={this.data}
                     path="currency"
                     title='statistics.settings.currency'/>
          <Form.DateTime data={this.data}
                         path="validFrom"
                         title='statistics.settings.validFrom'
                         withTime={false}
                         dontAllowClear={true}
                         format="%d.%m.%yyyy"/>
          <Form.Text data={this.data}
                     path="coefficient"
                     title='statistics.settings.coefficient'/>
        </div>
        <div className="buttons spacedTop">
          <div className="button"
               title={Translate.get('apply')}
               onClick={() => this.save()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default CurrencyEditDialog