import React from 'react'
import Translate from '../../../costra/Translate.js'
import Validator from '../../../costra/Validator.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'
import {PageDataContext} from '../../../PageDataContext.js'

import CaseRecord from '../../CaseRecord.js'
import '../../../css/form.css'

const exportSchemes = [
  {value: 'Regular', title: 'erl.pred.exportScheme.Regular'},
  {value: 'AdHoc', title: 'erl.pred.exportScheme.AdHoc'},
]

function PredProperties(props) {
  return <PageDataContext.Consumer>
    {pageData => {
      let contractors = Object.keys(pageData.users).map(id => {
        return {value: id, title: pageData.users[id].displayName}
      }).sort((a, b) => a.title.localeCompare(b.title))
      contractors.splice(0, 0, {value: null, title: 'Není zadáno'})
      return <>
        <div className="subsection">
          <div className="header-row">
            <div className="icon iconSection case" />
            <h3>{Translate.get('caserecord.properties.title')}</h3>
          </div>
          <div className="content">
            <Form.Text data={props.data}
                      disabled={!props.editOpen}
                      path="caseNo"
                      title='caserecord.properties.caseNo'
                      validate={Validator.notEmptyString} />
            <Form.Text data={props.data}
                        disabled={!props.editOpen}
                        path="evNum"
                        title='erl.pred.evNum'/>
            <Form.Text data={props.data}
                        disabled={!props.editOpen}
                        path="eventId"
                        title='erl.pred.eventId'/>
            <Form.Select data={props.data}
                         disabled={!props.editOpen}
                         path="source"
                         title="sal.source"
                         values={CaseRecord.sources}/>
            <Form.Select data={props.data}
                         disabled={!props.editOpen}
                         path="exportScheme"
                         title="erl.pred.exportScheme"
                         values={exportSchemes}/>
            <Form.Select data={props.data}
                      disabled={!props.editOpen}
                      path="caseContractorId"
                      title='erl.pred.caseContractorId'
                      values={contractors} />
            <Form.Select data={props.data}
                      disabled={!props.editOpen}
                      path="caseManagerId"
                      title='erl.pred.caseManagerId'
                      values={contractors} />
            <Form.DateTime data={props.data}
                            disabled={true}
                            path="processing.created"
                            title='caserecord.processing.created'
                            format="%d.%m.%yyyy" />
            <Form.DateTime data={props.data}
                            disabled={!props.editOpen}
                            path="plannedEnding"
                            title='caserecord.properties.plannedEnding'
                            format="%d.%m.%yyyy" />
            <Form.DateTime data={props.data}
                            disabled={true}
                            path="processing.finished"
                            title='caserecord.processing.finished'
                            format="%d.%m.%yyyy" />
          </div>
        </div>
        <ContactData
          data={props.data}
          path="client"
          disabled={!props.editOpen}
          title='contactData.relation.client'/>

        {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
          <ContactData key={index}
                       data={props.data}
                       path={'otherContacts.' + index}
                       disabled={!props.editOpen}/>
        )}
      </>
    }}
  </PageDataContext.Consumer>
}

export default PredProperties