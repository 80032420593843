import React, { Component } from 'react'
import Identity from './costra/Identity.js'
import Dialogs from './costra/Dialogs.js'
import Router from './costra/Router.js'
import Container from './costra/Container.js'
import Translate from './costra/Translate.js'

import LoginDialog from './LoginDialog.js'
import SearchBox from './SearchBox.js'

import Nav from './utils/Nav.js'
import NavPanelWrapper from './navpanel/NavPanelWrapper.js'

import Items from './addressbook/Items.js'
import CampaignEdit from './campaigns/CampaignEdit.js'
import Campaigns from './campaigns/Campaigns.js'
import Cases from './caserecord/CaseRecords.js'
import Objects from './caseobject/Objects.js'
import Imports from './imports/Imports.js'
import ImportRecordView from './imports/ImportRecordView.js'
import Exports from './exports/Exports.js'
import ExportBatchView from './exports/ExportBatchView.js'
import Users from './users/Users.js'
import UserEdit from './users/UserEdit.js'
import TasksPanel from './tasks/TasksPanel.js'
import CallPanel from './comms/CallPanel.js'
import MessageTemplateEdit from './message/MessageTemplateEdit.js'
import CommLogSection from './comms/CommLogSection.js'
import DropBoxes from './dropbox/DropBoxes.js'
import DropBoxEdit from './dropbox/DropBoxEdit.js'
import Search from './search/Search.js'
import FlaggedCasesList from './caserecord/FlaggedCasesList.js'
import LoadingPlaceholder from './utils/LoadingPlaceholder.js'
import MyAccount from './myaccount/MyAccount.js'
import TaskInfo from './tasks/TaskInfo.js'
import Statistics from './statistics/Statistics.js'

import locale from 'antd/lib/locale/cs_CZ';

import {ConfigProvider} from 'antd';
import {PageDataContext} from './PageDataContext.js'

import logo from './img/logo_Kordex_2021_RGB.jpg'
import './css/index.css'
import './css/icons.css'
import SelectedTasksList from './tasks/SelectedTasksList.js'
import UpdatePasswordDialog from './UpdatePasswordDialog.js'

class InitializedApp extends Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.container = new Container(this, 'com.optimsys.costra.kordex.ui.PageDataContainer', () => {
      this.setState({error: Translate.get('genericLoadError')})
    })
  }

  componentDidMount() {
    this.container.tell({_class: 'com.optimsys.costra.kordex.ui.PageDataContainer$Get'})
  }

  componentDidUpdate(prevProps, prevState) {
  }

  componentWillUnmount() {
    this.container.close()
  }

  route(path, urlParams) {
    switch(path[0]) {
      case 'addressBook':
        if (path[1]) return <NavPanelWrapper path={path} id={path[1]}/>
        else return <Items page={urlParams.get('page')}/>
      case 'case':
        if (path[1]) return <NavPanelWrapper path={path} id={path[1]}/>
        else return <Cases page={urlParams.get('page')}/>
      case 'caseObject':
        if (path[1]) return <NavPanelWrapper path={path} id={path[1]}/>
        else return <Objects page={urlParams.get('page')}/>
      case 'campaigns':
        if (path[1]) return <CampaignEdit id={path[1]}/>
        else return <Campaigns/>
      case 'issue':
        return <Cases flag={path[1]} campaignId={path[2]}/>
      case 'flag':
        switch (path[1]) {
          case 'case':
            return <FlaggedCasesList campaignId={path[2]} flag={path[3]} page={urlParams.get('page')}/>
          /*
          case 'object':
            return <FlaggedObjectsList campaignId={path[2]} flag={path[2]} page={urlParams.get('page')}/>
          */
          default:
            return null
        }
      case 'task':
        return <SelectedTasksList campaignId={path[1]} task={path[2]} page={urlParams.get('page')}/>
      case 'import':
        if (path[1]) return <ImportRecordView id={path[1]}/>
        else return <Imports page={urlParams.get('page')}/>
      case 'export':
        switch(path[1]) {
          case 'batch':
            return <ExportBatchView id={path[2]}/>
          default:
            return <Exports page={urlParams.get('page')}/>
        }
      case 'user':
        if (path[1]) return <UserEdit id={path[1]}/>
        else return <Users page={urlParams.get('page')}/>
      case 'messageTemplate':
        return <MessageTemplateEdit id={path[1]} campaignId={path[2]}/>
      case 'commLog':
        return <CommLogSection tab={path[1]} page={urlParams.get('page')}/>
      case 'dropBox':
        if (path[1]) return <DropBoxEdit id={path[1]} campaignId={path[2]}/>
        else return <DropBoxes/>
      case 'search':
        return <Search text={decodeURIComponent(path[1])}/>
      case 'taskinfo':
        return <TaskInfo id={path[1]} />
      case'myaccount':
        return <MyAccount id={this.state.currentUser.id.$oid}/>
      case'statistics':
        return <Statistics/>
      case '':
        return <div className="mainPanel">
          <div className="header">
            <div className="row">
              <div className="left icon iconBig users"/>
              <h1>{Translate.get('app.kordexIS')}</h1>
            </div>
          </div>
        </div>
      default:
        return <div className="mainPanel">
          <div className="header">
            <div className="row">
              <div className="left icon iconBig alert"/>
              <h1>{Translate.get('app.unknownPage')}</h1>
            </div>
          </div>
        </div>
    }
  }

  routeProc(path, urlParams) {
    return <div>
      <div className="menuPanel">
        <img className="logo" src={logo} alt="Logo"/>
        <div className="buttons">
          {this.state.currentUser.canSeeAddressBook &&
            <a href="/addressBook" onClick={(event) => Nav.go(event, '/addressBook')}>{Translate.get('app.menu.addressbook')}</a>
          }
          {this.state.currentUser.canSeeCampaigns &&
            <a href="/campaigns" onClick={(event) => Nav.go(event, '/campaigns')}>{Translate.get('app.menu.campaigns')}</a>
          }
          <a href="/case" onClick={(event) => Nav.go(event, '/case')}>{Translate.get('app.menu.cases')}</a>
          <a href="/caseObject" onClick={(event) => Nav.go(event, '/caseObject')}>{Translate.get('app.menu.objects')}</a>
          {this.state.currentUser.canSeeDropBox &&
            <a href="/dropBox" onClick={(event) => Nav.go(event, '/dropBox')}>{Translate.get('app.menu.dropbox')}</a>
          }
          {this.state.currentUser.canSeeImports  &&
            <a href="/import" onClick={(event) => Nav.go(event, '/import')}>{Translate.get('app.menu.import')}</a>
          }
          {this.state.currentUser.canSeeExports  &&
            <a href="/export" onClick={(event) => Nav.go(event, '/export')}>{Translate.get('app.menu.export')}</a>
          }
          {this.state.currentUser.canAccessCommLog &&
            <a href="/commLog" onClick={(event) => Nav.go(event, '/commLog')}>{Translate.get('app.menu.commlog')}</a>
          }
          {this.state.currentUser.canSeeStatistics &&
            <a href="/statistics" onClick={(event) => Nav.go(event, '/statistics')}>{Translate.get('app.menu.statistics')}</a>
          }
          {this.state.currentUser.canSeeUsers &&
            <a href="/user" onClick={(event) => Nav.go(event, '/user')}>{Translate.get('app.menu.users')}</a>
          }         
          {!this.state.currentUser.canSeeUsers &&
            <a href="/" onClick={(event) => Nav.go(event, '/myaccount')}>{Translate.get('app.menu.myaccount')}</a>
          }
          <a href="/" onClick={(event) => Identity.logout()}>{Translate.get('logOut')}</a>
        </div>
        <SearchBox/>
      </div>
      {this.route(path, urlParams)}
      <TasksPanel/>
      <CallPanel/>
      <Dialogs/>
    </div>
  }

  render() {
    if(!this.state.currentUser) {
      return <LoginDialog/>
    }
    if(this.state.currentUser.isPasswordExpired) {
      return <UpdatePasswordDialog userId={this.state.currentUser.id.$oid}/>
    }
    // all data for context is loaded
    else if(!this.state.campaigns && !this.state.users) {
      return <LoadingPlaceholder/>
    }
    else {
      return <ConfigProvider locale={locale}>
        <PageDataContext.Provider value={this.state}>
          <Router>
            {(path, urlParams) => this.routeProc(path, urlParams)}
          </Router>
        </PageDataContext.Provider>
      </ConfigProvider>
    }
  }
}

export default InitializedApp
