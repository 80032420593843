import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'

class FilterSummary extends Component {

  dateFilter(name, title, sequence) {
    var range = this.props.filter[name]
    if (!range) {
      return
    }
    switch(range.mode) {
      case 'Range':
        if (range.from || range.to) {
          sequence.push({name: name, title: title})
        }
        break;
      case 'Unset':
        sequence.push({name: name, title: title})
        break;
      default:
        if (range.from) {
          sequence.push({name: name, title: title})
        }
    }
  }

  amountFilter(name, title, sequence) {
    var range = this.props.filter[name]
    if (!range) {
      return
    }
    if (range.min !== undefined || range.max !== undefined) {
      sequence.push({name: name, title: title})
    }
  }

  arrayFilter(name, title, sequence) {
    var list = this.props.filter[name]
    if (!list) {
      return
    }
    if (list.length > 0) {
      sequence.push({name: name, title: title})
    }
  }

  removeFilter(event, name) {
    var filter = this.props.filter
    delete filter[name]
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      name: this.props.name,
      data: [filter],
    }).then((result) => {
      this.props.update()
    })
  }

  render() {
    var sequence = []
    this.dateFilter('created', 'caserecord.filter.created' , sequence)
    this.dateFilter('plannedEnding', 'caserecord.filter.plannedEnding' , sequence)
    this.dateFilter('finished', 'caserecord.filter.finished' , sequence)
    this.arrayFilter('campaigns', 'caserecord.filter.campaign.title' , sequence)
    this.arrayFilter('users', 'caserecord.filter.user.title' , sequence)
    this.arrayFilter('withFlags', 'caserecord.filter.withFlags' , sequence)
    this.arrayFilter('withoutFlags', 'caserecord.filter.withoutFlags' , sequence)
    this.arrayFilter('withScore', 'caserecord.filter.withScore' , sequence)
    this.arrayFilter('withoutScore', 'caserecord.filter.withoutScore' , sequence)
    this.amountFilter('valueMissing', 'caserecord.filter.valueMissing' , sequence)
    this.amountFilter('valuePayed', 'caserecord.filter.valuePayed' , sequence)
    this.amountFilter('valuePlanned', 'caserecord.filter.valuePlanned' , sequence)

    if (sequence.length <= 0) {
      return null
    }

    return <div className="listHeader spacedTop">
      <div>
        {sequence.map(part =>
        <div key={part.name}
              className="block"
              title={Translate.get(part.title)}>
            <div className="icon left filter"/>
            <span className="left">{Translate.get(part.title)}</span>
            <div className="button left"
                title={Translate.get('cancel')}
                onClick={(event) => this.removeFilter(event, part.name)}>
              <div className="icon left cancel"/>
            </div>
          </div>
        )}
      </div>
    </div>
  }
}

export default FilterSummary