import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'
import '../css/dialogs.css';

class CallDialDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dst: props.dst,
      error: null,
      working: true,
    }
    this.data = new EditData(this, '')
    //this.timer = setInterval(() => this.close(), 2500)
  }

  componentDidMount() {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.comms.DialPrepare',
    }).then((result) => {
      result.working = false
      if (!result.localExtensions) result.localExtensions = []
      if (!result.callerIds) {
        result.callerIds = []
      }
      else {
        result.callerId = result.callerIds[0]
      }
      result.localExtensions = result.localExtensions.map(num => {
        return {title: num, value: num}
      })
      result.callerIds = result.callerIds.map(num => {
        return {title: num, value: num}
      })
      this.setState(result)
    }, (error) => {
      this.setState({working: false, error: error})
    })
  }

  dialCall() {
    this.setState({working: true})
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.comms.DialCall',
      src: this.state.src,
      dst: this.state.dst,
      callerId: this.state.callerId,
    }).then((result) => {
      Dialogs.close(null)
    }, (error) => {
      this.setState({working: false, error: error})
    })
  }

  renderContent() {
    if (this.state.error) {
      return <div>{Translate.get('comms.dial.error')}</div>
    }
    if (this.state.working) {
      return <LoadingPlaceholder/>
    }
    return <>
      <Form.Text data={this.data}
                 path="dst"
                 title='comms.dial.dst'/>
      <Form.Select data={this.data}
                   path="src"
                   values={this.state.localExtensions}
                   title='comms.dial.src'/>
      <Form.Select data={this.data}
                   path="callerId"
                   values={this.state.callerIds}
                   title='comms.dial.callerId'/>
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig call"/>
          <span>{Translate.get('comms.dial.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          {!this.state.working && !this.state.error &&
            <div className="button" onClick={(event) => this.dialCall()}>
              <div className="icon iconBig ok"/>
            </div>
          }
          <div className="button" onClick={(event) => Dialogs.close(false)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default CallDialDialog