import React from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'
import Dialogs from '../costra/Dialogs.js'
import Router from '../costra/Router.js'

import StateIcons from '../utils/StateIcons.js'
import ConfirmDialog from '../utils/ConfirmDialog.js'
import ProcessingGroup from '../utils/ProcessingGroup.js'

import LustrationDialog from '../lustrations/LustrationDialog.js'
import ExportSelectDialog from '../exports/ExportSelectDialog.js'

import '../css/icons.css'
import '../css/form.css'

function paymentsInfo(values) {
  if (!values) {
    return null
  }
  return <div className="amounts">
    {values.map((value, index) =>
      <div key={index}
           className={'bigAmount ' + (value.missing <= 0 ? 'complete' : '')}
           title={Translate.get('paymentPlan.valueExplained')}>
        {Formatter.formatAmount(value.missing)} / {Formatter.formatAmount(value.planned)} {value.currency}
      </div>
    )}
  </div>
}

function renderValues(props) {
  switch(props.item._class) {

    case 'com.optimsys.costra.kordex.caserecord.sal.telephony.TelCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisSKCase':
    case 'com.optimsys.costra.kordex.caserecord.ucb.telephony.TelCase':
      return <div className="values">
        <StateIcons item={props.item} extraClass="iconBig"/>
        {paymentsInfo(props.item.processing.payments)}
      </div>

    case 'com.optimsys.costra.kordex.caserecord.erl.pred.PredCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.sf.SfCase':
      return <div className="values">
        <div className="score">
          {Translate.get('score.' + props.item.processing.score)}
        </div>
        <StateIcons item={props.item} extraClass="iconBig"/>
        {props.item.processing.objects &&
          <div title= {Translate.get('caserecord.header.objects')}
               className={'objects ' + (props.item.processing.finished ? 'complete' : '')}>
            ({props.item.processing.objects.total - props.item.processing.objects.incomplete}
            /
            {props.item.processing.objects.total})
          </div>
        }
      </div>
    
    case 'com.optimsys.costra.kordex.caserecord.vw.fs.VwFsCase':
    case 'com.optimsys.costra.kordex.caserecord.sal.collection.CollectionCase':
    case 'com.optimsys.costra.kordex.caserecord.cofidis.collection.CollectionCase':
      return <div className="values">
        <div className="score">
          {Translate.get('score.' + props.item.processing.score)}
        </div>
        <StateIcons item={props.item} extraClass="iconBig"/>
        <div className="amounts">
          {props.item.processing.payments && props.item.processing.payments.map((value, index) =>
            <div key={value.currency}>
              <div className={'bigAmount ' + (value.missing <= 0 ? 'complete' : '')}
                    title={Translate.get('paymentPlan.valueExplained')}>
                {Formatter.formatAmount(value.missing)} / {Formatter.formatAmount(value.planned)} {value.currency}
              </div>
              {props.item.initialDebt && props.item.initialDebt[value.currency] &&
                <div className="smallAmount" title={Translate.get('paymentPlan.initialDebt')}>
                  {Translate.get('paymentPlan.initialDebt')} {Formatter.formatAmount(props.item.initialDebt[value.currency])} {value.currency}
                </div>
              }
            </div>
          )}
        </div>
      </div>

    default:
      return <div>{props.item._class}</div>
  }
}

function exportDialog(event, props) {
  event.stopPropagation()
  Dialogs.open(<ExportSelectDialog record={props.item} exports={props.exports} />)
}

function removeCase(item){
  Dialogs.open(
    <ConfirmDialog text={Translate.get('caserecord.remove.prompt', null, {name: item.caseNo})}/>
  ).then((confirmed) => {
    if (confirmed) {
      WsContainers.transaction({
        _class: 'com.optimsys.costra.kordex.caserecord.RemoveCase',
        itemId: item._id.$oid,
      }).then(() => Router.go('/case/'))
    }
    else{
      Dialogs.close(null)
    }
  })
}

function lustrationDialog(event, props) {
  Dialogs.open(
    <LustrationDialog item={props.item.client}
                      load={props.load}
                      caseId={props.id}/>
  )
}

function CaseEditHeader(props) {
  return <>
    <>
      <div className="left icon iconBig case" />
      {ProcessingGroup.Group(props.item.processing.processingGroup)}
      <h1>{props.item.caseNo + " (" + props.campaigns[props.item.campaignId.$oid] + ")"}</h1>
    </>

    {renderValues(props)}

    <div className='header__buttons'>
      <button disabled={!(props.allowEdit && props.item.client)}
              onClick={event => lustrationDialog(event, props)}
              className={props.allowEdit && props.item.client ? "" : "disabled"}>
        {Translate.get('caserecord.header.button.lustration')}
      </button>
      <button disabled={!props.allowEdit}
              className={props.allowEdit ? "" : "disabled"}
              onClick={(event) => exportDialog(event, props)}>
        {Translate.get('caserecord.header.button.export')}
      </button>
      <button disabled={!props.allowRemove}
              className={props.allowRemove ? "" : "disabled"}
              onClick={() => removeCase(props.item, props)}>
        {Translate.get('caserecord.header.button.remove')}
      </button>
    </div>
  </>
}

export default CaseEditHeader
