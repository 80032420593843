import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import ListContainer from '../utils/ListContainer.js'
import SortIcon from '../utils/SortIcon.js'
import PageSelector from '../utils/PageSelector.js'
import Nav from '../utils/Nav.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import { PageDataContext } from '../PageDataContext.js'
import CommLog from './CommLog.js'
import '../css/list.css'
import '../css/icons.css'

class Container extends ListContainer {

  params() {
    var params = super.params()
    if (this.component.props.preset) {
      params['preset'] = this.component.props.preset
    }
    return params
  }
}

class CommLogList extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.recordsTotal = null
    state.itemsPerPage = 30
    state.sortField = "time"
    state.sortOrder = -1
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.comms.CommLogList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState({recordsTotal: null}, () => this.container.reload())
    }
  }

  markCallAsHandled(item, index) {
    var updatedItems = this.state.items
    delete updatedItems[index].tags.missedCall
    this.setState({items: updatedItems})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.comms.MarkCallAsHandled',
      id: item._id,
    })
  }

  updateSortParams(sortField) {
    this.setState(oldState => {
      if (oldState.sortField === sortField) {
        oldState.sortOrder = -oldState.sortOrder
      }
      else {
        oldState.sortOrder = -1
      }
      return {
        sortOrder: oldState.sortOrder,
        sortField: sortField !== "stateIcons" ? sortField : "state"
      }
    }, () => {
      this.container.reload()
    })
  }

  render() {
    if (this.state.recordsTotal === null) {
      return <LoadingPlaceholder/>
    }
    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => {
                        Nav.addUrlParam(event, 'page', page)
                      }}/>
      </div>
      <ul className="list">
        <li>
          <div className="row rowHeader">
            <div className="icon-placeholder" />
            <div className="size3c sortable"
              onClick={() => this.updateSortParams('time')}>
              {Translate.get('commlog.header.time')}
              {SortIcon.display('time', this.state.sortField, this.state.sortOrder)}
            </div>
            <div className="size5c sortable"
              onClick={() => this.updateSortParams('src')}>
              {Translate.get('commlog.header.src')}
              {SortIcon.display('src', this.state.sortField, this.state.sortOrder)}
            </div>
            <div className="size5c sortable"
              onClick={() => this.updateSortParams('dst')}>
              {Translate.get('commlog.header.dst')}
              {SortIcon.display('dst', this.state.sortField, this.state.sortOrder)}
            </div>
            <div className="size5c">
              {Translate.get('commlog.header.connectedOrState')}
            </div>
            <div className="size2c"></div>
          </div>
        </li>
        {this.state.items && this.state.items.map((item, index) =>
          <li key={item._id.$oid}>
            <CommLog.Row item={item}
                         canDialCall={this.context.currentUser.canDialCall}
                         markCallAsHandled={item => this.markCallAsHandled(item, index)}/>
          </li>
        )}
      </ul>
    </>
  }
}

CommLogList.contextType = PageDataContext

export default CommLogList