import React from 'react'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'

import {PageDataContext} from '../PageDataContext.js'

class ImportRecord {

  static go(event, id, newTab) {
    Nav.go(event, '/import/' + id, newTab)
  }

  static Header() {
    return <li>
      <div className="row rowHeader">
        <div className="icon-placeholder"/>
        <div className="size3c">
          {Translate.get('imports.header.time')}
        </div>
        <div className="size4c">
          {Translate.get('imports.header.importType')}
        </div>
        <div className="size3c">
          {Translate.get('imports.header.campaign')}
        </div>
        <div className="size8c">
          {Translate.get('imports.header.files')}
        </div>
        <div className="size2c">
          {Translate.get('imports.header.errors')}
        </div>
        <div className="buttons"/>
      </div>
    </li>
  }

  static Row(props) {
    return <PageDataContext.Consumer>
      {pageData =>
        <div className={'row' + (props.item.allowView ? ' withHover' : '')}
             onClick={(event) => props.item.allowView && ImportRecord.go(event, props.item.item._id.$oid)}>
          <div><div className="icon import"/></div>
          <div className="size3c">{Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM:%SS', props.item.item.time)}</div>
          <div className="size4c">{Translate.get(props.item.item.displayName)}</div>
          <div className="size3c">{pageData.campaigns[props.item.item.campaignId.$oid]}</div>
          <div className="size8c overflow">
            {props.item.item.files && props.item.item.files.map(file => {
              return <div key={file.info.fileName}>{file.info.fileName}</div>
            })}
          </div>
          <div className="size2c">{props.item.item.sumOfErrors}</div>
          <div className="buttons">
            <div className="right button"
                key="open"
                title={Translate.get('openInNewWindow')}
                onClick={(event) => ImportRecord.go(event, props.item.item._id.$oid, true)}>
              <div className="icon newWindow"/>
            </div>
          </div>
        </div>
      }
    </PageDataContext.Consumer>
  }
}

export default ImportRecord