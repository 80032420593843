import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Validator from '../costra/Validator.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import Nav from '../utils/Nav.js'

import Item from './Item.js'
import Contacts from './Contacts.js'
import ContactDataList from './ContactDataList.js'

import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen: this.props.id === 'new',
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    if (this.state.error) {
      this.setState({ error: null })
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.addressbook.FetchItem',
      id: this.props.id === 'new' ? undefined : this.props.id,
    }).then((result) => {
      result.editOpen = this.props.id === 'new'
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'addressbook.edit.error.cannotLoad' })
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.addressbook.UpdateItem',
      item: this.state.item,
    }).then((result) => {
      result.editOpen = false
      if (result.item._id.$oid !== this.props.id) {
        Nav.go(null, '/addressBook/' + result.item._id.$oid)
      }
      else {
        this.setState(result)
      }
      return result
    }, () => {
      this.setState({ error: 'addressbook.edit.error.cannotStore' })
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderPreferencesEdit() {
    return <div className="content">
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactHours.morning"
                  title='addressbook.preferences.contactHours.morning' />
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactHours.noon"
                  title='addressbook.preferences.contactHours.noon' />
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactHours.afternoon"
                  title='addressbook.preferences.contactHours.afternoon' />
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactHours.evening"
                  title='addressbook.preferences.contactHours.evening'/>
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactHours.night"
                  title='addressbook.preferences.contactHours.night'/>

      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactType.call"
                  title='addressbook.preferences.contactType.call' />
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactType.sms"
                  title='addressbook.preferences.contactType.sms' />
      <Form.Check data={this.data}
                  disabled={!this.state.editOpen}
                  path="extensions.kordex.preferredContactType.mail"
                  title='addressbook.preferences.contactType.mail' />
    </div>
  }

  renderPreferences() {
    return <div className="content">
      <div className="formRow">
        <label>{Translate.get('addressbook.preferences.contactHours.title')}:</label>
        <div className="formInput">
          {this.state.item.extensions.kordex.preferredContactHours.morning && Translate.get('addressbook.preferences.contactHours.morning')}
          {this.state.item.extensions.kordex.preferredContactHours.noon && Translate.get('addressbook.preferences.contactHours.noon')}
          {this.state.item.extensions.kordex.preferredContactHours.afternoon && Translate.get('addressbook.preferences.contactHours.afternoon')}
          {this.state.item.extensions.kordex.preferredContactHours.evening && Translate.get('addressbook.preferences.contactHours.evening')}
          {this.state.item.extensions.kordex.preferredContactHours.night && Translate.get('addressbook.preferences.contactHours.night')}
        </div>
      </div>
      <div className="formRow">
        <label>{Translate.get('addressbook.preferences.contactType.title')}:</label>
        <div className="formInput">
          {this.state.item.extensions.kordex.preferredContactType.call && Translate.get('addressbook.preferences.contactType.call')}
          {this.state.item.extensions.kordex.preferredContactType.sms && Translate.get('addressbook.preferences.contactType.sms')}
          {this.state.item.extensions.kordex.preferredContactType.mail && Translate.get('addressbook.preferences.contactType.mail')}
        </div>
      </div>
    </div>
  }

  render() {
    if (this.state.error) {
      return <div className="section">
        <ErrorPlaceholder error={this.state.error} reload={event => this.load()}/>
      </div>
    }
    if (!this.state.item) {
      return <div className="section">
        <LoadingPlaceholder/>
      </div>
    }

    return <div className="section">
      <div className="buttons">
        <EditButtons component={this} />
      </div>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection addressBookItem" />
          <h3>{Translate.get('addressbook.properties.title')}</h3>
        </div>

        <div className="content">
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="displayName"
                     title='contactData.displayName'
                     styling={this.props.styling}
                     validate={Validator.notEmptyString} />
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="companyId"
                     title='contactData.companyId' />
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path= "taxId"
                     title='contactData.taxId' />
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="personalId"
                     title='contactData.personalId'/>
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="extensions.kordex.salPartnerId"
                     title='contactData.salPartnerId'/>
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="firstName"
                     title='contactData.firstName'/>
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="lastName"
                     title='contactData.lastName'/>
          <Form.DateTime data={this.data}
                         disabled={!this.state.editOpen}
                         path="extensions.kordex.bornDate"
                         title='contactData.bornDate'
                         format="%d.%m.%yyyy"/>
          <Form.Select data={this.data}
                       disabled={!this.state.editOpen}
                       path="extensions.kordex.legalType"
                       title='contactData.legalType'
                       values={Item.legalTypes()} />
          <Form.TextArea data={this.data}
                         disabled={!this.state.editOpen}
                         path="note"
                         title='contactData.note'/>
        </div>
      </div>

      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection call" />
          <h3>{Translate.get('addressbook.preferences.title')}</h3>
        </div>
        {this.state.editOpen ? this.renderPreferencesEdit() : this.renderPreferences()}
      </div>

      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection phone"/>
          <h3>{Translate.get('contactData.contacts')}</h3>
        </div>
        <Contacts data={this.data}
                  disabled={!this.state.editOpen}
                  path="contacts"/>
      </div>

      <ContactDataList data={this.data}
                       disabled={!this.state.editOpen}
                       path="extensions.kordex.employers"
                       title='addressbook.propertiesEdit.employer'/>

      <ContactDataList data={this.data}
                       disabled={!this.state.editOpen}
                       path="extensions.kordex.statutaries"
                       title='addressbook.propertiesEdit.statutaries'/>
    </div>
  }
}

export default Properties
