import React from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'

import ImportDropFileSelectDialog from '../imports/ImportDropFileSelectDialog.js'
import {PageDataContext} from '../PageDataContext.js'

class DropFile {

  static go(event, id) {
    Nav.go(event, '/download/dropFile?id=' + id, true)
  }

  static markAsProcessed(event, item) {
    if (event) {
      event.stopPropagation()
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.dropfile.MarkFileAsProcessed',
      id: item._id,
    }).then(_ => {})
  }

  static importFile(event, imports, item) {
    if (event) {
      event.stopPropagation()
    }
    Dialogs.open(
      <ImportDropFileSelectDialog item={item} imports={imports}/>
    )
    /*
    return WsContainers.action({
      _class: 'com.optimsys.costra.kordex.imports.OpenImportAndRun',
      campaignId: importObj.campaignId,
      importId: importObj.importId,
      params:  {
        _class: 'com.optimsys.costra.kordex.imports.message.PrepareFromDropFile',
        dropFileId: item._id,
      },
    }).then(_ => {
      Nav.go(null, '/import')
    })
    */
  }

  static Row(props) {
    return <PageDataContext.Consumer>
      {pageData =>
        <div className="row multiple-columns">
          <div className={'row ' + (props.item.item.processedAt && 'disabled')}>
            <div><div className="icon dropFile"/></div>
            <div className="size6c">
              {props.item.item.userId && pageData.users[props.item.item.userId.$oid] && pageData.users[props.item.item.userId.$oid].displayName}
              {' (' + Formatter.formatDateTime('%d.%m. %yyyy %HH:%MM', props.item.item.time) + ')'}
            </div>
            <div className="size6c">
              {props.item.item.processedBy && pageData.users[props.item.item.processedBy.$oid] &&  pageData.users[props.item.item.processedBy.$oid].displayName}
              {props.item.item.processedAt ? ' (' + Formatter.formatDateTime('%d.%m. %yyyy %HH:%MM', props.item.item.processedAt) + ')' : ''}
            </div>
            <div className="size6c">
              {props.item.item.file.info.fileName}
            </div>
            {!props.item.item.processedAt && props.item.allowEdit &&
              <div className="right button"
                  title={Translate.get('dropfile.button.markAsProcessed')}
                  onClick={(event) => DropFile.markAsProcessed(event, props.item.item)}>
                <div className="icon ok"/>
              </div>
            }
            {props.imports &&
              <div className="right button"
                  title={Translate.get('dropfile.button.import')}
                  onClick={(event) => DropFile.importFile(event, props.imports, props.item.item)}>
                <div className="icon import"/>
              </div>
            }
            {props.item.allowView &&
              <div className="right button"
                  title={Translate.get('dropfile.button.download')}
                  onClick={(event) => DropFile.go(event, props.item.item._id.$oid)}>
                <div className="icon download"/>
              </div>
            }
          </div>
          {props.item.item.note &&
            <div className="row disabled">
              <div>
                <i className="shiftedMessageContainer">{props.item.item.note}</i>
              </div>
            </div>
          }
        </div>
      }
    </PageDataContext.Consumer>
  }

}

export default DropFile