import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'
import ListContainer from '../utils/ListContainer.js'
import Nav from '../utils/Nav.js'
import Table from '../utils/Table.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import PresetsDialog from '../utils/PresetsDialog.js'

import CampaignSelectDialog from '../campaigns/CampaignSelectDialog.js'

import CaseRecord from './CaseRecord.js'
import FilterDialog from './FilterDialog.js'
import FilterSummary from './FilterSummary.js'

import '../css/list.css'
import '../css/icons.css'

class Cases extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.filter = {}
    state.page = this.props.page ?? 1
    state.layout = ObjectHelper.copy(CaseRecord.defaultLayout)
    state.sortField = 'plannedEnding'
    state.sortOrder = -1
    this.state = state
    this.container = new ListContainer(this, 'com.optimsys.costra.kordex.caserecord.CasesList')
    this.createCase = this.createCase.bind(this)
    this.openFilter = this.openFilter.bind(this)
    this.openPresets = this.openPresets.bind(this)
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  createCase(event) {
    event.stopPropagation()
    Dialogs.open(
      <CampaignSelectDialog campaigns={this.state.campaignFilters}/>
    ).then((campaignId) => {
      if (campaignId) {
        return WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.caserecord.CreateCaseRecord',
          campaignId: campaignId,
        }).then((result) => {
          CaseRecord.go(null, result._id.$oid)
        })
      }
    }, () => {
      this.setState({error: 'caserecord.cases.createError'})
    })
  }

  openFilter(event) {
    Dialogs.open(
      <FilterDialog name="casesFilter" filter={this.state.filter}/>
    ).then((filter) => {
      if (filter) {
        Nav.addUrlParam(event, 'page', 1)
        this.container.reload()
      }
    })
  }

  openPresets(event) {
    Dialogs.open(
      <PresetsDialog name="casesFilter"
                     validationClass="com.optimsys.costra.kordex.caserecord.CasesList$Filter"
                     filter={this.state.filter}/>
    ).then((filter) => {
      if (filter) {
        Nav.addUrlParam(event, 'page', 1)
        this.container.reload()
      }
    })
  }

  renderContent() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
    <FilterSummary filter={this.state.filter}
                   name="casesFilter"
                   update={() => this.container.reload()}/>

    <div className="listHeader spacedTop">

      <PageSelector page={Number(this.props.page ?? 1)}
                  pages={this.state.pages}
                  onChange={(event, page) => {
                    Nav.addUrlParam(event, 'page', page)}
                  }/>

      <div>
        {this.state.allowAdd  &&
          <div className="button right"
               title={Translate.get('caserecord.cases.button.add')}
               onClick={this.createCase}>
            <div className="icon left caseAdd"/>
            <span className="left">{Translate.get('caserecord.cases.button.add')}</span>
          </div>
        }

        {!this.props.rightPanelFilter &&
          <>
            <div className="button right"
                 title={Translate.get('caserecord.cases.button.filter')}
                 onClick={this.openFilter}>
              <div className="icon left filter"/>
              <span className="left">{Translate.get('caserecord.cases.button.filter')}</span>
            </div>
            <div className="button right"
                 title={Translate.get('caserecord.cases.button.presets')}
                 onClick={this.openPresets}>
              <div className="icon left save"/>
              <span className="left">{Translate.get('caserecord.cases.button.presets')}</span>
            </div>
          </>
        }

      </div>
    </div>

    <Table name="casesLayout"
           withSemaphore={true}
           items={this.state.items}
           layout={this.state.layout}
           sortField={this.state.sortField}
           sortOrder={this.state.sortOrder}
           updateLayout={(layout) => this.setState({layout : layout})}
           sort={(sortName) => this.container.sortBy(sortName)}
           columns={CaseRecord.columns}
           itemFn={(item, index, key) =>
              <CaseRecord.Row layout={this.state.layout} item={item} key={key}/>
           }/>
    </>
  }

  render() {
    return <div className="mainPanel withTable">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig cases"/>
          <h1>{Translate.get('caserecord.cases.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderContent()}
        </div>
      </div>
    </div>
  }
}

export default Cases