import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'

import Translate from '../costra/Translate.js';
import '../css/dialogs.css';

class ExportSelectDialog extends Component {

  renderContent() {
    if (!this.props.exports ) {
      return <p>{Translate.get('exports.select.noExports')}</p>
    }
    return <ul className="list spacedBottom">
      {this.props.exports.map((exportData, i) =>
        <li key={i}>
          <form action='/download/export' method="post" encType='multipart/form-data' target='_blank'>
            {Object.entries(exportData).map(values => {
              let name = values[0]
              let value = values[1]
              if(name !== exportData.displayName){
                return <input type='hidden'
                              key={name}
                              name={name}
                              value={value.hasOwnProperty("$oid") ? value.$oid : value}/>
              }
              return ""
            })}
            <input type='submit' name='displayName' value={Translate.get(exportData._class)}/>
          </form>
        </li>
      )}
    </ul>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog small frame">
        <div className="title">
          <div className="left icon iconBig export"/>
          <span>{Translate.get('exports.select.title')}</span>
        </div>

        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel" />
          </div>
        </div>

      </div>
    </div>
  }
}

export default ExportSelectDialog