import React, { Component } from 'react'
import Translate from '../costra/Translate'

import CaseInfo from './CaseInfo'

import './navPanel.css'

class CasesList extends Component {

  render() {
    var filteredCases = (this.props.cases || []).filter((item, index) =>
      this.props.casesFilter === "all" ||
        !item.caseRecord.processing.finished ||
        this.props.caseIndex === index
    )
    return <div>
      {filteredCases.map(item =>
        <CaseInfo caseRecord={item}
                  key={item.caseRecord._id.$oid}
                  id={this.props.id}/>
      )}
      {filteredCases.length < 1 && (this.props.casesFilter === "all" ?
        <i>{Translate.get('nav.activeCases.allCases.noCases')}</i>
        :
        <i>{Translate.get('nav.activeCases.activeCases.noCases')}</i>
      )}
    </div>
  }
}

export default CasesList