import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import WsContainers from '../costra/WsContainers.js'

import PageSelector from '../utils/PageSelector.js'
import Table from '../utils/Table.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CaseRecord from '../caserecord/CaseRecord.js'

class Container extends SimpleListContainer {

  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class ExportBatchCases extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    state.layout = ObjectHelper.copy(CaseRecord.defaultLayout)
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.caserecord.ExportCasesList')
    this.selectAll = this.selectAll.bind(this)
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.taskType !== this.props.taskType) {
      this.container.reload()
    }
  }

  selectAll(event) {
    if (event) {
      event.stopPropagation()
    }
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.caserecord.SelectExportBatchCases',
      id: this.props.id,
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <div>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
      </div>

      <Table name="exportCasesLayout"
             withSemaphore={true}
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             sort={(sortName) => this.container.sortBy(sortName)}
             columns={CaseRecord.columns}
             itemFn={(item, index, key) =>
                <CaseRecord.Row layout={this.state.layout} item={item} key={key} selected={this.state.selected}/>
             }/>
    </div>
  }
}

export default ExportBatchCases
