import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import TableColumnsDialog from './TableColumnsDialog.js'
import LoadingPlaceholder from './LoadingPlaceholder.js'
import SortIcon from './SortIcon.js'

import '../css/table.css'

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offsetWidth: null,
      from: null,
    }
  }

  componentDidMount() {
    this.loadPreset(this.props.name)
  }

  loadPreset(name) {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.LoadPreset',
      name: name,
    }).then((result) => {
      if (result.data && this.props.updateLayout) {
        this.props.updateLayout(result.data)
      }
    })
  }

  storePreset(name, data) {
    this.props.updateLayout(data)
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      name: name,
      data: data,
    })
  }

  edit() {
    Dialogs.open(
      <TableColumnsDialog layout={this.props.layout}
                          columns={this.props.columns}/>
    ).then(result => {
      if (result) {
        this.storePreset(this.props.name, result)
      }
    })
  }

  dragStart(event, index) {
    console.log("Active drag started", index)
    this.setState({ offsetWidth: parseInt(event.nativeEvent.srcElement.parentElement.offsetWidth) })
    this.setState({ from: event.pageX })
  }

  dragOver(event) {
    event.stopPropagation()
    event.preventDefault()
  }

  dragEnd(event, index) {
    console.log("Active drag ended", index)
    event.stopPropagation()
    event.preventDefault()
    let pageX = event.pageX > window.screen.availWidth ? event.pageX - window.screen.availWidth : event.pageX

    let from = this.state.from
    let originalWidth = this.state.offsetWidth
    let newWidth = parseInt(originalWidth) + (pageX - from)

    let dcLayout = ObjectHelper.copy(this.props.layout)
    let col = dcLayout.find(x => x.name === index)

    if (col) {
      col.minWidth = (!newWidth ? 150 : newWidth) + 'px'
    }

    this.setState({ offsetWidth: null, from: null })
    if (this.props.updateLayout) {
      this.storePreset(this.props.name, dcLayout)
    }
  }

  getWidth(field) {
    const width = field.minWidth

    // Either the user hasnt dragged yet, so its basic layout, or some error happend while dragging -> back to default layout 
    if(!width) {
      if(field.extraClass) {
        let result = null
        switch(field.extraClass) {
          case "size2c" : result = {width: '100px'}; break;
          case "size3c" : result = {width: '150px'}; break;
          case "size4c" : result = {width: '200px'}; break;
          case "size5c" : result = {width: '250px'}; break;
          case "size6c" : result = {width: '300px'}; break;
          case "size7c" : result = {width: '400px'}; break;
          case "size8c" : result = {width: '500px'}; break;
          default : result = {width: '200px'}; break;
        }
        return result
      }
      return { width : '100px', minWidth: '100px'}
    }
    if(parseInt(width) > 100) {
      return {width: width, minWidth: width}
    }
    else {
      return {width: width, minWidth: '100px'}
    }
  }

  render() {
    if (!this.props.items && !this.props.notNull) {
      return <LoadingPlaceholder />
    }
    else if (this.props.items.length <= 0) {
      return null
    }
    else return <div className="tableContainer">
      <table>
        <thead>
          <tr>
            <td className="statePlaceholder"/>
            {this.props.withSemaphore && <td className="semaphorePlaceholder" />}
            {this.props.toggleSelectAll &&
              <td>
                <input type="checkbox"
                       disabled={this.props.disabled}
                       onChange={(event) => this.props.toggleSelectAll(event.target.checked)}/>
              </td>
            }

            {this.props.layout && this.props.layout.map(field => {
              let column = this.props.columns[field.name]
              if (!column) {
                return <td key={field.name}></td>
              }
              else if (field.name === 'icon') {
                let iconType = field.title ?? column.title
                return <td className="iconPlaceholder" key={field.name}><div className={"icon " + iconType} /></td>
              }
              else {
                return <td key={field.name}
                            style={this.getWidth(field)}
                            onClick={() => this.props.sort && column.sortable && this.props.sort(field.name)}
                            className={column.extraClass + (column.sortable ? ' sortable' : '') + (field.extraClass ? ' ' + field.extraClass : '')}>

                  {Translate.get(column.title)}
                  {this.props.sort && SortIcon.display(field.name, this.props.sortField, this.props.sortOrder)}

                  <div className="resizable"
                       onDragStart={(event) => this.dragStart(event, field.name)}
                       onDragOver={(event) => this.dragOver(event)}
                       onDragEnd={(event) => this.dragEnd(event, field.name)}
                       draggable/>
                </td>
              }
            })}
            <td>
              {this.props.updateLayout &&
                <div className="right button"
                     title="Upravit"
                     onClick={() => this.edit()}>
                  <div className="icon edit" />
                </div>
              }
            </td>
          </tr>
        </thead>
        <tbody>
          {this.props.items.map((item, index) => {
            let key = index
            if (item.item && item.item._id) {
              key = item.item._id.$oid
            }
            else if (item._id) {
              key = item._id.$oid
            }
            return this.props.itemFn(item, index, key)
          })}
        </tbody>

        {this.props.children}
      </table>
    </div>
  }
}

export default Table