import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import User from './User.js'
import Translate from '../costra/Translate.js'

import '../css/dialogs.css'

class UsersArray extends Component {

  constructor(props) {
    super(props)
    this.state = {
      users: null,
      usersMap: null,
      editOpen: false,
    }
  }

  loadUsers() {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.users.GetAllUsers',
    }).then((list) => {
      var map = {}
      if (list.users) {
        list.users.forEach(user =>
          map[user.id.$oid] = user.displayName
        )
      }
      else {
        list.users = []
      }
      this.setState({users: list.users, usersMap: map,  editOpen: false})
    }, () => {
      this.setState({error: 'genericLoadError' })
    })
  }

  componentDidMount() {
    this.loadUsers()
  }

  renderView() {
    var list = this.props.data.get(this.props.path)
    if (!list) {
      return null
    }
    return <div key="content" className="contentCompact">
      {Object.keys(list).map((index) =>
        <div key={index} className="formRow">
          <div className="left button"
               onClick={(event) => User.go(event, ObjectHelper.expand(list[index]))}>
            {this.state.usersMap[ObjectHelper.expand(list[index])]}
          </div>
        </div>
      )}
    </div>
  }

  renderEdit() {
    return <div key="content" className="content">
      {this.state.users.filter(user =>
        !this.props.filterFn || this.props.filterFn(user)
      ).map((user) =>
        <div key={user.id.$oid} className="formRow">
          <label>{user.displayName}</label>
          <input type="checkbox"
                 checked={this.props.data.isIdInArray(this.props.path, user.id)}
                 onChange={(event) => this.props.data.toggleIdInArray(this.props.path, user.id, event.target.checked)}/>
        </div>
      )}
    </div>
  }

  render() {
    if (!this.state.users) {
      return <LoadingPlaceholder/>
    }
    else {
      return <div className="subsection">
        <div key="header">
          <div className="header-row">
            <div className={'icon iconSection ' +  this.props.icon}/>
            <h3>{Translate.get(this.props.title)}</h3>
          </div>
        </div>
        {this.props.disabled ?
          this.renderView()
          :
          this.renderEdit()
        }
      </div>
    }
  }
}

export default UsersArray