import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Dialogs from '../costra/Dialogs.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import Filter from '../utils/Filter.js'
import Metrics from '../utils/Metrics.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import { PageDataContext } from '../PageDataContext.js'

import CaseObject from './CaseObject.js'
import PredProperties from './erl/pred/PredProperties.js'
import VwFsProperties from './vw/fs/VwFsProperties.js'

class FilterDialog extends Component {

  static tasksAndFlags=[
    'com.optimsys.costra.kordex.flag.BackOfficeCheckNeeded',
    'com.optimsys.costra.kordex.flag.CaseDocumentsMissing',
    'com.optimsys.costra.kordex.flag.CaseEntryInvalid',
    'com.optimsys.costra.kordex.flag.CaseExportReady',
    'com.optimsys.costra.kordex.flag.CaseOverdue',
    'com.optimsys.costra.kordex.flag.CaseStartDelayed',
    'com.optimsys.costra.kordex.flag.ExpediteCaseProcessing',
    'com.optimsys.costra.kordex.flag.ObjectDocumentsMissing',
    'com.optimsys.costra.kordex.flag.ObjectProcessingStopped',
    'com.optimsys.costra.kordex.flag.RestoreObject',
    'com.optimsys.costra.kordex.flag.GpsMissing',
    'com.optimsys.costra.kordex.flag.PlannedPastDeadline',
    'com.optimsys.costra.kordex.flag.ObjectLockedDown',

    'com.optimsys.costra.kordex.task.CollectionTask',
    'com.optimsys.costra.kordex.task.ContactTask',
    'com.optimsys.costra.kordex.task.GenericCaseTask',
    'com.optimsys.costra.kordex.task.VisitTask',
  ].map(cls => [cls, cls]).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  constructor(props) {
    super(props)
    var state={
      filter: {}
    }
    if (this.props.filter) {
      state.filter=ObjectHelper.copy(this.props.filter)
    }
    this.state=state
    this.data=new EditData(this, 'filter.')
  }

  applyFilter(event) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: 'com.optimsys.costra.kordex.caseobject.ObjectsList$Filter',
      name: this.props.name,
      data: [this.state.filter],
    }).then((result) => {
      Dialogs.close(this.state.filter)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  render() {
    if (this.state.error) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <ErrorPlaceholder error={this.state.error}/>
          <div className="buttons">
            <div className="right-position">
              <div className="button" onClick={(event) => Dialogs.close(null)}>
                <div className="icon iconBig cancel"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig campaign"/>
          <span>{Translate.get('caseobject.filter.campaign.title')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={this.context.accessibleCampaigns}
                        pathName='campaigns'
                        placeholder='caseobject.filter.campaigns'
                        label='caseobject.filter.campaigns'/>

        <div className="title spacedTop">
          <div className="icon iconBig inProgress"/>
          <span>{Translate.get('caseobject.filter.flagsAndTasks')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.tasksAndFlags}
                        pathName='withFlags'
                        placeholder='caseobject.filter.flagsAndTasks'
                        label='filter.mustContain'/>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.tasksAndFlags}
                        pathName='withoutFlags'
                        placeholder='caseobject.filter.flagsAndTasks'
                        label='filter.mustNotContain'/>

        <div className="title spacedTop">
          <div className="icon iconBig semaphore"/>
          <span>{Translate.get('caseobject.filter.semaphore.title')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={Metrics.Semaphore}
                        pathName='withScore'
                        placeholder='caseobject.filter.score'
                        label='filter.mustContain'
                        attribute='text'/>
        <Filter.FromMap data={this.data}
                        array={Metrics.Semaphore}
                        pathName='withoutScore'
                        placeholder='caseobject.filter.score'
                        label='filter.mustNotContain'
                        attribute='text'/>

        <div className="title spacedTop">
          <div className="icon iconBig time"/>
          <span>{Translate.get('caseobject.filter.time.title')}</span>
        </div>
        <Filter.DateRangeRow data={this.data}
                             pathName='created'
                             title='caseobject.filter.created'/>
        <Filter.DateRangeRow data={this.data}
                             pathName='finished'
                             title='caseobject.filter.finished'/>

        <div className="title spacedTop">
          <div className="icon iconBig task"/>
          <span>{Translate.get('caseobject.filter.state.title')}</span>
        </div>
        <Filter.FromArrayOfObjects data={this.data}
                                   array={CaseObject.states}
                                   pathName='states'
                                   placeholder='caseobject.filter.states'
                                   label='caseobject.filter.states'/>
        <Filter.FromArrayOfObjects data={this.data}
                                   array={CaseObject.technicalCondition}
                                   pathName='technicalConditions'
                                   placeholder='caseobject.filter.technicalConditions'
                                   label='caseobject.filter.technicalConditions'/>
        <Filter.FromArrayOfObjects data={this.data}
                                   array={PredProperties.carUsage}
                                   pathName='usage'
                                   placeholder='caseobject.erl.pred.usage'
                                   label='caseobject.erl.pred.usage'/>
        <Filter.FromArrayOfObjects data={this.data}
                                   array={PredProperties.workingCondition}
                                   pathName='workingCondition'
                                   placeholder='caseobject.erl.pred.workingCondition'
                                   label='caseobject.erl.pred.workingCondition'/>
        <Filter.FromArrayOfObjects data={this.data}
                                   array={VwFsProperties.tpStates}
                                   pathName='tpStates'
                                   placeholder='caseobject.vw.fs.tpState'
                                   label='caseobject.vw.fs.tpState'/>
        <Filter.CheckboxRow data={this.data}
                            pathName='isClientOwnerOfVTP'
                            placeholder='caseobject.vw.fs.isClientOwnerOfVTP'
                            label='caseobject.filter.isClientOwnerOfVTP'/>
        <Filter.CheckboxRow data={this.data}
                            pathName='wantsVwToBeOwnerOfVTP'
                            placeholder='caseobject.vw.fs.wantsVwToBeOwnerOfVTP'
                            label='caseobject.filter.wantsVwToBeOwnerOfVTP'/>
        <Filter.TwoInputsRow data={this.data}
                             pathName1='odometerStateFrom'
                             pathName2='odometerStateTo'
                             label='caseobject.filter.odometerState'/>

        <div className="title spacedTop">
          <div className="icon iconBig task"/>
          <span>{Translate.get('caseobject.filter.properties.title')}</span>
        </div>
        <Filter.TwoInputsRow data={this.data}
                             pathName1='yearFrom'
                             pathName2='yearTo'
                             label='caseobject.properties.productionYear'/>
        <Filter.InputRow data={this.data}
                         pathName='distributor'
                         placeholder='caseobject.filter.distributor'
                         label='caseobject.filter.distributor'/>
        <Filter.TwoInputsRow data={this.data}
                             pathName1='priceFrom'
                             pathName2='priceTo'
                             label='caseobject.properties.purchasePrice'/>

        <div className="buttons">
          <div className="button" onClick={(event) => this.applyFilter(event)}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button" onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>

      </div>
    </div>
  }

}
FilterDialog.contextType=PageDataContext

export default FilterDialog