import React from 'react'
import Translate from '../../../costra/Translate.js'

import Input from '../../../utils/Input.js'

import '../../../css/form.css'

const insuranceTypes = [
  {value: 'POV', title: 'sal.collection.insurance.insuranceType.POV'},
  {value: 'HAV', title: 'sal.collection.insurance.insuranceType.HAV'},
  {value: 'MAJ', title: 'sal.collection.insurance.insuranceType.MAJ'},
  {value: 'Other', title: 'sal.collection.insurance.insuranceType.Other'},
]

const errorCodes = [
  {value: 'NoError', title: 'sal.collection.insurance.errorCode.NoError'},
  {value: 'Unverified', title: 'sal.collection.insurance.errorCode.Unverified'},
  {value: 'Invalid', title: 'sal.collection.insurance.errorCode.Invalid'},
  {value: 'Unknown', title: 'sal.collection.insurance.errorCode.Unknown'},
]

function Insurance(props) {
  const insurance = props.data.get('insurance') || []
  if (insurance.length <= 0 && props.disabled) {
    return null
  }
  return <div className="subsection">
    <div className="header-row">
      <div className="icon iconSection document" />
      <h3>{Translate.get('sal.collection.insurance')}</h3>
      {!props.disabled &&
        <div className="button right" onClick={event => props.data.addToArray('insurance', {})}>
          <div className="icon add"/>
          {Translate.get('sal.collection.insurance.add')}
        </div>
      }
    </div>

    <ul className="list">
      {insurance.map((insurance, index) =>
        <li key={index}>
          <div className="row">
            <div key="icon"><div className="icon document"/></div>
            <Input.Select data={props.data}
                        disabled={props.disabled}
                        path={'insurance.' + index + '.insuranceType'}
                        title='sal.collection.insurance.insuranceType'
                        className="flex1c"
                        values={insuranceTypes}/>
            <Input.Select data={props.data}
                          disabled={props.disabled}
                          path={'insurance.' + index + '.errorCode'}
                          title='sal.collection.insurance.errorCode'
                          className="flex2c"
                          values={errorCodes}/>
            <Input.Select data={props.data}
                          disabled={props.disabled}
                          path={'insurance.' + index + '.securityErrorCode'}
                          title='sal.collection.insurance.securityErrorCode'
                          className="flex2c"
                          values={errorCodes}/>
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'insurance.' + index + '.contractNo'}
                        title='sal.collection.insurance.contractNo'
                        className="flex4c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'insurance.' + index + '.company'}
                        title='sal.collection.insurance.company'
                        className="flex8c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'insurance.' + index + '.sysCode'}
                        title='sal.collection.insurance.sysCode'
                        className="flex2c" />
            {!props.disabled &&
              <div className="button right"
                   title={Translate.get('sal.collection.insurance.remove')}
                   onClick={event => props.data.removeFromArray('insurance', index)}>
                <div className="icon cancel"/>
              </div>
            }
          </div>
        </li>
      )}
    </ul>
  </div>
}

export default Insurance
