import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import ConfirmDialog from '../utils/ConfirmDialog.js'
import Nav from '../utils/Nav.js'
import FileImage from '../img/file.jpeg'

import RotateImageDialog from './RotateImageDialog.js'

class File {

  static fileTypes = [
    "ObjectPhoto",
    "ObjectPhotoVin",
    "Papers",
    "Contract",
    "Signature",
    "ClosingDocument",
    "LegalDocument",
    "Unknown",
  ]

  static go(event, id) {
    Nav.go(event, '/download/file?id=' + id, true)
  }

  static remove(event, item) {
    event.stopPropagation()
    Dialogs.open(
      <ConfirmDialog text={Translate.get('files.remove.prompt', null, {name: item.info.fileName})}/>
    ).then((confirmed) => {
      if (confirmed) {
        return WsContainers.action({
          _class: 'com.optimsys.costra.kordex.file.RemoveFile',
          id: item._id,
        })
      }
    }, () => {
      this.setState({error: 'genericStoreError'})
    })
  }

  static rotate(event, item) {
    event.stopPropagation()
    Dialogs.open(
      <RotateImageDialog item={item}/>
    )
  }

  static Row(props) {

    return <div className="imageContainer" key={props.item.item.file.id}>
      <div className="header-row">
        <h3>{Translate.get('files.fileType.' + props.item.item.fileType)}</h3>
        <div className="right">
          {props.item.item.thumbnailFile &&
            <div className="button"
                 title={Translate.get('files.button.rotate')}
                 onClick={(event) => File.rotate(event, props.item.item)}>
              <div className="icon rotate"/>
            </div>
          }
          {props.item.allowEdit &&
            <div className="button"
                 title={Translate.get('files.button.remove')}
                 onClick={(event) => File.remove(event, props.item.item)}>
              <div className="icon remove"/>
            </div>
          }
        </div>
      </div>

      <div className="left text">
        {props.item.item.info.fileName}
      </div>
      <div className="left text">
        {Formatter.formatDateTime('%d.%m. %yyyy %HH:%MM', props.item.item.time) + ' ' +
          Translate.get('files.source.' + props.item.source)}
      </div>

      {/* file.id is used as additional download param here to trigger reload if content changes */}
      {props.item.item.thumbnailFile ?
        <img src={'/download/thumbnail?id=' + props.item.item._id.$oid + '&key=' + props.item.item.file.id}
             alt={props.item.item.info.fileName}
             title={props.item.item.note}
             onClick={(event) => File.go(event, props.item.item._id.$oid)}/>
        :
        <img src={FileImage}
             alt={props.item.item.info.fileName}
             title={props.item.item.note}
             onClick={(event) => File.go(event, props.item.item._id.$oid)}/>
      }
    </div>
  }
}

export default File