import Id from './Id.js'
import ObjectHelper from './ObjectHelper.js'

class Translate {

  static language = ''
  static values = {}
  static loadProc = null
  static stateProcs = {}

  static init(language, loadProc) {
    Translate.loadProc = loadProc
    Translate.setLanguage(language)
  }

  static setLanguage(language) {
    console.log("#Translate using language", language)
    Translate.language = language
    if (Translate.values[language]) {
      Object.keys(Translate.stateProcs).forEach(key =>
        Translate.stateProcs[key](language, true)
      )
    }
    else {
      Object.keys(Translate.stateProcs).forEach(key =>
        Translate.stateProcs[key](language, false)
      )
      Translate.values[language] = {}
      Translate.loadProc(language).then(() => {
        console.log("#Translate language loaded", language)
        Object.keys(Translate.stateProcs).forEach(key =>
          Translate.stateProcs[key](language, true)
        )
      }, (error) => {
        console.log("#Translate cannot load language", error)
        Object.keys(Translate.stateProcs).forEach(key =>
          Translate.stateProcs[key](language, true)
        )
      })
    }
  }

  static loadFile(language, path) {
    console.log("#Translate loading file", language, path)
    return fetch(path, {method: 'GET'}).then((result) => {
      if (result.status < 200 || result.status > 202) {
        console.log("#Translate cannot load file", language, path)
        return {}
      }
      else {
        return result.json()
      }
    }).then((values) => {
      Object.keys(values).forEach(key =>
        Translate.values[language][key] = values[key]
      )
    })
  }

  static get(identifier, language, params) {
    if (!language) {
      language = Translate.language
    }
    if (!Translate.values[language]) {
      return identifier
    }
    var value = Translate.values[language][identifier]
    if (!value) {
      return identifier
    }
    if (params) {
      Object.keys(params).forEach(key =>
        value = value.replace('%' + key + '%', ObjectHelper.expand(params[key]))
      )
    }
    return value
  }

  static onStateChange(proc) {
    var id = Id.get()
    Translate.stateProcs[id] = proc
    return {
      cancel: () => {
        delete Translate.stateProcs[id]
      }
    }
  }
}

export default Translate