import React, { Component } from 'react';

import Router from './costra/Router.js'

import './css/index.css'
import './css/icons.css'
import Translate from './costra/Translate.js';

class SearchBox extends Component {

  constructor(props) {
    super(props)
    this.state = {
      search: '',
    }
  }

  submitSearch(event) {
    event.preventDefault()
    Router.go('/search/' + encodeURIComponent(this.state.search))
  }

  render() {
    return (
      <div className="searchBox">
        <form onSubmit={(event) => this.submitSearch(event)}>
          <input type="text" placeholder={Translate.get('search.placeholder')} onChange={(event) => this.setState({search: event.target.value})}/>
          <div className="button"
               onClick={(event) => this.submitSearch(event)}>
            <div className="icon search"/>
          </div>
        </form>
      </div>
    )
  }
}

export default SearchBox