import React, { Component } from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import CaseObject from '../../CaseObject.js'
import '../../../css/form.css'

import Accessories from './Accessories.js'

function checkColor(score){
  switch(score){
    case 1 : return "orangeInput" 
    case 2 : return "redInput"
    default : return ""
  }   
}

function getColor(array, path, data) {
  let wanted = array.find(object => object.value === data.get(path))
  return checkColor(wanted.score)
}

class SfCarProperties extends Component {

  static odometerCheck = [
    {value: 'NotChecked', title:  'caseobject.sal.sfcar.odometerCheck.NotChecked' , score: 0},
    {value: 'Checked', title:  'caseobject.sal.sfcar.odometerCheck.Checked', score: 0},
    {value: 'NoBattery', title:  'caseobject.sal.sfcar.odometerCheck.NoBattery', score: 1},
    {value: 'NoKeys', title: 'caseobject.sal.sfcar.odometerCheck.NoKeys', score: 1},
    {value: 'NoOdometer', title: 'caseobject.sal.sfcar.odometerCheck.NoOdometer' , score: 0},
  ]

  render(){
    return <>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection car"/>
          <h3>{Translate.get('caseobject.properties.title')}</h3>
        </div>
        <div className="content">
          {this.props.editOpen && <>
            <Form.Text data={this.props.component.data}
                       disabled={!this.props.editOpen}
                       path="sfrNo"
                       title='caseobject.sal.sfcar.sfrNo'/>
            <Form.Text data={this.props.component.data}
                       disabled={!this.props.editOpen}
                       path="checkNo"
                       title='caseobject.sal.sfcar.checkNo'/>
          </>}
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="name"
                     title='caseobject.properties.objectName'
                     validate={Validator.notEmptyString}/>
          <Form.Address data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="address"
                        title='caseobject.properties.address'
                        caseIds={[this.props.component.data.get("caseId")]}/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="vin"
                     title='caseobject.properties.vin'
                     validate={Validator.notEmptyString}
                     styling={Metrics.CheckVin(this.props.component.data.get("vin"),"Vin")}/>
          <Form.Select data={this.props.component.data}
                       disabled={!this.props.editOpen}
                       path="technicalCondition"
                       title='caseobject.properties.technicalCondition'
                       values={CaseObject.technicalCondition}
                       styling={getColor(CaseObject.technicalCondition, "technicalCondition", this.props.component.data)}/>
          <Form.Select data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="odometerCheck"
                        title='caseobject.sal.sfcar.odometerCheck'
                        values={SfCarProperties.odometerCheck}
                        styling={getColor(SfCarProperties.odometerCheck, "odometerCheck", this.props.component.data)}/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="odometerState"
                     title='caseobject.sal.sfcar.odometerState'
                     validate={
                        this.props.item.odometerCheck !== 'Checked' ? null : Validator.notEmpty
                     }/>
        </div>

        <Form.TextArea data={this.props.component.data}
                      disabled={!this.props.editOpen}
                      path="exportedNote"
                      styling="textArea big"
                      title='caseobject.properties.exportedNote'/>
      </div>

      <Accessories data={this.props.component.data}
                   disabled={!this.props.editOpen}
                   path="accessories"/>

      {this.props.component.data.get('contactData') && this.props.component.data.get('contactData').map((contactData, index) =>
        <ContactData key={index}
                     data={this.props.component.data}
                     path={'contactData.' + index}
                     disabled={!this.props.editOpen}/>
      )}
    </>
  }
}

export default SfCarProperties
