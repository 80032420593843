import { Component } from 'react'
import Translate from '../costra/Translate'
import Dialogs from '../costra/Dialogs.js'
import Formatter from '../costra/Formatter.js'
import DateHelper from '../costra/DateHelper.js'
import WsContainers from '../costra/WsContainers.js'

import Nav from '../utils/Nav.js'
import Metrics from '../utils/Metrics.js'

import Event from '../events/Event.js'
import CaseRecord from '../caserecord/CaseRecord.js'
import TaskEditDialog from './TaskEditDialog.js'
import CreateTaskDialog from './CreateTaskDialog.js'

class Task extends Component {

  static taskClasses = [
    'com.optimsys.costra.kordex.task.CollectionTask',
    'com.optimsys.costra.kordex.task.ContactTask',
    'com.optimsys.costra.kordex.task.GenericCaseTask',
    'com.optimsys.costra.kordex.task.PaymentPromiseTask',
    'com.optimsys.costra.kordex.task.VisitTask',
  ]

  static Icon(props) {
    var taskClass = props.task ? props.task._class : props.taskClass
    var icon = 'alert'
    switch (taskClass) {
      case 'com.optimsys.costra.kordex.task.CollectionTask': icon = 'taskCollection'; break;
      case 'com.optimsys.costra.kordex.task.ContactTask': icon = 'taskContact'; break;
      case 'com.optimsys.costra.kordex.task.GenericCaseTask': icon = 'taskGeneric'; break;
      case 'com.optimsys.costra.kordex.task.PaymentPromiseTask': icon = 'coins'; break;
      case 'com.optimsys.costra.kordex.task.VisitTask': icon = 'taskVisit'; break;
      //case 'com.optimsys.costra.kordex.task.VtpPromise': icon = 'taskVtpPromise'; break;
      default: break;
    }
    return <div className={'icon left ' + icon + ' ' + props.extraClass} title={Translate.get(taskClass)}/>
  }

  static closeTask(task) {
    task.discardedAt = DateHelper.now()
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.UpdateTask',
      item: task,
    })
  }

  static reopenTask(task) {
    task.discardedAt = null
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.UpdateTask',
      item: task,
    })
  }

  static editDialog(task) {
    return Dialogs.open(<TaskEditDialog task={task}/>)
  }

  static continueDialog(task) {
    Dialogs.open(<CreateTaskDialog caseIds={task.caseIds} userId={task.userId}/>)
  }

  static Header(props) {
    return <div className="row rowHeader ">
      <div><Task.Icon taskClass={props.taskClass}/></div>
      <div className="size4c">{Translate.get('task.header.deadline')}</div>
      <div className="size4c">{Translate.get('task.header.user')}</div>
      <div className="size6c">{Translate.get('task.header.client')}</div>
      <div className="size4c">{Translate.get('task.header.case')}</div>
      <div>{Translate.get('task.header.address')}</div>
    </div>
  }

  static renderTaskButtons(task) {
    return <>
      {task.caseIds &&
        <div className="button right"
             title={Translate.get('task.button.case')}
             key={task.caseIds[0].$oid}
             onClick={event => CaseRecord.go(event, task.caseIds[0].$oid)}>
          <div className="icon case"/>
        </div>
      }
      {!task.result &&
        <div className="button right"
             title={Translate.get('task.button.close')}
             onClick={() => Task.closeTask(task)}>
          <div className="icon cancel"></div>
        </div>
      }
      <div className="button"
           title={Translate.get('task.button.continue')}
           onClick={() => Task.continueDialog(task)}>
        <div className="icon add"></div>
      </div>
      <div className="button"
           title={Translate.get('task.button.newTab')}
           onClick={evt => Nav.go(evt, '/taskinfo/' + task._id.$oid, true)}>
        <div className="icon newWindow"></div>
      </div>
      <div className="button"
           title={Translate.get('task.button.open')}
           onClick={evt => Nav.go(evt, '/taskinfo/' + task._id.$oid)}>
        <div className="icon task"></div>
      </div>
    </>
  }

  static Row(props) {
    let user = props.users[props.item.item.userId.$oid]
    return <div className={"row multiple-columns " + Metrics.TaskColor(props.item.item)}>
      <div className='row'>
        <Task.Icon task={props.item.item}/>
        <div className="size4c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", props.item.item.deadlineAt )}</div>
        <div className="size4c">{user && user.displayName}</div>
        <div className="size6c">{props.item.firstCaseRecord && props.item.firstCaseRecord.client.displayName}</div>
        <div className="size4c">
          {props.item.firstCaseRecord && props.item.item.caseIds && (props.item.item.caseIds.length === 1 ?
            props.item.firstCaseRecord.caseNo
            : "Případů: " + props.item.item.caseIds.length
          )}
        </div>
        <div>
          {props.item.item.address && props.item.item.address.matchStr}
        </div>
        {Task.renderTaskButtons(props.item.item)}
      </div>
      {props.item.events.map(event =>
        <div className="row">
          <div><Event.Icon event={event}/></div>
          <div className="size4c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", event.time)}</div>
          <div className="flexRest multiline">
            {event.note ? event.note : Translate.get(event._class)}
          </div>
        </div>
      )}
    </div>
  }
}

export default Task