import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import '../css/dialogs.css'

function ConfirmDialog(props) {
  return (
    <div className="dialogsContainer">
      <div className="dialog small frame">

        <p>{Translate.get(props.text)}</p>
        <div className="buttons">
          <div className="button"
               onClick={(event) => Dialogs.close(true)}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               onClick={(event) => Dialogs.close(false)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ConfirmDialog