import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import BatchesList from './BatchesList.js'

import '../css/list.css'
import '../css/icons.css'

class Exports extends Component {
  
  render() {
    return <div className="mainPanel">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig export"/>
          <h1>{Translate.get('exports.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          <BatchesList page={this.props.page}/>
        </div>
      </div>
    </div>
  }
}

export default Exports