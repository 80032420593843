import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js';
import Formatter from '../costra/Formatter.js';
import EditData from '../costra/EditData.js';

import '../css/dialogs.css';
import Form from '../utils/Form.js';
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js';
import Translate from '../costra/Translate.js';

class SelectAddressDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      addresses : null,
      error : null,
      address: props.address,
    }
    this.data = new EditData(this, '')
  }

  componentDidMount() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.addressbook.GetAddresses',
      caseIds: this.props.caseIds,
    }).then((result) => {
      this.setState({ addresses: result.addresses })
    }, () => {
      this.setState({ error: 'Nelze načíst záznam' })
    })
  }

  renderAddressRow(address, index) {
    if (address.matchStr === '') {
      return null
    }
    return <li key={index} >
      <div className='row withHover'
           onClick={()=> this.setState({address: address})}>
        <div>{Formatter.address(address)}</div>
      </div>
    </li>
  }

  renderContent() {
    if (this.state.addresses === null) {
      return <LoadingPlaceholder/>
    }
    return <>
      <div>
        <Form.Text data={this.data}
                   path="address.street"
                   title='contactData.address.street'/>
        <Form.Text data={this.data}
                   path="address.streetCode"
                   title='contactData.address.streetCode'/>
        <Form.Text data={this.data}
                   path="address.city"
                   title='contactData.address.city'/>
        <Form.Text data={this.data}
                   path="address.postCode"
                   title='contactData.address.postCode'/>
        <Form.Text data={this.data}
                   path="address.area"
                   title='contactData.address.area'/>
        <Form.Text data={this.data}
                   path="address.country"
                   title='contactData.address.country'/>
      </div>
      {this.state.addresses &&
        <ul className="list spacedBottom">
          {this.state.addresses.map((address, index) => this.renderAddressRow(address, index))}
        </ul>
      }
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
      <div className="title">
        <span>{Translate.get('nav.selectAddress.title')}</span>
      </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('apply')}
               onClick={(event) => Dialogs.close(this.data.get('address'))}>
            <div className="icon iconBig ok" />
          </div>
          <div className="button"
               title={Translate.get('cancel')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel" />
          </div>
        </div>
      </div>
    </div>
  }
}

export default SelectAddressDialog