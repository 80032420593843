import ObjectHelper from './ObjectHelper.js'

class Validator {

  static notEmptyString(value) {
    return (value && value.toString().trim().length > 0)
  }

  static notEmptyId(value) {
    if (!value) {
      return false
    }
    value = ObjectHelper.expand(value)
    return (value && value !== '000000000000000000000000')
  }

  static notEmpty(value) {
    return (value !== null && value !== undefined && value !== '')
  }

  static number(value) {
    if (!value) {
      return true
    }
    return value.toString().match('^[0-9 ]+[,.]?[0-9 ]*$')
  }

}

export default Validator
