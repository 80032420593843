import React, { Component } from 'react'
import EditData from '../costra/EditData.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'

import '../css/dialogs.css'
import Event from './Event.js'

class EventEditDialog extends Component {

  static lockdownMethods = ['WheelClamp', 'LicensePlates', 'Deposit']

  constructor(props) {
    super(props)
    this.state = {
      event : ObjectHelper.copy(this.props.event),
    }
    this.data = new EditData(this, 'event.')
  }

  updateEvent() {
    this.setState({working: true})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.processing.UpdateEvent',
      event: this.state.event,
    }).then((result) => {
      Dialogs.close(result)
    }, () => {
      this.setState({ error : 'event.updateError' })
    })
  }

  render() {
    if(this.state.working) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <LoadingPlaceholder/>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className='title'>
          <Event.Icon eventClass={this.props.event._class} extraClass="iconBig"/>
          <span>
            {Translate.get(this.props.event._class) + ' ' +
              Translate.get('event.form.ownersCount', null, {count: this.state.event.owner ? this.state.event.owner.length : 0})}
          </span>
        </div>
        <div>
          <Form.DateTime data={this.data}
                         path="time"
                         title='event.form.time'
                         withTime={true}
                         dontAllowClear={true}
                         format="%d.%m.%yyyy %HH:%MM"/>
          {this.state.event.address &&
            <Form.Address data={this.data}
                          path="address"
                          title='contactData.address.title'
                          caseIds={this.state.event.owner}/>
          }

          {this.state.event._class === 'com.optimsys.costra.kordex.events.ObjectSold' &&
            <Form.DateTime data={this.data}
                           path="sellDate"
                           title='event.form.sellDate'
                           format="%d.%m.%yyyy"/>
          }

          {(this.state.event._class === 'com.optimsys.costra.kordex.events.ObjectInDemo' ||
            this.state.event._class === 'com.optimsys.costra.kordex.events.ObjectLent' ||
            this.state.event._class === 'com.optimsys.costra.kordex.events.ObjectServiced') &&
            <Form.DateTime data={this.data}
                           path="sellDate"
                           title='event.form.sellDate'
                           format="%d.%m.%yyyy"/>
          }

          {this.state.event._class === 'com.optimsys.costra.kordex.events.ObjectLockdownStateChanged' &&
            EventEditDialog.lockdownMethods.map(method =>
              <div className="formRow"
                   key={method}>
                <label>{Translate.get('lockdownMethod.' + method)}:</label>
                <input type="checkbox"
                       checked={this.data.isInArray('methods', method)}
                       onChange={event => this.data.toggleInArray('methods', method, event.target.checked)}/>
              </div>
            )
          }

          <Form.TextArea data={this.data}
                         path="note"
                         title='event.form.note'/>
        </div>
        <div className="buttons spacedTop">
          <div className="button"
               title={Translate.get('apply')}
               onClick={() => this.updateEvent()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default EventEditDialog