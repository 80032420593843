import React, { Component } from 'react'
import ObjectHelper from '../costra/ObjectHelper.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'
import ListContainer from '../utils/ListContainer.js'
import Nav from '../utils/Nav.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import Table from '../utils/Table.js'

import Item from './Item.js'

import '../css/list.css'
import '../css/icons.css'

class Items extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.layout = ObjectHelper.copy(Item.defaultLayout)
    state.itemsPerPage = 30
    state.error = null
    this.state = state
    this.container = new ListContainer(
      this, 'com.optimsys.costra.kordex.addressbook.ItemsList',
      error => this.setState({error: error})
    )
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  renderContent() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={Number(this.props.page ?? 1)}
                      pages={this.state.pages}
                      onChange={(event, page) => {
                        Nav.addUrlParam(event, 'page', page)}
                      }/>
        {this.state.allowAdd &&
          <div className="button right" title="Nový záznam"
               onClick={(event) => Item.create(event)}>
            <div className="icon left addressBookItemAdd"/>
            <span className="left">{Translate.get('addressbook.button.new')}</span>
          </div>
        }
      </div>

      <Table name="addressBookLayout"
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             columns = {Item.columns}
             itemFn={ (item, index, key) =>
              <Item.Row layout={this.state.layout} item={item} key={key}/>
             }/>
    </>
  }

  render() {
    return <div className="mainPanel withTable">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig addressBook"/>
          <h1>{Translate.get('addressbook.list.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderContent()}
        </div>
      </div>
    </div>
  }
}

export default Items