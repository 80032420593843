import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import PageSelector from '../utils/PageSelector.js'
import Table from '../utils/Table.js'

import CaseRecord from './CaseRecord.js'
import '../css/list.css'
import '../css/icons.css'

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params['importId'] = this.component.props.id
    return params
  }
}
  
class ImportedCases extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    state.layout = ObjectHelper.copy(CaseRecord.defaultLayout)
    state.sortField = 'plannedEnding'
    state.sortOrder = -1
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.caserecord.ImportedCases')
  }

  componentDidMount() {
    this.container.reload()
  }

  componentDidUpdate(oldProps) {
    if (oldProps.importId !== this.props.importId) {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
      </div>

      <Table name="importedCasesLayout"
             withSemaphore={true}
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             sort={(sortName) => this.container.sortBy(sortName)}
             columns={CaseRecord.columns}
             itemFn={(item, index, key) =>
                <CaseRecord.Row layout={this.state.layout} item={item} key={key} selected={this.state.selected}/>
             }/>
    </>
  }
}

export default ImportedCases