import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'
import Dialogs from '../costra/Dialogs.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import AnnotatedPaymentPlan from './AnnotatedPaymentPlan.js'
import ReceivedPayments from './ReceivedPayments.js'
import AddPaymentDialog from './AddPaymentDialog.js'
import AddInvoiceDialog from './AddInvoiceDialog.js'
import '../css/list.css'
import '../css/icons.css'
import '../css/form.css'

class CasePaymentPlan extends Component {

  constructor(props) {
    super(props)
    this.state = {
      plans: null,
      planIndex: 0,
      calculationIndex: 0,
      relevantOnly: true,
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.paymentplan.FetchPaymentPlans',
      caseId: this.props.caseId,
    }).then((result) => {
      this.setState({
        plans: result.plans ? result.plans : [],
        planIndex: 0,
        calculationIndex: 0,
        relevantOnly: true,
      })
    })
  }

  addPayment() {
    Dialogs.open(
      <AddPaymentDialog caseId={this.props.caseId}/>
    ).then(result => {
      if (result) {
        this.setState({plans: null})
        this.load()
      }
    })
  }

  addInvoice() {
    Dialogs.open(
      <AddInvoiceDialog caseId={this.props.caseId}/>
    ).then(result => {
      if (result) {
        this.setState({plans: null})
        this.load()
      }
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.caseId !== this.props.caseId) {
      this.load()
    }
  }

  toggleItemExpanded(index) {
    var expanded = this.state.expanded
    expanded[index] = !expanded[index]
    this.setState({ expanded: expanded })
  }

  renderReceived(plan) {
    return <ul className="list">
      <li>
        <div className="row rowHeader">
          <div className="icon-placeholder" />
          <div className="size4c">{Translate.get('paymentplan.received.date')}</div>
          <div className="size6c">{Translate.get('paymentplan.received.id')}</div>
          <div className="size4c">{Translate.get('paymentplan.received.amount')}</div>
        </div>
      </li>
      {plan.received && plan.received.map((item, index) =>
        <li key={index}>
          <div className="row">
            <div><div className="icon coins" /></div>
            <div className="size4c">{Formatter.formatDateTime("%d.%m. %yyyy", item.time)}</div>
            <div className="size6c">{item.id}</div>
            <div className="size4c">{Formatter.formatAmount(item.amount) + ' ' + item.currency}</div>
            <div>{item.note}</div>
          </div>
        </li>
      )}
    </ul>
  }


  selectPlan(index) {
    let plan = this.state.plans[index]
    if (plan.relevantDates && this.state.calculationIndex < plan.relevantDates.length) {
      this.setState({planIndex: index})
    }
    else {
      this.setState({planIndex: index, calculationIndex: 0})
    }
  }

  render() {
    if (!this.state.plans) {
      return <LoadingPlaceholder />
    }
    var currentPlan = this.state.planIndex >= 0 && this.state.plans[this.state.planIndex]
    return <div>
      <div className="controlsHeader spacedTop">
        <div>
          {this.state.plans.length > 0 ?
            <>
              <select value={this.state.planIndex}
                      onChange={(event) => this.selectPlan(event.target.value)}>
                {this.state.plans.map((plan, index) =>
                  <option key={plan.id.$oid} value={index}>
                    {Translate.get('paymentPlan.type.' + plan.planType) + ' ' +
                      Formatter.formatDateTime("%d.%m. %yyyy %HH:%MM", plan.time)}
                  </option>
                )}
              </select>
              {currentPlan && currentPlan.relevantDates &&
                <select value={this.state.calculationIndex}
                        onChange={(event) => this.setState({calculationIndex: event.target.value })}>
                  {currentPlan.relevantDates.map((date, index) =>
                    <option key={date} value={index}>{Translate.get('paymentplan.calculation') + ' ' +
                      Formatter.formatDateTime("%d.%m. %yyyy", date)}</option>
                  )}
                  <option value="-1">{Translate.get('paymentplan.calculation.received')}</option>
                </select>
              }
              {currentPlan && this.state.calculationIndex >= 0 &&
                <div>
                  {Translate.get('paymentplan.relevantOnly')}
                  <input type="checkbox"
                         checked={this.state.relevantOnly}
                         onChange={(event) => this.setState({relevantOnly: event.target.checked})}/>
                </div>
              }


            </>
            :
            <span>{Translate.get('paymentplan.noCalendar')}</span>
          }
        </div>
        <div className="right">
          <div className='button' onClick={() => this.addPayment()}>
            <div className="icon left select"></div>
            <div>{Translate.get('paymentplan.select.button.addPayment')}</div>
          </div>
          <div className='button margin7' onClick={() => this.addInvoice()}>
            <div className="icon left select"></div>
            <div>{Translate.get('paymentplan.select.button.addInvoice')}</div>
          </div>
        </div>
      </div>
      {currentPlan && this.state.calculationIndex >= 0 &&
        <AnnotatedPaymentPlan planId={currentPlan.id.$oid}
                              date={currentPlan.relevantDates[this.state.calculationIndex]}
                              relevantOnly={this.state.relevantOnly}/>
      }
      {currentPlan && this.state.calculationIndex < 0 &&
        <ReceivedPayments planId={currentPlan.id.$oid}/>
      }
    </div>
  }

}

export default CasePaymentPlan
