import React, { Component } from 'react'
import Router from '../costra/Router.js'
import Translate from '../costra/Translate.js'
//import Metrics from '../utils/Metrics.js'

import './navPanel.css'

class ItemInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      abDetailOpen: true,
    }
  }

  /*selectedColor(state) {
    switch (true) {
      case state === "Pending": return ' orangeSelected'
      case state === "Errors": return ' redSelected'
      case state === "Finished": return ' greenSelected'
      default: return ' redSelected'
    }
  }*/
  canView(item){
    return item.allowView? " allowView" : ""
  }

  renderContactData(item, position, promises) {
    return <>
      <div className='navPanel__object__header'>
        <div className='flex'>
          <div className="ratingIcon" />
          <div>{item && item.displayName} {position && "(" + Translate.get(position) +")"}</div>
        </div>
        {promises &&
          <div className='flex' title={Translate.get('contactData.promisesOverview')}>
            <span className="redText">{promises.noJoy}</span><span>/</span>
            <span className="yellowText">{promises.partial}</span><span>/</span>
            <span className="greenText">{promises.full}</span><span>/</span>
            <span className="greyText">{promises.cancelled}</span><span>/</span>
            <span className="greyText">{promises.incomplete}</span>
          </div>
        }
      </div>
      <div className='navPanel__object__details'>
        {item.personalId &&
          <div>
            <span className='title'>{Translate.get('contactData.personalId')}</span>
            <span>{item.personalId}</span>
          </div>
        }
        {item.companyId &&
          <div>
            <span className='title'>{Translate.get('contactData.companyId')}</span>
            <span>{item.companyId}</span>
          </div>
        }
        {item.taxId &&
          <div>
            <span className='title'>{Translate.get('contactData.taxId')}</span>
            <span>{item.taxId}</span>
          </div>
        }
      </div>
    </>
  }

  render() {
    return <>
      <div className={this.props.id === this.props.item.item._id.$oid ? "navPanel__object--adressee" + 
              " addresseeSelected" + this.canView(this.props.item)
              : "navPanel__object--adressee" + this.canView(this.props.item) }
           key={this.props.item.item._id.$oid}>
        <div onClick={event => this.props.item.allowView && Router.go('/addressBook/' + this.props.item.item._id.$oid)}>
          {this.renderContactData(this.props.item.item, null, this.props.item.promises)}
          {this.props.item.item.extensions.kordex.statutaries && this.state.abDetailOpen &&
            <div className="detail">
              {/*this.props.items && this.props.items.slice(1).map((item) =>*/}
              {this.props.item.item.extensions.kordex.statutaries.map((item, i) =>
                <div className="navPanel__object statutaties" key={this.props.item.item._id.$oid + i}>
                  {this.renderContactData(item, 'contactData.relation.statutary')}
                </div>
              )}
              {this.props.item.item.extensions.kordex.employers && this.props.item.item.extensions.kordex.employers.map((item, i) =>
                <div className="navPanel__object statutaties" key={this.props.item.item._id.$oid + i}>
                  {this.renderContactData(item, 'contactData.relation.employer')}
                </div>
              )}
            </div>
          }
        </div>
        {this.props.item.item.extensions.kordex.statutaries && this.props.item.item.extensions.kordex.statutaries.length > 0 && 
          <div className='plusDetail'
               onClick={() => this.setState({ abDetailOpen: !this.state.abDetailOpen })}>
            {this.state.abDetailOpen ? "-" : "+"}
          </div>
        }
      </div>
    </>
  }
}

export default ItemInfo
