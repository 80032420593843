import React, { Component } from 'react'
import Router from '../costra/Router.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import StateIcons from '../utils/StateIcons.js'
import Metrics from '../utils/Metrics.js'

import ObjectInfo from './ObjectInfo'
import './navPanel.css'
import {PageDataContext} from  '../PageDataContext.js'

class CaseInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      caseDetailOpen: true,
    }
  }
  canView(item){
    return item.allowView? " allowView" : ""
  }

  handleClick(event) {
    if(!this.props.caseRecord.allowView) {
      return
    }
    event.stopPropagation()
    Router.go('/case/' + this.props.caseRecord.caseRecord._id.$oid)
  }

  render() {
    return <div className={this.props.id === this.props.caseRecord.caseRecord._id.$oid ?
                  "navPanel__object" + Metrics.SelectedColor(this.props.caseRecord.state) + Metrics.CheckStatus(this.props.caseRecord.state) + this.canView(this.props.caseRecord)
                  : "navPanel__object "  + Metrics.CheckStatus(this.props.caseRecord.state) + this.canView(this.props.caseRecord)
                }>
      <div onClick={(event) => this.handleClick(event)}>
        <div className='navPanel__object__header'>
          <div className='flex' >
            <div className={"ratingIcon " + Metrics.ScoreColor(this.props.caseRecord.caseRecord.processing.score)} />
            <div>{this.props.caseRecord.caseRecord.caseNo}</div>
          </div>
          <div>
            <StateIcons item={this.props.caseRecord.caseRecord}/>
          </div>
        </div>
        <div className='navPanel__object__details'>
          <div>
            <span className='title'>{Translate.get('contactData.relation.client')}</span>
            <span>{this.props.caseRecord.caseRecord.client.displayName}</span>
          </div>
        </div>
        <div className='navPanel__object__details'>
          <div>
            <span className='title'>{Translate.get('caserecord.campaign')}</span>
            <span>{this.context.campaigns[this.props.caseRecord.caseRecord.campaignId.$oid]}</span>
          </div>
        </div>
        <div className='navPanel__object__details'>
          <div>
            <span className='title'>{Translate.get('caserecord.properties.plannedEnding')}</span>
            <span>{Formatter.formatDateTime('%d.%m. %yyyy', this.props.caseRecord.caseRecord.plannedEnding)}</span>
          </div>
        </div>
        {this.props.caseRecord.caseRecord.processing.payments && this.props.caseRecord.caseRecord.processing.payments.map(value =>
          <div className='navPanel__object__details' key={value.currency}>
            <div>
              <span className='title'>{Translate.get('paymentPlan.value')}</span>
              <span title={Translate.get('paymentPlan.valueExplained')}>
                {Formatter.formatAmount(value.missing) + ' / ' +
                  Formatter.formatAmount(value.planned) + ' ' + value.currency}
              </span>
            </div>
          </div>
        )}
        <div className={(this.state.caseDetailOpen) ? "detail" : "detailHidden"}>
          <div >
            {this.props.caseRecord.objects && this.props.caseRecord.objects.map(object =>
              <ObjectInfo object={object}
                          key={object.item._id.$oid}
                          id={this.props.id}/>
            )}
          </div>
        </div>
      </div>
      
      <div className='plusDetail'
           onClick={()=> this.setState({caseDetailOpen: !this.state.caseDetailOpen})}>
        {this.state.caseDetailOpen? "-" : "+"}
      </div>
    </div>
  }

}
CaseInfo.contextType = PageDataContext

export default CaseInfo