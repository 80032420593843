import React from 'react'

import Dialogs from '../costra/Dialogs.js'

import Input from '../utils/Input.js'
import { PageDataContext } from '../PageDataContext.js'

import CallDialDialog from '../comms/CallDialDialog.js'
import SmsSendDialog from '../comms/SmsSendDialog.js'
import Translate from '../costra/Translate.js'

class Contact {

  static addressFlags = [
    {value: 'Home', title: 'addressFlag.home'},
    {value: 'Contact', title: 'addressFlag.contact'},
    {value: 'Billing', title: 'addressFlag.billing'},
    {value: 'Shop', title: 'addressFlag.shop'},
    {value: 'Rented', title: 'addressFlag.rented'},
    {value: 'Historical', title: 'addressFlag.historical'},
    {value: 'Invalid', title: 'addressFlag.invalid' },
    {value: 'Other', title: 'addressFlag.other'},
    {value: 'Unknown', title: 'addressFlag.unknown'},
  ]
  static mailFlags = [
    {value: 'Preferred', title: 'mailFlag.preferred'},
    {value: 'LegalGuardian', title:  'mailFlag.legalGuardian'},
    {value: 'Active', title:  'mailFlag.active' },
    {value: 'DoNotUse', title:  'mailFlag.doNotUse' },
    {value: 'WrongPerson', title:  'mailFlag.wrongPerson'},
    {value: 'NotExisting', title:  'mailFlag.nonExisting'},
    {value: 'Unknown', title:  'mailFlag.unknown'},
  ]

  static phoneFlags = [
    {value: 'Preferred', title:  'phoneFlag.preferred'},
    {value: 'LegalGuardian', title: 'phoneFlag.legalGuardian'},
    {value: 'Active', title: 'phoneFlag.active'},
    {value: 'DoNotUse', title: 'phoneFlag.doNotUse'},
    {value: 'WrongNumber', title: 'phoneFlag.wrongNumber'},
    {value: 'NotExisting', title: 'phoneFlag.nonExisting'},
    {value: 'Unknown', title: 'phoneFlag.unknown'},
  ]

  static dialCall(pageData, props) {
    Dialogs.open(
      <CallDialDialog dst={props.data.get(props.path + '.' + props.index + '.phoneNum')}/>
    )
  }

  static sendSms(pageData, props) {
    Dialogs.open(
      <SmsSendDialog dst={props.data.get(props.path + '.' + props.index + '.phoneNum')}/>
    )
  }

  static addressColumns(props) {
    return <>
      <div className="icons contacts">        
        <div className='contacts--flex'>
          <div className="icon address" title={Translate.get('contactData.address.title')}/>
          <Input.Select data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        values={Contact.addressFlags}
                        path={props.path + '.' + props.index + '.extensions.kordex.flag'}
                        title='contactData.contact.flag'
                        className="size3c"/>
        </div>
        <div > 
          {!props.disabled &&
            <div className="buttonIcons">
              <div className="button"
                  title={Translate.get('contactData.contact.button.remove')}
                  onClick={(event) => props.data.removeFromArray(props.path, props.index)}>
                <div className="icon remove" />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="stretched">
        <div className="lineForm">
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.street'}
                      title='contactData.address.street'
                      className="size3c"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.streetCode'}
                      title='contactData.address.streetCode'
                      className="max--size2c short"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.city'}
                      title='contactData.address.city'
                      className="size3c"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.postCode'}
                      title='contactData.address.postCode'
                      className="max--size2c medium"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.extensions.kordex.area'}
                      title='contactData.address.area'
                      className="size3c"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.country'}
                      title='contactData.address.country'
                      className="size3c"/>
        </div>
        <div className="lineForm">
          <Input.TextArea data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + props.index + '.extensions.kordex.note'}
                          title='contactData.contact.note'
                          className="size9c"/>
        </div>
      </div>   
    </>
  }

  static mailColumns(props) {
    return <>
      <div className="icons contacts">
        <div className='contacts--flex'>
          <div className="icon email" title={Translate.get('contactData.email.title')}/>
          <Input.Select data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        values={Contact.mailFlags}
                        path={props.path + '.' + props.index + '.extensions.kordex.flag'}
                        title='contactData.contact.flag'
                        className="size2c right"/>
        </div>
        <div>
          {!props.disabled &&
            <div className="buttonIcons">
              <div className="button"
                  title={Translate.get('contactData.button.remove')}
                  onClick={(event) => props.data.removeFromArray(props.path, props.index)}>
                <div className="icon remove" />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="stretched">
        <div className="lineForm">
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.' + props.index + '.mailAddress'}
                      title='contactData.email'
                      className="size0c"/>
        </div>
        <div className="lineForm">
          <Input.TextArea data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + props.index + '.extensions.kordex.note'}
                          title='contactData.contact.note'
                          className="size9c newLine"/>
        </div>
      </div>     
    </>
  }

  static phoneColumns(props) {
    return <PageDataContext.Consumer>
      {pageData => (
        <>
          <div className="icons contacts">
            <div className='contacts--flex'>
              <div className="icon phone" title={Translate.get('contactData.phoneNumber.title')}/>
              <Input.Select data={props.data}
                            showEmpty={true}
                            disabled={props.disabled}
                            values={Contact.phoneFlags}
                            path={props.path + '.' + props.index + '.extensions.kordex.flag'}
                            title='contactData.contact.flag'
                            className="size2c right"/>
            </div>
            <div>
              {!props.disabled &&
                <div className="buttonIcons">
                  <div className="button"
                      title={Translate.get('contactData.button.remove')}
                      onClick={(event) => props.data.removeFromArray(props.path, props.index)}>
                    <div className="icon remove" />
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="stretched">
            <div className="lineForm">
              <Input.Phone data={props.data}
                          showEmpty={true}
                          disabled={props.disabled}
                          path={props.path + '.' + props.index + '.phoneNum'}
                          title='contactData.phoneNumber'
                          className="size0c"/>

            </div>
            <div className="lineForm">
              <Input.TextArea data={props.data}
                              disabled={props.disabled}
                              path={props.path + '.' + props.index + '.extensions.kordex.note'}
                              title='contactData.contact.note'
                              className="size9c newLine"/>
            </div>
          </div>

          <div className="contact-buttons">
            {pageData.currentUser.canDialCall &&
              <div className="button"
                   onClick={() => Contact.dialCall(pageData, props)}>
                <div className="icon call"
                     title={Translate.get('contactData.contact.button.call')}/>
              </div>
            }
            {pageData.currentUser.canSendSms &&
              <div className="button"
                   onClick={() => Contact.sendSms(pageData, props)}>
                <div className="icon sms"
                     title={Translate.get('contactData.contact.button.sendSms')}/>
              </div>
            }
          </div>

        </>
      )}
    </PageDataContext.Consumer>
  }

  static columns(props) {
    switch (props.item._class) {

      case "com.optimsys.costra.addressbook.contact.Address":
        return Contact.addressColumns(props)

      case "com.optimsys.costra.addressbook.contact.Mail":
        return Contact.mailColumns(props)

      case "com.optimsys.costra.addressbook.contact.Phone":
        return Contact.phoneColumns(props)

      default:
        return <div>{props.item._class}</div>
    }
  }

  static Row(props) {
    return <div className="row contact">
      {Contact.columns(props)}
    </div>
  }
}

export default Contact