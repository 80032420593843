import React, { Component } from 'react'
import Container from '../costra/Container.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import { PageDataContext } from '../PageDataContext.js'

import CommLog from './CommLog.js'
import CallDialDialog from './CallDialDialog.js'

import '../css/icons.css'
import '../css/callPanel.css'

class CallPanel extends Component {

  constructor(props) {
    super(props)
    this.state = {
      expanded : false,
      calls: [],
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.comms.ActiveCalls')
  }

  load() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.comms.ActiveCalls$Get',
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }

  toggleExpanded(event, call) {
    event.stopPropagation()
    this.setState({expanded: !this.state.expanded})
  }

  dialCall(event) {
    event.stopPropagation()
    Dialogs.open(<CallDialDialog dst=""/>)
  }

  render() {
    if (!this.context.currentUser.canDialCall) {
      return <div className="callPanel collapsed">
        <div className="content">
          <div className="header">
            <div className="icon close"/>
            <span>{Translate.get('comms.activeCalls.noAccess')}</span>
          </div>
        </div>
      </div>
    }

    if (!this.state.expanded) {
      return <div className="callPanel collapsed"
                  onMouseEnter={() => this.setState({expanded: true})}
                  onMouseLeave={() => this.setState({expanded: false})}>
        <div className="content">
          <div className="header">
            <div className="icon call"/>
            {this.state.calls.length > 0 ?
              <span>{Translate.get('comms.activeCalls.title')} ({this.state.calls.length})</span>
              :
              <span>{Translate.get('comms.activeCalls.noCalls')}</span>
            }
          </div>
        </div>
      </div>
    }

    return <div className="callPanel expanded"
                onMouseEnter={() => this.setState({expanded: true})}
                onMouseLeave={() => this.setState({expanded: false})}>
      <div className="content">
        <div className="header">
          <div className="icon iconBig call"/>
          <h3>{Translate.get('comms.activeCalls.title')} ({this.state.calls.length})</h3>
          <div className="button right"
               onClick={event => this.dialCall(event)}>
            <div className="icon call"/>
            {Translate.get('comms.activeCalls.dial')}
          </div>
        </div>
        <ul className="list">
          {this.state.calls && this.state.calls.map(item =>
            <li key={item._id.$oid}>
              <CommLog.Row item={item}/>
            </li>
          )}
        </ul>
      </div>
    </div>
  }
}
CallPanel.contextType = PageDataContext

export default CallPanel