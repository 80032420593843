import React, { Component } from 'react'
import ObjectHelper from '../costra/ObjectHelper.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'
import ListContainer from '../utils/ListContainer.js'
import Table from '../utils/Table.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CaseObject from './CaseObject.js'
import NewObjectDialog from './NewObjectDialog.js'

class Container extends ListContainer {

  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class CaseObjectsList extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.layout = ObjectHelper.copy(CaseObject.defaultLayout)
    state.sortField = 'name'
    state.sortOrder = 1
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.caseobject.CaseObjectsList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.container.reload()
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>

        {this.props.allowEditCase &&
          <div className="button right"
               title={Translate.get('caseobject.objects.button.add')}
               onClick={(event) => Dialogs.open(<NewObjectDialog  id={this.props.id}/>)}>
            <div className="icon left caseAdd"/>
            <span className="left">{Translate.get('caseobject.objects.button.add')}</span>
          </div>
        }
      </div>
      <Table name="caseObjectsLayout"
             withSemaphore={true}
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             sort={(sortName) => this.container.sortBy(sortName)}
             columns = {CaseObject.columns}
             itemFn={(item, index, key) =>
                <CaseObject.Row layout={this.state.layout} item={item} key={key}/>
             }/>
    </>
  }
}

export default CaseObjectsList
