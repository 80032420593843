import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'

import MessageTemplate from './MessageTemplate.js'

import '../css/list.css'
import '../css/icons.css'
import Translate from '../costra/Translate.js'

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params.campaignId = this.component.props.id
    return params
  }
}

class MessageTemplatesList extends Component {

  constructor(props) {
    super(props)
    var state = Container.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.message.MessageTemplatesList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    if (this.props.id === 'new') {
      return <div className="spacedTop">
        <div className="icon left info"/>
        <div className="left">{Translate.get('message.templateList.info')}</div>
      </div>
    }

    return (
      <div>
        <div className="listHeader spacedTop">
          <PageSelector page={this.state.page}
                        pages={this.state.pages}
                        onChange={(event, page) => this.container.setPage(page)}/>

          <div className="button right"
               title={Translate.get('message.templateList.button.newTemplate')}
               onClick={(event) => MessageTemplate.create(event, this.props.id)}>
            <div className="icon left import"/>
            <span className="left">{Translate.get('message.templateList.button.newTemplate')}</span>
          </div>

        </div>

        <List list={this.state.items} itemFn={item =>
          <MessageTemplate.Row item={item}/>
        }/>
      </div>
    )
  }

}

export default MessageTemplatesList