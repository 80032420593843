import React, { Component } from 'react'
import Container from '../costra/Container.js'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Nav from '../utils/Nav.js'

import '../css/dialogs.css'
import Translate from '../costra/Translate.js'

class ImportDropFileSelectDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      state: null,
      fileState: null,
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.imports.ImportView')
  }

  load() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Get',
      campaignId:  this.props.item.campaignId,
    })
  }

  open(item) {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.OpenImport',
        importObject: item,
      },
    })
  }

  prepare() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.PrepareImport',
        params: this.state.params,
      },
    })
    Dialogs.close(null)
    Nav.go(null, '/import')
  }

  kill() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.imports.ImportView$Command',
      command: {
        _class: 'com.optimsys.costra.kordex.imports.message.KillImport',
      },
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }

  useDropFile(fileId) {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.imports.UseDropFile',
      fileId : fileId,
      dropFileId : this.props.item._id,
    }).then(result => {
      if (result.fileImported) {
        this.setState({fileState: 'uploaded'})
      }
      else {
        this.setState({fileState: 'error'})
      }
    })
  }

  navigateToImport() {
    Dialogs.close(null)
    Nav.go(null, '/import')
  }

  renderState() {
    if (!this.state.state) {
      return <LoadingPlaceholder />
    }
    switch (this.state.fileState) {
      case 'error':
        return <>
          <div className="title">
            <div className="icon iconBig import"/>
            <span>{Translate.get('imports.dropFile.fileError')}</span>
          </div>
          <ul className="list spacedBottom">
            <li>
              <div className="row withHover"
                onClick={(event) => this.navigateToImport()}>
                <div><div className="icon import" /></div>
                <div>{Translate.get('imports.navigateTo.btn')}</div>
              </div>
            </li>
          </ul>
        </>

      default:
        break
    }

    switch (this.state.state._class) {

      case 'com.optimsys.costra.kordex.imports.message.ImportState$NoImport':
        return <>
          <div className="title">
            <div className="icon iconBig import"/>
            <span>{Translate.get('imports.open.title')}</span>
          </div>
          <ul className="list spacedBottom">
            {this.state.state.availableImports && this.state.state.availableImports.map((item, index) =>
              item.campaignId.$oid === this.props.item.campaignId.$oid &&
                <li key={index}>
                  <div className="row withHover"
                       onClick={(event) => this.open(item)}>
                    <div><div className="icon import" /></div>
                    <div className="size3c">{item.campaignName}</div>
                    <div>{Translate.get(item._class)}</div>
                  </div>
                </li>
            )}
          </ul>
        </>

      case 'com.optimsys.costra.kordex.imports.message.ImportState$PreImport':
        return <>
          <div className="title">
            <div className="icon iconBig import"/>
            <span>{Translate.get(this.state.state.importObject._class)}</span>
          </div>
          <ul className="list spacedBottom">
            {this.state.state.importObject.files && this.state.state.importObject.files.map(file =>
              <li key={file.id}>
                <div className="row withHover"
                  onClick={(event) => this.useDropFile(file.id)}>
                  <div><div className="icon file" /></div>
                  <div>{Translate.get('imports.dropFile.useAs', null,
                    {fileType: Translate.get(this.state.state.importObject._class + '.' + file.id)})}</div>
                  {this.state.state.uploadedFiles && this.state.state.uploadedFiles[file.id] &&
                    <div>{Translate.get('imports.dropFile.fileTypeUploaded', null, {file: this.state.state.uploadedFiles[file.id]})}</div>
                  }
                </div>
              </li>
            )}

            {this.state.state.canBePrepared &&
              <li>
                <div className="row withHover"
                     onClick={(event) => this.prepare()}>
                  <div><div className="icon ok" /></div>
                  <div>{Translate.get('imports.button.continue')}</div>
                </div>
              </li>
            }
            <li>
              <div className="row withHover"
                   onClick={(event) => this.navigateToImport()}>
                <div><div className="icon import" /></div>
                <div>{Translate.get('imports.button.navigateTo')}</div>
              </div>
            </li>
            <li>
              <div className="row withHover"
                onClick={(event) => this.kill()}>
                <div><div className="icon cancel" /></div>
                <div>{Translate.get('imports.button.close')}</div>
              </div>
            </li>
          </ul>
        </>

      default:
        return <>
          <div className="title">
            <div className="icon iconBig import"/>
            <span>{Translate.get('imports.dropFile.importRunning')}</span>
          </div>
          <ul className="list spacedBottom">
            <li>
              <div className="row withHover"
                onClick={(event) => this.navigateToImport()}>
                <div><div className="icon import" /></div>
                <div>{Translate.get('imports.button.navigateTo')}</div>
              </div>
            </li>
            <li>
              <div className="row withHover"
                onClick={(event) => this.kill()}>
                <div><div className="icon cancel" /></div>
                <div>{Translate.get('imports.button.close')}</div>
              </div>
            </li>
          </ul>
        </>
    }
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog wide frame">
          {this.renderState()}

          <div className="buttons">
            <div className="button"
                 title={Translate.get('close')}
                 onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ImportDropFileSelectDialog