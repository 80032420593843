class Metrics {
  static Semaphore = {
    0 : {text: 'semaphore.0', color: ' green'},
    1 : {text: 'semaphore.1', color: ' orange'},
    2 : {text: 'semaphore.2', color: ' red'}
  }

  static ScoreColor(score) {
    switch (score) {
      case 0: return ' green'
      case 1: return ' orange'
      case 2: return ' red'
      default: return ''
    }
  }

  static SelectedColor(state){
    switch(state) {
      case 'Pending': return ' orangeSelected'
      case 'Errors': return ' redSelected'
      case 'Finished': return ' greenSelected'
      default: return ''
    }
  }

  static CheckStatus(state){
    switch(state) {
      case 'Pending': return ' orangeBorder'
      case 'Errors': return ' redBorder'
      case 'Finished': return ' greenBorder'
      default: return ''
    }    
  }

  static TaskColor(item){
    switch(true) {      
      case item.hasOwnProperty("discardedAt"): return ' greenBorder'
      case item.deadlineAt >= +new Date() : return ' orangeBorder'
      case item.deadlineAt < +new Date(): return ' redBorder'      
      default: return ''
    }    
  }

  static CheckVin(vin,vinType) {  
    const specialChars = /`!@#$%^&*()_+-=\[\]{};':"\\|,.<>\/?~/
    return (vinType === "Vin" && vin.length !== 17) || (vinType === "Vin" && specialChars.test(vin)) ? "redInput" : ""
  }
  
}

export default Metrics