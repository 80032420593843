import React, { Component } from 'react'
import Router from '../costra/Router.js'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'

import ConfirmDialog from '../utils/ConfirmDialog.js'
import WatchedItemContainer from '../utils/WatchedItemContainer.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import UserFilesList from '../files/UserFilesList.js'
import Properties from './Properties.js'
import '../css/icons.css'

class UserEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item : null,
      tab: 'props',
    }
    this.container = new WatchedItemContainer(this, 'com.optimsys.costra.kordex.users.UserContainer')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.load(this.props.id)
    }
    else {
      this.setState({item: {}})
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.container.load(this.props.id)
    }
  }

  remove(event) {
    event.stopPropagation()
    Dialogs.open(
      <ConfirmDialog text={Translate.get('users.remove.prompt', null, {name: this.state.item.login})}/>
    ).then((confirmed) => {
      if (confirmed) {
        return WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.users.RemoveUser',
          id: this.state.item._id,
        })
      }
    }).then((confirmed) => {
      if (confirmed) {
        Router.go('/users')
      }
    }, () => {
      this.setState({error: 'genericStoreError' })
    })
  }

  renderTabs() {
    switch(this.state.tab) {
      case 'files':
        return <UserFilesList id={this.props.id} allowEdit={this.state.allowEdit}/>
      default:
        return <Properties id={this.props.id}/>
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="mainPanel withTabs"><LoadingPlaceholder/></div>
    }
    return <div className={'mainPanel' + (this.props.id !== 'new' ? ' withTabs' : '')}>
      <div className="header">
        <div className="row">
          <div className="left icon iconBig user"/>
          {this.props.id === 'new' ?
            <h1>{Translate.get('users.newUser.title')}</h1> :
            <h1>{this.state.item.login}</h1>
          }
          <div className="values">
            <div className="icons">
              {/* congratulations, you found the part, that should have been a separate component. Now there is one more instance of this... */}
              {this.state.item.extensions &&
                this.state.item.extensions.kordex &&
                this.state.item.extensions.kordex.processing.missingSignature &&
                <div className="icon left iconBig missingSignature" title={Translate.get('users.flag.missingSignature')}/>
              }
              {this.state.item.extensions &&
                this.state.item.extensions.kordex &&
                this.state.item.extensions.kordex.processing.weakPassword &&
                <div className="icon left iconBig weakPassword" title={Translate.get('users.flag.weakPassword')}/>
              }
              {this.state.item.extensions &&
                this.state.item.extensions.kordex &&
                this.state.item.extensions.kordex.processing.isContractor &&
                <div className="icon left iconBig user" title={Translate.get('users.flag.isContractor')}/>
              }
            </div>
          </div>
        </div>

        {this.props.id !== 'new' &&
          <div className="row">
            <div className="tabs">
              <div className={'tab' + (this.state.tab === 'props' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'props'})}>{Translate.get('users.tabs.props')}</div>
              <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'files'})}>{Translate.get('users.tabs.files')}</div>
            </div>
          </div>
        }
      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderTabs()}
        </div>
      </div>
    </div>
  }
}

export default UserEdit
