import React from 'react'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import ImportRecord from '../imports/ImportRecord.js'
import CaseRecord from '../caserecord/CaseRecord.js'

function LogRow(props) {
  let item = props.item
  switch (item._class) {

    case 'com.optimsys.costra.changelog.ChangeLog':
      let user = props.users[item.identity.$oid]
      return <div className="row">
        <div><div className="icon import"/></div>
        <div className="size4c">{Formatter.formatDateTime('%d.%m.%yyyy, %HH:%MM:%SS', item.time)}</div>
        <div className="size5c">{Translate.get(item._class)}</div>
        <div className="size5c">{user ? user.displayName : '-'}</div>
        <div className="size2c">{Translate.get(item.source._class)}</div>
      </div>

    case 'com.optimsys.costra.kordex.imports.log.ImportLog':
      return <div className="row">
        <div><div className="icon import"/></div>
        <div className="size4c">{Formatter.formatDateTime('%d.%m.%yyyy, %HH:%MM:%SS', item.time)}</div>
        <div className="size5c">{Translate.get(item._class)}</div>
        <div className="button right"
             title={Translate.get('open')}
             onClick={event => ImportRecord.go(event, item.importId.$oid)}>
          <div className="icon import"/>
        </div>
        <div className="button right"
             title={Translate.get('openInNewWindow')}
             onClick={event => ImportRecord.go(event, item.importId.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      </div>

    case 'com.optimsys.costra.kordex.imports.log.UpdatedFrom':
      return <div className="row">
        <div><div className="icon case"/></div>
        <div className="size4c">{Formatter.formatDateTime('%d.%m.%yyyy, %HH:%MM:%SS', item.time)}</div>
        <div className="size5c">{Translate.get(item._class)}</div>
        <div className="button right"
             title={Translate.get('open')}
             onClick={event => CaseRecord.go(event, item.source.$oid)}>
          <div className="icon case"/>
        </div>
        <div className="button right"
             title={Translate.get('openInNewWindow')}
             onClick={event => CaseRecord.go(event, item.source.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      </div>

    default:
      return <div className="row">
        <div><div className="icon alert"/></div>
        <div>{item._class}</div>
      </div>
  }
}

export default LogRow

/*
class LogRow extends Component {

  static codeToString(code) {
    switch(code) {
      // model
      case "DateFormatInvalid$": return 'Špatný formát data a/nebo času'
      case "EmailFormatInvalid$": return 'Špatný formát E-mailové adresy'
      case "NoAsciiString$": return 'Řetězec nesmí obsahovat speciální znaky'
      case "NumberFormatInvalid$": return 'Špatný formát čísla'
      case "NumberOutOfBounds$": return 'Číslo je mimo povolený rozsah'
      case "NumberStringFormatInvalid$": return 'Špatný formát čísla'
      case "PersonalIdInvalid$": return 'Špatný formát rodného čísla'
      case "PhoneNumberFormatInvalid$": return 'Špatný formát telefonního čísla'
      case "ValueEmpty$": return 'Hodnota není zadána'
      // kordex
      case "CaseNoNotFound$": return 'Nebyl nalezen aktivní případ s tímto číslem'
      case "CaseNoUsed$": return 'Aktivní případ s tímto číslem už existuje'
      case "PaymentPlanExists$": return 'Případ s tímto číslem už má splátkový kalendář'
      // default
      default: return code
    }
  }

  static title(item) {
    return item.fileName + ' ' + item.position + ' ' + LogRow.codeToString(item.code)
  }

  render() {
    return <div className="row">
      <div><div className={this.props.event.code ? 'icon error' : 'icon edit'}/></div>
      <div className="size6c">{this.props.event.fileName}</div>
      <div className="size2c">{this.props.event.position}</div>
      <div className="size6c">{this.props.event.title}</div>
      <div className="size6c">{this.props.event.value}</div>
      {this.props.event.code &&
        <div className="newLine">{LogRow.codeToString(this.props.event.code)}</div>
      }
      {this.props.children}
    </div>
  }

}

export default LogRow
*/