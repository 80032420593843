import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import '../../../css/form.css'

const productTypes = [
  {value: 'Loan', title: 'ucb.telephony.productType.Loan'},
  {value: 'MortgageLoan', title: 'ucb.telephony.productType.MortgageLoan'},
  {value: 'CreditCard', title: 'ucb.telephony.productType.CreditCard'},
  {value: 'CurrentAccount', title: 'ucb.telephony.productType.CurrentAccount'},
  {value: 'BusinessLoan', title: 'ucb.telephony.productType.BusinessLoan'},
  {value: 'Unknown', title: 'ucb.telephony.productType.Unknown'},
]

function TelProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection case"/>
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="caseNo"
                   title="caserecord.properties.caseNo"
                   validate={Validator.notEmptyString}/>

        <Form.Check data={props.data}
                    disabled={!props.editOpen}
                    path="isMainProduct"
                    title="ucb.telephony.isMainProduct"/>

        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="handoverMonth"
                   title="ucb.telephony.handoverMonth"/>

        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="plannedEnding"
                       title="caserecord.properties.plannedEnding"
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.data}
                       disabled={true}
                       path="processing.created"
                       title='caserecord.processing.created'
                       format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                       disabled={true}
                       path="processing.finished"
                       title='caserecord.processing.finished'
                       format="%d.%m.%yyyy" />
      </div>
    </div>

    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection money"/>
        <h3>{Translate.get('ucb.telephony.product.title')}</h3>
      </div>
      <div className="content">
        <Form.Select data={props.data}
                      //showEmpty={true}
                      disabled={!props.editOpen}
                      values={productTypes}
                      path='productType'
                      title='ucb.telephony.productType'
                      className="size2c right"/>

        <Form.Check data={props.data}
                    disabled
                    path="isBlocked"
                    title="ucb.telephony.isBlocked"/>

        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="terminated.date"
                       title="ucb.telephony.terminated.date"
                       format="%d.%m.%yyyy"/>
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="terminated.reason"
                   title="ucb.telephony.terminated.reason"/>
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="terminated.proposal"
                   title="ucb.telephony.terminated.proposal"/>


        <Form.Amount data={props.data}
                     disabled={!props.editOpen}
                     path="monthlyPayment"
                     title="ucb.telephony.monthlyPayment"
                     currency=""/>
        <Form.Amount data={props.data}
                     disabled={!props.editOpen}
                     path="monthlyInsurance"
                     title="ucb.telephony.monthlyInsurance"
                     currency=""/>
        <Form.Amount data={props.data}
                     disabled={!props.editOpen}
                     path="overdueInterestRate"
                     title="ucb.telephony.overdueInterestRate"
                     currency="%"/>

      </div>
    </div>

    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection log"/>
        <h3>{Translate.get('caserecord.contract.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="contractName"
                   title="ucb.telephony.contractName"/>
        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="contractDate"
                       title="ucb.telephony.contractDate"
                       format="%d.%m.%yyyy"/>
      </div>
    </div>

    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((_, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default TelProperties