import React from 'react'
import Translate from '../../../costra/Translate.js'
import Validator from '../../../costra/Validator.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import '../../../css/form.css'

const requests = [
  {value: 'PAT', title: 'vw.fs.request.PAT'},
  {value: 'PAV', title: 'vw.fs.request.PAV'},
  {value: 'PLA', title: 'vw.fs.request.PLA'},
  {value: 'PNT', title: 'vw.fs.request.PNT'},
  {value: 'PNV', title: 'vw.fs.request.PNV'},
  {value: 'POJ', title: 'vw.fs.request.POJ'},
  {value: 'PVT', title: 'vw.fs.request.PVT'},
  {value: 'TPO', title: 'vw.fs.request.TPO'},
  {value: 'VAT', title: 'vw.fs.request.VAT'},
  {value: 'VOZ', title: 'vw.fs.request.VOZ'},
  {value: 'Unknown', title: 'vw.fs.request.Unknown'},
]

const results = [
  {value: 'NEU', title: 'vw.fs.result.NEU' },
  {value: 'ZRU', title: 'vw.fs.result.ZRU'},
  {value: 'POT', title: 'vw.fs.result.POT'},
  {value: 'ZAJ', title: 'vw.fs.result.ZAJ'},
  {value: 'ZAP', title: 'vw.fs.result.ZAP'},
  {value: 'ZBN', title: 'vw.fs.result.ZBN'},
  {value: 'ZPM', title: 'vw.fs.result.ZPM'},
  {value: 'LUS', title: 'vw.fs.result.LUS'},
  {value: 'POZ', title: 'vw.fs.result.POZ'},
  {value: 'ZAV', title: 'vw.fs.result.ZAV'},
  {value: 'ZTP', title: 'vw.fs.result.ZTP'},
  {value: 'Unknown', title: 'vw.fs.result.Unknown'},
]

function VwFsProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection case" />
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <div className="column-block">
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="caseNo"
                     title='caserecord.properties.caseNo'
                     validate={Validator.notEmptyString} />
          {/*
          styling={"bold highlighted" + (this.state.item.updatedFields && ~this.state.item.updatedFields.indexOf("Requirement") ? " yellow" : "")}
          */}

          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="paymentAfterFreeze"
                     title='vw.fs.paymentAfterFreeze'/>
          <Form.Amount data={props.data}
                       currency={"CZK"}
                       disabled={!props.editOpen}
                       path="balanceValue"
                       title='vw.fs.balanceValue'
                       validate={Validator.number}/>
          <Form.DateTime data={props.data}
                         disabled={!props.editOpen}
                         path="dateOfBalanceInvoice"
                         title='vw.fs.dateOfBalanceInvoice'
                         format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                         disabled={!props.editOpen}
                         path="delayedStartDate"
                         title='vw.fs.delayedStartDate'
                         format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                         disabled={true}
                         path="processing.created"
                         title='caserecord.processing.created'
                         format="%d.%m.%yyyy %HH:%MM" />
          <Form.DateTime data={props.data}
                         disabled={!props.editOpen}
                         path="plannedEnding"
                         title='caserecord.properties.plannedEnding'
                         format="%d.%m.%yyyy %HH:%MM" />
          <Form.DateTime data={props.data}
                         disabled={true}
                         path="processing.finished"
                         title='caserecord.processing.finished'
                         format="%d.%m.%yyyy %HH:%MM" />

          <Form.Select data={props.data}
                       disabled={!props.editOpen}
                       path="request"
                       title='vw.fs.request'
                       validate={Validator.notEmptyString}
                       values={requests}/>
          <Form.Select data={props.data}
                       disabled={!props.editOpen}
                       path="result"
                       title='vw.fs.result'
                       validate={Validator.notEmptyString}
                       values={results}/>
        </div>
      </div>
      <Form.TextArea data={props.data}
              disabled={!props.editOpen}
              path="requestNote"
              styling="textArea big"
              title='vw.fs.requestNote'/>
      <Form.TextArea data={props.data}
              disabled={!props.editOpen}
              path="reportedContact"
              styling="textArea big"
              title='vw.fs.reportedContact'/>
    </div>

    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection log" />
        <h3>{Translate.get('caserecord.contract.title')}</h3>
      </div>
      <div className="content">
        <div className="column-block">
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="contract.contractState"
                     title='vw.fs.contractState'/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="contract.contractType"
                     title='vw.fs.contractType'/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="contract.lengthOfLS"
                     title='vw.fs.lengthOfLS' />
          <Form.DateTime data={props.data}
                         disabled={!props.editOpen}
                         path="contract.cancellationDate"
                         title='vw.fs.contractCancellationDate'
                         format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                         disabled={!props.editOpen}
                         path="contract.endDate"
                         title='vw.fs.contractEndDate'
                         format="%d.%m.%yyyy" />
        </div>
      </div>
    </div>

    <ContactData data={props.data}
                 path="client"
                 contactPerson="contactPerson"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default VwFsProperties