import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Validator from '../costra/Validator.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import TextInputArray from '../utils/TextInputArray.js'

import UsersArray from '../users/UsersArray.js'

import { PageDataContext } from '../PageDataContext.js'
import Campaign from './Campaign.js'
import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen : false,
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.campaign.FetchCampaign',
      id : this.props.id === 'new' ? undefined : this.props.id,
    }).then((result) => {
      result.editOpen = (this.props.id === 'new')
      this.setState(result)
      return result
    }, () => {
      this.setState({error: 'genericLoadError'})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.campaign.UpdateCampaign',
      item : this.state.item,
    }).then((result) => {
      if (this.props.id === result.item._id.$oid) {
        result.editOpen = false
        this.setState(result)
      }
      else {
        Campaign.go(null, result.item._id.$oid)
      }
      return result
    }, () => {
      this.setState({error: 'genericStoreError'})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderCampaignSelect() {
    return <div className="formRow">
      <label>{Translate.get('campaigns.class')}:</label>
      <select className="formInput"
              title={Translate.get('campaigns.class')}
              disabled={this.props.id !== 'new'}
              value={this.state.item._class}
              onChange={(event) => this.data.set('_class', event.target.value)}>
        {Campaign.classes.map(campaign =>
          <option key={campaign.value} value={campaign.value}>{Translate.get(campaign.title)}</option>
        )}
      </select>
    </div>
  }

  render() {
    if (this.state.error) {
      return <div className="section spacedTop">
        <ErrorPlaceholder error={this.state.error} reload={() => this.load()}/>
      </div>
    }
    if (!this.state.item) {
      return <div className="section spacedTop"><LoadingPlaceholder/></div>
    }
    switch (this.state.item._class) {

      case 'com.optimsys.costra.kordex.campaign.erl.pred.PredCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                         disabled={!this.state.editOpen}
                         path="displayName"
                         title='campaigns.displayName'
                         validate={Validator.notEmptyString}/>
            </div>
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.times')}</h3>  
              <div className="right">
                <div className="icon info left"/>
                {Translate.get('campaigns.properties.time.format')}
              </div>      
            </div>
            <div className="content">
              <Form.Duration data={this.data}
                            disabled={!this.state.editOpen}
                            path="defaultDuration"
                            title='campaigns.defaultDuration'/>
              <Form.Duration data={this.data}
                            disabled={!this.state.editOpen}
                            path="notificationTimeBeforeEnd"
                            title='campaigns.notificationTimeBeforeEnd'/>
              </div>
            </div>
            <TextInputArray data={this.data}
                            disabled={!this.state.editOpen}
                            path="supervisorMails"
                            title='campaigns.supervisorMails'
                            icon="email"
                            newValue={() => ''}/>
            <UsersArray data={this.data}
                        disabled={!this.state.editOpen}
                        path="supervisors"
                        title='campaigns.supervisors'
                        icon="user"/>
        </div>  

      case 'com.optimsys.costra.kordex.campaign.vw.fs.VwFsCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
            </div>
          </div>

          <TextInputArray data={this.data}
                          disabled={!this.state.editOpen}
                          path="endNotificationMails"
                          title='campaigns.properties.endNotificationMails'
                          icon="email"
                          newValue={() => ''}/>
          <TextInputArray data={this.data}
                          disabled={!this.state.editOpen}
                          path="contractorMails"
                          title='campaigns.properties.contractorMails'
                          icon="email"
                          newValue={() => ''}/>
          <TextInputArray data={this.data}
                          disabled={!this.state.editOpen}
                          path="supervisorMails"
                          title='campaigns.properties.supervisorMails'
                          icon="email"
                          newValue={() => ''}/>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="superrvisors"
                      title='campaigns.properties.supervisors'
                      icon="user"/>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="backOfficeAgents"
                      title='campaigns.properties.backOfficeAgents'
                      icon="user"/>
          
        </div>

      case 'com.optimsys.costra.kordex.campaign.sal.pis.PisCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="mailSender.mailAddress"
                        title='campaigns.sender.mailAddress'
                        validate={Validator.notEmptyString}/>
            </div>
          </div>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>
        </div>

      case 'com.optimsys.costra.kordex.campaign.sal.telephony.TelCampaign':
        let campaignSelect = [{value: '', title: 'campaigns.alternativeCampaign.none'}]
        Object.keys(this.context.campaigns).forEach(key => {
          campaignSelect.push({value: key, title: this.context.campaigns[key]})
        })
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                         disabled={!this.state.editOpen}
                         path="displayName"
                         title='campaigns.displayName'
                         validate={Validator.notEmptyString}/>
              <Form.Duration data={this.data}
                             disabled={!this.state.editOpen}
                             path="defaultDuration"
                             title='campaigns.defaultDuration'/>
              <Form.Select data={this.data}
                           disabled={!this.state.editOpen}
                           path="alternativeCampaign"
                           title='campaigns.alternativeCampaign'
                           values={campaignSelect}/>
              <Form.Text data={this.data}
                         disabled={!this.state.editOpen}
                         path="rewardWindowStarts"
                         title='campaigns.rewardWindowStarts'/>
              <Form.Text data={this.data}
                         disabled={!this.state.editOpen}
                         path="rewardWindowEnds"
                         title='campaigns.rewardWindowEnds'/>
              <Form.Check data={this.data}
                          disabled={!this.state.editOpen}
                          path="closeWhenResultsAreIn"
                          title='campaigns.closeWhenResultsAreIn'/>
            </div>
          </div>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>
        </div>

      case 'com.optimsys.costra.kordex.campaign.ucb.telephony.TelCampaign':
      case 'com.optimsys.costra.kordex.campaign.cofidis.collection.CollectionCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                         disabled={!this.state.editOpen}
                         path="displayName"
                         title='campaigns.displayName'
                         validate={Validator.notEmptyString}/>
              <Form.Duration data={this.data}
                             disabled={!this.state.editOpen}
                             path="defaultDuration"
                             title='campaigns.defaultDuration'/>
            </div>
          </div>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>
        </div>
        
      case 'com.optimsys.costra.kordex.campaign.sal.collection.CollectionCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
              <Form.Duration data={this.data}
                             disabled={!this.state.editOpen}
                             path="defaultDuration"
                             title='campaigns.defaultDuration'/>
            </div>
          </div>
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>
        </div>

      case 'com.optimsys.costra.kordex.campaign.sal.sf.SfCampaign':
      case 'com.optimsys.costra.kordex.campaign.sal.pis.PisSKCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.title')}</h3>
            </div>
            <div className="content">
              {this.renderCampaignSelect()}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
            </div>
          </div> 
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>
        </div>

      /*
      case 'com.optimsys.costra.kordex.campaign.erl.telephony.TelCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.KvCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.RetCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.RupCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.TelCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.WcCampaign':
      case 'com.optimsys.costra.kordex.campaign.lcs.telephony.ZamCampaign':
      case 'com.optimsys.costra.kordex.campaign.sal.telephony.SkWcCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.h3.campaign')}</h3>        
            </div>
            <div className="content">
              {this.selectCampaign(this.props.id, this.state.item._class)}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
            </div>
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.h3.time')}</h3>  
              <div className="right">
                <div className="icon info left"/>
                {Translate.get('campaigns.properties.time.format')}
              </div>      
            </div>
            <div className="content">
              <Form.Duration data={this.data}
                        disabled={!this.state.editOpen}
                        path="defaultDuration"
                        title='campaigns.defaultDuration'
                        validate={Validator.notEmptyString}/>
            </div>
          </div> 
          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="supervisors"
                      title='campaigns.supervisors'
                      icon="user"/>

          <UsersArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="backOfficeAgents"
                      title='campaigns.properties.backOfficeAgents'
                      icon="user"/>
        </div>

      case 'com.optimsys.costra.kordex.campaign.sal.telephony.SkTelCampaign':
        return <div className="section">
          <div className="buttons">
            <EditButtons component={this}/>
          </div>
          <div className="subsection">
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.h3.campaign')}</h3>        
            </div>
            <div className="content">
              {this.selectCampaign(this.props.id, this.state.item._class)}
              <Form.Text data={this.data}
                        disabled={!this.state.editOpen}
                        path="displayName"
                        title='campaigns.displayName'
                        validate={Validator.notEmptyString}/>
            </div>
            <div className="content"> 
              <Form.Duration data={this.data}
                          disabled={!this.state.editOpen}
                          path="rewardStartsAfter"
                          title='campaigns.defaultDuration'
                          validate={Validator.notEmptyString}/>
              <Form.Duration data={this.data}
                          disabled={!this.state.editOpen}
                          path="rewardEndsAfter"
                          title='campaigns.rewardEndsAfter'
                          validate={Validator.notEmptyString}/>              
            </div>
            <div className="header-row">
              <div className="icon iconSection campaign"/>
              <h3>{Translate.get('campaigns.properties.times')}</h3>  
              <div className="right">
                <div className="icon info left"/>
                {Translate.get('campaigns.properties.time.format')}
              </div>      
            </div>
            <div className="content">
              <Form.Duration data={this.data}
                          disabled={!this.state.editOpen}
                          path="defaultDuration"
                          title='campaigns.properties.defaultDuration'
                          validate={Validator.notEmptyString}/> 
            </div>            
          </div> 
            <UsersArray data={this.data}
                        disabled={!this.state.editOpen}
                        path="supervisors"
                        title='campaigns.properties.supervisors'
                        icon="user"/>

            <UsersArray data={this.data}
                        disabled={!this.state.editOpen}
                        path="backOfficeAgents"
                        title='campaigns.properties.backOfficeAgents'
                        icon="user"/>
          
        </div>
        */

      default:
        return <div>{this.state.item._class}</div>
    }
  }
}
Properties.contextType = PageDataContext

export default Properties
