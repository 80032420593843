import Container from '../costra/Container.js'

class WatchedItemContainer extends Container {
  load(id) {
    return this.ask({
      _class: 'com.optimsys.costra.kordex.ui.WatchedItemContainer$Get',
      id: id,
    })
  }
}

export default WatchedItemContainer