import React from 'react'

import Nav from '../utils/Nav.js'
import Translate from '../costra/Translate.js'

class Campaign {

  static go(event, id, newTab) {
    Nav.go(event, '/campaigns/' + id, newTab)
  }

  static create(event) {
    Nav.go(event, '/campaigns/new')
  }

  static classes = [
    {value: 'com.optimsys.costra.kordex.campaign.erl.pred.PredCampaign', title: 'com.optimsys.costra.kordex.campaign.erl.pred.PredCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.sal.collection.CollectionCampaign', title: 'com.optimsys.costra.kordex.campaign.sal.collection.CollectionCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.sal.sf.SfCampaign', title: 'com.optimsys.costra.kordex.campaign.sal.sf.SfCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.sal.pis.PisCampaign', title: 'com.optimsys.costra.kordex.campaign.sal.pis.PisCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.sal.pis.PisSKCampaign', title: 'com.optimsys.costra.kordex.campaign.sal.pis.PisSKCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.sal.telephony.TelCampaign', title: 'com.optimsys.costra.kordex.campaign.sal.telephony.TelCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.vw.fs.VwFsCampaign', title: 'com.optimsys.costra.kordex.campaign.vw.fs.VwFsCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.ucb.telephony.TelCampaign', title: 'com.optimsys.costra.kordex.campaign.ucb.telephony.TelCampaign' },
    {value: 'com.optimsys.costra.kordex.campaign.cofidis.collection.CollectionCampaign', title: 'com.optimsys.costra.kordex.campaign.cofidis.collection.CollectionCampaign'}
    /*
    {value: 'com.optimsys.costra.kordex.campaign.erl.telephony.TelCampaign', title: 'campaigns.campaign.classes.erl.TelCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.lcs.telephony.KvCampaign', title: 'campaigns.campaign.classes.KvCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.lcs.telephony.RetCampaign', title: 'campaigns.campaign.classes.RetCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.lcs.telephony.RupCampaign', title: 'campaigns.campaign.classes.RupCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.lcs.telephony.WcCampaign', title: 'campaigns.campaign.classes.WcCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.lcs.telephony.ZamCampaign', title: 'campaigns.campaign.classes.ZamCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.sal.telephony.SkWcCampaign', title: 'campaigns.campaign.classes.SkWcCampaign'},
    {value: 'com.optimsys.costra.kordex.campaign.sal.telephony.SkTelCampaign', title: 'campaigns.campaign.classes.SkTelCampaign'},
    */
  ]

  static Row(props) {
    return <div className={'row' + (props.item.allowView ? ' withHover' : '')}
                onClick={(event) => props.item.allowView && Campaign.go(event, props.item.item._id.$oid)}>
      <div><div className="icon campaign"/></div>
      <div>{props.item.item.displayName}</div>
      {props.item.allowView &&
        <div className="right button"
             key="open"
             title={Translate.get('openInNewWindow')}
             onClick={(event) => Campaign.go(event, props.item.item._id.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      }
    </div>
  }
}

export default Campaign