import React from 'react'
import Translate from '../../../costra/Translate.js'
import Validator from '../../../costra/Validator.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import CaseRecord from '../../CaseRecord.js'
import '../../../css/form.css'

const pisState = [
  {value: 'OD', title: 'sal.pis.state.OD'},
  {value: 'U11', title: 'sal.pis.state.U11'},
  {value: 'U12', title: 'sal.pis.state.U12'},
  {value: 'U13', title: 'sal.pis.state.U13'},
  {value: 'U14', title: 'sal.pis.state.U14'},
  {value: 'U15', title: 'sal.pis.state.U15'},
  {value: 'Doruc', title: 'sal.pis.state.Doruc'},
  {value: 'Doruc_EMAIL', title: 'sal.pis.state.Doruc_EMAIL'},
  {value: 'VUpEMAIL_Neodeslano', title: 'sal.pis.state.VUpEMAIL_Neodeslano'},
]

function PisSkProperties(props) {
  return <div>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection case" />
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <div className="column-block">
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="caseNo"
                     title='caserecord.properties.caseNo'
                     validate={Validator.notEmptyString} />

          {props.editOpen && <>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.evNum"
                       title="caserecord.sal.evNum"/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.documentId"
                       title="caserecord.sal.documentId"/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.eventId"
                       title="caserecord.sal.eventId"/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.fk"
                       title="caserecord.sal.fk"/>
          </>}

          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="notificationType"
                     title='sal.pis.notificationType'/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="productName"
                     title='sal.pis.productName' />
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="regularPayment"
                     title='sal.pis.regularPayment' />
          <Form.DateTime data={props.data}
                          disabled={!props.editOpen}
                          path="notificationDate"
                          title='caserecord.properties.notificationDate'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={!props.editOpen}
                          path="signatureDate"
                          title='caserecord.properties.signatureDate'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={true}
                          path="processing.created"
                          title='caserecord.processing.created'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={!props.editOpen}
                          path="plannedEnding"
                          title='caserecord.properties.plannedEnding'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={true}
                          path="processing.finished"
                          title='caserecord.processing.finished'
                          format="%d.%m.%yyyy" />
          <Form.Select data={props.data}
                      disabled={!props.editOpen}
                      path="pisState"
                      title="sal.pis.state"
                      values={pisState} />
          <Form.Select data={props.data}
                       disabled={!props.editOpen}
                       path="source"
                       title="sal.source"
                       values={CaseRecord.sources}/>
          <Form.Text data={props.data}
                      disabled={!props.editOpen}
                      path="exportedNote"
                      styling="textArea big"
                      title='caserecord.properties.exportedNote' />
        </div>
      </div>
    </div>
    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </div>
}

export default PisSkProperties