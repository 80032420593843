import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'
import Dialogs from '../costra/Dialogs.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'
import '../css/dialogs.css'

class AddPaymentDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      working: false,
      received: null,
      currencies: [],
    }
    this.data = new EditData(this, 'received.')
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.caseId !== this.props.caseId) {
      this.load()
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.paymentplan.PrepareNewPayment',
      caseId: this.props.caseId,
    }).then(result => {
      if (result.currencies) {
        result.currencies = result.currencies.map(c => {
          return {title: c, value: c}
        })
      }
      this.setState(result)
    })
  }

  addPayment() {
    this.setState({working: true})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.paymentplan.AddNewPayment',
      caseId: this.props.caseId,
      received: this.state.received,
    }).then(() => {
      Dialogs.close(true)
    })
  }

  renderContent() {
    if (!this.state.received || this.state.working) {
      return <LoadingPlaceholder/>
    }
    return <>
      <Form.Text data={this.data}
                 path="id"
                 title='paymentplan.received.id'/>
      <Form.Amount data={this.data}
                   path="amount"
                   title='paymentplan.received.amount'/>
      <Form.Select data={this.data}
                   path="currency"
                   values={this.state.currencies}
                   title='paymentplan.received.currency'/>
      <Form.DateTime data={this.data}
                     path="time"
                     title='paymentplan.received.date'
                     format="%d.%m.%yyyy"/>
      <Form.Text data={this.data}
                 path="source"
                 title='paymentplan.received.source'/>
      <Form.TextArea data={this.data}
                     path="note"
                     title='paymentplan.received.note'/>
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig money"/>
          <span>{Translate.get('paymentplan.received.add.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button" onClick={(event) => this.addPayment()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button" onClick={(event) => Dialogs.close(false)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>

      </div>
    </div>
  }
}

export default AddPaymentDialog