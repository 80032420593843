import Container from '../costra/Container.js'

class NavContainer extends Container {
  load(id, source) {
    let kordexClass = ''
    switch(source) {
      case 'case': kordexClass = 'com.optimsys.costra.kordex.nav.NavContainer$GetByCase'
        break;
      case 'caseObject': kordexClass = 'com.optimsys.costra.kordex.nav.NavContainer$GetByObject'
        break;
      case 'addressBook': kordexClass = 'com.optimsys.costra.kordex.nav.NavContainer$GetByAddressBook'
        break;
      default: kordexClass = 'com.optimsys.costra.kordex.nav.NavContainer$GetByCase'
    }
    return this.ask({
      _class: kordexClass,
      id: id,
    })
  }
}

export default NavContainer