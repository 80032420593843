import { Component } from 'react'
import Translate from '../costra/Translate'
import Dialogs from '../costra/Dialogs.js'
import DateHelper from '../costra/DateHelper.js'
import WsContainers from '../costra/WsContainers.js'

import AddNoteDialog from './AddNoteDialog.js'
import EventEditDialog from './EventEditDialog.js'

class Event extends Component {

  static Icon(props) {
    var eventClass = props.event ? props.event._class : props.eventClass
    var icon = 'alert'
    switch(eventClass) {
      case 'com.optimsys.costra.kordex.events.CaseUpdateAcknowledged': icon = 'ok'; break;
      case 'com.optimsys.costra.kordex.events.CaseUpdated': icon = 'edit'; break;
      case 'com.optimsys.costra.kordex.events.ObjectBackOfficeCheck': icon = 'taskCompleted'; break;
      case 'com.optimsys.costra.kordex.events.ObjectCertification': icon = 'law'; break;
      case 'com.optimsys.costra.kordex.events.ObjectChecked': icon = 'ok'; break;
      case 'com.optimsys.costra.kordex.events.ObjectCollected': icon = 'tow'; break;
      case 'com.optimsys.costra.kordex.events.ObjectCollectionImpossible': icon = 'cancel'; break;
      case 'com.optimsys.costra.kordex.events.ObjectEnRoute': icon = 'tow'; break;
      case 'com.optimsys.costra.kordex.events.ObjectInCustomsDepot': icon = 'law'; break;
      case 'com.optimsys.costra.kordex.events.ObjectNotFound': icon = 'unknown'; break;
      case 'com.optimsys.costra.kordex.events.ObjectProcessingCancelled': icon = 'cancel'; break;
      case 'com.optimsys.costra.kordex.events.ObjectTpCollected': icon = 'law'; break;
      case 'com.optimsys.costra.kordex.events.ObjectLockdownStateChanged':  icon = 'wheel'; break;
      case 'com.optimsys.costra.kordex.events.ObjectTransferred': icon = 'tow'; break;
      case 'com.optimsys.costra.kordex.events.ObjectInDemo': icon = 'demo'; break;
      case 'com.optimsys.costra.kordex.events.ObjectLent': icon = 'wheel'; break;
      case 'com.optimsys.costra.kordex.events.ObjectServiced': icon = 'tow'; break;
      case 'com.optimsys.costra.kordex.events.ObjectSold': icon = 'payment'; break;
      case 'com.optimsys.costra.kordex.events.DocumentSigned':  icon = 'document'; break;
      case 'com.optimsys.costra.kordex.events.NoteEvent': icon = 'note'; break;
      case 'com.optimsys.costra.kordex.events.PrivateNoteEvent': icon = 'hidden'; break;
      case 'com.optimsys.costra.kordex.events.LocationVisited': icon = 'address'; break;
      default: break;
    }
    return <div className={'icon left ' + icon + ' ' + props.extraClass} title={Translate.get(eventClass)}/>
  }

  static editEvent(event) {
    return Dialogs.open(<EventEditDialog event={event}/>)
  }

  static removeEvent(event) {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.processing.RemoveEvent',
      id: event._id,
    })
  }

  static addNote(caseIds) {
    return Dialogs.open(<AddNoteDialog caseIds={caseIds}/>)
  }

  static markLocationVisited(userId, caseIds, address) {
    var event = {
      _class: 'com.optimsys.costra.kordex.events.LocationVisited',
      owner: caseIds,
      userId: userId,
      time: DateHelper.now(),
      address: address,
      note: '',
      privateNone: '',
    }
    return Dialogs.open(<EventEditDialog event={event}/>)
  }
}

export default Event