import React, { Component } from 'react'

import ItemInfo from './ItemInfo.js'

import './navPanel.css'

class ItemsList extends Component {

  render() {
    return <div className='navPanel__object adressee'>
      {this.props.items && this.props.items.map(item =>
        <ItemInfo item={item}
                  id={this.props.id}
                  campaigns={this.props.campaigns}
                  key={item.item._id.$oid}/>
      )}
    </div>
  }
}

export default ItemsList