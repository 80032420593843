import React from 'react'
import Translate from '../costra/Translate.js'

import Item from '../addressbook/Item.js'
import CaseRecord from '../caserecord/CaseRecord.js'
import CaseObject from '../caseobject/CaseObject.js'

class ImportLog {

  static rowHeader(props) {
    var classParts = props.item.entityClass.split('.')
    var part = null
    if (classParts.length > 5) {
      part = classParts[4]
    }
    switch(part) {
      case 'payment':
        return <div className="row">
          <div><div className="icon money"/></div>
          <div className="size4c">{Translate.get('imports.log.paymentPlan')}</div>
          {props.item.values &&
            <div>{props.item.values[0].value}</div>
          }
        </div>

      case 'caserecord':
        return <div className="row withHover"
                    onClick={event => CaseRecord.go(event, props.item.entityId.$oid)}>
          <div><div className="icon case"/></div>
          <div className="size4c">{Translate.get('imports.log.case')}</div>
          {props.item.values &&
            <div>{props.item.values[0].value}</div>
          }
        </div>

      case 'caseobject':
        return <div className="row withHover"
                    onClick={event => CaseObject.go(event, props.item.entityId.$oid)}>
          <div><div className="icon car"/></div>
          <div className="size4c">{Translate.get('imports.log.object')}</div>
          {props.item.values &&
            <div>{props.item.values[0].value}</div>
          }
        </div>

      // this is in fact addressbook item
      case 'db':
        return <div className="row withHover"
                    onClick={event => Item.go(event, props.item.entityId.$oid)}>
          <div><div className="icon person"/></div>
          <div className="size4c">{Translate.get('imports.log.addressBook')}</div>
          {props.item.values &&
            <div>{props.item.values[0].value}</div>
          }
        </div>

      default:
        return <div className="row">
          <div><div className="icon error"/></div>
          <div>{props.item.entityClass} {part}</div>
        </div>
    }
  }

  static Row(props) {
    return <>
      {ImportLog.rowHeader(props)}
      <ul className="list spaced">
        {props.item.errors && props.item.errors.map((error, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon cancel"/></div>
              <div className="size7c">{error.fileName}</div>
              <div className="size2c">{error.position}</div>
              <div className="size4c">{error.title}</div>
              <div className="size4c">{error.value}</div>
              <div className="size4c">{Translate.get(error.code)}</div>
            </div>
          </li>
        )}
        {props.item.values && props.item.values.map((value, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon ok"/></div>
              <div className="size7c">{value.fileName}</div>
              <div className="size2c">{value.position}</div>
              <div className="size4c">{value.title}</div>
              <div>{value.value}</div>
            </div>
          </li>
        )}
      </ul>
    </>
  }
}

export default ImportLog