import React, { Component } from 'react'
import Formatter from '../costra/Formatter.js'

import '../css/form.css'
import Translate from '../costra/Translate.js'

class Processing extends Component {

  renderCheckupsCampaignCase() {
    switch(this.props.item.closedWith) {
      case 'ObjectDocumentOnly':
      case 'LocationDocumentOnly':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.case')}</h2>
          <ul>
            <li>
              {Translate.get('campaigns.processing.caseIsMarkedAs')} 
              <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
              {Translate.get('campaigns.processing.tillAllLocationsHaveBeenCecked')}
            </li>
            <li>
              {Translate.get('campaigns.processing.afterCheck')} <b>{Translate.get('campaigns.processing.closed')}</b>,
              {Translate.get('campaigns.processing.marked')} <b>{Translate.get('campaigns.processing.forInvoicing')}</b>
              {Translate.get('campaigns.processing.systemSendsMail')}<b>{Translate.get('campaigns.processing.clientsMails')}</b>, 
              <b>{Translate.get('campaigns.processing.clientsCasesMails')}</b>{Translate.get('campaigns.processing.and')}
              <b>{Translate.get('campaigns.processing.notificationsMails')}</b>
            </li>
            <li>
              <b>{Formatter.formatSeconds(this.props.item.notificationTimeBeforeEnd)}</b>
              {Translate.get('campaigns.processing.caseIsTaggedAs')}<b>{Translate.get('campaigns.processing.caseEnd')}</b>
            </li>
            <li> 
              {Translate.get('campaigns.processing.caseIsUnfinishedAfterDeadline')} 
              <b>{Translate.get('campaigns.processing.forSupervisor')} </b>
            </li>
            <li> {Translate.get('campaigns.processing.caseIsUnfinished')}
              <b>{Formatter.formatSeconds(this.props.item.timeTillOverdue)}</b>{Translate.get('campaigns.processing.mailIsSendTo')} 
              <b>{Translate.get('campaigns.processing.supervisorsMails')}</b>{Translate.get('campaigns.processing.and')} 
              <b> {Translate.get('campaigns.processing.usersAsSupervisors')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.caseCanBe')}
              <b>{Translate.get('campaigns.processing.toInvoice')}</b>{Translate.get('campaigns.processing.taggedByUser')}<b>
              {Translate.get('campaigns.processing.invoiced')}</b>
            </li>
          </ul>
        </div>

      case 'ObjectDocumentAndVerification':
      case 'LocationDocumentAndVerification':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.case')}</h2>
          <ul>
            <li>
              {Translate.get('campaigns.processing.caseIsMarkedAs')} 
              <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b> 
              {Translate.get('campaigns.processing.tillAllLocationsHaveBeenCecked')}
            </li>
            <li>
              {Translate.get('campaigns.processing.afterCheck')} {Translate.get('campaigns.processing.marked')} 
              <b>{Translate.get('campaigns.processing.closed')}</b>{Translate.get('campaigns.processing.taskIsCreatedFor')}
              <b> {Translate.get('campaigns.processing.backOfficeAgents')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.userTagCaseAs')} <b>{Translate.get('campaigns.processing.closed')}</b>
              {Translate.get('campaigns.processing.isMarked')} <b>{Translate.get('campaigns.processing.forInvoicing')}</b>
              {Translate.get('campaigns.processing.systemSendsMail')}<b>{Translate.get('campaigns.processing.clientsMails')}</b>,
              <b>{Translate.get('campaigns.processing.clientsCasesMails')}</b>{Translate.get('campaigns.processing.and')}
              <b>{Translate.get('campaigns.processing.notificationsMails')}</b>
            </li>
            <li>
              <b>{Formatter.formatSeconds(this.props.item.notificationTimeBeforeEnd)}</b>
              {Translate.get('campaigns.processing.caseIsTaggedAs')} 
              <b>{Translate.get('campaigns.processing.campaigns.processing.caseEnd')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.caseIsUnfinishedAfterDeadline')} 
              <b>{Translate.get('campaigns.processing.forSupervisor')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.caseIsUnfinished')} 
              <b>{Formatter.formatSeconds(this.props.item.timeTillOverdue)}</b>
              {Translate.get('campaigns.processing.afterDeadlineMailIsSent')} 
              <b>{Translate.get('campaigns.processing.supervisorsMails')}</b> {Translate.get('campaigns.processing.and')} 
              <b>{Translate.get('campaigns.processing.usersAsSupervisors')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.caseCanBe')} <b>{Translate.get('campaigns.processing.forInvoicing')}</b>
              {Translate.get('campaigns.processing.taggedByUser')} <b>{Translate.get('campaigns.processing.invoiced')}</b>
            </li>
          </ul>
        </div>

      default:
        return null
    }
  }

  renderCheckupsCampaignLocation() {
    switch(this.props.item.closedWith) {
      case 'LocationDocumentOnly':
      case 'LocationDocumentAndVerification':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.location')}</h2>
          <ul>
            <li>{Translate.get('campaigns.processing.locationWithoutObjec')}</li>
            <li>{Translate.get('campaigns.processing.definitionOfCheckedLocation')}</li>
            <li>{Translate.get('campaigns.processing.checkedLocationWithoutProtocol')}
               <b>{Translate.get('campaigns.processing.missingProtocol')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilAllObjectIsChecked')}
              <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilLocalityISIsChecked')}<b>
              {Translate.get('campaigns.processing.noVisitsScheduled')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilVisitORContactIsSchedule')}
              <b>{Formatter.formatSeconds(this.props.item.timeToPlanVisit)}</b>
              {Translate.get('campaigns.processing.fromBeginningTaskIsCreated')}
              <b>{Translate.get('campaigns.processing.scheduleVisit')}</b>{Translate.get('campaigns.processing.for')} 
              <b>{Translate.get('campaigns.processing.localityCheck')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.afterScheduleVisitMailIsSent')} 
              <b>{Translate.get('campaigns.processing.clientsMails')}</b> {Translate.get('campaigns.processing.and')} 
              <b>{Translate.get('campaigns.processing.clientsCasesMails')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilAllObjectsIsChecked')} 
              <b>{Formatter.formatSeconds(this.props.item.timeToCompleteVisit)}</b>
              {Translate.get('campaigns.processing.fromThePlannedVisitLocationIsTaggedAs')} 
              <b>{Translate.get('campaigns.processing.thereWasNoPlannedVisit')}</b> 
              {Translate.get('campaigns.processing.taskIsCreatedFor')} <b>{Translate.get('campaigns.processing.supervisors')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.newTaskIsCreatedAfterRescheduling')} 
              <b>{Translate.get('campaigns.processing.scheduleVisit')}</b> {Translate.get('campaigns.processing.for')} 
              <b>{Translate.get('campaigns.processing.localityCheck')}</b>
            </li>
          </ul>
        </div>

      case 'ObjectDocumentOnly':
      case 'ObjectDocumentAndVerification':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.location')}</h2>
          <ul>
            <li>{Translate.get('campaigns.processing.locationWithoutObjec')}</li>
            <li>{Translate.get('campaigns.processing.definitionOfCheckedLocation')}</li>
            <li>{Translate.get('campaigns.processing.untilAllObjectIsChecked')}<b>
              {Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilLocalityISIsChecked')} 
              <b>{Translate.get('campaigns.processing.noVisitsScheduled')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilVisitORContactIsSchedule')}
              <b>{Formatter.formatSeconds(this.props.item.timeToPlanVisit)}</b> 
              {Translate.get('campaigns.processing.fromBeginningTaskIsCreated')} 
              <b>{Translate.get('campaigns.processing.scheduleVisit')}</b> {Translate.get('campaigns.processing.for')} 
              <b>{Translate.get('campaigns.processing.localityCheck')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.afterScheduleVisitMailIsSent')}
              <b>{Translate.get('campaigns.processing.clientsMails')}</b> {Translate.get('campaigns.processing.and')} 
              <b>{Translate.get('campaigns.processing.clientsCasesMails')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.untilAllObjectsIsChecked')} 
              <b>{Formatter.formatSeconds(this.props.item.timeToCompleteVisit)}</b>
              {Translate.get('campaigns.processing.fromThePlannedVisitLocationIsTaggedAs')} 
              <b>{Translate.get('campaigns.processing.thereWasNoPlannedVisit')}</b> 
              {Translate.get('campaigns.processing.taskIsCreatedFor')} <b>{Translate.get('campaigns.processing.supervisors')}</b>
            </li>
            <li>
              {Translate.get('campaigns.processing.newTaskIsCreatedAfterRescheduling')}
              <b>{Translate.get('campaigns.processing.scheduleVisit')}</b> {Translate.get('campaigns.processing.for')} 
              <b>{Translate.get('campaigns.processing.localityCheck')}</b>
            </li>
          </ul>
        </div>

      default:
        return null
    }
  }

  renderVwFsCase() {
    return <div>
      <h2 className="fullRow">{Translate.get('campaigns.processing.case')}</h2>
      <ul>
        <li>{Translate.get('campaigns.processing.caseIsMarkedAs')} 
          <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
          {Translate.get('campaigns.processing.untilAllLocationsAreChecked')}
        </li>
        <li>
          {Translate.get('campaigns.processing.afterChangedStateOfObjectCaseIs')} 
          <b>{Translate.get('campaigns.processing.closed')}</b>, {Translate.get('campaigns.processing.marked')} 
          <b>{Translate.get('campaigns.processing.forInvoicing')}</b>
          {Translate.get('campaigns.processing.systemSendsMail')}<b>{Translate.get('campaigns.processing.clientsMails')}</b>,
          <b>{Translate.get('campaigns.processing.clientsCasesMails')}</b> {Translate.get('campaigns.processing.and')} 
          <b>{Translate.get('campaigns.processing.notificationsMails')}</b>
        </li>
        <li>
          <b>{Formatter.formatSeconds(this.props.item.notificationTimeBeforeEnd)}</b> 
          {Translate.get('campaigns.processing.caseIsTaggedAs')}
          <b>{Translate.get('campaigns.processing.caseEnd')}</b>
        </li>
        <li>
          {Translate.get('campaigns.processing.caseIsUnfinishedAfterDeadline')} 
          <b>{Translate.get('campaigns.processing.forSupervisor')}</b>
        </li>
        <li>
          {Translate.get('campaigns.processing.caseIsUnfinished')}
          <b>{Formatter.formatSeconds(this.props.item.timeTillOverdue)}</b>
          {Translate.get('campaigns.processing.mailIsSendTo')} <b>{Translate.get('campaigns.processing.supervisorsMails')}</b> 
          {Translate.get('campaigns.processing.and')}<b>{Translate.get('campaigns.processing.usersAsSupervisors')}</b>
        </li>
        <li>
          {Translate.get('campaigns.processing.caseCanBe')}  <b>{Translate.get('campaigns.processing.forInvoicing')}</b>
          {Translate.get('campaigns.processing.taggedByUser')} <b>{Translate.get('campaigns.processing.invoiced')}</b>
        </li>
        <li>
          {Translate.get('campaigns.processing.messageDeadtlineIS')} <b>{Translate.get('campaigns.processing.45days')}</b>
        </li>
        <li>
          {Translate.get('campaigns.processing.futurePayments')}<b>{Translate.get('campaigns.processing.3days')}</b>
          {Translate.get('campaigns.processing.before')}
        </li>
        <li>
          {Translate.get('campaigns.processing.company')} <b>{Translate.get('campaigns.processing.contactingClient')}</b>
          {Translate.get('campaigns.processing.specifiedByCompany')}
        </li>
        <li>
          {Translate.get('campaigns.processing.ifOrderIsTerminated')}<b>{Translate.get('campaigns.processing.seciuringCas')}</b>
          {Translate.get('campaigns.processing.writeHandoverProtocol')}
          <b>{Translate.get('campaigns.processing.creditAgreements')}</b>{Translate.get('campaigns.processing.verifiedPower')} 
        </li>
      </ul>
    </div>
  }

  renderCheckupsCampaignObject() {
    switch(this.props.item.closedWith) {
      case 'LocationDocumentOnly':
      case 'LocationDocumentAndVerification':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.object')}</h2>
          <ul>
            <li>
              {Translate.get('campaigns.processing.objectIsMarkedAs')} 
              <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
              {Translate.get('campaigns.processing.untilUserTagAs')} 
              <b>{Translate.get('campaigns.processing.isChecked')}</b> {Translate.get('campaigns.processing.or')} 
              <b>{Translate.get('campaigns.processing.notFound')}</b>
            </li>
            <li>
              <b>{Translate.get('campaigns.processing.notFound')}</b>{Translate.get('campaigns.processing.objectIsChecked')} 
            </li>
            <li>
              {Translate.get('campaigns.processing.to')} <b>{Translate.get('campaigns.processing.toChecked')}</b>
              {Translate.get('campaigns.processing.timeAndPhotoMustBe')}
              <b>{Translate.get('campaigns.processing.missingPhoto')}</b>{Translate.get('campaigns.processing.notChecked')}
            </li>
          </ul>
        </div>

      case 'ObjectDocumentOnly':
      case 'ObjectDocumentAndVerification':
        return <div>
          <h2 className="fullRow">{Translate.get('campaigns.processing.object')}</h2>
          <ul>
            <li>
              {Translate.get('campaigns.processing.objectIsMarkedAs')} 
              <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
              {Translate.get('campaigns.processing.untilUserTagAs')} <b>{Translate.get('campaigns.processing.isChecked')}</b>
              {Translate.get('campaigns.processing.or')}  <b>{Translate.get('campaigns.processing.notFound')}</b>
            </li>
            <li>
              <b>{Translate.get('campaigns.processing.notFound')}</b> {Translate.get('campaigns.processing.objectIsChecked')}
            </li>
            <li>
              {Translate.get('campaigns.processing.to')} <b>{Translate.get('campaigns.processing.toChecked')}</b>
              {Translate.get('campaigns.processing.timeAndPhotoAndPRotocolMustBe')}  
              <b>{Translate.get('campaigns.processing.missingPhoto')}</b>{Translate.get('campaigns.processing.andOr')}  
              <b>{Translate.get('campaigns.processing.missingProtocol')}</b>{Translate.get('campaigns.processing.notChecked')}
            </li>
          </ul>
        </div>

      default:
        return null
    }
  }

  renderVwFsObject() {
    return <div>
      <h2 className="fullRow">{Translate.get('campaigns.processing.location')}</h2>
      <ul>
        <li>
          {Translate.get('campaigns.processing.objectIsMarkedAs')}
          <b>{Translate.get('campaigns.processing.inspectionIsInProgress')}</b>
          {Translate.get('campaigns.processing.untilTheUserChooseDifferent')}<b>{Translate.get('campaigns.processing.state')}</b>
        </li>
      </ul>
    </div>
  }

  render() {
    if (this.props.id === 'new') {
      return <div className="spacedTop">
        <div className="icon left info"/>
        <div className="left">{Translate.get('campaigns.processing.notification')}</div>
      </div>
    }

    switch (this.props.item._class) {
      case 'com.optimsys.costra.kordex.campaign.erl.pred.PredCampaign':
        return <div>
          {this.renderCheckupsCampaignCase()}
          {this.renderCheckupsCampaignLocation()}
          {this.renderCheckupsCampaignObject()}
        </div>

    case 'com.optimsys.costra.kordex.campaign.vw.fs.VwFsCampaign':
      return <div>
        {this.renderVwFsCase()}
        {this.renderCheckupsCampaignLocation()}
        {this.renderVwFsObject()}
      </div>

      default:
        return <div>{this.props.item._class}</div>
    }
  }
}

export default Processing
