import Container from '../costra/Container.js'

class SimpleListContainer extends Container {

  static initialState() {
    return {
      items : null,
      page : 1,
      pages : 1,
      recordsTotal : 0,
      itemsPerPage : null,
      ordering: {},
    }
  }

  params() {
    return {
      page : this.component.state.page,
      itemsPerPage : this.component.state.itemsPerPage,
      ordering: this.component.state.ordering,
    }
  }

  reload() {
    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params : this.params(),
    })
  }

  setPage(page) {
    if (page < 1) page = 1
    else if (page > this.component.state.pages) page = this.component.state.pages
    var params = this.params()
    params.page = page
    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params: params,
    })
  }

  setItemsPerPage(itemsPerPage) {
    var params = this.params()
    params.itemsPerPage = itemsPerPage
    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params: params,
    })
  }

  setPageWithItemsPerPage(page, itemsPerPage) {
    let params = this.params()
    if (params.itemsPerPage !== itemsPerPage) {
      params.page = 1
      params.itemsPerPage = itemsPerPage
    }
    else {
      if (page < 1) page = 1
      else if (page > this.component.state.pages) page = this.component.state.pages
      params.page = page
    }

    this.tell({
      _class : 'com.optimsys.costra.ws.mongo.SimpleListContainer$Get',
      params: params,
    })

  }
}

export default SimpleListContainer
