import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import DropBox from '../dropbox/DropBox.js'
import '../css/dialogs.css'

class DropBoxSelectDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading : true,
    }
  }

  componentDidMount() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.dropbox.GetDropBoxes',
      campaignId : this.props.campaignId,
    }).then((result) => {
      if (!result.items) {
        result.items = []
      }
      this.setState({dropBoxes: result.items, loading : false})
      return result
    }, () => {
      this.setState({error: 'genericLoadError', loading: false})
    })
  }

  doExport(event, dropBoxId) {
    this.setState({loading : true})
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.exports.ExportToDropBox',
      exportData : this.props.exportData,
      dropBoxId : dropBoxId,
    }).then(result => {
      Dialogs.close(null)
      DropBox.go(null, dropBoxId.$oid)
      return result
    }, () => {
      this.setState({error: 'genericStoreError', loading: false})
    })
  }

  renderContent() {
    if (this.state.error) {
      return <p>Translate.get(this.state.error)</p>
    }
    if (this.state.loading) {
      return <LoadingPlaceholder/>
    }
    return (
      <ul className="list spacedBottom">
        {this.state.dropBoxes.map(dropBox =>
          <li key={dropBox._id.$oid}>
            <div className="row withHover"
                 onClick={event => this.doExport(event, dropBox._id)}>
              <div><div className="icon dropBox"/></div>
              <div>{dropBox.name}</div>
            </div>
          </li>
        )}
      </ul>
    )
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog small frame">
          <div className="title ">
            <div className="left icon iconBig export"/>
            <span>{Translate.get('exports.selectDropBox.title')}</span>
          </div>
          {this.renderContent()}
          <div className="buttons">
            <div className="button"
              title={Translate.get('close')}
              onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel" />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default DropBoxSelectDialog