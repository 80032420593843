import Translate from "../costra/Translate"

class ProcessingGroup {
    
  static Group(group) {
    return <div className="group__icon">
      <div className="dotted"
           title={Translate.get('processingGroup.' + group)}>
        {Translate.get('processingGroup.short.' + group)}
      </div>
    </div>
  }
}
  
export default ProcessingGroup