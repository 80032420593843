import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import EditButtons from '../utils/EditButtons.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import ErlPredProperties from './erl/pred/PredProperties.js'
import SalCollectionProperties from './sal/collection/CollectionProperties.js'
import SalSfCarProperties from './sal/sf/SfCarProperties.js'
import SalSfVtpProperties from './sal/sf/SfVtpProperties.js'
import SalTelProperties from './sal/telephony/TelProperties.js'
import SalPisProperties from './sal/pis/PisProperties.js'
import VwFsProperties from './vw/fs/VwFsProperties.js'
import CofidisCollectionProperties from './cofidis/collection/CollectionProperties.js'

import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen: false,
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caseobject.FetchObject',
      id: this.props.id,
    }).then((result) => {
      // no, No, no no no no no, NO NO, nO no no nooooooo nonononono
      /*
      var item = result.item
      if (item._class === "com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject" && !item.contactAddress.extensions) {
        item.contactAddress = {...item.contactAddress,
          extensions: {
            kordex: {
              note : "Porsche Praha Prosek",
              _class : "com.optimsys.costra.kordex.addressbook.KordexAddressExtension"
            }
          }
        }
      }
      */
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'caseobject.propertiesEdit.error'})
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caseobject.UpdateObject',
      item: this.state.item,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderContent() {
    switch (this.state.item._class) {

      case 'com.optimsys.costra.kordex.caseobject.erl.pred.PredCaseObject':
        return <ErlPredProperties component={this}
                                  item={this.state.item}
                                  editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
        return <SalSfCarProperties component={this}
                                   item={this.state.item}
                                   editOpen={this.state.editOpen} />

      case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectVtp':
        return <SalSfVtpProperties component={this}
                                   item={this.state.item}
                                   editOpen={this.state.editOpen} />

      case 'com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject':
        return <VwFsProperties component={this}
                               item={this.state.item}
                               editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caseobject.sal.telephony.TelCaseObject':
        return <SalTelProperties component={this}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caseobject.sal.pis.PisCaseObject':
        return <SalPisProperties component={this}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caseobject.sal.collection.CollectionCaseObject':
        return <SalCollectionProperties component={this}
                                        item={this.state.item}
                                        editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caseobject.cofidis.collection.CollectionCaseObject':
        return <CofidisCollectionProperties component={this}
                                            item={this.state.item}
                                            editOpen={this.state.editOpen}/>

      /* -------------------------------- TELEPHONY ------------------------- */
      /*
      case 'com.optimsys.costra.kordex.caseobject.erl.telephony.TelCaseObject':
        return <ErlTelEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.KvCaseObject':
        return <KvEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RetCaseObject':
        return <RetEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RupCaseObject':
        return <RupEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.TelCaseObject':
        return <TelEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.WcCaseObject':
        return <WcEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.ZamCaseObject':
        return <div>Nemá předmět</div>
      case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkWcCaseObject':
        return <SkWcEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkTelCaseObject':
        return <SkTelEdit title={this.props.title}
            component={this}
            item={this.state.item}
            editOpen={this.state.editOpen}
            vtpTypes={vtpTypes}/>
      */
      default:
        return <div>{this.state.item._class}</div>
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="section spacedTop"><LoadingPlaceholder /></div>
    }
    if (this.state.error) {
      return <div className="section spacedTop">{Translate.get(this.state.error)}</div>
    }
    return <>
      <div className="section spacedTop">
        <div className="buttons">
          <EditButtons component={this}/>
        </div>
        {this.renderContent()}
      </div>
    </>
  }
}

export default Properties
