import React, { Component } from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import '../../../css/form.css'

class VwFsProperties extends Component{

  static tpStates = [
    {value: 'ORI', title: 'caseobject.vw.fs.tpStates.ORI'},
    {value: 'CTP', title: 'caseobject.vw.fs.tpStates.CTP'},
    {value: 'PUJ', title: 'caseobject.vw.fs.tpStates.PUJ'},
    {value: 'VYD', title: 'caseobject.vw.fs.tpStates.VYD'},
    {value: 'Unknown', title: 'caseobject.vw.fs.tpStates.Unknown'},
  ]

  render() {
    return <>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection car"/>
          <h3>{Translate.get('caseobject.properties.title')}</h3>
        </div>
        <div className="content">
          <Form.Address data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="address"
                        title='caseobject.properties.address'
                        caseIds={[this.props.component.data.get("caseId")]}/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="name"
                     title='caseobject.properties.objectName'/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="licenseNumber"
                     title='caseobject.properties.licenseNumber'
                     validate={Validator.notEmptyId}/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="vin"
                     title='caseobject.properties.vin'
                     validate={Validator.notEmptyId}
                     styling={Metrics.CheckVin(this.props.component.data.get("vin"),"Vin")}/>
          <Form.Text data={this.props.component.data}
                     disabled={!this.props.editOpen}
                     path="vtpNo"
                     title='caseobject.properties.vtp'
                     validate={Validator.notEmptyId}/>
          <Form.Select data={this.props.component.data}
                       disabled={!this.props.editOpen}
                       path="tpState"
                       title='caseobject.vw.fs.tpState'
                       validate={Validator.notEmptyString}
                       values={VwFsProperties.tpStates}/>
          <Form.Amount data={this.props.component.data}
                       currency = "CZK"
                       disabled={!this.props.editOpen}
                       path="purchasePrice"
                       title='caseobject.properties.purchasePrice'
                       validate={Validator.notEmptyId}/>
        </div>
      </div>

      {this.props.component.data.get('contactData') && this.props.component.data.get('contactData').map((contactData, index) =>
        <ContactData key={index}
                     data={this.props.component.data}
                     path={'contactData.' + index}
                     disabled={!this.props.editOpen}/>
      )}

    </>
  }
}

export default VwFsProperties