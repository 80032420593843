import React, { Component } from 'react'
import EditData from '../costra/EditData.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import DateHelper from '../costra/DateHelper.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Form from '../utils/Form.js'

import '../css/dialogs.css'

class AddNoteDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      time: DateHelper.now(),
      note : '',
      privateNote: '',
    }
    this.data = new EditData(this, '')
  }

  addNote() {
    this.setState({working: true})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.processing.AddNote',
      caseIds: this.props.caseIds,
      note: this.state.note,
      privateNote: this.state.privateNote,
    }).then((result) => {
      Dialogs.close(result)
    }, () => {
      this.setState({ error : 'event.updateError' })
    })
  }

  render() {
    if(this.state.working) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <LoadingPlaceholder/>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className='title'>
          <div className="icon iconBig edit"/>
          <span>
            {Translate.get('event.form.addNoteTitle') + ' ' +
                          Translate.get('event.form.ownersCount', null, {count: this.props.caseIds.length})}
          </span>
        </div>
        <div>
          <Form.DateTime data={this.data}
                         path="time"
                         title='event.form.time'
                         withTime={true}
                         dontAllowClear={true}
                         format="%d.%m.%yyyy %HH:%MM"/>
          <Form.TextArea data={this.data}
                         path="note"
                         title='event.form.note'/>
          <Form.TextArea data={this.data}
                         path="privateNote"
                         title='event.form.privateNote'/>
        </div>
        <div className="buttons spacedTop">
          <div className="button"
               title={Translate.get('apply')}
               onClick={() => this.addNote()}>
            <div className="icon iconBig ok"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default AddNoteDialog