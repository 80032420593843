import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import WatchedItemContainer from '../utils/WatchedItemContainer.js'

import EntityChangeLog from '../changelog/EntityChangeLog.js'
import MessageTemplatesList from '../message/MessageTemplatesList.js'
import CampaignFilesList from '../files/CampaignFilesList.js'

import Properties from './Properties.js'
import Processing from './Processing.js'
import '../css/list.css'
import '../css/icons.css'
import '../css/form.css'

class CampaignEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item : null,
      tab: 'props',
    }
    this.container = new WatchedItemContainer(this, 'com.optimsys.costra.kordex.campaign.CampaignContainer')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.load(this.props.id)
    }
    else {
      this.setState({item: null})
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  renderTabs() {
    switch(this.state.tab) {
      case 'files':
        return <CampaignFilesList id={this.props.id} allowEdit={this.state.allowEdit}/>
      case 'processing':
        return <Processing id={this.props.id} item={this.state.item}/>
      case 'tpl':
        return <MessageTemplatesList id={this.props.id}/>
      case 'log':
        return <EntityChangeLog id={this.props.id} item={this.state.item}/>
      default:
        return <Properties id={this.props.id}/>
    }
  }

  render() {
    return (
      <div className={'mainPanel' + (this.props.id === 'new' ? '' : ' withTabs')}>
        <div className="header">
          <div className="row">
            <div className="left icon iconBig campaign"/>
            {this.props.id === 'new' &&
              <h1>{Translate.get('campaigns.new.title')}</h1>
            }
            {this.state.item && <h1>{this.state.item.displayName}</h1>}
          </div>
          {this.props.id !== 'new' &&
            <div className="row">
              <div className="tabs">
                <div className={'tab' + (this.state.tab === 'props' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'props'})}>{Translate.get('campaigns.tab.properties')}</div>
                {/*
                <div className={'tab' + (this.state.tab === 'processing' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'processing'})}>{Translate.get('campaigns.tab.processing')}</div>
                */}
                <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'files'})}>{Translate.get('campaigns.tab.files')}</div>
                <div className={'tab' + (this.state.tab === 'tpl' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'tpl'})}>{Translate.get('campaigns.tab.tpl')}</div>
                <div className={'tab' + (this.state.tab === 'log' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'log'})}>{Translate.get('campaigns.tab.log')}</div>
              </div>
            </div>
          }
        </div>
        <div className="scrollable">
          <div className="content">
            {this.renderTabs()}
          </div>
        </div>
      </div>
    )
  }
}

export default CampaignEdit
