import Id from './Id.js'

class WsContainers {

  static isInitialized = false
  static isConnected = false

  static actions = {}
  static containers = {}
  static stateProcs = {}
  static wsConnection = null

  static init() {
    if(!WsContainers.isInitialized) {
      WsContainers.isInitialized = true
      WsContainers._reconnect()
      WsContainers._sendKeepAlive()
    }
  }

  static onStateChange(proc) {
    var id = Id.get()
    WsContainers.stateProcs[id] = proc
    return {
      cancel: () => {
        delete WsContainers.stateProcs[id]
      }
    }
  }

  static action(params) {
    return new Promise((success, failed) => {
      var id = Id.get()
      if (!WsContainers.isConnected || !WsContainers.wsConnection) {
        console.log('#WS Action cannot be processed while disconnected', params)
        failed('NotConnected')
      }
      else {
        console.log('#WS Sending action message', id, params)
        WsContainers.actions[id] = {
          success: success,
          failed: failed,
        }
        params['_actionId'] = id
        WsContainers.wsConnection.send(JSON.stringify(params))
        setTimeout(() => {
          if (WsContainers.actions[id]) {
            console.log("#WS Action timeout", id)
            WsContainers.actions[id].failed("Timeout")
            delete WsContainers.actions[id]
          }
        }, 30000)
      }
    })
  }

  static transaction(transaction) {
    return WsContainers.action({
      _class: 'com.optimsys.costra.ws.mongo.RunTransaction',
      transaction: transaction,
      tenant: transaction['_tenant'],
    })
  }

  static _reconnect() {
    var uri = 'ws://'
    if (window.location.protocol === 'https:') {
      uri = 'wss://'
    }
    uri += window.location.host + '/ws'
    console.log('#WS Connecting', uri)
    WsContainers.wsConnection = new WebSocket(uri)
    WsContainers.wsConnection.onmessage = WsContainers._onMessage
    WsContainers.wsConnection.onopen = _ => {
      console.log('#WS WebSocket connected')
      WsContainers.isConnected = true
      Object.keys(WsContainers.stateProcs).forEach(key =>
        WsContainers.stateProcs[key](true)
      )
    }
    WsContainers.wsConnection.onclose = _ => {
      console.log('#WS WebSocket disconnected')
      WsContainers.wsConnection = null
      if (WsContainers.isConnected) {
        WsContainers.isConnected = false
        Object.keys(WsContainers.stateProcs).forEach(key =>
          WsContainers.stateProcs[key](false)
        )
      }
      setTimeout((event) => WsContainers._reconnect(), 10000)
    }
  }

  static _onMessage(event) {
    var message = JSON.parse(event.data)
    if (message['_containerId']) {
      WsContainers._onContainerMessage(message)
    }
    else if (message['_replyId']) {
      WsContainers._onActionReply(message)
    }
    else {
      console.log('#WS Received unknown message', message)
    }
  }

  static _onContainerMessage(message) {
    if (WsContainers.containers[message['_containerId']]) {
      var containerId = message['_containerId']
      delete message._containerId
      WsContainers.containers[containerId]._onMessage(message)
    }
    else {
      console.log('#WS Received message to unknown container', message['_containerId'], message)
    }
  }

  static _onActionReply(reply) {
    var id = reply['_replyId']
    delete reply._replyId
    if (WsContainers.actions[id]) {
      if (reply['_failure']) {
        console.log("#WS Received action failure", id, reply['_failure'])
        WsContainers.actions[id].failed(reply['_failure'])
      }
      else {
        console.log("#WS Received action reply", id, reply)
        WsContainers.actions[id].success(reply)
      }
      delete WsContainers.actions[id]
    }
    else {
      console.log("#WS Received reply to unknown action", id, reply)
    }
  }

  static _sendKeepAlive(event) {
    if (WsContainers.isConnected && WsContainers.wsConnection) {
      console.log("#WS Sending keepalive")
      WsContainers.wsConnection.send(JSON.stringify({_keepalive: true}))
    }
    setTimeout(WsContainers._sendKeepAlive, 30000)
  }
}

export default WsContainers