import React from 'react';
import Translate from '../costra/Translate'

function LoadingPlaceholder(props) {
  return <div className="placeholder clearLeft">
    <div className={'icon loading left ' + props.className}/>
    <div className="text left">{Translate.get('loadingPlaceholderText')}</div>
  </div>
}

export default LoadingPlaceholder