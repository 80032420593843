import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'
import Nav from '../utils/Nav.js'
import ListContainer from '../utils/ListContainer.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import Batch from './Batch.js'
import PackagerSelectDialog from './PackagerSelectDialog.js'
import FilterDialog from './FilterDialog.js'
import FilterSummary from './FilterSummary.js'

import '../css/list.css'
import '../css/icons.css'

class BatchesLists extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.page = this.props.page ?? 1
    state.itemsPerPage = 30
    this.state = state
    this.container = new ListContainer(this, 'com.optimsys.costra.kordex.exports.ExportBatchesList')
    this.openFilter = this.openFilter.bind(this)
    this.runPackager = this.runPackager.bind(this)
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  runPackager(event) {
    Dialogs.open(<PackagerSelectDialog/>)
  }

  openFilter(event) {
    Dialogs.open(
      <FilterDialog name="exportBatchesFilter" filter={this.state.filter}/>
    ).then((filter) => {
      if (filter) {
        Nav.addUrlParam(event, 'page', 1)
        this.container.reload()
      }
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <FilterSummary filter={this.state.filter}
                     name="exportBatchesFilter"
                     update={() => this.container.reload()}/>

      <div className="listHeader spacedTop">

        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => Nav.addUrlParam(event, 'page', page)}/>

        <div>
          <div className="button right"
               title={Translate.get('exports.button.filter')}
               onClick={this.openFilter}>
            <div className="icon left filter"/>
            <span className="left">{Translate.get('exports.button.filter')}</span>
          </div>
          <div className="button right"
               title={Translate.get('exports.button.createBatch')}
               onClick={this.runPackager}>
            <div className="icon left add"/>
            <span className="left">{Translate.get('exports.button.createBatch')}</span>
          </div>
        </div>

      </div>
      <List list={this.state.items} itemFn={item => <Batch.Row item={item}/>}/>
    </>
  }
}

export default BatchesLists