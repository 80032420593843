import ObjectHelper from './ObjectHelper.js'

class Formatter {

  static _doubleDigits(value) {
    if (value < 10) return '0' + value
    return value
  }

  static _tripleDigits(value) {
    if (value < 10) return '00' + value
    if (value < 100) return '0' + value
    return value
  }

  static formatMinutes(value) {
    var expanded = ObjectHelper.expand(value)
    var abs = Math.abs(Math.floor(expanded))
    var ret = Formatter._doubleDigits(Math.floor(abs / 60)) + ':' + Formatter._doubleDigits(Math.floor(Math.round(abs) % 60))
    if (expanded < 0) {
      return '-' + ret
    }
    return ret
  }

  static amount(value) {
    var expanded = ObjectHelper.expand(value)
    return (+expanded).toFixed(2)
  }

  static formatAmount(value) {
    return value.toLocaleString(
      undefined, // leave undefined to use the browser's locale,
                 // or use a string like 'en-US' to override it.
      { minimumFractionDigits: 2 })
  }

  static formatSeconds(value) {
    var expanded = ObjectHelper.expand(value)
    var abs = Math.abs(Math.floor(expanded))
    var seconds = Math.floor(abs) % 60
    var minutes = Math.floor(abs / 60) % 60
    var hours = Math.floor(abs / 60 / 60) % 24
    var days = Math.floor(abs / 60 / 60 / 24)

    var ret = ''
    if (days > 0) {
      ret = days.toString() + ':'
    }
    ret += Formatter._doubleDigits(hours) + ':' +
      Formatter._doubleDigits(minutes) + ':' +
      Formatter._doubleDigits(seconds)
    if (expanded < 0) {
      return '-' + ret
    }
    return ret
  }

  static formatMillis(value) {
    var expanded = ObjectHelper.expand(value)
    var abs = Math.abs(Math.floor(expanded))
    var seconds = Math.floor(abs / 1000)
    var minutes = Math.floor(seconds / 60)
    var ret = Formatter._doubleDigits(Math.floor(minutes / 60)) + ':' +
      Formatter._doubleDigits(Math.floor(minutes % 60)) + ':' +
      Formatter._doubleDigits(Math.floor(Math.round(seconds) % 60)) + '.' +
      Formatter._tripleDigits(Math.floor(Math.round(abs) % 1000))
    if (expanded < 0) {
      return '-' + ret
    }
    return ret
  }

  static formatDateTime(format, value) {
    value = ObjectHelper.expand(value)
    if (!value) {
      return ''
    }
    var dateObj = new Date(value)
    return format.replace("%dd", Formatter._doubleDigits(dateObj.getDate()).toString()
      ).replace("%d", dateObj.getDate().toString()
      ).replace("%mm", Formatter._doubleDigits(dateObj.getMonth() + 1).toString()
      ).replace("%m", (dateObj.getMonth() + 1).toString()
      ).replace("%yyyy", dateObj.getFullYear().toString()
      ).replace("%HH", Formatter._doubleDigits(dateObj.getHours()).toString()
      ).replace("%MM", Formatter._doubleDigits(dateObj.getMinutes()).toString()
      ).replace("%SS", Formatter._doubleDigits(dateObj.getSeconds()).toString()
      ).replace("%III", Formatter._tripleDigits(dateObj.getMilliseconds()).toString())
  }

  // this method is not needed and should be phased out where it is used
  static formatDoubleDigitsDateTime(format, value) {
    return Formatter.formatDateTime(format, value)
  }

  static formatSize(value) {
    value = ObjectHelper.expand(value)
    if (value < 1024) {
      return value + 'B'
    }
    value /= 1024
    if (value < 1024) {
      return (Math.round(value * 100) / 100) + 'KB'
    }
    value /= 1024
    if (value < 1024) {
      return (Math.round(value * 100) / 100) + 'MB'
    }
    value /= 1024
    if (value < 1024) {
      return (Math.round(value * 100) / 100) + 'GB'
    }
    value /= 1024
    (Math.round(value * 100) / 100) + 'TB'
  }

  static address(value, index) {
    let address = value
    let prefix = index > 0 ? "; " : ""
    if(address.street && address.street !== "") {
      
      let addressString = ""+ address.street + " " + address.streetCode
      if(address.city) {addressString +=  ", " + address.city + " " + address.postCode}
      if(address.country !== '') {addressString += ", " + address.country}
      return prefix + addressString
    } else {
      let addressString = ""+ address.city + " " + address.streetCode
      if(address.postCode) {addressString +=  ", " + address.postCode}
      if(address.country) {addressString += ", " + address.country}
      return prefix + addressString
    }
  }

  static phoneNumber(number, prefix) {
    const regex = /^\+?[0-9]{9,15}$/g;
    // no phone number, don't format
    if (!number.match(regex)) {
      return number
    }

    // remove national prefix
    if (prefix && number.indexOf(prefix) === 0) {
      number = number.substr(prefix.length)
    }

    var out = ''
    // split national prefix
    if (number[0] === '+' && number.length > 6) {
      out = number.substr(0, 4) + ' '
      number = number.substr(4)
    }
    // format by threes
    while(number.length > 5) {
      out += number.substr(0, 3) + ' '
      number = number.substr(3)
    }
    return out + number
  }
}

export default Formatter