import React, { Component } from 'react'
import ObjectHelper from '../costra/ObjectHelper.js'
import Translate from '../costra/Translate.js'

import {PageDataContext} from '../PageDataContext.js'
import Campaign from './Campaign.js'

import '../css/dialogs.css'

class CampaignsArray extends Component {

  renderView(campaigns) {
    var list = this.props.data.get(this.props.path)
    if (!list) {
      return null
    }
    return <>
      {Object.keys(list).map((index) =>
        <div key={index} className="formRow">
          <div className="left button"
               onClick={(event) => Campaign.go(event, ObjectHelper.expand(list[index]))}>
            {campaigns[ObjectHelper.expand(list[index])]}
          </div>
        </div>
      )}
    </>
  }

  renderEdit(campaigns) {
    return Object.keys(campaigns).map((campaignId) => {
      var id = {'$oid': campaignId}
      return <div key={campaignId} className="formRow">
        <label>{campaigns[campaignId]}</label>
        <input type="checkbox"
               checked={this.props.data.isIdInArray(this.props.path, id)}
               onChange={(event) => this.props.data.toggleIdInArray(this.props.path, id, event.target.checked)}/>
      </div>
    })
  }

  render() {
    return <div className="subsection">
          <div className="header-row">
            <div className={'icon iconSection ' + this.props.icon}/>
            <h3>{Translate.get(this.props.title)}</h3>
          </div>
          <div className="content">
            {(this.props.disabled) ?
              this.renderView(this.context.campaigns)
              :
              this.renderEdit(this.context.campaigns)
            }
          </div>
        </div>
  }
}

CampaignsArray.contextType = PageDataContext

export default CampaignsArray