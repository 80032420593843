import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'

import File from './File.js'
import UploadFileDialog from './UploadFileDialog.js'

import '../css/list.css'
import '../css/icons.css'

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class CaseFilesList extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.file.CaseFilesList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  uploadFile(event) {
    event.stopPropagation()
    return Dialogs.open(<UploadFileDialog owner={this.props.id}/>)
  }

  render() {
    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
        {this.props.allowEdit &&
          <div className="button right"
               title={Translate.get('files.button.uploadFile')}
               onClick={(event) => this.uploadFile(event)}>
            <div className="icon left import"/>
            <span className="left">{Translate.get('files.button.uploadFile')}</span>
          </div>
        }
      </div>
      <div className="files-list">
        {this.state.items && this.state.items.map((item, index) => {
          return <File.Row item={item} key={(item._id ? item._id.$oid : index)}/>
        })}
      </div>
    </>
  }
}

export default CaseFilesList