import React from 'react';
import Translate from '../costra/Translate.js'

function editOpen(component) {
  component.load().then(() => component.setState({editOpen: true}))
}

function EditButtons(props, context) {
  if (!props.component.state.allowEdit) {
    return null
  }
  if (!props.component.state.editOpen) {
    return <div className="button right"
                onClick={(event) => editOpen(props.component)}>
      <div className="icon left edit"/>
      {Translate.get('edit.button.edit')}
    </div>
  }
  var buttons = []
  if (props.component.props.id !== 'new') {
    buttons.push(
      <div className="button right"
           key="cancel"
           onClick={(event) => props.component.load()}>
        <div className="icon left cancel"/>
        {Translate.get('edit.button.cancel')}
      </div>
    )
  }
  buttons.push(
    <div className="button right"
         key="save"
         onClick={(event) => props.component.save()}>
      <div className="icon left save"/>
      {Translate.get('edit.button.save')}
     
    </div>
  )
  return buttons
}

export default EditButtons