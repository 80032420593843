import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'
import Column from '../costra/Column.js'

import Nav from '../utils/Nav.js'
import ConfirmDialog from '../utils/ConfirmDialog.js'
import { message } from 'antd'

class User {

  static go(event, id, newTab) {
    Nav.go(event, '/user/' + id, newTab)
  }

  static create(event) {
    Nav.go(event, '/user/new')
  }

  static rights = [
    {_class : "com.optimsys.costra.kordex.identity.rights.AddressBookAccess"},
    {_class : "com.optimsys.costra.kordex.identity.rights.AddressBookManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CampaignAccessAll"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CampaignManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CaseManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CaseView"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CaseUpdate"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CaseAccessAll"},
    {_class : "com.optimsys.costra.kordex.identity.rights.DropBoxView"},
    {_class : "com.optimsys.costra.kordex.identity.rights.DropBoxManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.DropFileUpload"},
    {_class : "com.optimsys.costra.kordex.identity.rights.ImportManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.EventManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.ExportManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.UserManagement"},
    {_class : "com.optimsys.costra.kordex.identity.rights.MobileAppAccess"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CanSeeHistory"},
    {_class : "com.optimsys.costra.kordex.identity.rights.ContractorRight"},
    {_class : "com.optimsys.costra.kordex.identity.rights.ManagesTasksForOthers"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CommLogAccess"},
    {_class : "com.optimsys.costra.kordex.identity.rights.CallDialing"},
    {_class : "com.optimsys.costra.kordex.identity.rights.SmsSending"},
    //{_class : "com.optimsys.costra.kordex.identity.rights.MailSending"},
    {_class : "com.optimsys.costra.kordex.identity.rights.StatisticsAccess"},
    {_class : "com.optimsys.costra.kordex.identity.rights.StatisticsManagement"},
  ]

  static columns = Column.createMap([
    Column.element('icon', "user", <div className="icon user"/>),
    new Column('extensions.kordex.contact.displayName', Translate.get('users.header.contactName'),
     (item) => item.extensions && item.extensions.kordex && item.extensions.kordex.contact && item.extensions.kordex.contact.displayName, true),
    new Column('login', Translate.get('users.properties.login'),
     (item) => item.login, true),
    new Column('displayName', Translate.get('users.properties.displayName'),
     (item) => item.displayName, true),
    new Column('state', Translate.get('users.header.state'),
     (item) => {
      return <>
        {item.extensions && item.extensions.kordex && item.extensions.kordex.processing.missingSignature &&
          <div className="icon missingSignature" title={Translate.get('users.flag.missingSignature')}/>
        }
        {item.extensions && item.extensions.kordex && item.extensions.kordex.processing.weakPassword &&
          <div className="icon weakPassword" title={Translate.get('users.flag.weakPassword')}/>
        }
        {item.extensions && item.extensions.kordex && item.extensions.kordex.processing.isContractor &&
          <div className="icon user" title={Translate.get('users.flag.isContractor')}/>
        }
      </>
    }),
    new Column('localLine', Translate.get('users.properties.localLine'),
     (item) => item.extensions && item.extensions.kordex ? item.extensions.kordex.localLine : ''),
  ])

  static defaultLayout = [
    {name: 'icon'},
    {name: 'extensions.kordex.contact.displayName', extraClass: 'size4c'},
    {name: 'login', extraClass: 'size3c'},
    {name: 'displayName', extraClass: 'size4c'},
    {name: 'state', extraClass: 'size3c'},
    {name: 'localLine', extraClass: 'size3c'},
  ]

  static removeUser(event, user) {
    event.stopPropagation()
    Dialogs.open(
      <ConfirmDialog text={Translate.get('users.remove.prompt', null, {name: user.login})}/>
    ).then((confirmed) => {
      if (confirmed) {
        return WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.users.RemoveUser',
          id: user._id,
        })
      }
    })
  }

  static toggleActivation(event, user) {
    event.stopPropagation()
    let updatedUser = null
    if(user.login.indexOf('inactive') === -1) {
      updatedUser = {...user, login: user.login + '-inactive' + Math.floor(Math.random() * 8999 + 1000), password: ''}
    }
    else {
      updatedUser = {...user, login: user.login.slice(0, user.login.length - 13), password: ''} 
    }
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.users.UpdateUser',
      item: updatedUser,
    }).then(() => {
      if(user.login.indexOf('inactive') === -1) {
        message.info('users.user.message.inactive')
      }
      else {
        message.info('users.user.message.active')
      }
    }, () => {
      message.error('genericStoreError')
    })
  }

  static Row(props) {
    return <tr className={"row" +
                          (props.item.allowView ? ' withHover' : '') +
                          (props.item.item.login.indexOf("inactive") !== -1 ? " inactive" : "")}
               onClick={props.item.allowView ? (event) => User.go(event, props.item.item._id.$oid) : undefined}>
      <td/>
      {Column.render(User.columns, props.layout, props.item.item)}
      <td>
        <div className="buttons">
          {props.allowRemove &&
            <div className="right button"
                 title={Translate.get('users.button.remove')}
                 onClick={(event) => User.removeUser(event, props.item.item)}>
              <div className="icon remove"/>
            </div>
          }
          <div className="button"
              key="inactivate"
              title={Translate.get('users.button.deactivate')}
              onClick={(event) => User.toggleActivation(event, props.item.item)}>
            <div className="icon lock"/>
          </div>
        </div>
      </td>
    </tr>
  }
}

export default User