import React, { Component } from 'react'

import LustrationInfo from './LustrationInfo'

import './navPanel.css'

class LustrationsList extends Component {

  render() {
    return <div className='navPanel__object adressee'>
      {this.props.lustrations && this.props.lustrations.map( (lustration, index) => 
        <LustrationInfo lustration={lustration} itemId={this.props.id}  key={index}/>
      )}
    </div>
  }
}

export default LustrationsList