import React, { Component } from 'react'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Dialogs from '../costra/Dialogs.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import CurrencyEditDialog from './CurrencyEditDialog.js'
import '../css/list.css'
import '../css/icons.css'

class StatisticsSettings extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tenant: null,
      latestCurrencies: null,
      currencyHistory: null,
      editOpen: false,
      allowEdit: true,
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.statistics.GetSettings',
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.statistics.UpdateSettings',
      item: this.state.item,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
    })
  }

  currencyEditDialog(id) {
    Dialogs.open(
      <CurrencyEditDialog id={id}/>
    ).then(result => {
      if (result) {
        this.load()
      }
    })
  }

  componentDidMount() {
    this.load()
  }

  renderCurrenciesList(list, allowEdit) {
    return <table className="list">
      <thead>
        <tr>
          <td><div className="icon coins"/></td>
          <td>{Translate.get('statistics.settings.currency')}</td>
          <td>{Translate.get('statistics.settings.validFrom')}</td>
          <td>{Translate.get('statistics.settings.coefficient')}</td>
        </tr>
      </thead>
      <tbody>
        {list.map(item =>
          <tr className={'row' + (allowEdit ? ' withHover' : '')}
              onClick={event => allowEdit && this.currencyEditDialog(item._id)}
              key={item._id.$oid}>
            <td><div className="icon coins"/></td>
            <td className="size4c">{item.currency}</td>
            <td className="size4c">{Formatter.formatDateTime('%d.%m.%yyyy', item.validFrom)}</td>
            <td className="size2c">{item.coefficient}</td>
          </tr>
        )}
      </tbody>
    </table>
  }

  render() {
    if (!this.state.item) {
      return <LoadingPlaceholder />
    }
    return <>
      <div className="section spacedTop">
        <div className="buttons"><EditButtons component={this}/></div>
        <div className="subsection">
          <div className="listHeader">
            <div>
              <div className="icon iconBig statistics"/>
              <h2>{Translate.get('statistics.settings.title')}</h2>
            </div>
          </div>
          <div className="content">
            <Form.Text data={this.data}
                       disabled={!this.state.editOpen}
                       path="defaultCurrency"
                       title='statistics.settings.defaultCurrency'/>
            <Form.Text data={this.data}
                       disabled={!this.state.editOpen}
                       path="sharedStatisticsThreshold"
                       title='statistics.settings.sharedStatisticsThreshold'/>
          </div>
        </div>
      </div>

      <div className="section spacedTop">
        <div className="buttons">
          <div className="button right"
               onClick={event => this.currencyEditDialog(null)}>
            <div className="icon left coins"/>
            {Translate.get('statistics.settings.currency.addButton')}
          </div>
        </div>
        <div className="listHeader">
          <div>
            <div className="icon iconBig coins"/>
            <h2>{Translate.get('statistics.settings.currency.latest')}</h2>
          </div>
        </div>
        {this.state.latestCurrencies && this.renderCurrenciesList(this.state.latestCurrencies, true)}
      </div>

      {this.state.currencyHistory &&
        <div className="section spacedTop">
          <div className="listHeader">
            <div>
              <div className="icon iconBig coins"/>
              <h2>{Translate.get('statistics.settings.currency.history')}</h2>
            </div>
          </div>
          {this.renderCurrenciesList(this.state.currencyHistory, false)}
        </div>
      }
    </>
  }
}

export default StatisticsSettings
