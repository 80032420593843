import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import WsContainers from '../costra/WsContainers.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CommLogBase from '../comms/CommLog.js'
import '../css/list.css'
import '../css/icons.css'

class Container extends SimpleListContainer {

  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class CommLog extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    state.errors = null
    this.state = state
    this.container = new Container(
      this, 'com.optimsys.costra.kordex.comms.ItemCommLogList',
      error => this.setState({error: error})
    )
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  markCallAsHandled(item, index) {
    var updatedItems = this.state.items
    delete updatedItems[index].tags.missedCall
    this.setState({items: updatedItems})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.comms.MarkCallAsHandled',
      id: item._id,
    })
  }

  render() {
    if (this.props.id === 'new') {
      return null
    }
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <div>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
      </div>
      <List list={this.state.items} itemFn={(item, index) => 
        <CommLogBase.Row item={item} markCallAsHandled={item => this.markCallAsHandled(item, index)}/>
      }/>
    </div>
  }
}

export default CommLog