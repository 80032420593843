import DateTimeInput from '../costra/DateTimeInput.js'
import Translate from '../costra/Translate.js'

import Input from '../utils/Input.js'

class Filter {

  static FromMap(props) {
    const sorted = props.array && Object.entries(props.array).map(([key, value]) =>
      [key, Translate.get(props.attribute ? value[props.attribute] : value)]
    ).sort(([,a], [,b]) => a.localeCompare(b)).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
    return <>
      <div className="selection-row">
        <div className="select">
          {props.label && <div className="label">{Translate.get(props.label)}</div>}
          <select value={''}
                  onChange={(e) => {
                    props.data.toggleInArray(props.pathName, e.target.value, !props.data.isInArray(props.pathName, e.target.value))
                  }}>
            <option value='' disabled>{Translate.get(props.placeholder)}</option>
            {sorted && Object.keys(sorted).map(id =>
              <option key={id} value={id}>{sorted[id]}</option>
            )}
          </select>
        </div>
        <div className="selected-items">
          {props.label && <div className="label" />}
          <div className="options">
            {sorted && Object.keys(sorted).map(id => props.data.isInArray(props.pathName, id) ?
              <div className="option selected"
                   key={id}
                   onClick={() => props.data.toggleInArray(props.pathName, id, false)}>
                <div>{sorted[id]}</div>
                <div className="icon iconSmall cancel" />
              </div>
              :
              <div className="option hidden" key={id}>
                <div>{sorted[id]}</div>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  }

  static FromArrayOfObjects(props) {
    const sorted = props.array && props.array.map(obj =>
      Object.assign({}, obj, {title: Translate.get(obj.title)})
    ).sort((a, b) => a.title.localeCompare(b.title))
    return <>
      <div className="selection-row">
        <div className="select">
          {props.label && <div className="label">{Translate.get(props.label)}</div>}
          <select value={''}
                  onChange={(e) => {
                    props.data.toggleInArray(props.pathName, e.target.value, !props.data.isInArray(props.pathName, e.target.value))
                  }}>
            <option value='' disabled>{Translate.get(props.placeholder)}</option>
            {sorted.map((obj) =>
              <option key={obj.value} value={obj.value}>{obj.title}</option>
            )}
          </select>
        </div>
        <div className="selected-items">
          {props.label && <div className="label" />}
          <div className="options">
            {sorted.map((obj) => props.data.isInArray(props.pathName, obj.value) ?
              <div className="option selected"
                   key={obj.value}
                   onClick={() => props.data.toggleInArray(props.pathName,  obj.value, false)}>
                <div>{obj.title}</div>
                <div className="icon iconSmall cancel" />
              </div>
              :
              <div className="option hidden" key={obj.value}>
                <div>{obj.title}</div>
              </div>
            )}
          </div>
        </div>
      </div>

    </>
  }

  static DateRangeRow(props) {
    var mode = props.data.get(props.pathName + '.mode')
    return <>
    <div className="mixed-row">
      <div className="label">{Translate.get(props.title)}</div>
      <div className="select">
        <select className="shortInput"
                value={mode}
                onChange={(event) => props.data.set(props.pathName + '.mode', event.target.value)}>
          <option value="Is">{Translate.get('filter.is')}</option>
          <option value="Range">{Translate.get('filter.range')}</option>
          <option value="Unset">{Translate.get('filter.unset')}</option>
        </select>
      </div>
      {(!mode || mode === 'Is') &&
        <DateTimeInput value={props.data.get(props.pathName + '.from')}
                       onChange={(value) => props.data.set(props.pathName + '.from', value)}
                       className="shortInput"
                       placeholder={Translate.get('filter.date.placeholder')}
                       format="%d.%m.%yyyy"/>
      }

      {mode === 'Range' && <div className="date-range">
        <DateTimeInput value={props.data.get(props.pathName + '.from')}
                       onChange={(value) => props.data.set(props.pathName + '.from', value)}
                       className="shortInput"
                       placeholder={Translate.get('filter.date.placeholder')}
                       format="%d.%m.%yyyy"/>
        <DateTimeInput value={props.data.get(props.pathName + '.to')}
                       onChange={(value) => props.data.set(props.pathName + '.to', value)}
                       className="shortInput"
                       placeholder={Translate.get('filter.date.placeholder')}
                       format="%d.%m.%yyyy"/>
        </div>
      }
      {mode === 'Unset' && <div className="placeholder"/>}
    </div>
    </>
  }

  static AmountRangeRow(props) {
    return <>
      <div className="mixed-row">
        <div className="label">{Translate.get(props.title)}</div>
        <div className="date-range">
          <Input.Amount data={props.data}
                        path={props.path + '.min'}
                        className="shortInput"
                        title={Translate.get('filter.amount.minPlaceholder')}/>
          <Input.Amount data={props.data}
                        path={props.path + '.max'}
                        className="shortInput"
                        title={Translate.get('filter.amount.maxPlaceholder')}/>
        </div>
      </div>
    </>
  }

  static CheckboxRow(props) {
    return <>
      <div className="selection-row">
        <div className="select">
          {props.label && <div className="label">{Translate.get(props.label)}</div>}
          <select value={''}
            onChange={(e) => {
              props.data.set(props.pathName, e.target.value,)
            }}>
            <option value='' disabled>{Translate.get(props.placeholder)}</option>
            <option key={true} value={true}>{Translate.get('true')}</option>
            <option key={false} value={false}>{Translate.get('false')}</option>
          </select>
        </div>
        <div className="selected-items">
          {props.label && <div className="label" />}
          <div className="options">           
              {props.data.isInArray(props.pathName, 'true') &&
                <div className="option selected"
                  key='true'
                  onClick={() => props.data.set(props.pathName,  null)}>
                  <div>{Translate.get('true')}</div>
                  <div className="icon iconSmall cancel" />
                </div>                              
              }
              {props.data.isInArray(props.pathName, 'false') &&
                <div className="option selected"
                  key='false'
                  onClick={() => props.data.set(props.pathName,  null)}>
                  <div>{Translate.get('false')}</div>
                  <div className="icon iconSmall cancel" />
                </div>                             
              }
            
          </div>
        </div>
      </div>
    </>
  }

  static InputRow(props) {
    return <>
      <div className="selection-row">  
        <div className="mixed-row input">      
          {props.label && <div className="label">{Translate.get(props.label)}</div>}
              <input type="text"
                  className="shortInput"
                  placeholder={Translate.get(props.placeholder)}
                  value={props.data.get(props.pathName)}
                  onChange={(e) => props.data.set(props.pathName, e.target.value)}/>                  
        </div>
      </div>
    </>
  }

  static TwoInputsRow(props) {
    return <>
      <div className="selection-row">  
        <div className="mixed-row input">      
          {props.label && <div className="label">{Translate.get(props.label)}</div>}
              <input type="number"
                  className="shortInput"
                  placeholder={Translate.get('from')}
                  value={props.data.get(props.pathName1)}
                  onChange={(e) => props.data.set(props.pathName1, e.target.value)}/> 
              <input type="number"
                  className="shortInput"
                  placeholder={Translate.get('to')}
                  value={props.data.get(props.pathName2)}
                  onChange={(e) => props.data.set(props.pathName2, e.target.value)}/>                  
        </div>
      </div>
    </>
  }
}

export default Filter