import React from 'react';
import Translate from '../costra/Translate'

function Divider(value) {
  return <div className="divider">
    <div className="a" />
    <div className="text">{Translate.get(value.text)}</div>
    <div className="b" />
  </div>
}

export default Divider