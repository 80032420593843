import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Dialogs from '../costra/Dialogs.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import { PageDataContext } from '../PageDataContext.js'

import Filter from '../utils/Filter.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

class FilterDialog extends Component {

  static exportTypes = {
    'ClosingExport': 'Závěrečný export pro zadavatele',
    'BillingExport': 'Export pro fakturaci',
    'PostExport': 'Export pro tisk dopisů',
    'OverviewExport': 'Přehledový export',
  }

  constructor(props) {
    super(props)
    var state={
      filter: {},
    }
    if (this.props.filter) {
      state.filter=ObjectHelper.copy(this.props.filter)
    }
    this.state=state
    this.data=new EditData(this, 'filter.')
  }

  applyFilter(event) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: 'com.optimsys.costra.kordex.exports.ExportBatchesList$Filter',
      name: this.props.name,
      data: [this.state.filter],
    }).then((result) => {
      Dialogs.close(this.state.filter)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  render() {
    if (this.state.error) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <ErrorPlaceholder error={this.state.error}/>
          <div className="buttons">
            <div className="right-position">
              <div className="button" onClick={(event) => Dialogs.close(null)}>
                <div className="icon iconBig cancel"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">

        <div className="title">
          <div className="icon iconBig filter"/>
          <span>{Translate.get('exports.filter.title')}</span>
        </div>

        <Filter.FromMap data={this.data}
                        array={this.context.accessibleCampaigns}
                        pathName='campaigns'
                        placeholder='exports.filter.campaign'
                        label='exports.filter.campaign'/>
        <Filter.DateRangeRow data={this.data}
                             pathName='time'
                             title='exports.filter.time'/>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.exportTypes}
                        pathName='exportType'
                        placeholder='exports.filter.exportType'
                        label='exports.filter.exportType'/>

        <div className="buttons">
          <div className="right-position">
            <div className="button" onClick={(event) => this.applyFilter(event)}>
              <div className="icon iconBig ok"/>
            </div>
            <div className="button" onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

}
FilterDialog.contextType=PageDataContext

export default FilterDialog