import React, { Component } from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import CaseObject from '../../CaseObject.js'
import Accessories from './Accessories.js'

import '../../../css/form.css'

function checkColor(score){
  switch(score){
    case 1 : return "orangeInput" 
    case 2 : return "redInput"
    default : return ""
  }   
}

function getColor(array, path, data) {
  let wanted = array.find(object => object.value === data.get(path))
  return checkColor(wanted.score)
}

class PredProperties extends Component {

  static odometerUnit = [
    {value: 'Km', title: 'Km'},
    {value: 'Hr', title: 'Mth'},
    {value: 'Mil', title: 'Mil'},
  ]

  static vinType = [
    {value: 'Vin', title: 'caseobject.erl.pred.vinType.vin' },
    {value: 'SN', title: 'caseobject.erl.pred.vinType.sn'},
  ]

  static vinCheck = [
    {value: 'Unknown', title: 'caseobject.erl.pred.vinCheck.Unknown', score: 1},
    {value: 'AsAssigned', title: 'caseobject.erl.pred.vinCheck.AsAssigned', score: 0},
    {value: 'WithoutVin', title: 'caseobject.erl.pred.vinCheck.WithoutVin', score: 0},
    {value: 'Unreadable', title: 'caseobject.erl.pred.vinCheck.Unreadable', score: 2},
    {value: 'DiffersFromAssigned', title: 'caseobject.erl.pred.vinCheck.DiffersFromAssigned', score: 2},
    {value: 'Acquired', title: 'caseobject.erl.pred.vinCheck.Acquired', score: 1},
    {value: 'Uncheckable', title: 'caseobject.erl.pred.vinCheck.Uncheckable', score: 2},
  ]

  static carUsage = [
    {value: 'New', title: 'caseobject.erl.pred.carUsage.New' },
    {value: 'FloorModel', title: 'caseobject.erl.pred.carUsage.FloorModel'},
    {value: 'Used', title: 'caseobject.erl.pred.carUsage.Used'},
  ]

  static workingCondition = [
    {value: 'Unknown', title: 'caseobject.erl.pred.workingCondition.Unknown', score: 1},
    {value: 'Working', title:'caseobject.erl.pred.workingCondition.Working', score: 0},
    {value: 'NotWorking', title:'caseobject.erl.pred.workingCondition.NotWorking', score: 2},
  ]

  static odometerCheck = [
    {value: 'NotChecked', title: 'caseobject.erl.pred.odometerCheck.NotChecked', score: 2},
    {value: 'Checked', title: 'caseobject.erl.pred.odometerCheck.Checked', score: 0},
    {value: 'NoBattery', title: 'caseobject.erl.pred.odometerCheck.NoBattery', score: 1},
    {value: 'NoKeys', title: 'caseobject.erl.pred.odometerCheck.NoKeys', score: 1},
    {value: 'NoOdometer', title: 'caseobject.erl.pred.odometerCheck.NoOdometer' , score: 0},
  ]

  render(){
    return <>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection car"/>
          <h3>{Translate.get('caseobject.properties.title')}</h3>
        </div>

        <div className="content">
          <div className="column-block">
            <Form.Text data={this.props.component.data}
                      disabled={!this.props.editOpen}
                      path="name"
                      title='caseobject.properties.objectName'
                      validate={Validator.notEmptyId}
                      styling = {!Validator.notEmptyId && "redInput"}/>
            <Form.Address data={this.props.component.data}
                          disabled={!this.props.editOpen}
                          path="address"
                          title='caseobject.properties.address'
                          caseIds={[this.props.component.data.get("caseId")]}/>
            <Form.Text data={this.props.component.data}
                      disabled={!this.props.editOpen}
                      path="licenseNumber"
                      title='caseobject.properties.licenseNo'/>
            <Form.Text data={this.props.component.data}
                      disabled={!this.props.editOpen}
                      path="productionYear"
                      title='caseobject.properties.productionYear'/>
            <Form.Select data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="technicalCondition"
                        title='caseobject.properties.technicalCondition'
                        values={CaseObject.technicalCondition}
                        styling={getColor(CaseObject.technicalCondition, "technicalCondition", this.props.component.data)}/>
            <Form.TextWithUnit data={this.props.component.data}
                              disabled={!this.props.editOpen}
                              path="vin"
                              title='caseobject.properties.vin'
                              unitPath="vinType"
                              unitValues={PredProperties.vinType}
                              styling={Metrics.CheckVin(this.props.component.data.get("vin"),this.props.component.data.get("vinType"))}/>
            <Form.Select data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="vinCheck"
                        title='caseobject.erl.pred.vinCheck'
                        values={PredProperties.vinCheck}
                        styling={getColor(PredProperties.vinCheck, "vinCheck", this.props.component.data)}/>
            <Form.Select data={this.props.component.data}
                        disabled={!this.props.editOpen}
                        path="usage"
                        title='caseobject.erl.pred.usage'
                        values={PredProperties.carUsage}/>
            <Form.Select data={this.props.component.data}
                        disabled={!this.props.component.state.editOpen}
                        path="workingCondition"
                        title='caseobject.erl.pred.workingCondition'
                        values={PredProperties.workingCondition}
                        styling={getColor(PredProperties.workingCondition, "workingCondition", this.props.component.data)}/>
            <Form.Select data={this.props.component.data}
                        disabled={!this.props.component.state.editOpen}
                        path="odometerCheck"
                        title='caseobject.erl.pred.odometerCheck'
                        values={PredProperties.odometerCheck}
                        styling={getColor(PredProperties.odometerCheck, "odometerCheck",this.props.component.data)}/>
            {this.props.item.odometerCheck === "Checked" &&
              <Form.TextWithUnit data={this.props.component.data}
                                 disabled={!this.props.component.state.editOpen}
                                 path="odometerState"
                                 title='caseobject.erl.pred.odometerState'
                                 showEmpty={true}
                                 unitPath="odometerUnit"
                                 unitValues={PredProperties.odometerUnit}
                                 className={this.props.component.data.get('odometerState') === "" && "redInput"}/>
            }
          </div>
        </div>

        <Form.TextArea data={this.props.component.data}
                      disabled={!this.props.editOpen}
                      path="exportedNote"
                      styling="textArea big"
                      title='caseobject.properties.exportedNote'/>
      </div>

      <Accessories data={this.props.component.data}
                   disabled={!this.props.editOpen}
                   path="accessoriesList"/>

      {this.props.component.data.get('contactData') && this.props.component.data.get('contactData').map((contactData, index) =>
        <ContactData key={index}
                     data={this.props.component.data}
                     path={'contactData.' + index}
                     disabled={!this.props.editOpen}/>
      )}
    </>
  }
}

export default PredProperties
