import React from 'react'
import Translate from '../../../costra/Translate.js'

import Input from '../../../utils/Input.js'

import '../../../css/form.css'

function Securities(props) {
  const securities = props.data.get('securities') || []
  if (securities.length <= 0 && props.disabled) {
    return null
  }
  return <div className="subsection">
    <div className="header-row">
      <div className="icon iconSection law" />
      <h3>{Translate.get('sal.collection.securities')}</h3>
      {!props.disabled &&
        <div className="button right" onClick={event => props.data.addToArray('securities', {})}>
          <div className="icon add"/>
          {Translate.get('sal.collection.security.add')}
        </div>
      }
    </div>

    <ul className="list">
      {securities.map((security, index) =>
        <li key={index}>
          <div className="row">
            <div key="icon"><div className="icon law"/></div>
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'securities.' + index + '.code'}
                        title='sal.collection.security.code'
                        className="flex1c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'securities.' + index + '.description'}
                        title='sal.collection.security.description'
                        className="flex8c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'securities.' + index + '.relatedName'}
                        title='sal.collection.security.relatedName'
                        className="flex8c" />
            {!props.disabled &&
              <div className="button right"
                   title={Translate.get('sal.collection.security.remove')}
                   onClick={event => props.data.removeFromArray('securities', index)}>
                <div className="icon cancel"/>
              </div>
            }
          </div>
        </li>
      )}
    </ul>
  </div>
}

export default Securities
