import React from 'react'
import ObjectHelper from '../costra/ObjectHelper.js'
import DurationInput from '../costra/DurationInput.js'
import Formatter from '../costra/Formatter.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'
import Dialogs from '../costra/Dialogs.js'

import moment from 'moment'
import 'moment/locale/cs';
import SelectAddressDialog from '../addressbook/SelectAddressDialog.js'

import {DatePicker} from 'antd';

class Input {
  
  static getExtraClass(props, value) {
    if (props.validate) {
      if (props.validate(value)) {
        return ''
      }
      return ' invalid'
    }
    else {
      var invalidFields = props.data.get('_invalid')
      if(invalidFields && invalidFields.indexOf(props.path) >= 0) {
        return ' invalid'
      }
      return ''
    }
  }

  static Text(props) {
    var value = props.data.get(props.path)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{value}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <input type="text"
                    className={props.className + extraClass}
                    value={value}
                    placeholder={Translate.get(props.title) + '...'}
                    title={Translate.get(props.title)}
                    onChange={(event) => props.data.set(props.path, event.target.value)}/>
    }
  }

  static Amount(props) {
    var value = props.data.get(props.path) ? props.data.get(props.path) : "0"
    let currency = " " + props.currency ? props.currency : "CZK"

    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{Formatter.formatAmount(value) + " " + currency}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <input type="text"
                    className={props.className + extraClass}
                    value={props.data.get(props.path)} 
                    placeholder={Translate.get(props.title) + '...'}
                    title={Translate.get(props.title)}
                    onChange={(event) => props.data.set(props.path, event.target.value)}/>
    }
  }

  static Phone(props) {
    var value = props.data.get(props.path)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{Formatter.phoneNumber(value, '+420')}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <input type="text"
                    className={props.className + extraClass}
                    value={value}
                    placeholder={Translate.get(props.title) + '...'}
                    title={Translate.get(props.title)}
                    onChange={(event) => props.data.set(props.path, event.target.value)}/>
    }
  }

  static TextArea(props) {
    var value = props.data.get(props.path)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement multiline ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{value}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <textarea className={props.className + extraClass}
                       value={value}
                       placeholder={Translate.get(props.title) + '...'}
                       title={Translate.get(props.title)}
                       onChange={(event) => props.data.set(props.path, event.target.value)}/>
    }
  }

  static Select(props) {
    var value = ObjectHelper.expand(props.data.get(props.path))
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      var valueObj = (props.values && props.values.find((vo) => ObjectHelper.expand(vo.value) === value))
      if (valueObj || value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{(valueObj ? Translate.get(valueObj.title) : Translate.get(value))}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <select className={props.className + extraClass}
                     value={value}
                     title={Translate.get(props.title)}
                     onChange={(event) => props.data.set(props.path, event.target.value)}>
        <option value='' disabled={!props.enableFirstOption}>{Translate.get(props.title)}</option>
        {props.values && props.values.map(valueObj =>
          <option key={valueObj.value} value={valueObj.value}>{Translate.get(valueObj.title)}</option>
        )}
      </select>
    }
  }

  static Check(props) {
    var value = props.data.get(props.path)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      return <div className={'inputReplacement ' + props.className + extraClass}
                  title={Translate.get(props.title)}>{value ? Translate.get('true') : Translate.get('false')}</div>
    }
    else {
      return <input type="checkbox"
                    className={props.className + extraClass}
                    checked={value}
                    title={Translate.get(props.title)}
                    onChange={(event) => props.data.set(props.path, event.target.checked)}/>
    }
  }

  static ToggleCheck(props) {    
    var value = !!props.data.isObjectInArray(props.path, props.value)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      return <div className={'inputReplacement ' + props.className + extraClass}
                  title={Translate.get(props.title)}>{value ? 'Ano' : 'Ne'}</div>
    }
    else {
      return <input type="checkbox"
                    className={props.className + extraClass}
                    checked={value}
                    title={Translate.get(props.title)}
                    onChange={(event) => props.data.toggleObjectInArray(props.path, props.value, event.target.checked)}/>
    }
  }

  static onChange(props, date, hours, minutes) {
    if(date) {
      let value = date._d.getTime()
      let newDate = +new Date(new Date(value).setHours(hours)).setMinutes(minutes)
      props.data.set(props.path, newDate)
    }
    else {
      props.data.set(props.path, null)
    }
  }

  static DateTime(props) {
    var value = +ObjectHelper.expand(props.data.get(props.path))
    var hasError = (value === 0)
    var extraClass = Input.getExtraClass(props, value)
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>
          {Formatter.formatDateTime(props.format, value)}
        </div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>
          &nbsp;
        </div>
      }
      else {
        return null
      }
    }
    else {
      let format = "D.M.YYYY"
      let date = moment(value)
      let hours = date.hour()
      let minutes = date.minute()
      return <>
          <DatePicker defaultValue={value ? moment(value) : undefined}
                      onChange={(date, dateString) => Input.onChange(props, date, hours, minutes)}
                      className={props.className + extraClass}
                      allowClear={!props.dontAllowClear}
                      title={Translate.get(props.title)}
                      placeholder={Translate.get(props.title) + '...'}
                      autoComplete="new-password"
                      format={format}/>

          {props.withTime && <>
            <input className={!hasError ?  'timeInput' : 'timeInput invalid'}
                   placeholder={hours}
                   onBlur={event => Input.onChange(props, date, +event.target.value, minutes)}/>
            :
            <input className={!hasError ?  'timeInput' : 'timeInput invalid'}
                   placeholder={minutes > 9 ? minutes : ('0' + minutes)}
                   onBlur={event => Input.onChange(props, date, hours, +event.target.value)}/>
          </>}
        </>

        /*<DateTimePicker
            
            onChange={(date) => Input.onChangeNew(props, date)}
            format="dd.MM.yyyy, hh:mm"
            locale="cs-CZ"
            showLeadingZeros={true}
            disableClock={true}
            openWidgetsOnFocus={true}
            value={new Date(value)}
             />
        <DatePicker onChange={(date, dateString)=> Input.onChange(date, dateString)} />,            
        <div className="button"
             key="nowButton"
             title="Aktuální datum a čas"
             onClick={(event) => props.data.set(props.path, DateHelper.now())}>
          <div className="icon time"/>
        </div>*/
    }
  }

  static Duration(props) {
    var value = props.data.get(props.path)
    var invalidFields = props.data.get('_invalid')
    var isInvalid =
      (props.validate && !props.validate(value)) || (invalidFields && invalidFields.indexOf(props.path) >= 0)
    var extraClass = (isInvalid ? ' invalid' : '')
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={'inputReplacement ' + props.className + extraClass}
                    title={Translate.get(props.title)}>{Formatter.formatSeconds(value)}</div>
      }
      else if (extraClass) {
        return <div className={'inputReplacement ' + props.className + extraClass} title={Translate.get(props.title)}>&nbsp;</div>
      }
      else {
        return null
      }
    }
    else {
      return <DurationInput value={value}
                            className={props.className + extraClass}
                            title={Translate.get(props.title)}
                            placeholder={Translate.get(props.title) + '...'}
                            onChange={(value) => props.data.set(props.path, value)}/>
    }
  }

  static getArea(data, path, address) {
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.addressbook.GetArea',
      address: address,
    }).then(reply => {
      data.set(path, reply.area)
    })
  }

  static Area(props) {
    var value = props.data.get(props.path)
    if (props.disabled) {
      if (value || props.showEmpty) {
        return <div className={props.className}
                    title={Translate.get(props.title)}>{value}</div>
      }
      else {
        return null
      }
    }
    else {
      return <>
        <input type="text"
               className={props.className}
               value={value}
               placeholder={Translate.get(props.title) + '...'}
               title={Translate.get(props.title)}
               onChange={(event) => props.data.set(props.path, event.target.value)}/>
        <div className="button"
             title={Translate.get('utils.input.area.areaLoadBtn')}
             onClick={(event) => Input.getArea(props.data, props.path, props.data.get(props.addressPath))}>
          <div className="icon address"/>
        </div>
      </>
    }
  }

  static Password(props) {
    var value = props.data.get(props.path)
    return <div className ="password-row"> 
      <input type="password"
        id={props.path}
        value={value}
        autoComplete="new-password"
        placeholder={Translate.get(props.placeholder)}
        onChange={(event) => {
          props.data.set(props.path, event.target.value)
          props.data.set('error', null)}
        }>
      </input>
      <div className="left icon showPassword" onClick={() => revealPassword(props.path)}/> 
    </div>

    function revealPassword(id) {
      let x = document.getElementById(id)
      x.type = x.type === "password" ? "text" : "password"
    }
  }

  static selectAddress(props) {
    Dialogs.open(
      <SelectAddressDialog address={props.data.get(props.path)} caseIds={props.caseIds}/>
    ).then(address => {
      if(address) {
        props.data.set(props.path, address)
      }
    })
  }

  static Address(props) {
    return <>
      {!props.disabled &&
        <div className="button"
             onClick={() => Input.selectAddress(props)}>
          <div className="icon address"/>
        </div>
      }
      <div className="inputReplacement">
        {Formatter.address(props.data.get(props.path))}
      </div>
    </>
  }
}

export default Input
