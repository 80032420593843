import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CampaignSelectDialog from '../campaigns/CampaignSelectDialog.js'

import DropBox from './DropBox.js'

import '../css/list.css'
import '../css/icons.css'

class DropBoxes extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new SimpleListContainer(this, 'com.optimsys.costra.kordex.dropbox.DropBoxesList',
      error => this.setState({error: error}))
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  createDropBox(event) {
    event.stopPropagation()
    Dialogs.open(
      <CampaignSelectDialog/>
    ).then((campaignId) => {
      if (campaignId) {
        DropBox.create(event, campaignId)
      }
    }, () => {
      this.setState({error: 'dropbox.dropboxes.error'})
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return (
    <div className="mainPanel">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig dropBox"/>
          <h1>{Translate.get('dropbox.list.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          <div className="listHeader spacedTop">
            <PageSelector page={this.state.page}
                          pages={this.state.pages}
                          onChange={(event, page) => this.container.setPage(page)}/>

            {this.state.allowCreation &&
              <div className="button right"
                   title={Translate.get('dropbox.button.newDropbox')}
                   onClick={(event) => this.createDropBox(event)}>
                <div className="icon left dropBoxAdd"/>
                {Translate.get('dropbox.button.newDropbox')}
              </div>
            }

          </div>
          <List list={this.state.items} itemFn={item => <DropBox.Row item={item}/> }/>
        </div>
      </div>
    </div>
    )
  }
}

export default DropBoxes