import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'

import PageSelector from '../utils/PageSelector.js'
import ListContainer from '../utils/ListContainer.js'
import Nav from '../utils/Nav.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import Metrics from '../utils/Metrics.js'

import Task from '../tasks/Task.js'
import { PageDataContext } from '../PageDataContext.js'

import '../css/list.css'
import '../css/icons.css'

class Container extends ListContainer {
  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class Promises extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.error = null
    this.state = state
    this.container = new Container(
      this, 'com.optimsys.costra.kordex.addressbook.PromisesList',
      error => this.setState({error: error})
    )
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.id !== this.props.id) {
      this.container.reload()
    }
  }

  renderRow(item) {
    let user = this.context.users[item.item.userId.$oid]
    return <div className={"row multiple-columns " + Metrics.TaskColor(item.item)}>
      <div className='row'>
        <Task.Icon task={item.item}/>
        <div className="size4c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.item.deadlineAt )}</div>
        <div className="size4c">{user && user.displayName}</div>
        <div className="size2c">{Translate.get('task.promiseResult.' + item.item.promiseProcessing.result)}</div>
        {item.item.promiseProcessing.fulfilment && item.item.promiseProcessing.fulfilment.map(fulfilment =>
          <div className="size6c">
            {Formatter.formatAmount(fulfilment.payed) + ' / ' +
              Formatter.formatAmount(fulfilment.promised) + ' ' + fulfilment.currency}
          </div>
        )}
        {Task.renderTaskButtons(item.item)}
      </div>
    </div>
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={Number(this.props.page ?? 1)}
                      pages={this.state.pages}
                      onChange={(event, page) => {
                        Nav.addUrlParam(event, 'page', page)}
                      }/>
      </div>
      <ul className="list">
        <li>
          <div className="row rowHeader ">
            <div><Task.Icon taskClass={'com.optimsys.costra.kordex.task.PaymentPromiseTask'}/></div>
            <div className="size4c">{Translate.get('task.header.deadline')}</div>
            <div className="size4c">{Translate.get('task.header.user')}</div>
            <div className="size2c">{Translate.get('task.header.state')}</div>
            <div className="size6c">{Translate.get('task.header.promisedAmount')}</div>
          </div>
        </li>
        {this.state.items && this.state.items.map(item =>
          <li key={item.item._id.$oid}>
            {this.renderRow(item)}
          </li>
        )}
      </ul>
    </>
  }
}

Promises.contextType = PageDataContext

export default Promises