import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import ContinuationStep from './ContinuationStep.js'
import '../css/dialogs.css';

class ContinuationDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      nextSteps : null,
      error : false,
      loading: false,
    }
    this.load = this.load.bind(this)
  }

  load() {
    this.setState({loading: true})
    WsContainers.transaction({
      _class: this.props.continuationClass,
      id : this.props.id,
    }).then((result) => {
      this.setState({ nextSteps: result.nextSteps, loading : false })
    }, () => this.setState({ error: true }))
  }

  componentDidMount() {   
    this.load()
  }

  renderContent() {
    if (this.state.error) {
      return <p>{Translate.get('task.continuation.requestError')}</p>
    }
    if (this.state.loading) {
      return <LoadingPlaceholder/>
    }
    if (!this.state.nextSteps) {
      return <p>{Translate.get('task.continuation.noContinuation')}</p>
    }
    return <ul className="list spacedBottom">
      {this.state.nextSteps.map((step, i) =>
        <li key={i}>
          <ContinuationStep step={step}/>
        </li>
      )}
    </ul>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <span>{Translate.get('caserecord.continuation.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel" />
          </div>
       </div>
      </div>
    </div>
  } 
}

export default ContinuationDialog
 