import React, { Component } from 'react'
import WsContainers from './costra/WsContainers.js'
import Translate from './costra/Translate.js'

import InitializedApp from './InitializedApp.js'

import './css/index.css'
import './css/icons.css'

import 'antd/dist/antd.min.css'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      connected: false,
      translateLoaded: false,
    }
  }

  componentDidMount() {
    WsContainers.onStateChange((connected) => {
      this.setState({connected: connected})
    })
    WsContainers.init()
    Translate.onStateChange((language, state) => {
      this.setState({ translateLoaded: state })
    })
    WsContainers.init()

    const appLanguageCookie = document.cookie
      .split('; ')
      .find(row => row.startsWith('appLanguage='))
    
    const language = appLanguageCookie && appLanguageCookie.split('=')[1]

    Translate.init(language || 'cs', (language) => {
      return Translate.loadFile(language, '/lang/' + language + '.json')
    })
  }

  render() {
    if (!this.state.connected || !this.state.translateLoaded) {
      return <div>Connecting...</div>
    }
    else {
      return <InitializedApp/>
    }
  }
}

export default App
