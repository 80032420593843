import React from 'react'
import Translate from '../costra/Translate'

import '../css/icons.css'
import '../css/form.css'

function ObjectEditTabs(props) {
  switch(props.item._class) {
    /*
    case 'com.optimsys.costra.kordex.caseobject.erl.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.KvCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RetCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RupCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.WcCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkWcCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkTelCaseObject':
      return <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>
          {Translate.get('caseobject.tabs.props')}
        </div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>
          {Translate.get('caseobject.tabs.events')}
        </div>
        <div className={'tab' + (props.component.state.tab === 'promises' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'promises'})}>{Translate.get('caseobject.tabs.promises')}</div>
        {props.component.state.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caseobject.tabs.log')}</div>
        }
      </div>
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.ZamCaseObject':
      return <div className="tabs">    
        {props.component.state.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caseobject.tabs.log')}</div>
        }
      </div>
    */
    /*
    case 'com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject':
      return  <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caseobject.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caseobject.tabs.events')}</div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caseobject.tabs.files')}</div>
        <div className={'tab' + (props.component.state.tab === 'gps' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'gps'})}>{Translate.get('caseobject.tabs.gps')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'promises' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'promises'})}>{Translate.get('caseobject.tabs.promises')}</div>
        }
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
              onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caseobject.tabs.log')}</div>
        }
      </div>
    */
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.pis.PisCaseObject':
      return  <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caseobject.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caseobject.tabs.events')}</div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caseobject.tabs.files')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caseobject.tabs.log')}</div>
        }
      </div>

    case 'com.optimsys.costra.kordex.caseobject.cofidis.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectVtp':
    case 'com.optimsys.costra.kordex.caseobject.erl.pred.PredCaseObject':
      return  <div className="tabs">
        <div className={'tab' + (props.component.state.tab === 'props' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'props'})}>{Translate.get('caseobject.tabs.props')}</div>
        <div className={'tab' + (props.component.state.tab === 'events' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'events'})}>{Translate.get('caseobject.tabs.events')}</div>
        <div className={'tab' + (props.component.state.tab === 'files' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'files'})}>{Translate.get('caseobject.tabs.files')}</div>
        <div className={'tab' + (props.component.state.tab === 'gps' ? ' active' : '')}
             onClick={(event) => props.component.setState({tab: 'gps'})}>{Translate.get('caseobject.tabs.gps')}</div>
        {props.allowEdit &&
          <div className={'tab' + (props.component.state.tab === 'log' ? ' active' : '')}
               onClick={(event) => props.component.setState({tab: 'log'})}>{Translate.get('caseobject.tabs.log')}</div>
        }
      </div>

    default:
      return  <div className="tabs">{props.item._class}</div>
  }
}

export default ObjectEditTabs
