import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import OperatorStatistics from './OperatorStatistics.js'
import CommStatistics from './CommStatistics.js'
import StatisticsSettings from './StatisticsSettings.js'
import {PageDataContext} from '../PageDataContext.js'

import '../css/list.css'
import '../css/icons.css'

class Statistics extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'operators',
    }
  }

  renderTabs() {
    switch(this.state.tab) {
      case 'settings':
        return <StatisticsSettings/>
      case 'comms':
        return <CommStatistics/>
      default:
        return <OperatorStatistics/>
    }
  }

  render() {
    return <div className="mainPanel withTabs">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig statistics" />
          <h1>{Translate.get('statistics.title')}</h1>
        </div>
        <div className="tabs">
          <div className={'tab' + (this.state.tab === 'operators' ? ' active' : '')}
               onClick={(event) => this.setState({tab: 'operators'})}>{Translate.get('statistics.operators.title')}</div>
          <div className={'tab' + (this.state.tab === 'comms' ? ' active' : '')}
               onClick={(event) => this.setState({tab: 'comms'})}>{Translate.get('statistics.comms.title')}</div>
          {this.context.currentUser.canManageStatistics &&
            <div className={'tab' + (this.state.tab === 'settings' ? ' active' : '')}
                 onClick={(event) => this.setState({tab: 'settings'})}>{Translate.get('statistics.settings.title')}</div>
          }
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderTabs()}
        </div>
      </div>
    </div>
  }
}

Statistics.contextType = PageDataContext

export default Statistics
