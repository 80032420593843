import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import ImportProgress from './ImportProgress.js'
import ImportRecords from './ImportRecords.js'

import '../css/list.css'
import '../css/icons.css'

class Imports extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'progress',
    }
  }

  renderTabs() {
    switch(this.state.tab) {
      case 'history':
        return <ImportRecords page={this.props.page}/>
      default:
        return <ImportProgress/>
    }
  }

  render() {
    return <div className="mainPanel withTabs">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig import" />
          <h1>{Translate.get('imports.title')}</h1>
        </div>
        <div className="tabs">
          <div className={'tab' + (this.state.tab === 'progress' ? ' active' : '')}
               onClick={(event) => this.setState({tab: 'progress'})}>{Translate.get('imports.tabs.import')}</div>
          <div className={'tab' + (this.state.tab === 'history' ? ' active' : '')}
               onClick={(event) => this.setState({tab: 'history'})}>{Translate.get('imports.tabs.history')}</div>
        </div>

      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderTabs()}
        </div>
      </div>
    </div>
  }
}

export default Imports
