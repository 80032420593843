import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import ViewLustrationDialog from '../lustrations/ViewLustrationDialog.js'
//import Metrics from '../utils/Metrics.js'

import './navPanel.css'

class LustrationInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      lustrationDetailOpen: true,
    }
  }

  openLustration() {
    Dialogs.open(
      <ViewLustrationDialog lustrationResult={this.props.lustration} itemId={this.props.itemId}/>
    )
  }

  render() {
    switch (this.props.lustration._class) {

      case "com.optimsys.costra.kordex.lustration.LustrationError":
        return <>
          <div className="navPanel__object--adressee"
               key={this.props.lustration._id.$oid}>
            <div className='navPanel__object__header'>
              <div className='flex' >
                <div className="icon alert"/>
                <div>{Translate.get('lustrations.error.title')}</div>
              </div>
            </div>
            <div className='navPanel__object__details'>
              <div>
                <span className='title'>{Translate.get('lustrations.time')}</span>
                <span>{Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM', this.props.lustration.time)}</span>
              </div>
              {this.props.lustration && this.props.lustration.companyId &&
                <div>
                  <span className='title'>{Translate.get('contactData.companyId')}</span>
                  <span>{this.props.lustration.companyId}</span>
                </div>
              }
            </div>
            <div className='navPanel__object__details multiline'>
              {this.props.lustration.errorDescription}
            </div>
          </div>
        </>

      case "com.optimsys.costra.kordex.lustration.ares.AresResult":
        return <>
          <div className="navPanel__object--adressee"
               key={this.props.lustration._id.$oid}>
            <div onClick={event => this.openLustration()}>
              <div className='navPanel__object__header'>
                <div className='flex'>
                  <div className="ratingIcon" />
                  <div>{this.props.lustration.displayName}</div>
                </div>
                <div>
                  <div className="icon left law"/>
                  <span className="left">{Translate.get('lustrations.ares.title')}</span>
                </div>
              </div>
              <div className='navPanel__object__details'>
                <div>
                  <span className='title'>{Translate.get('lustrations.time')}</span>
                  <span>{Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM', this.props.lustration.time)}</span>
                </div>
              </div>
              <div className='navPanel__object__details'>                        
                {this.props.lustration && this.props.lustration.addresses && this.props.lustration.addresses.length > 0 &&
                  <div>
                    <span className='title'>{Translate.get('contactData.address.title')}</span>
                    {this.props.lustration.addresses.map((address , index) =>
                      <span key={index}>{Formatter.address(address)}</span>
                    )}
                  </div>
                }
              </div>
              <div className='navPanel__object__details'> 
                {this.props.lustration && this.props.lustration.companyId &&
                  <div>
                    <span className='title'>{Translate.get('contactData.companyId')}</span>
                    <span>{this.props.lustration.companyId}</span>
                  </div>
                }
              </div>
            </div> 
            {this.state.lustrationDetailOpen && this.props.lustration.statutaries &&
              <div className="detail--statutaries">
                {this.props.lustration.statutaries.map((statutary, index) =>
                  <div className="navPanel__object statutaties"
                       key={index}>
                    <div className='navPanel__object__header'>
                      <div className='flex' >
                        <div>{statutary.contactData.displayName}</div>
                      </div>
                    </div>
                    <div className='navPanel__object__details'>
                      <div>
                        <span className='title'>{Translate.get('contactData.address.title')}</span>
                        {statutary.contactData.contacts.map((address, index) =>
                          <span key={index}>{Formatter.address(address, index)}</span>
                        )}
                      </div>
                    </div>
                    {statutary.description &&
                      <div className='navPanel__object__details'>
                        <div>
                          <span className='title'>{Translate.get('lustrations.statutary.description')}</span>
                          <span>{statutary.description}</span>
                        </div>
                      </div>
                    }
                    {statutary.contactData.bornDate &&
                      <div className='navPanel__object__details'>
                        <div>
                          <span className='title'>{Translate.get('contactData.bornDate')}</span>
                          <span>{Formatter.formatDateTime('%dd.%mm.%yyyy', statutary.contactData.bornDate)}</span>
                        </div>
                      </div>
                    }
                  </div>
                )}
              </div>
            }
          </div>
          <div className='plusDetail'
               onClick={()=>this.setState({lustrationDetailOpen: !this.state.lustrationDetailOpen})}>
            {this.state.lustrationDetailOpen? "-" : "+"}
          </div>
        </>

      default:
        return ""
    }
  }
}

export default LustrationInfo
