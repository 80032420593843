import React, {Component} from 'react'
import Translate from '../costra/Translate'

import LoadingPlaceholder from '../utils/LoadingPlaceholder'

const timeArray = [
  {value: "All", displayName: 'tasksPanel.time.All' },
  {value: "Today", displayName: 'tasksPanel.time.Today' },
  {value: "ThreeDays", displayName: 'tasksPanel.time.ThreeDays' },
  {value: "Week", displayName: 'tasksPanel.time.Week' },
  {value: "Month", displayName: 'tasksPanel.time.Month' },
  {value: "Late", displayName: 'tasksPanel.time.Late' },
  {value: "DayLate", displayName: 'tasksPanel.time.DayLate' },
  {value: "WeekLate", displayName: 'tasksPanel.time.WeekLate' },
]

class TimeSelect extends Component {
  constructor(props) {
    super()
    this.state = {
      isDropDownShown : false,
      search: "",
    }
  }

  renderItem(item) {
    let isSelected = (this.props.data.get('timeInterval') === item.value)
    let clickProc = () => {
      this.props.data.set('timeInterval', item.value, () => {
        this.setState({isDropDownShown: false})
        this.props.apply()
      })
    }
    return <div className={"value" + (isSelected ? " selected": "")}
                key={item.value}
                onClick={clickProc}>
      {Translate.get(item.displayName)}
    </div>
  }

  render() { 
    if(!this.props.data) {
      return <LoadingPlaceholder/>
    }
    return <div className="select-with-dropdown times">
      <div className={"click-catcher" + (this.state.isDropDownShown ? "" : " hidden")}
           onClick={() => this.setState({isDropDownShown: false})}/>
      <div className="name"
           onClick={() => this.setState({isDropDownShown: !this.state.isDropDownShown})}>
        {Translate.get('tasksPanel.time.title')}
      </div>
      <div className={"values times" + (this.state.isDropDownShown? "" : " hidden")}>
        {timeArray.map((item, i) => this.renderItem(item))}
      </div>
    </div>
  }
}

export default TimeSelect
