import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'
import Dialogs from '../costra/Dialogs.js'
import Router from '../costra/Router.js'

import ConfirmDialog from '../utils/ConfirmDialog.js'

import EntityChangeLog from '../changelog/EntityChangeLog.js'
import AddressBookFilesList from '../files/AddressBookFilesList.js'
import LustrationDialog from '../lustrations/LustrationDialog.js'
import Properties from './Properties.js'
import CommLog from './CommLog.js'
import Promises from './Promises.js'

import '../css/icons.css'
import '../css/index.css'

class ItemEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'props',
      //clientRating: null,
    }
  }

  /*
  chooseColor(rating) {
    switch(true) {
      case rating < 20: return 'red'
      case rating < 60: return 'orange'
      case rating < 80: return 'yellow'
      case rating < 101: return 'green'
      default: return ''
    }
  }
  */

  renderTabs() {
    switch(this.state.tab) {
      case "comms":
        return <CommLog id={this.props.id}/>
      case 'promises':
        return <Promises id={this.props.id}/>
      case 'users':
        return "" //<Users id={this.props.id}/>
      case 'files':
        return <AddressBookFilesList id={this.props.id} allowEdit={this.props.allowEdit}/>
      case "log":
        return <EntityChangeLog id={this.props.id} item={this.props.item}/>
      default:
        return <Properties id={this.props.id}/>
    }
  }

  removeItem() {
    Dialogs.open(
      <ConfirmDialog text={'Opravdu chcete odstránit adresářový záznam ' + this.props.item.displayName + '?'}/>
    ).then((confirmed) => {
      if (confirmed) {
        WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.addressbook.RemoveItem',
          id: this.props.item._id.$oid,
        }).then(() => Router.goBack())
      }
      else{
        Dialogs.close(null)
      }
    })
  }

  lustrationDialog(event) {
    Dialogs.open(
      <LustrationDialog item={this.props.item}
                        load={this.props.load}/>
    )
  }

  render() {
    return <>
      <div className={"mainPanel" + (this.props.id !== 'new' ? " withTabs mainPanelWithNavPanel" : "")}>         
        <div className="header">
          <div className="row">
            <div className='flex'>
              <div className="left icon iconBig addressBookItem"/>
              {this.props.id === 'new' &&
                <h1>{Translate.get('addressbook.edit.title.new')}</h1>
              }
              {this.props.item && this.props.item.displayName &&
                <h1>{this.props.item.displayName}</h1>
              }
            </div>

            {this.props.id !== 'new' &&
              <div className='header__buttons'>
                <button disabled={!this.props.allowEdit}
                        onClick={event => this.lustrationDialog(event)}
                        className={this.props.allowEdit ? "" : "disabled"}>
                  {Translate.get('addressbook.button.lustration')}
                </button>
                <button disabled={!this.props.allowRemove}
                        className={this.props.allowRemove ? "" : "disabled"}
                        onClick={() => this.removeItem()}>
                  {Translate.get('addressbook.button.remove')}
                </button>
              </div>
            }
          </div>
          
        </div>
        
        <div className="scrollable">
          <div className='subMenu'>
            {this.props.id !== 'new' && <div className="row">
              <div className="tabs">
                <div className={'tab' + (this.state.tab === 'props' ? ' active' : '')}
                     onClick={(event) => this.setState({tab: 'props'})}>{Translate.get('addressbook.tab.properties')}</div>
                <div className={'tab' + (this.state.tab === 'comms' ? ' active' : '')}
                     onClick={(event) => this.setState({tab: 'comms'})}>{Translate.get('addressbook.tab.comms')}</div>
                <div className={'tab' + (this.state.tab === 'promises' ? ' active' : '')}
                     onClick={(event) => this.setState({tab: 'promises'})}>{Translate.get('addressbook.tab.promises')}</div>
                {/*
                <div className={'tab' + (this.state.tab === 'users' ? ' active' : '')}
                    onClick={(event) => this.setState({tab: 'users'})}>{Translate.get('addressbook.tab.users')}</div>
                */}
                <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                     onClick={(event) => this.setState({tab: 'files'})}>{Translate.get('addressbook.tab.files')}</div>
                {this.props.allowEdit &&
                  <div className={'tab' + (this.state.tab === 'log' ? ' active' : '')}
                       onClick={(event) => this.setState({tab: 'log'})}>{Translate.get('addressbook.tab.protocol')}</div>
                }
                </div>
              </div>
            }
          </div>
          <div className="content">
            {this.renderTabs()}
          </div>
        </div>
      </div>
    </>
  }
}

export default ItemEdit
