import React from 'react'
import Translate from '../costra/Translate.js'

import Contact from './Contact.js'

function newPhone(props) {
  var list = props.data.get(props.path)
  if (!list) {
    list = []
  }
  list.push({
    _class: 'com.optimsys.costra.addressbook.contact.Phone',
    phoneNum: '',
    canReceiveSms: true,
    extensions: { kordex: {
      flag: 'Unknown',
      _class: 'com.optimsys.costra.kordex.addressbook.KordexPhoneExtension',
    } },
  })
  props.data.set(props.path, list)
}

function newMail(props) {
  var list = props.data.get(props.path)
  if (!list) {
    list = []
  }
  list.push({
    _class: 'com.optimsys.costra.addressbook.contact.Mail',
    mailAddress: '',
    extensions: { kordex: {
      flag: 'Unknown',
      _class: 'com.optimsys.costra.kordex.addressbook.KordexMailExtension',
    } }
  })
  props.data.set(props.path, list)
}

function newAddress(props) {
  var list = props.data.get(props.path)
  if (!list) {
    list = []
  }
  list.push({
    _class: 'com.optimsys.costra.addressbook.contact.Address',
    country: 'CZ',
    extensions: { kordex: {
      flag: 'Unknown',
      _class: 'com.optimsys.costra.kordex.addressbook.KordexAddressExtension',
    } },
  })
  props.data.set(props.path, list)
}

function Contacts(props) {

  var list = props.data.get(props.path)
  
  return <ul className="list">
    {list.length > 0 && list.map((item, index) =>
      <li key={index}>
        <Contact.Row item={item}
                     index={index}
                     data={props.data}
                     path={props.path}
                     disabled={props.disabled}/>
      </li>
    )}
    {!props.disabled &&
      <li>
        <div className="contact-buttons">
          <div className="button"
              onClick={(event) => newAddress(props)}>
            <div className="icon left address"/>
            {Translate.get('contactData.button.newAddress')}
          </div>
          <div className="button"
              onClick={(event) => newMail(props)}>
            <div className="icon left email"/>
            {Translate.get('contactData.button.newMail')}
          </div>
          <div className="button"
              onClick={(event) => newPhone(props)}>
            <div className="icon left phone"/>
            {Translate.get('contactData.button.newPhone')}
          </div>
        </div>
      </li>

    }
  </ul>
}

export default Contacts
