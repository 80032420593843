import React, { Component } from 'react';
import Identity from './costra/Identity.js'
import Translate from './costra/Translate.js'

import logo from './img/logo_Kordex_2021_RGB.jpg'
import './css/dialogs.css';

class LoginDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      login: '',
      password: '',
      error: null,
    }
  }

  login(event) {
    Identity.login(this.state.login, this.state.password).then(
      (result) => {},
      () => {
        this.setState({error: 'Nelze přihlásit uživatele'})
      }
    )
    event.preventDefault()
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog small">
          <img src={logo} alt="Logo"/>
          <div className="frame">
            <form onSubmit={(event) => this.login(event)}>
              {this.state.error}
              <input type="text"
                     className="wide"
                     placeholder={Translate.get('login.name')}
                     value={this.state.login}
                     onChange={(event) => this.setState({login: event.target.value})}/>
              <input type="password"
                     className="wide"
                     placeholder={Translate.get('login.password')}
                     value={this.state.password}
                     onChange={(event) => this.setState({password: event.target.value})}/>
              <input type="submit" className="wide" value={Translate.get('login.logIn')} />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginDialog