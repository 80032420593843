import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import ListContainer from '../utils/ListContainer.js'
import PageSelector from '../utils/PageSelector.js'
import Nav from '../utils/Nav.js'
import Table from '../utils/Table.js'

import User from '../users/User.js'
import '../css/list.css'
import '../css/icons.css'

class Users extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.page = this.props.page ?? 1
    state.layout = ObjectHelper.copy(User.defaultLayout)
    state.sortField = 'login'
    state.sortOrder = -1
    this.state = state
    this.container = new ListContainer(this, 'com.optimsys.costra.kordex.users.UsersList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  render() {
    return <div className="mainPanel withTable">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig users"/>
          <h1>{Translate.get('users.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          <div className="listHeader spacedTop">
            <PageSelector page={this.state.page}
                          pages={this.state.pages}
                          onChange={(event, page) => {
                            Nav.addUrlParam(event, 'page', page)}
                          }/>

            <div className="button right"
                 title={Translate.get('users.button.newUser')}
                 onClick={(event) => User.create(event)}>
              <div className="icon left user"/>
              {Translate.get('users.button.newUser')}
            </div>
          </div>

          <Table name="usersLayout"
                 items={this.state.items}
                 layout={this.state.layout}
                 sortField={this.state.sortField}
                 sortOrder={this.state.sortOrder}
                 updateLayout={(layout) => this.setState({layout : layout})}
                 sort={(sortName) => this.container.sortBy(sortName)}
                 columns={User.columns}
                 itemFn={(item, index, key) =>
                  <User.Row layout={this.state.layout} item={item} key={key}/>
                 }/>

        </div>
      </div>
    </div>
  }
}

export default Users