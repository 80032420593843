import React, { Component } from 'react'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import WatchedItemContainer from '../utils/WatchedItemContainer.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import EntityChangeLog from '../changelog/EntityChangeLog.js'
import TaskObjectsList from '../caseobject/TaskObjectsList.js'
import TaskFilesList from '../files/TaskFilesList.js'
import Task from './Task.js'
import Properties from './Properties.js'

import '../css/list.css'
import '../css/icons.css'

class TaskInfo extends Component {
  
  constructor(props) {
    super(props)
    this.state = {   
      item : null,   
      tab: 'properties',
    }
    this.container = new WatchedItemContainer(this, 'com.optimsys.costra.kordex.task.TaskContainer')
  }

  componentDidMount() {
    this.container.load(this.props.id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }
  
  renderTabs() {
    switch(this.state.tab) {
      case 'properties':
        return <Properties id={this.props.id}/>
      case 'caseObjects':
        return <TaskObjectsList caseIds={this.state.item.caseIds} />
      case 'files':
        return <TaskFilesList id={this.state.item._id.$oid} allowEdit={this.state.allowContinue}/>
      case "log":
        return <EntityChangeLog id={this.props.id} item={this.props.item}/>
      default:
        return ""
    }
  }

  render() {
    if(!this.state.item) {
       return <LoadingPlaceholder/>
    }
    return <div className="mainPanel taskInfo withTabs">
      <div className="header">
        <div className="row">
          <div className='header--flex'>
            <div>
              <Task.Icon taskClass={this.state.item._class} extraClass="iconBig"/>
              <h1>{Translate.get(this.state.item._class) + ' ' +
                  (this.state.item.address ? " - " + Formatter.address(this.state.item.address) : '')}</h1>
            </div>
          </div>
          <div className="values">
            {(this.state.item.deadlineAt < +new Date()) && !this.state.item.discardedAt &&
              <div className='icon expired iconBig' title={Translate.get('task.info.expired')}/>
            }
            {this.state.item.discardedAt &&
              <div className='icon ok iconBig' title={Translate.get('task.info.complete')}/>
            }
            {this.state.item.promiseProcessing &&
              <div className="amounts">
                <div className={'bigAmount' + (this.state.item.promiseProcessing.result === 'Full' ? ' complete' : '')}
                     title={Translate.get('task.header.state')}>
                  {Translate.get('task.promiseResult.' + this.state.item.promiseProcessing.result)}
                </div>
                {this.state.item.promiseProcessing.fulfilment && this.state.item.promiseProcessing.fulfilment.map(value =>
                  <div className={'smallAmount' + (this.state.item.promiseProcessing.result === 'Full' ? ' complete' : '')}
                       key={value.currency}
                       title={Translate.get('task.header.promisedAmount')}>
                    {Formatter.formatAmount(value.payed) + ' / ' +
                      Formatter.formatAmount(value.promised) + ' ' +
                      value.currency}
                  </div>
                )}
              </div>
            }
          </div>
          <div className='header__buttons'>
            <button disabled={!this.state.allowClose}
                    className={this.state.allowClose ? "" : "disabled"}
                    onClick={()=> Task.closeTask(this.state.item)}>
              {Translate.get('task.button.close')}
            </button>
            <button disabled={!this.state.allowEdit}
                    className={this.state.allowEdit ? "" : "disabled"}
                    onClick={() => Task.continueDialog(this.state.item)}>
              {Translate.get('task.button.continue')}
            </button>
            {(this.state.allowEdit && this.state.item.discardedAt) &&
              <button onClick={() => Task.reopenTask(this.state.item)}>
                {Translate.get('task.button.reopen')}
              </button>
            }
          </div>
        </div>
      </div>

      <div className="scrollable">
        <div className='subMenu'>
          <div className="row">
            <div className="tabs">
              <div className={'tab' + (this.state.tab === 'properties' ? ' active' : '')}
                   onClick={(event) => this.setState({tab: 'properties'})}>
                {Translate.get('task.tab.properties')}
              </div>
              <div className={'tab' + (this.state.tab === 'caseObjects' ? ' active' : '')}
                   onClick={(event) => this.setState({tab: 'caseObjects'})}>
                {Translate.get('task.tab.caseObjects')}
              </div>
              <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                   onClick={(event) => this.setState({tab: 'files'})}>
                {Translate.get('task.tab.files')}
              </div>
              {this.state.allowEdit &&
                <div className={'tab' + (this.state.tab === 'log' ? ' active' : '')}
                     onClick={(event) => this.setState({tab: 'log'})}>
                  {Translate.get('task.tab.log')}
                </div>
              }
            </div>
          </div>
        </div>
        <div className="content">
          {this.renderTabs()}
        </div>
      </div>
    </div>
  }
}

export default TaskInfo