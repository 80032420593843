import React, { Component } from 'react'
import ObjectHelper from '../costra/ObjectHelper.js'

import ListContainer from '../utils/ListContainer.js'
import PageSelector from '../utils/PageSelector.js'
import Nav from '../utils/Nav.js'
import Table from '../utils/Table.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import CaseObject from '../caseobject/CaseObject.js'

import '../css/list.css'
import '../css/icons.css'

class Container extends ListContainer {

  params() {
    var params = super.params()
    params.caseIds = this.component.props.caseIds   
    return params
  }
}

class TaskObjectsList extends Component {

  constructor(props) {    
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.filter = {}
    state.page = this.props.page ?? 1
    state.layout = ObjectHelper.copy(CaseObject.defaultLayout)
    state.sortField = 'name'
    state.sortOrder = 1
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.caseobject.TaskObjectsList',
      error => this.setState({error: error}))
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      if(prevProps.page !== this.props.page) {
        this.container.setPage(this.props.page)
      }  
      if(prevProps.rightPanel && JSON.stringify(prevProps.rightPanel) !== JSON.stringify(this.props.rightPanel)) {
        this.container.reload()  
      }
      if(prevProps.task !== this.props.task) {
        this.container.reload()  
      }
    }    
  }

  render() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => {Nav.addUrlParam(event, 'page', page)}}/>
      </div>
      <Table name="taskObjectsLayout"
             withSemaphore={true}
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             sort={(sortName) => this.container.sortBy(sortName)}
             columns={CaseObject.columns}
             itemFn={(item, index, key) =>
                <CaseObject.Row layout={this.state.layout} item={item} key={key}/>
             }/>
    </>
  }
}

export default TaskObjectsList