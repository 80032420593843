import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import CaseRecord from '../../CaseRecord.js'
import '../../../css/form.css'

const states = [
  {value: 'D10', title: 'sal.telephony.telState.D10'},
  {value: 'D20', title: 'sal.telephony.telState.D20'},
  {value: 'D30', title: 'sal.telephony.telState.D30'},
  {value: 'D11', title: 'sal.telephony.telState.D11'},
  {value: 'D12', title: 'sal.telephony.telState.D12'},
  {value: 'D13', title: 'sal.telephony.telState.D13'},
  {value: 'D14', title: 'sal.telephony.telState.D14'},
  {value: 'D15', title: 'sal.telephony.telState.D15'},
  {value: 'D16', title: 'sal.telephony.telState.D16'},
  {value: 'D17', title: 'sal.telephony.telState.D17'},
  {value: 'D18', title: 'sal.telephony.telState.D18'},
  {value: 'D19', title: 'sal.telephony.telState.D19'},
  {value: 'D21', title: 'sal.telephony.telState.D21'},
  {value: 'Unknown', title: 'sal.telephony.telState.Unknown'}
]

// TODO campaing check and lang val rework
function TelProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection car"/>
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="caseNo"
                   title="caserecord.properties.caseNo"
                   validate={Validator.notEmptyString}/>
        <Form.Select data={props.data}
                     disabled={!props.editOpen}
                     path="telState"
                     title="sal.telephony.telState"
                     values={states}/>
        {props.editOpen && <>
          <Form.Select data={props.data}
                       disabled={!props.editOpen}
                       path="source"
                       title="sal.source"
                       values={CaseRecord.sources}/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.evNum"
                     title="caserecord.sal.evNum"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.documentId"
                     title="caserecord.sal.documentId"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.eventId"
                     title="caserecord.sal.eventId"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.fk"
                     title="caserecord.sal.fk"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="productName"
                     title="sal.telephony.productName"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="paymentId"
                     title="sal.telephony.paymentId"/>
        </>}
        <Form.Text data={props.data}
                  disabled={!props.editOpen}
                  path="regularPayment"
                  title="sal.telephony.regularPayment"/>
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="numberOfPayments"
                   title="caserecord.properties.numberOfPayments"/>
        {props.editOpen && <>
          <Form.DateTime data={props.data}
                        disabled={!props.editOpen}
                        path="notificationDate"
                        title="sal.telephony.notificationDate"
                        format="%d.%m.%yyyy"/>
          <Form.DateTime data={props.data}
                        disabled={!props.editOpen}
                        path="signatureDate"
                        title="caserecord.properties.signatureDate"
                        format="%d.%m.%yyyy"/>
          <Form.DateTime data={props.data}
                        disabled={!props.editOpen}
                        path="requestedEnding"
                        title="caserecord.properties.requestedEnding"
                        format="%d.%m.%yyyy"/>
        </>}
        <Form.DateTime data={props.data}
                      disabled={!props.editOpen}
                      path="plannedEnding"
                      title="caserecord.properties.plannedEnding"
                      format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.data}
                        disabled={true}
                        path="processing.created"
                        title='caserecord.processing.created'
                        format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                        disabled={true}
                        path="processing.finished"
                        title='caserecord.processing.finished'
                        format="%d.%m.%yyyy" />
      </div>
      <Form.TextArea data={props.data}
                    disabled={!props.editOpen}
                    path="exportedNote"
                    styling="textArea big"
                    title="caserecord.properties.exportedNote"/>
    </div>
    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title="contactData.relation.client"/>
    {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default TelProperties