import React, { Component } from 'react'
import Container from '../costra/Container.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Nav from '../utils/Nav.js'

import {PageDataContext} from '../PageDataContext.js'
import '../css/list.css'
import '../css/icons.css'

class OperatorStatistics extends Component {

  constructor(props) {
    super(props)
    this.state = {
      items: null,
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.statistics.OperatorStatistics')
  }

  load() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.statistics.OperatorStatistics$Get',
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }

  renderUserName(userId) {
    if (userId.$oid === "000000000000000000000000") {
      return Translate.get('statistics.operators.shared')
    }
    let user = this.context.users[userId.$oid]
    if (!user) {
      return '?'
    }
    return user.displayName
  }

  renderSummaryTable(items, summary) {
    return <table className="list">
      <thead>
        <tr>
          <td><div className="icon user"/></td>
          <td>{Translate.get('statistics.operators.user')}</td>
          <td></td>
          <td className="alignRight">{Translate.get('statistics.operators.fullPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.partialPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.noPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.noValueCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.cancelledCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.payedValue')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.totalValue')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.percentage')}</td>
          <td className="size1c"></td>
        </tr>
      </thead>
      <tbody>
        <tr className="row">
          <td><div className="icon user"/></td>
          <td className="size4c">{Translate.get('statistics.operators.allUsers')}</td>
          <td className="size3c"></td>
          <td className="size2c alignRight">{summary.fullPaymentCases}</td>
          <td className="size2c alignRight">{summary.partialPaymentCases}</td>
          <td className="size2c alignRight">{summary.noPaymentCases}</td>
          <td className="size2c alignRight">{summary.noValueCases}</td>
          <td className="size2c alignRight">{summary.cancelledCases}</td>
          <td className="size3c alignRight">{Formatter.formatAmount(summary.payedValue)}</td>
          <td className="size3c alignRight">{Formatter.formatAmount(summary.totalValue)}</td>
          <td className="size2c alignRight">{(summary.payedValue * 100 / summary.totalValue).toFixed(2)}</td>
          <td className="size1c"></td>
        </tr>
        {items.map((item, index) =>
          <tr className="row"
              key={index}>
            <td><div className="icon user"/></td>
            <td className="size4c">{this.renderUserName(item.userId)}</td>
            <td className="size3c"></td>
            <td className="size2c alignRight">{item.fullPaymentCases}</td>
            <td className="size2c alignRight">{item.partialPaymentCases}</td>
            <td className="size2c alignRight">{item.noPaymentCases}</td>
            <td className="size2c alignRight">{item.noValueCases}</td>
            <td className="size2c alignRight">{item.cancelledCases}</td>
            <td className="size3c alignRight">{Formatter.formatAmount(item.payedValue)}</td>
            <td className="size3c alignRight">{Formatter.formatAmount(item.totalValue)}</td>
            <td className="size2c alignRight">{(item.payedValue * 100 / item.totalValue).toFixed(2)}</td>
            <td className="size1c"></td>
          </tr>
        )}
      </tbody>
    </table>
  }

  renderItemsTable(items) {
    return <table className="list">
      <thead>
        <tr>
          <td><div className="icon user"/></td>
          <td>{Translate.get('statistics.operators.campaign')}</td>
          <td>{Translate.get('statistics.operators.user')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.fullPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.partialPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.noPaymentCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.noValueCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.cancelledCases')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.payedValue')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.totalValue')}</td>
          <td className="alignRight">{Translate.get('statistics.operators.percentage')}</td>
          <td className="size1c"></td>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) =>
          <tr className="row"
              key={index}>
            <td><div className="icon user"/></td>
            <td className="size4c">{this.context.campaigns[item.campaignId.$oid] + ' ' + item.subgroup}</td>
            <td className="size3c">{this.renderUserName(item.userId)}</td>
            <td className="size2c alignRight">{item.fullPaymentCases}</td>
            <td className="size2c alignRight">{item.partialPaymentCases}</td>
            <td className="size2c alignRight">{item.noPaymentCases}</td>
            <td className="size2c alignRight">{item.noValueCases}</td>
            <td className="size2c alignRight">{item.cancelledCases}</td>
            <td className="size3c alignRight">{Formatter.formatAmount(item.payedValue)}</td>
            <td className="size3c alignRight">{Formatter.formatAmount(item.totalValue)}</td>
            <td className="size2c alignRight">{(item.payedValue * 100 / item.totalValue).toFixed(2)}</td>
            <td className="size1c"></td>
          </tr>
        )}
      </tbody>
    </table>
  }

  render() {
    if (!this.state.thisMonthItems || !this.state.lastMonthItems) {
      return <LoadingPlaceholder />
    }
    return <>
      <div className="section spacedTop">
        <div className="listHeader">
          <div>
            <div className="icon iconBig statistics"/>
            <h2>{Translate.get('statistics.thisMonth.title')}</h2>
          </div>
          <div>
            <div className="button right"
                 onClick={event => Nav.go(event, '/statistics/operator?date=' + this.state.thisMonthDate.toString(), true)}>
              <div className="icon export"/>
              {Translate.get('statistics.button.downloadCsv')}
            </div>
          </div>
        </div>
        {this.renderSummaryTable(this.state.thisMonthUsers, this.state.thisMonthSummary)}
        {this.renderItemsTable(this.state.thisMonthItems)}
      </div>
      <div className="section spacedTop">
        <div className="listHeader">
          <div>
            <div className="icon iconBig statistics"/>
            <h2>{Translate.get('statistics.lastMonth.title')}</h2>
          </div>
          <div>
            <div className="button right"
                 onClick={event => Nav.go(event, '/statistics/operator?date=' + this.state.lastMonthDate.toString(), true)}>
              <div className="icon export"/>
              {Translate.get('statistics.button.downloadCsv')}
            </div>
          </div>
        </div>
        {this.renderSummaryTable(this.state.lastMonthUsers, this.state.lastMonthSummary)}
        {this.renderItemsTable(this.state.lastMonthItems)}
      </div>
    </>
  }
}

OperatorStatistics.contextType = PageDataContext

export default OperatorStatistics
