import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import '../css/dialogs.css'
import {PageDataContext} from '../PageDataContext.js'

class CampaignSelectDialog extends Component {

  renderList() {
    return <ul className="list spacedBottom">
      {Object.keys(this.context.accessibleCampaigns).map(campaignId =>
        <li key={campaignId}>
          <div className="row withHover"
               onClick={(event) => Dialogs.close(campaignId)}>
            <div><div className="icon campaign"/></div>
            <div>{this.context.accessibleCampaigns[campaignId]}</div>
          </div>
        </li>
      )}
    </ul>
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog wide frame">
          <div className="title">
            <div className="icon iconBig campaign"/>
            <span>{Translate.get('campaigns.select.title')}</span>
          </div>
          {this.renderList()}
          <div className="buttons">
            <div className="button"
                 title={Translate.get('close')}
                 onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

CampaignSelectDialog.contextType = PageDataContext

export default CampaignSelectDialog