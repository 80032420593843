import React, { Component } from 'react';
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js';

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import File from './File.js'

import '../css/dialogs.css';

class UploadFileDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {uploading: false}
  }

  uploadFile(event, stayOpen) {
    this.setState({uploading: true})
    event.preventDefault()
    var data = new FormData(document.getElementById('fileUploadForm'))
    fetch('/upload/file', {
      method: 'POST',
      body: data,
    }).then((result) => {
      this.setState({uploading: false})
      if (result.status < 200 || result.status > 202) {
        this.setState({error: 'files.uploadFile.error'})
      }
      else if (!stayOpen) {
        Dialogs.close(null)
      }
    }, (error) => {
      this.setState({error: 'files.uploadFile.error'})
    })
  }

  renderContent() {
    if (this.state.uploading) {
      return <LoadingPlaceholder/>
    }

    return <form id="fileUploadForm"
                 onSubmit={(event) => this.uploadFile(event)} encType="multipart/form-data">
      <input type="hidden" name="owner" value={this.props.owner}/>
      <input className="wide" type="file" name="file"/>
      <select className="wide" name="fileType" defaultValue={this.props.fileType}>
        {File.fileTypes.map(key =>
          <option key={key} value={key}>{Translate.get('files.fileType.' + key)}</option>
        )}
      </select>
      <textarea className="wide" name="note" placeholder={Translate.get('files.uploadFile.note')}/>
    </form>
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog small frame">
          <div className="title">
            <div className="left icon iconBig files"/>
            <span>{Translate.get('files.uploadFile.title')}</span>
          </div>
          {this.renderContent()}
          <div className="buttons">
            <div className="button"
                 onClick={(event) => this.uploadFile(event)}
                 title={Translate.get('files.button.uploadFile')}>
              <div className="icon iconBig ok"/>
            </div>
            <div className="button"
                 onClick={(event) => this.uploadFile(event, true)}
                 title={Translate.get('files.button.uploadNext')}>
              <div className="icon iconBig add"/>
            </div>
            <div className="button"
                 onClick={(event) => Dialogs.close(false)}
                 title={Translate.get('close')}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default UploadFileDialog