import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'
import Translate from '../costra/Translate.js'

import PageSelector from '../utils/PageSelector.js'

import { PageDataContext } from '../PageDataContext.js'
import LogRow from './LogRow.js'

class Container extends SimpleListContainer {
  params() {
    var params = super.params()
    params.id = this.component.props.id
    return params
  }
}

class EntityChangeLog extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new Container(this,'com.optimsys.costra.kordex.changelog.EntityLogList')
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.reload()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  render() {
    if (this.props.id === 'new') {
      return <div className="spacedTop">
        <div className="icon left info"/>
        <div className="left">{Translate.get('changelog.newRecord.info')}</div>
      </div>
    }

    return (
      <div>
        <div className="listHeader spacedTop">
          <PageSelector page={this.state.page}
                        pages={this.state.pages}
                        onChange={(event, page) => this.container.setPage(page)}/>
        </div>
        <ul className="list change-log">
          <li>
            <div className="row rowHeader">
              <div className="icon-placeholder" />
              <div className="size4c">{Translate.get('changelog.time')}</div>
              <div className="size5c">{Translate.get('changelog.type')}</div>
              <div className="size5c">{Translate.get('changelog.user')}</div>
              <div className="size2c">{Translate.get('changelog.source')}</div>
            </div>
          </li>
          {this.state.items && this.state.items.map(item =>
            <li key={item._id.$oid}><LogRow item={item} users={this.context.users}/></li>
          )}
        </ul>
      </div>
    )
  }
}

EntityChangeLog.contextType = PageDataContext

export default EntityChangeLog
