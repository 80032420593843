import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import ConfirmDialog from './ConfirmDialog.js'

// TODO remove, rework error reporting
function EditConfirmDialog(component) {
  Dialogs.open(<ConfirmDialog
    text={Translate.get("utils.edit.mismatch")}
  />).then((reload) => {
    if (reload) {
      component.load()
    }
  })
}

export default EditConfirmDialog