import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'

import '../css/dialogs.css'

class CaseRewardDialog extends Component {

  constructor(props) {
    super(props)
    this.state = props.step
    this.data = new EditData(this, '')
  }

  render() {
    return (
      <div className="dialogsContainer">
        <div className="dialog wide frame">
          <div className="title">
            <span>{Translate.get('processing.caseRewardDialog.title')}</span>
          </div>
          <div>
            <Form.Text data={this.data}
                       path="reward"
                       title='processing.caseRewardDialog.reward'/>
            <Form.Text data={this.data}
                       path="currency"
                       title='processing.caseRewardDialog.currency'/>
          </div>
          {this.state.options &&
            <ul className="list spacedBottom">
              {this.state.options.map((option, index) =>
                <li key={index}>
                  <div className='row withHover'
                       onClick={()=> this.setState({reward: option.reward, currency: option.currency})}>
                    <div>
                      {Translate.get(option.title)}
                      {/*Formatter.formatAmount(option.reward)} {option.currency*/}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          }

          <div className="buttons">
            <div className="button"
                 title={Translate.get('apply')}
                 onClick={(event) => Dialogs.close(this.state)}>
              <div className="icon iconBig ok" />
            </div>
            <div className="button"
                 title={Translate.get('cancel')}
                 onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel" />
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CaseRewardDialog