import React, { Component } from 'react'
import Formatter from '../costra/Formatter'
import Translate from '../costra/Translate.js'
import Router from '../costra/Router.js'

import Metrics from '../utils/Metrics.js'
import StateIcons from '../utils/StateIcons'

import './navPanel.css'

class ObjectInfo extends Component {

  canView(item){
    return item.allowView ? " allowView" : ""
  }

  handleClick(event) {
    if(!this.props.object.allowView) {
      return
    }
    event.stopPropagation()
    Router.go('/caseObject/' + this.props.object.item._id.$oid)
  }

  getElementClass() {
    if (this.props.id === this.props.object.item._id.$oid) {
      return "navPanel__object " + Metrics.SelectedColor(this.props.object.state) +
        Metrics.CheckStatus(this.props.object.state) + this.canView(this.props.object.state)
    }
    return "navPanel__object " + Metrics.CheckStatus(this.props.object.state) + this.canView(this.props.object)
  }

  render() {
    return <div className='navPanel__object--flex' >
      <div className={this.getElementClass()}
           onClick={(event) => this.handleClick(event)}>
        <div className='navPanel__object__header' >
          <div className='flex'>
            <div className={"ratingIcon " + Metrics.ScoreColor(this.props.object.item.processing.score)}/>
            <div>{this.props.object.item.name}</div>
          </div>
          <div className='navPanel__object__icons'> 
            <StateIcons item={this.props.object.item}/>
          </div>
        </div>
        <div className='navPanel__object__details'>
          {this.props.object.item.address &&
            <div>
              <span className='title'>{Translate.get('contactData.address.title')}</span>
              <span>
                {this.props.object.item.address.matchStr ?
                  Formatter.address(this.props.object.item.address) :
                  Translate.get('contactData.address.empty')
                }
              </span>
            </div>
          }
        </div>
        <div className='navPanel__object__details'>
          {this.props.object.item.vin &&
            <div className="licenseNumber">
              <span className='title'>{Translate.get('caseobject.properties.vin')}</span>
              <span>{this.props.object.item.vin}</span>
            </div>
          }
          {this.props.object.item.licenseNumber &&
            <div>
              <span className='title'>{Translate.get('caseobject.properties.licenseNumber')}</span>
              <span>{this.props.object.item.licenseNumber}</span>
            </div>
          }
        </div>
        <div className='navPanel__object__details'>
          {this.props.object.item.accessoriesList &&
            <div>
              <span className='title'>{Translate.get('caseobject.erl.accessories')} </span>
              {this.props.object.item.accessoriesList.map((accessory, index) =>
                <span key={index}>
                  {accessory.name + ' ' + (accessory.productionYear ? "(" + accessory.productionYear + ")" : '')}
                </span>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  }

}

export default ObjectInfo