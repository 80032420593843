import React, {Component} from 'react'
import Translate from '../costra/Translate'

import LoadingPlaceholder from '../utils/LoadingPlaceholder'

class UserSelect extends Component {
  constructor(props) {
    super()
    this.state = {
      isDropDownShown : false,
      search: "",
    }
  }

  renderItem(item, selected) {
    /*
    if (item._id) {
      let isSelected = this.props.data.isIdInArray(this.props.path, item._id)
      return <div className={"value" + (isSelected ? " selected": "")}
                  key={item._id.$oid}
                  onClick={() => this.props.data.toggleIdInArray(this.props.path, item._id, !isSelected)}>
        {item.displayName}
      </div>
    }
    */
    let isSelected = this.props.data.isIdInArray('userIds', item.id)
    let isEverythingSelected = selected.length === this.props.items.length
    let clickProc = (isEverythingSelected ?
      () => this.props.data.set('userIds', [item.id]) :
      () => this.props.data.toggleIdInArray('userIds', item.id, !isSelected)
    )
    return <div className={"value" + (isSelected ? " selected": "")}
                key={item.id.$oid}
                onClick={clickProc}>
      {item.displayName}
    </div>
  }

  togglePopup() {
    this.setState({isDropDownShown: !this.state.isDropDownShown})
    if (this.state.isDropDownShown && this.props.apply) {
      this.props.apply()
    }
  }

  toggleAll(selected) {
    if (selected.length === this.props.items.length) {
      this.props.data.set('userIds', [])
    }
    else {
      let items = this.props.items.map(item => {
        if (item._id) return item._id
        if (item.id) return item.id
        return item.value
      })
      this.props.data.set('userIds', items)
    }
  }

  render() { 
    if(!this.props.data) {
      return <LoadingPlaceholder/>
    }
    const selected = this.props.data.get('userIds') || []
    let filteredItems = this.props.items &&
      this.props.items.filter(item => item.displayName.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
    let isEverythingSelected = selected.length === this.props.items.length

    return <div className="select-with-dropdown users">
      <div className={"click-catcher" + (this.state.isDropDownShown ? "" : " hidden")}
           onClick={() => this.togglePopup()}/>
      <div className="name"
           onClick={() => this.togglePopup()}>
        {Translate.get('tasksPanel.users.title')}
      </div>
      <div className={"values users" + (this.state.isDropDownShown? "" : " hidden")}>
        <input value={this.state.search}
               onChange={(e) => this.setState({search : e.target.value})}
               placeholder={Translate.get('search.placeholder')}/>
        <div className={"value" + (isEverythingSelected ? " selected": "")}
             onClick={() => this.toggleAll(selected)}>
          {Translate.get('tasksPanel.users.all')}
        </div>
        {filteredItems && filteredItems.map((item, i) => this.renderItem(item, selected))}
      </div>
      <div className={"number-of-selected" + (selected.length === 0 ? " hidden" : "")}>{Array.isArray(selected) ? selected.length : 1}</div>
    </div>
  }
}

export default UserSelect
