import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'

import ImportError from './ImportError.js'

import '../css/list.css'
import '../css/icons.css'

class Container extends SimpleListContainer {

  params() {
    var params = super.params()
    params['importId'] = this.component.props.id
    return params
  }
}

class ImportErrors extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.imports.ImportErrors')
  }

  componentDidMount() {
    this.container.reload()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => this.container.setPage(page)}/>
      </div>
      <List list={this.state.items}
            itemFn={item => <ImportError.Row item={item}/> }/>
    </>
  }

}

export default ImportErrors