import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import CaseRecord from '../caserecord/CaseRecord.js'

import PageSelector from '../utils/PageSelector.js'
import ListContainer from '../utils/ListContainer.js'
import Nav from '../utils/Nav.js'
import Table from '../utils/Table.js'

import Flag from '../flags/Flag.js'
import '../css/list.css'
import '../css/icons.css'

class Container extends ListContainer {

  params() {
    var params = super.params()
    params.campaignId = this.component.props.campaignId
    params.flagClass = 'com.optimsys.costra.kordex.flag.' + this.component.props.flag
    return params
  }
}

class FlaggedCasesList extends Component {

  constructor(props) {    
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.filter = {}
    state.layout = CaseRecord.defaultLayout
    state.page = this.props.page ?? 1
    state.sortField = 'caseNo'
    state.sortOrder = 1
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.caserecord.FlaggedCasesList')
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.flag !== this.props.flag ||
        prevProps.page !== this.props.page ||
        prevProps.campaignId !== this.props.campaignId) {
      this.container.reload()
    }
  }

  render() {
    let flagClass = 'com.optimsys.costra.kordex.flag.' + this.props.flag
    return <div className="mainPanel">
      <div className="header">
        <div className="row">
          <Flag.Icon flagClass={flagClass}/>
          <h1>{Translate.get(flagClass)}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          <div className="listHeader spacedTop">
            <PageSelector page={this.state.page}
                          pages={this.state.pages}
                          onChange={(event, page) => {
                            Nav.addUrlParam(event, 'page', page)}
                          }/>
          </div>

          <Table name="casesLayout"
                 withSemaphore={true}
                 items={this.state.items}
                 layout={this.state.layout}
                 sortField={this.state.sortField}
                 sortOrder={this.state.sortOrder}
                 updateLayout={(layout) => this.setState({layout : layout})}
                 sort={(sortName) => this.container.sortBy(sortName)}
                 columns={CaseRecord.columns}
                 itemFn={(item, index, key) =>
                    <CaseRecord.Row layout={this.state.layout} item={item} key={key} selected={this.state.selected}/>
                 }/>

        </div>
      </div>
    </div>
  }
}

export default FlaggedCasesList
