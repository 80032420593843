import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import ObjectHelper from '../costra/ObjectHelper.js'

import PageSelector from '../utils/PageSelector.js'
import Nav from '../utils/Nav.js'
import ListContainer from '../utils/ListContainer.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import PresetsDialog from '../utils/PresetsDialog.js'

import CampaignSelectDialog from '../campaigns/CampaignSelectDialog.js'

import CaseObject from './CaseObject.js'
import FilterDialog from './FilterDialog.js'
import FilterSummary from './FilterSummary.js'
import Translate from '../costra/Translate.js'
import Table from '../utils/Table.js'

class Objects extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.filter = {}
    state.layout = ObjectHelper.copy(CaseObject.defaultLayout)
    state.sortField = 'name'
    state.sortOrder = 1
    this.state = state
    this.container = new ListContainer(this, 'com.optimsys.costra.kordex.caseobject.ObjectsList',
      error => this.setState({error: error}))
    this.createObject = this.createObject.bind(this)
    this.openFilter = this.openFilter.bind(this)
    this.openPresets = this.openPresets.bind(this)
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  createObject(event) {
    event.stopPropagation()
    Dialogs.open(
      <CampaignSelectDialog campaigns={this.state.campaignFilters}/>
    ).then((campaignId) => {
      if (campaignId) {
        CaseObject.create(null, campaignId)
      }
    }, () => {
      this.setState({error: Translate.get('caseobject.objects.error')})
    })
  }

  openFilter(event) {
    Dialogs.open(<FilterDialog name="objectsFilter" filter={this.state.filter}/>).then((filter) => {
      if (filter) {
        Nav.addUrlParam(event, 'page', 1)
        this.container.reload()
      }
    })
  }

  openPresets(event) {
    Dialogs.open(
      <PresetsDialog name="objectsFilter"
                     validationClass="com.optimsys.costra.kordex.caseobject.ObjectsList$Filter"
                     filter={this.state.filter}/>
    ).then((filter) => {
      if (filter) {
        Nav.addUrlParam(event, 'page', 1)
        this.container.reload()
      }
    })
  }

  renderContent() {
    if (this.state.error) {
      return <ErrorPlaceholder reload={() => this.container.reload()}/>
    }
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }

    return <>
      <FilterSummary filter={this.state.filter}
                     name="objectsFilter"
                     update={() => this.container.reload()}/>

      <div className="listHeader spacedTop">
        <PageSelector page={Number(this.props.page ?? 1)}
                      pages={this.state.pages}
                      onChange={(event, page) => {
                        Nav.addUrlParam(event, 'page', page)}
                      }/>

        <div>
          {this.state.allowEdit &&
            <div className="button right"
                 title={Translate.get('caseobject.objects.button.newObject')}
                 onClick={this.createObject}>
              <div className="icon left caseAdd"/>
              <span className="left">{Translate.get('caseobject.objects.button.newObject')}</span>
            </div>
          }
          <div className="button right"
               title={Translate.get('caseobject.objects.button.filter')}
               onClick={this.openFilter}>
            <div className="icon left filter"/>
            <span className="left">{Translate.get('caseobject.objects.button.filter')}</span>
          </div>
            <div className="button right" title={Translate.get('caseobject.objects.button.presets')}
               onClick={this.openPresets}>
            <div className="icon left save"/>
            <span className="left">{Translate.get('caseobject.objects.button.presets')}</span>
          </div>
        </div>
      </div>

      <Table name="objectsLayout"
             withSemaphore={true}
             items={this.state.items}
             layout={this.state.layout}
             sortField={this.state.sortField}
             sortOrder={this.state.sortOrder}
             updateLayout={(layout) => this.setState({layout : layout})}
             sort={(sortName) => this.container.sortBy(sortName)}
             columns = {CaseObject.columns}
             itemFn={(item, index, key) =>
                <CaseObject.Row layout={this.state.layout} item={item} key={key}/>
             }/>
    </>
  }

  render() {
    return <div className="mainPanel">
      <div className="header">
        <div className="row">
          <div className="left icon iconBig car"/>
          <h1>{Translate.get('caseobject.objects.title')}</h1>
        </div>
      </div>
      <div className="scrollable">
        <div className="content">
          {this.renderContent()}
        </div>
      </div>
    </div>
  }
}

export default Objects
