import React, { Component } from 'react'

import GpsItemsList from '../gps/GpsItemsList.js'
import EntityChangeLog from '../changelog/EntityChangeLog.js'
import ObjectFilesList from '../files/ObjectFilesList.js'
import Events from '../events/Events.js'

import ObjectEditHeader from './ObjectEditHeader.js'
import ObjectEditTabs from './ObjectEditTabs.js'
import Properties from './Properties.js'

import '../css/icons.css'
import '../css/form.css'

class ObjectEdit extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tab: 'props',
    }
  }

  renderTabs() {
    switch(this.state.tab) {
      case 'files':
        return <ObjectFilesList id={this.props.id} allowEdit={this.props.allowEdit}/>
      case "gps":
        return <GpsItemsList id={this.props.id}/>
      case "log":
        return <EntityChangeLog id={this.props.id} item={this.props.item}/>
      case 'events':
        return <Events id={this.props.id}
                       containerClass="com.optimsys.costra.kordex.caseobject.ObjectEvents"
                       continuationClass="com.optimsys.costra.kordex.caseobject.GetObjectContinuation"/>
      default:
        return <Properties id={this.props.id}
                           parentType={this.props.parentType}
                           parentId={this.props.parentId}/>
    }
  }

  render() {
    return (<>
      <div className="mainPanel withTabs mainPanelWithNavPanel">
        <div className="header">
          <div className="row">
            {this.props.item &&
              <ObjectEditHeader item={this.props.item}
                                exports={this.props.exports}
                                allowEdit={this.props.allowEdit}
                                allowRemove={this.props.allowRemove}/>
            }
          </div>
        </div>
        <div className="scrollable">
          <div className='subMenu'>
            <div className="row">
              {this.props.item &&
                <ObjectEditTabs item={this.props.item} component={this} allowEdit={this.props.allowEdit} />
              }
            </div>
          </div>
          <div className="content">
            {this.renderTabs()}
          </div>
        </div>
      </div>
    </>)
  }
}

export default ObjectEdit
