import ObjectHelper from './ObjectHelper.js'

class DateHelper {

  static plusDays(value, days) {
    var date = new Date(ObjectHelper.expand(value))
    date.setDate(date.getDate() + days)
    return {$numberLong: date.getTime().toString()}
  }

  static now() {
    return {$numberLong: (new Date()).getTime().toString()}
  }
}

export default DateHelper