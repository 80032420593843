import React from 'react'
import PreparedLines from './PreparedLines.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

class VwFs {

  static dailyImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('vw.fs.result.importedPlans', data.importedPlans)}
        {PreparedLines.reportLine('vw.fs.result.casesNotFound', data.casesNotFound, 'cancel')}
        {PreparedLines.reportLine('vw.fs.result.skippedPayments', data.skipped, 'cancel')}
      </ul>
    </div>
  }

  static caseUpdateImportDone(component, data) {
    return <div>
      {PreparedLines.importDone(component, data)}
      <ul className="list">
        <li>
          <div className="row">
            <div><div className="icon ok" /></div>
            <div>{Translate.get('imports.vw.fs.row.caseUpdateImportDone.updatedCases')} {data.updatedCases}</div>
          </div>
        </li>
        <li>
          <div className="row">
            <div><div className="icon cancel" /></div>
            <div>{Translate.get('imports.vw.fs.row.caseUpdateImportDone.skipped')} {data.skipped}</div>
          </div>
        </li>
      </ul>
    </div>
  }

  static dailyPrepared(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.payments && data.payments.map(payment =>
          <li>
            <div className="row">
              <div className="size4c">{payment.accNumber}</div>
              <div className="size4c">{Formatter.formatDateTime("%d.%m. %yyyy", payment.payment.time)}</div>
              <div className="size4c">{Formatter.formatAmount(payment.payment.amount)}</div>
              <div className="size4c">{payment.payment.note}</div>
              <div className="size4c">{payment.debit ? "D" : "C"}</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static caseUpdateImportPrepared(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.updateDataSeq && data.updateDataSeq.map(updatedData => {
          return <li>
            <div className="row">
              <div className="size4c">{Translate.get('imports.vw.fs.row.caseUpdateImportPrepared.accNumber')} {updatedData.accNumber}</div>
              <div className="size6c">{Translate.get('imports.vw.fs.row.caseUpdateImportPrepared.startDate')} {Formatter.formatDateTime("%d.%m. %yyyy", updatedData.startDate)}</div>
              <div className="size6c">{Translate.get('imports.vw.fs.row.caseUpdateImportPrepared.endDate')} {Formatter.formatDateTime("%d.%m. %yyyy", updatedData.endDate)}</div>
              <div className="size4c">{Translate.get('imports.vw.fs.row.caseUpdateImportPrepared.requirement')} {updatedData.requirement}</div>
              <div className="size4c">{Translate.get('imports.vw.fs.row.caseUpdateImportPrepared.state')} {updatedData.state}</div>
            </div>
          </li>
          })
        }
      </ul>
    </div>
  }

  static checkupsImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.caseRowsImported', data.importedObjectRows)}
        {PreparedLines.reportLine('imports.result.paymentRowsImported', data.importedPlansRows)}
        {PreparedLines.reportLine('imports.result.caseRowsSkipped', data.skippedObjectRows, 'cancel')}
        {PreparedLines.reportLine('imports.result.paymentRowsSkipped', data.skippedPlanRows, 'cancel')}
      </ul>
    </div>
  }

}

export default VwFs