import React from 'react'
import Translate from '../costra/Translate.js'
import Validator from '../costra/Validator.js'

import Form from '../utils/Form.js'
import Divider from '../utils/Divider.js'

import Item from './Item.js'
import Contacts from './Contacts.js'

import '../css/form.css'

function getTitle(props) {
  if (props.title) {
    return props.title
  }
  let relation = props.data.get(props.path + '.relation')
  if (relation) {
    return 'contactData.relation.' + relation
  }
  return 'contactData.otherContact'
}

function ContactData(props)  {
  return <div className="subsection">
    <div className="header-row">
      <div className="icon iconSection  addressBookItem" />
      <h3>{Translate.get(getTitle(props))}: {props.data.get(props.path + ".displayName")}</h3>
    </div>

    <div className="content">
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".displayName"}
                 title='contactData.displayName'
                 styling={props.styling}
                 validate={props.noValidation ? null : Validator.notEmptyString} />
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".name"}
                 title='contactData.firstName' />
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".surname"}
                 title='contactData.lastName' />
      <Form.DateTime data={props.data}
                     disabled={props.disabled}
                     path={props.path + ".bornDate"}
                     title='contactData.bornDate'
                     format="%d.%m.%yyyy"/>
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".personalId"}
                 title='contactData.personalId' />
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".companyId"}
                 title='contactData.companyId' />
      <Form.Text data={props.data}
                 disabled={props.disabled}
                 path={props.path + ".taxId"}
                 title='contactData.taxId' />
      <Form.Select data={props.data}
                   disabled={props.disabled}
                   path={props.path + ".legalType"}
                   title='contactData.legalType'
                   values={Item.legalTypes()} />
      <Form.TextArea data={props.data}
                     disabled={props.disabled}
                     path={props.path + ".note"}
                     title='contactData.note' />
    </div>

    {(props.data.get(props.path + ".contacts") || !props.disabled) &&
      <Divider text='contactData.contacts'/>
    }
    <Contacts data={props.data}
              disabled={props.disabled}
              path={props.path + ".contacts"}/>

  </div>
}


export default ContactData
