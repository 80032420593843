import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Table from '../utils/Table.js'
import User from '../users/User.js'

import Item from '../addressbook/Item.js'
import CaseRecord from '../caserecord/CaseRecord.js'
import CaseObject from '../caseobject/CaseObject.js'

import '../css/list.css'
import '../css/icons.css'
import '../css/form.css'

class Search extends Component {

  constructor(props) {
    super(props)
    this.state = {
      text: null,
      addressBookLayout: ObjectHelper.copy(Item.defaultLayout),
      casesLayout: ObjectHelper.copy(CaseRecord.defaultLayout),
      objectsLayout: ObjectHelper.copy(CaseObject.defaultLayout),
      usersLayout: ObjectHelper.copy(User.defaultLayout),
    }
  }

  search(text) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.search.Search',
      text: text,
    }).then((result) => {
      if (!result.addressBook) result.addressBook = null
      if (!result.cases) result.cases = null
      if (!result.objects) result.objects = null
      if (!result.users) result.users = null
      this.setState(result)
    }, () => {
      this.setState({error: 'genericLoadError'})
    })
  }

  componentDidMount() {
    this.search(this.props.text)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.text !== this.props.text) {
      this.setState({text: null})
      this.search(this.props.text)
    }
  }

  renderContent() {
    if (this.state.text === null) {
      return <LoadingPlaceholder/>
    }
    return <>
      {this.state.addressBook &&
        <>
          <div className="listHeader spacedTop"><h2>{Translate.get('search.results.addressBook')}</h2></div>
          <Table name="addressBookLayout"
                 items={this.state.addressBook}
                 layout={this.state.addressBookLayout}
                 updateLayout={(layout) => this.setState({addressBookLayout : layout})}
                 columns={Item.columns}
                 itemFn={ (item, index, key) =>
                    <Item.Row layout={this.state.addressBookLayout} item={item} key={key}/>
                 }/>
        </>
      }
      {this.state.cases &&
        <>
          <div className="listHeader spacedTop"><h2>{Translate.get('search.results.cases')}</h2></div>
          <Table name="casesLayout"
                 withSemaphore={true}
                 items={this.state.cases}
                 layout={this.state.casesLayout}
                 updateLayout={(layout) => this.setState({casesLayout : layout})}
                 columns={CaseRecord.columns}
                 itemFn={(item, index, key) =>
                    <CaseRecord.Row layout={this.state.casesLayout} item={item} key={key}/>
                 }/>
        </>
      }
      {this.state.objects &&
        <>
          <div className="listHeader spacedTop"><h2>{Translate.get('search.results.objects')}</h2></div>
          <Table name="objectsLayout"
                 withSemaphore={true}
                 items={this.state.objects}
                 layout={this.state.objectsLayout}
                 updateLayout={(layout) => this.setState({objectsLayout : layout})}
                 columns={CaseObject.columns}
                 itemFn={(item, index, key) =>
                    <CaseObject.Row layout={this.state.objectsLayout} item={item} key={key}/>
                 }/>
        </>
      }
      {this.state.users &&
        <>
          <div className="listHeader spacedTop"><h2>{Translate.get('search.results.users')}</h2></div>
          <Table name="usersLayout"
                 items={this.state.users}
                 layout={this.state.usersLayout}
                 updateLayout={(layout) => this.setState({usersLayout : layout})}
                 columns={User.columns}
                 itemFn={(item, index, key) =>
                    <User.Row layout={this.state.usersLayout} item={item} key={key}/>
                 }/>
        </>
      }
    </>
  }

  render() {
    return (
      <div className="mainPanel">
        <div className="header">
          <div className="row">
            <div className="left icon iconBig search"/>
            <h1>
              {Translate.get('search.results.title')} "{this.props.text}"
            </h1>
          </div>
         </div>
        <div className="scrollable">
          <div className="content">
            {this.renderContent()}
          </div>
        </div>
      </div>
    )
  }
}

export default Search
