import React, { Component } from 'react'

export const RouterContext = React.createContext([])

class Router extends Component {

  static browserHistory = require('history').createBrowserHistory()

  static prefix() {
    var prefix = `${process.env.PUBLIC_URL}/`
    if (prefix.endsWith('/')) {
      prefix = prefix.substr(0, prefix.length - 1)
    }
    return prefix
  }

  static changeOrAddUrlParams(paramObject, url, replacing) {
    const urlParams = replacing ? new URLSearchParams('') : new URLSearchParams(window.location.search)
    Object.keys(paramObject).forEach(k => urlParams.set(k, paramObject[k]))
    return window.location.protocol + "//" +  window.location.host + (url ?? window.location.pathname) + '?' + urlParams.toString();
  }

  static removeUrlParams(arrayToRemove, url) {
    const urlParams = new URLSearchParams(window.location.search)
    arrayToRemove.forEach(k => urlParams.delete(k))
    return window.location.protocol + "//" +  window.location.host + (url ?? window.location.pathname) + '?' + urlParams.toString();
  }

  static getUrlParams() {
    return new URLSearchParams(window.location.search)
  }

  static go(pathname, query, hash) {
    let pathnameWithSearch = pathname?.split('?')
    Router.browserHistory.push({
      pathname: Router.prefix() + pathnameWithSearch?.[0] || '',
      search: pathnameWithSearch?.[1] && ('?' + pathnameWithSearch[1]),
      query: query,
      hash: hash,
    })
  }

  static goBack() {
    Router.browserHistory.back()
  }

  constructor(props) {
    super(props)
    this.unlisten = null
    var path = Router.browserHistory.location.pathname
    var prefix = Router.prefix()
    if (path.startsWith(prefix)) {
      path = path.substr(prefix.length)
    }
    console.log("#Router Initiating router for path", path)
    this.state = {
      'route': path.substring(1).split('/'),
      urlParams: new URLSearchParams(window.location.search.substring(1))
    }
  }

  componentDidMount() {
    if (!this.unlisten) {
      this.unlisten = Router.browserHistory.listen((event) => {
        var path = event.location && event.location.pathname ? event.location.pathname : event.pathname
        var prefix = Router.prefix()
        if (path.startsWith(prefix)) {
          path = path.substr(prefix.length)
        }
        console.log("#Router Initiating router for path", path)
        this.setState({
          'route': path.substring(1).split('/'),
          urlParams: new URLSearchParams(window.location.search.substring(1))
        })
      })
    }
  }

  componentDidUpdate() {
    /* 
      Functionality to block a user from changing the page until some condition is met.
      App which want to use this needs to specify: 
        + setPageBlocking fn
        + _pageBlocking flag
        + confirmationPromise - confirmation dialog   
        + nonBlockingUrlParams - url params which can be changed even if the page is blocked, like tab switching (optional)
    */
    if (this.props.setPageBlocking) {
      if (this.props._pageBlocking) {
        let stringifyBlockingUrlParams = (locationSearch) => JSON.stringify(
          Array.from(new URLSearchParams(locationSearch).entries()).filter(entry =>
            this.props.nonBlockingUrlParams ? !this.props.nonBlockingUrlParams.includes(entry[0]) : true 
          ).sort()
        )

        let unblock = Router.browserHistory.block((tx) => {
          // perform the transition if only non-blocking url params were changed, but keep the page blocked
          if((window.location.protocol + '//' + window.location.host + window.location.pathname) === tx.location.pathname && 
              stringifyBlockingUrlParams(window.location.search) === stringifyBlockingUrlParams(tx.location.search)) {
            // Unblock and retry the transition
            unblock()
            tx.retry()
          }
          // Navigation was blocked! Let's show a confirmation dialog
          // so the user can decide if they actually want to navigate
          // away and discard changes they've made in the current page.
          else {
            this.props.confirmationPromise().then((confirmed) => {
              if (confirmed) {
                // Unblock page + transition and retry the transition
                this.props.setPageBlocking(false)
                unblock()
                tx.retry()
              }
            })
          }
        })
        // if there was a previous unblock - release it
        this.unblock && this.unblock()
        // assign new unblock
        this.unblock = unblock
      }
      else {
        // page is not locked - release the block if it is asigned
        if (this.unblock) {
          this.unblock()
          this.unblock = null
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten()
      this.unlisten = null
    }
    if (this.unblock) {
      this.unblock()
      this.unblock = null
    }
  }

  render() {
    return <RouterContext.Provider value={this.state}>
      {this.props.proc && this.props.proc(this.state.route, this.state.urlParams)}
      {this.props.children && this.props.children(this.state.route, this.state.urlParams)}
    </RouterContext.Provider>
  }
}

export default Router