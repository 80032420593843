import React, { Component } from 'react';
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

import '../css/dialogs.css'

class RotateImageDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      working: false,
      error: null,
    }
  }

  rotateImage(event, direction) {
    this.setState({working: true})
    event.preventDefault()
    WsContainers.action({
      _class: 'com.optimsys.costra.kordex.file.RotateImage',
      id: this.props.item._id,
      direction: direction,
    }).then(() => {
      Dialogs.close(null)
    }, (error) => {
      this.setState({error: error})
    })
  }

  renderContent() {
    if (this.state.error) {
      return <ErrorPlaceholder/>
    }
    if (this.state.working) {
      return <LoadingPlaceholder/>
    }
    return <>
      <img className="thumbImage"
           src={'/download/thumbnail?id=' + this.props.item._id.$oid}
           alt={this.props.item.info.fileName}
           title={this.props.item.note}/>
      <p>{Translate.get('files.rotateImage.prompt')}</p>
      <div className="buttons">
        <div className="button"
             onClick={(event) => Dialogs.close(false)}>
          <div className="icon iconBig imageRotate0"/>
        </div>
        <div className="button"
             onClick={(event) => this.rotateImage(event, 'RotateLeft')}>
          <div className="icon iconBig imageRotateRight90"/>
        </div>
        <div className="button"
             onClick={(event) => this.rotateImage(event, 'Rotate180')}>
          <div className="icon iconBig imageRotate180"/>
        </div>
        <div className="button"
             onClick={(event) =>this.rotateImage(event, 'RotateRight')}>
          <div className="icon iconBig imageRotateLeft90"/>
        </div>
      </div>
    </>
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog small frame">
        <div className="title">
          <div className="left icon iconBig files"/>
          <span>{Translate.get('files.rotateImage.title')}</span>
        </div>
        {this.renderContent()}
        <div className="buttons">
          <div className="button"
               onClick={(event) => Dialogs.close(false)}
               title={Translate.get('close')}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default RotateImageDialog