import { Component } from 'react'
import Translate from '../costra/Translate'

class Flag extends Component {

  static Icon(props) {
    var flagClass = props.flag ? props.flag._class : props.flagClass
    var icon = 'alert'
    switch(flagClass) {
      case 'com.optimsys.costra.kordex.flag.BackOfficeCheckNeeded': icon = 'alert'; break;
      case 'com.optimsys.costra.kordex.flag.CaseDocumentsMissing': icon = 'log'; break;
      case 'com.optimsys.costra.kordex.flag.CaseEntryInvalid':  icon = 'alert'; break;
      case 'com.optimsys.costra.kordex.flag.CaseExportReady': icon = 'export'; break;
      case 'com.optimsys.costra.kordex.flag.CaseOverdue': icon = 'time'; break;
      case 'com.optimsys.costra.kordex.flag.CaseStartDelayed': icon = 'time'; break;
      case 'com.optimsys.costra.kordex.flag.ExpediteCaseProcessing': icon = 'tasks'; break;
      case 'com.optimsys.costra.kordex.flag.ObjectDocumentsMissing': icon = 'log'; break;
      case 'com.optimsys.costra.kordex.flag.ObjectProcessingStopped': icon = 'alert'; break;
      case 'com.optimsys.costra.kordex.flag.RestoreObject': icon = 'maintenance'; break;
      case 'com.optimsys.costra.kordex.flag.GpsMissing': icon = 'gps'; break;
      case 'com.optimsys.costra.kordex.flag.PlannedPastDeadline': icon = 'expired'; break;
      case 'com.optimsys.costra.kordex.flag.ObjectLockedDown': icon = 'wheel'; break;
      default: break;
    }
    return <div className={'icon left ' + icon + ' ' + props.extraClass} title={Translate.get(flagClass)}/>
  }
}

export default Flag