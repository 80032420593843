import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Dialogs from '../costra/Dialogs.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import { PageDataContext } from '../PageDataContext.js'

import Filter from '../utils/Filter.js'
import Metrics from '../utils/Metrics.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'

class FilterDialog extends Component {

  static tasksAndFlags=[
    'com.optimsys.costra.kordex.flag.BackOfficeCheckNeeded',
    'com.optimsys.costra.kordex.flag.CaseDocumentsMissing',
    'com.optimsys.costra.kordex.flag.CaseEntryInvalid',
    'com.optimsys.costra.kordex.flag.CaseExportReady',
    'com.optimsys.costra.kordex.flag.CaseOverdue',
    'com.optimsys.costra.kordex.flag.CaseStartDelayed',
    'com.optimsys.costra.kordex.flag.ExpediteCaseProcessing',
    'com.optimsys.costra.kordex.flag.ObjectDocumentsMissing',
    'com.optimsys.costra.kordex.flag.ObjectProcessingStopped',
    'com.optimsys.costra.kordex.flag.RestoreObject',
    'com.optimsys.costra.kordex.flag.GpsMissing',
    'com.optimsys.costra.kordex.flag.PlannedPastDeadline',

    'com.optimsys.costra.kordex.task.ContactTask',
    'com.optimsys.costra.kordex.task.GenericCaseTask',
    'com.optimsys.costra.kordex.task.CollectionTask',
    'com.optimsys.costra.kordex.task.VisitTask',
  ].map(cls => [cls, cls]).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

  static processingGroups = {
    'Unknown': 'processingGroup.Unknown',
    'InHouse': 'processingGroup.InHouse',
    'Contractor': 'processingGroup.Contractor',
    'Mixed': 'processingGroup.Mixed',
  }

  constructor(props) {
    super(props)
    var state={
      filter: {},
    }
    if (this.props.filter) {
      state.filter=ObjectHelper.copy(this.props.filter)
    }
    this.state=state
    this.data=new EditData(this, 'filter.')
  }

  applyFilter(event) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: 'com.optimsys.costra.kordex.caserecord.CasesList$Filter',
      name: this.props.name,
      data: [this.state.filter],
    }).then((result) => {
      Dialogs.close(this.state.filter)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  render() {
    if (this.state.error) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <ErrorPlaceholder error={this.state.error}/>
          <div className="buttons">
            <div className="right-position">
              <div className="button" onClick={(event) => Dialogs.close(null)}>
                <div className="icon iconBig cancel"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    const users = Object.entries(this.context.users).filter(x =>
      x[1].login.indexOf('inactive') === -1
    ).reduce((prev, [ key, value ]) => ({ ...prev, [ key ]: value }), {})
    return <div className="dialogsContainer">
      <div className="dialog wide frame">

        <div className="title">
          <div className="icon iconBig campaign"/>
          <span>{Translate.get('caserecord.filter.campaign.title')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={this.context.accessibleCampaigns}
                        pathName='campaigns'
                        placeholder='caserecord.filter.campaign.title'
                        label='caserecord.filter.campaign.title'/>

        <div className="title spacedTop">
          <div className="icon iconBig inProgress"/>
          <span>{Translate.get('caserecord.filter.flagsAndTasks')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.tasksAndFlags}
                        pathName='withFlags'
                        placeholder='caserecord.filter.flagsAndTasks'
                        label='filter.mustContain'/>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.tasksAndFlags}
                        pathName='withoutFlags'
                        placeholder='caserecord.filter.flagsAndTasks'
                        label='filter.mustNotContain'/>

        <div className="title spacedTop">
          <div className="icon iconBig semaphore"/>
          <span>{Translate.get('caserecord.filter.semaphore.title')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={Metrics.Semaphore}
                        pathName='withScore'
                        placeholder='caserecord.filter.score'
                        label='filter.mustContain'
                        attribute='text'/>
        <Filter.FromMap data={this.data}
                        array={Metrics.Semaphore}
                        pathName='withoutScore'
                        placeholder='caserecord.filter.score'
                        label='filter.mustNotContain'
                        attribute='text'/>

        <div className="title spacedTop">
          <div className="icon iconBig time"/>
          <span>{Translate.get('caserecord.filter.time.title')}</span>
        </div>
        <Filter.DateRangeRow data={this.data}
                             pathName='created'
                             title='caserecord.filter.created'/>
        <Filter.DateRangeRow data={this.data}
                             pathName='plannedEnding'
                             title='caserecord.filter.plannedEnding'/>
        <Filter.DateRangeRow data={this.data}
                             pathName='finished'
                             title='caserecord.filter.finished'/>

        <div className="title spacedTop">
          <div className="icon iconBig user"/>
          <span>{Translate.get('caserecord.filter.user.title')}</span>
        </div>
        <Filter.FromMap data={this.data}
                        array={users}
                        pathName='users'
                        placeholder='caserecord.filter.user.title'
                        attribute='displayName'
                        label='caserecord.filter.user.title'/>
        <Filter.FromMap data={this.data}
                        array={FilterDialog.processingGroups}
                        pathName='processingGroup'
                        placeholder='caserecord.filter.processingGroup'
                        label='caserecord.filter.processingGroup'/>

        <div className="title spacedTop">
          <div className="icon iconBig coins"/>
          <span>{Translate.get('caserecord.filter.value.title')}</span>
        </div>

        <Filter.AmountRangeRow data={this.data}
                               path="valueMissing"
                               title='caserecord.filter.valueMissing'/>
        <Filter.AmountRangeRow data={this.data}
                               path="valuePayed"
                               title='caserecord.filter.valuePayed'/>
        <Filter.AmountRangeRow data={this.data}
                               path="valuePlanned"
                               title='caserecord.filter.valuePlanned'/>


        <div className="buttons">
          <div className="right-position">
            <div className="button" onClick={(event) => this.applyFilter(event)}>
              <div className="icon iconBig ok"/>
            </div>
            <div className="button" onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

}
FilterDialog.contextType=PageDataContext

export default FilterDialog