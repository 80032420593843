import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import Metrics from '../../../utils/Metrics.js'

import ContactData from '../../../addressbook/ContactData.js'
import Accessories from './Accessories.js'
import Insurance from './Insurance.js'
import Securities from './Securities.js'
import '../../../css/form.css'

const vtpTypes = [
  {value: 'Original', title: 'sal.collection.vtpType.Original'},
  {value: 'NoTp', title: 'sal.collection.vtpType.None'},
  {value: 'NoTpNew', title: 'sal.collection.vtpType.NoneNew'},
  {value: 'Import', title:'sal.collection.vtpType.Import' },
  {value: 'Full', title:'sal.collection.vtpType.Full' },
  {value: 'SalOwned', title:'sal.collection.vtpType.SalOwned' },
  {value: 'ClientOwned', title:'sal.collection.vtpType.ClientOwned'},
  {value: 'Lost', title:'sal.collection.vtpType.Lost' },
  {value: 'Unknown', title:'sal.collection.vtpType.Unknown' },
]

function CollectionProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection car" />
        <h3>{Translate.get('caseobject.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="name"
                  title='caseobject.properties.objectName'
                  validate={Validator.notEmptyString}/>
        <Form.Address data={props.component.data}
                      disabled={!props.editOpen}
                      path="address"
                      title='caseobject.properties.address'
                      caseIds={[props.component.data.get("caseId")]}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="licenseNumber"
                  title="caseobject.properties.licenseNo"/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="vin"
                  title='caseobject.properties.vin'
                  styling={Metrics.CheckVin(props.component.data.get("vin"),"Vin")}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="providedAmount"
                  title='caseobject.properties.fundedAmount'
                  validate={Validator.number}/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="purchasePrice"
                  title='caseobject.properties.purchasePrice'
                  validate={Validator.number}/>
        <Form.DateTime data={props.component.data}
                      disabled={!props.editOpen}
                      path="purchaseDate"
                      title='caseobject.properties.purchaseDate'
                      format="%d.%m.%yyyy"/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="color"
                  title='caseobject.properties.color'/>
        <Form.Text data={props.component.data}
                  disabled={!props.editOpen}
                  path="productionYear"
                  title='caseobject.properties.productionYear'/>
      </div>
    </div>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection document" />
        <h3>{Translate.get('sal.collection.vtp.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpNo"
                       title='caseobject.properties.vtp'/>
        <Form.Check data={props.component.data}
                    disabled={!props.editOpen}
                    path="vtpDetails.tpIssues"
                    title='sal.collection.tpIssues'/>
        <Form.Check data={props.component.data}
                    disabled={!props.editOpen}
                    path="vtpDetails.isTpHeldBySal"
                    title='sal.collection.isTpHeldBySal'/>
        <Form.Select data={props.component.data}
                     disabled={!props.editOpen}
                     path="vtpDetails.vtpType"
                     title='sal.collection.vtpType'
                     values={vtpTypes}/>
        <Form.DateTime data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpDetails.acquisitionDate"
                       title='sal.collection.acquisitionDate'
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpDetails.borrowDate"
                       title='sal.collection.borrowDate'
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpDetails.checkReturnDate"
                       title='sal.collection.checkReturnDate'
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpDetails.lastReturnDate"
                       title='sal.collection.lastReturnDate'
                       format="%d.%m.%yyyy"/>
        <Form.DateTime data={props.component.data}
                       disabled={!props.editOpen}
                       path="vtpDetails.discardDate"
                       title='sal.collection.discardDate'
                       format="%d.%m.%yyyy"/>
      </div>
    </div>

    <Accessories data={props.component.data}
                 disabled={!props.editOpen}/>
    <Insurance data={props.component.data}
               disabled={!props.editOpen}/>
    <Securities data={props.component.data}
                disabled={!props.editOpen}/>

    {props.component.data.get('contactData') && props.component.data.get('contactData').map((contactData, index) =>
      <ContactData key={index}
                   data={props.component.data}
                   path={'contactData.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default CollectionProperties
