class ObjectHelper {

  static get(obj, path) {
    var item = obj
    var parts
    if (path.includes('\'')) {
      parts = path.split('\'').map((part, index) => { 
        if (index % 2 === 0) return part.split('.')
        else return [part]
      }).flat().filter(part => part)
    }
    else {
      parts = path.split('.').filter(Boolean)
    } 
    parts.forEach((part) => {
      if (item && (item[part] || item[part] === 0)) {
        item = item[part]
      }
      else item = ''
    })
    return item
  }

  static set(obj, path, value) {
    if (!obj) return obj
    var item = obj
    var parts
    if (path.includes('\'')) {
      parts = path.split('\'').map((part, index) => { 
        if (index % 2 === 0) return part.split('.')
        else return [part]
      }).flat().filter(part => part)
    }
    else {
      parts = path.split('.')
    }
    var lastPart = parts.pop()
    parts.forEach((part) => {
      if (!item[part]) {
        item[part] = {}
      }
      item = item[part]
    })
    item[lastPart] = value
    return obj
  }

  static unset(obj, path) {

    function clearEmpties(o) {
      for (var k in o) {
        if (!o[k] || typeof o[k] !== "object") {
          continue // If null or not an object, skip to the next iteration
        }
    
        // The property is an object
        clearEmpties(o[k]); 
        if (Object.keys(o[k]).length === 0) {
          delete o[k]; // The object had no properties, so delete that property
        }
      }
      return o;
    }

    if (!obj) return obj
    var item = obj
    var parts
    if (path.includes('\'')) {
      parts = path.split('\'').map((part, index) => { 
        if (index % 2 === 0) return part.split('.')
        else return [part]
      }).flat().filter(Boolean)
    }
    else {
      parts = path.split('.').filter(Boolean)
    }
    let lastPart = parts.pop()
    parts.forEach((part) => {
      if (!item[part]) {
        return
      }
      item = item[part]
    })
    delete item[lastPart]

    return clearEmpties(obj)
  }

  static isIdInArray(array, id) {
    if (!array) {
      return false
    }
    return (array.find(itemId =>
      itemId.$oid === id.$oid
    ) ? true : false)
  }

  static toggleIdInArray(array, id, value) {
    if (!array) {
      array = []
    }
    var updatedList = []
    array.forEach(listId => {
      if (listId.$oid !== id.$oid) {
        updatedList.push(listId)
      }
    })
    if (value) {
      updatedList.push(id)
    }
    return updatedList
  }

  static expand(value) {
    if (!value) {
      return value
    }
    if (typeof value === 'object') {
      if (value.$numberLong) return +value.$numberLong
      if (value.$oid) return value.$oid
    }
    return value
  }

  static copy(obj) {
    var result = null
    if (obj instanceof Date) {
      result = new Date()
      result.setTime(obj.getTime())
      return result
    }
    if (obj instanceof Array) {
      result = []
      for (var i = 0, len = obj.length; i < len; i++) {
          result[i] = ObjectHelper.copy(obj[i])
      }
      return result
    }
    if (obj instanceof Object) {
      result = {}
      Object.keys(obj).forEach(key => {
        result[key] = ObjectHelper.copy(obj[key])
      })
      return result
    }
    return obj
  }

  static isEqual(o1, o2) {
    if (typeof o1 !== "object" || typeof o2 !== "object") {
      return o1 === o2
    }
    else {
      return Object.keys(o1).length === Object.keys(o2).length &&
             Object.keys(o1).every(p => ObjectHelper.isEqual(o1[p], o2[p]))
    }
  }
}

export default ObjectHelper