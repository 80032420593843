import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import '../css/list.css'
import '../css/icons.css'
import '../css/form.css'

function BankConnection(props) {
  return <div className="row">
    <div><div className="icon company" /></div>
    <div className="size4c">{Translate.get('paymentplan.invoice.bankConnection')}</div>
    <div className="size6c">{props.bankConnection.account}</div>
    <div className="size4c">{Translate.get('paymentplan.invoice.vs')}:{props.bankConnection.vs}</div>
  </div>
}

function Payment(props) {
  return <div className="row inactive">
    <div><div className="icon coins" /></div>
    <div className="size2c">{Formatter.formatDateTime("%d.%m. %yyyy", props.payment.time)}</div>
    <div className="size5c">{props.payment.id}</div>
    <div className="size4c">
      {Translate.get('paymentplan.received.title')} {Formatter.formatAmount(props.payment.amount) + ' ' + props.payment.currency}
    </div>
    <div className="size4c">{props.payment.source}</div>
    {props.payment.note &&
      <div className="size6c">{props.payment.note}</div>
    }
  </div>
}

function Invoice(props) {
  return <>
    <div className={'row withHover ' + ((props.item.missing > 0 && props.item.markedForCollection) ? '' : 'inactive')}
         onClick={(event) => props.toggleExpanded()}>
      <div><div className={props.item.missing > 0 ? 'icon moneyPartial' : 'icon money'} /></div>
      <div className="size2c" title={Translate.get('paymentplan.invoice.ordNo')}>
        {props.item.invoice.ordNo}
      </div>
      <div className="size3c" title={Translate.get('paymentplan.invoice.date')}>
        {Formatter.formatDateTime("%d.%m. %yyyy", props.item.invoice.time)}
      </div>
      <div className="size3c" title={Translate.get('paymentplan.invoice.id')}>
        {props.item.invoice.id}
      </div>
      <div className="size3c " title={Translate.get('paymentplan.invoice.total')}>
        {Formatter.formatAmount(props.item.total) + ' ' + props.item.invoice.currency}
      </div>
      <div className="size3c " title={Translate.get('paymentplan.invoice.received')}>
        {Formatter.formatAmount(props.item.payed) + ' ' + props.item.invoice.currency}
      </div>
      <div className="size3c " title={Translate.get('paymentplan.invoice.missing')}>
        {Formatter.formatAmount(props.item.missing) + ' ' + props.item.invoice.currency}
      </div>
      {props.item.invoice.note &&
        <div className="size6c" title={Translate.get('paymentplan.invoice.note')}>
          {props.item.invoice.note}
        </div>
      }
    </div>
    {props.expanded &&
      <ul className="list spaced">
        {props.item.invoice.bankConnection &&
          <li><BankConnection bankConnection={props.item.invoice.bankConnection}/></li>
        }
        {!props.item.assignedPayments &&
          <li>
            <div className="row inactive padding7">
              <div><div className="icon coins" /></div>
              <div>{Translate.get('paymentplan.invoice.noPayments')}</div>
            </div>
          </li>
        }
        {props.item.assignedPayments && props.item.assignedPayments.map((payment, index) =>
          <li key={index}>
            <Payment payment={payment}/>
          </li>
        )}
      </ul>
    }
  </>
}

function PaymentConnection(props) {
  return <div className="row inactive">
    <div><div className="icon coins" /></div>
    <div className="size5c">{Translate.get('paymentPlan.paymentConnection')}</div>
    <div className="size4c">{props.connection.bankConnection.account}</div>
    <div className="size4c">{Translate.get('paymentplan.invoice.vs')}:{props.connection.bankConnection.vs}</div>
    <div className="size4c">{Formatter.formatAmount(props.connection.missing)} {props.connection.currency}</div>
  </div>
}

function PlanValue(props) {
  return <div className="row inactive">
    <div><div className="icon coins" /></div>
    <div className="size5c">{Translate.get('paymentPlan.value')}</div>
    <div>
      {Formatter.formatAmount(props.value.missing)} / {Formatter.formatAmount(props.value.planned)} {props.value.currency}
    </div>
  </div>
}

class AnnotatedPaymentPlan extends Component {

  constructor(props) {
    super(props)
    this.state = {
      plan: null,
      connection: [],
      expanded: {},
    }
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.paymentplan.AnnotatePaymentPlan',
      planId: this.props.planId,
      date: this.props.date,
    }).then((result) => {
      this.setState({
        plan: result.plan,
        connection: result.connection || [],
        expanded: {},
      })
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && (prevProps.planId !== this.props.planId || prevProps.date !== this.props.date)) {
      this.load()
    }
  }

  toggleItemExpanded(index) {
    var expanded = this.state.expanded
    expanded[index] = !expanded[index]
    this.setState({ expanded: expanded })
  }

  render() {
    if (!this.state.plan) {
      return <LoadingPlaceholder />
    }
    return <>
      <ul className="list">
        {this.state.plan.value && this.state.plan.value.map(value =>
          <li key={value.currency}><PlanValue value={value}/></li>
        )}
        {this.state.connection.map((connection, index) =>
          <li key={index}><PaymentConnection connection={connection}/></li>
        )}
      </ul>

      <ul className="list spacedTop">
        <li>
          <div className="row rowHeader">
            <div className="icon-placeholder" />
            <div className="size2c">{Translate.get('paymentplan.invoice.ordNo')}</div>
            <div className="size3c">{Translate.get('paymentplan.invoice.date')}</div>
            <div className="size3c">{Translate.get('paymentplan.invoice.id')}</div>
            <div className="size3c">{Translate.get('paymentplan.invoice.total')}</div>
            <div className="size3c">{Translate.get('paymentplan.invoice.received')}</div>
            <div className="size3c">{Translate.get('paymentplan.invoice.missing')}</div>
          </div>
        </li>

        {this.state.plan.items && this.state.plan.items.map((item, index) =>
          (!this.props.relevantOnly || item.caseRelevant) &&
            <li key={index}>
              <Invoice item={item}
                       expanded={this.state.expanded[index]}
                       toggleExpanded={() => this.toggleItemExpanded(index)}/>
            </li>
        )}
        {this.state.plan.overflow && this.state.plan.overflow.map((overflow, index) =>
          <li row={index}>
            <div className="row withHover">
              <div><div className="icon alert" /></div>
              <div className="size4c">{Translate.get('paymentplan.overflow') + ' (' + overflow.currency + ')'}:</div>
              <div className="size4c">{Formatter.formatAmount(overflow.total) + ' ' + overflow.currency}</div>
            </div>
          </li>
        )}
      </ul>
    </>
  }

  /*
  renderReceived(plan) {
    return <ul className="list">
      <li>
        <div className="row rowHeader">
          <div className="icon-placeholder" />
          <div className="size4c">{Translate.get('paymentplan.received.date')}</div>
          <div className="size6c">{Translate.get('paymentplan.received.id')}</div>
          <div className="size4c">{Translate.get('paymentplan.received.amount')}</div>
        </div>
      </li>
      {plan.received && plan.received.map((item, index) =>
        <li key={index}>
          <div className="row">
            <div><div className="icon coins" /></div>
            <div className="size4c">{Formatter.formatDateTime("%d.%m. %yyyy", item.time)}</div>
            <div className="size6c">{item.id}</div>
            <div className="size4c">{Formatter.formatAmount(item.amount) + ' ' + item.currency}</div>
            <div>{item.note}</div>
          </div>
        </li>
      )}
    </ul>
  }
  */

}

export default AnnotatedPaymentPlan
