import React from 'react'
import Translate from '../../../costra/Translate.js'

import Input from '../../../utils/Input.js'

import '../../../css/form.css'

function Accessories(props) {
  const accessories = props.data.get('accessories') || []
  if (accessories.length <= 0 && props.disabled) {
    return null
  }
  return <div className="subsection">
    <div className="header-row">
      <div className="icon iconSection accessory" />
      <h3>{Translate.get('sal.collection.accessories')}</h3>
      {!props.disabled &&
        <div className="button right" onClick={event => props.data.addToArray('accessories', {})}>
          <div className="icon add"/>
          {Translate.get('sal.collection.accessory.add')}
        </div>
      }
    </div>

    <ul className="list">
      {accessories.map((accessory, index) =>
        <li key={index}>
          <div className="row">
            <div key="icon"><div className="icon accessory"/></div>
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'accessories.' + index + '.name'}
                        title='sal.collection.accessory.name'
                        className="flex8c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'accessories.' + index + '.vinOrSerial'}
                        title='sal.collection.accessory.vinOrSerial'
                        className="flex2c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'accessories.' + index + '.productionYear'}
                        title='sal.collection.accessory.productionYear'
                        className="flex2c" />
            <Input.Text data={props.data}
                        showEmpty={true}
                        disabled={props.disabled}
                        path={'accessories.' + index + '.purchasePrice'}
                        title='sal.collection.accessory.purchasePrice'
                        className="flex2c" />
            {!props.disabled &&
              <div className="button right"
                   title={Translate.get('sal.collection.accessory.remove')}
                   onClick={event => props.data.removeFromArray('accessories', index)}>
                <div className="icon cancel"/>
              </div>
            }
          </div>
        </li>
      )}
    </ul>
  </div>
}

export default Accessories
