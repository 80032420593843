import React, { Component } from 'react'
import Column from '../costra/Column.js'
import Formatter from '../costra/Formatter.js'
import Translate  from '../costra/Translate.js';

import StateIcons from '../utils/StateIcons.js'
import Metrics from '../utils/Metrics.js'
import Nav from '../utils/Nav.js'

import {PageDataContext} from '../PageDataContext.js'
import VwFsProperties from './vw/fs/VwFsProperties.js'

class CaseObject extends Component {

  static states = [
    {value: 'Unknown', title: 'caseobject.state.unknown'},
    {value: 'Checked', title: 'caseobject.state.Checked' },
    {value: 'CustomsDepot', title: 'caseobject.state.CustomsDepot'},
    {value: 'OnSite', title: 'caseobject.state.OnSite'},
    {value: 'Borrowed', title: 'caseobject.state.Borrowed'},
    {value: 'Demo', title: 'caseobject.state.Demo' },
    {value: 'Sold', title: 'caseobject.state.Sold' },
    {value: 'Repair', title: 'caseobject.state.Repair' },
    {value: 'EnRoute', title: 'caseobject.state.EnRoute' },
    {value: 'Transfer', title: 'caseobject.state.Transfer'},
    {value: 'Certification', title: 'caseobject.state.Certification'},
    {value: 'Transport', title: 'caseobject.state.Transport'},
    {value: 'NotFound', title: 'caseobject.state.NotFound'},
    {value: 'Cancelled', title: 'caseobject.state.Cancelled'},
  ]

  static technicalCondition = [
    {value: 'Unknown', title: 'caseobject.technicalCondition.unknown', score: 1},
    {value: 'Good', title: 'caseobject.technicalCondition.Good', score: 0},
    {value: 'MinorDamage', title: 'caseobject.technicalCondition.MinorDamage', score: 0},
    {value: 'MajorDamage', title: 'caseobject.technicalCondition.MajorDamage', score: 2},
    {value: 'Trashed', title: 'caseobject.technicalCondition.Trashed', score: 2},
  ]

  static go(event, id, newTab) {
    Nav.go(event, '/caseObject/' + id, newTab)
  }

  static create(event, parentId) {
    Nav.go(event, '/caseObject/new/' + parentId)
  }

  static columns = Column.createMap([
    new Column('icon', "file", (item) => {
      switch(item._class) {
        case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
          return <div className="icon car"/>
        case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectVtp':
          return <div className="icon document"/>
        default:
          return <div className="icon car"/>
      }
    }),
    new Column('name', Translate.get('caseobject.properties.objectName'), (item) => {
      switch(item._class) {
        case 'com.optimsys.costra.kordex.caseobject.erl.telephony.TelCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.KvCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RetCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RupCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.TelCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.WcCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.ZamCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkWcCaseObject':  
        case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkTelCaseObject': 
          return  item.vehicleInfo.name
        default:
          return item.name
      }
    }, true),
    new Column('campaign', Translate.get('caserecord.campaign'), item =>
      <PageDataContext.Consumer>
        {pageData => pageData.campaigns[item.campaignId.$oid]}
      </PageDataContext.Consumer>,
      true
    ),
    Column.primitive('vtpNo', Translate.get('caseobject.properties.vtp')),
    Column.primitive('sfrNo', Translate.get('caseobject.sal.sfcar.sfrNo')),
    Column.primitive('checkNo', Translate.get('caseobject.sal.sfcar.checkNo')),
    new Column('vin',Translate.get('caseobject.properties.vin') , (item) => {
      switch(item._class) {
        case 'com.optimsys.costra.kordex.caseobject.erl.telephony.TelCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.KvCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RetCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RupCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.TelCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.WcCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.ZamCaseObject':
        case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkWcCaseObject':  
        case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkTelCaseObject': 
          return  item.vehicleInfo.vin
        default:
          return item.vin
      }
    }, true),
    //Column.primitive('vin', 'VIN'),
    //new Column('checkupState', Translate.get('caseobject.object.state'),
    //  (item) => item.checkupState && Translate.get(CaseObject.states.find(obj => obj.value === item.checkupState).title)),
    Column.select('checkupState',  Translate.get('caseobject.properties.checkupState'), CaseObject.states),
    Column.select('technicalCondition', Translate.get('caseobject.properties.technicalCondition'), CaseObject.technicalCondition),
    Column.dateTime('processing.created', Translate.get('caserecord.processing.created'), '%d.%m. %yyyy %HH:%MM', true),
    Column.dateTime('processing.finished', Translate.get('caserecord.processing.finished'), '%d.%m. %yyyy %HH:%MM', true),
    new Column('stateIcons', Translate.get('caserecord.stateIcons'), (item) => <StateIcons item={item} />),
    new Column('gps.distance', Translate.get('caserecord.distance'), (item) =>
      (item.gps && item.gps.distance && Number(item.gps.distance).toFixed(2))),
    new Column("address", Translate.get('contactData.address.title'), (item) =>item.address && Formatter.address(item.address)),
    new Column('usage', Translate.get('caseobject.erl.pred.usage'),
      (item) => item.usage && Translate.get('caseobject.erl.pred.carUsage.' + item.usage)),
    new Column('workingCondition', Translate.get('caseobject.erl.pred.workingCondition'),
      (item) => item.workingCondition && Translate.get('caseobject.erl.pred.workingCondition.' + item.workingCondition)),
    new Column('productionYear', Translate.get('caseobject.properties.productionYear', true),
      (item) => item.productionYear &&  item.productionYear),
    new Column('distributor.displayName', Translate.get('contactData.relation.distributor'),
      (item) => item.distributor && item.distributor.displayName && item.distributor.displayName),
    new Column('odometerState', Translate.get('caseobject.erl.pred.odometerState'),
      (item) => item.odometerState),
    new Column('variable', Translate.get('caseobject.sal.sfvtp.variable'),
      (item) => item.variable  && item.variable),  
    new Column('vtpNo', Translate.get('caseobject.sal.sfvtp.vtpNo'),
      (item) => item.vtpNo  && item.vtpNo),
    new Column('tpState', Translate.get('caseobject.vw.fs.tpState'),
      (item) => item.tpState  && VwFsProperties.tpStates.find(obj => obj.value === item.tpState).title),
    new Column('isClientOwnerOfVTP', Translate.get('caseobject.vw.fs.isClientOwnerOfVTP'),
      (item) => item.isClientOwnerOfVTP  && Translate.get(item.isClientOwnerOfVTP ? 'yes' : 'no')),
    new Column('wantsVwToBeOwnerOfVTP', Translate.get('caseobject.vw.fs.wantsVwToBeOwnerOfVTP'),
      (item) => item.wantsVwToBeOwnerOfVTP  && Translate.get(item.wantsVwToBeOwnerOfVTP ? 'yes' : 'no')),
    new Column('dealer', Translate.get('caseobject.vw.fs.dealer'),
      (item) => item.dealer  && item.dealer),
    new Column('vehicle.purchasePrize', Translate.get('caseobject.vw.fs.purchasePrize'),
      (item) => item.vehicle  && item.vehicle.purchasePrize  && item.vehicle.purchasePrize),
    new Column('vehicle.vehicleType', Translate.get('caseobject.vw.fs.vehicleType'),
      (item) => item.vehicle  && item.vehicle.vehicleType  && item.vehicle.vehicleType),
    new Column('vehicle.vehicleModel', Translate.get('caseobject.vw.fs.vehicleModel'),
      (item) => item.vehicle  && item.vehicle.vehicleModel  && item.vehicle.vehicleModel),
    new Column('vehicle.vehicleEngineVolume', Translate.get('caseobject.vw.fs.vehicleEngineVolume'),
      (item) => item.vehicle  && item.vehicle.vehicleEngineVolume  && item.vehicle.vehicleEngineVolume),
    new Column('vehicle.vtp', Translate.get('caseobject.properties.vtp'),
      (item) => item.vehicle  && item.vehicle.vtp  && item.vehicle.vtp),
    new Column('vehicle.licenseNumber', Translate.get('caseobject.properties.licenseNumber'),
      (item) => item.vehicle  && item.vehicle.licenseNumber  && item.vehicle.licenseNumber),
  ])

  static defaultLayout = [
    {name: 'icon'},
    {name: 'campaign', extraClass: 'size3c'},
    {name: 'name', extraClass: 'size8c'},
    {name: 'stateIcons', extraClass: 'size1c'},
    {name: 'processing.finished', extraClass: 'size3c'},
    {name: 'address', extraClass: 'size4c'},
  ]

  static scoreColor(item) {
    let result = ''
    switch (item._class) {
      case 'com.optimsys.costra.kordex.caseobject.erl.pred.PredCaseObject':
      case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
        if(item.processing.score !== undefined) {
          result = Metrics.ScoreColor(item.processing.score)
        }
        break;
      default:
    }
    return result
  }

  static Row(props) {
    var className = 'row' + (props.item.allowView ? ' withHover' : '') + (' ' + Metrics.CheckStatus(props.item.state))
    return <tr className={className}
               onClick={(event) => props.item.allowView && CaseObject.go(event, props.item.item._id.$oid)}>
      <td className={Metrics.CheckStatus(props.item.state)}></td>
      <td>{<div className={"ratingIcon " + CaseObject.scoreColor(props.item.item? props.item.item : props.item)}/>}</td>

      {Column.render(CaseObject.columns, props.layout, props.item.item? props.item.item : props.item)}
      <td>
        <div className="">
          <div className="button"
              key="open"
              title={Translate.get('caseobject.object.button.title')}
              onClick={(event) => CaseObject.go(event, props.item.item._id.$oid, true)}>
            <div className="icon newWindow"/>
          </div>
        </div>
      </td>
    </tr>
  }
}

export default CaseObject