import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Container from '../costra/Container.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'

import Nav from '../utils/Nav.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import TaskEditDialog from '../tasks/TaskEditDialog.js'
import UserSelect from './UserSelect.js'
import TimeSelect from './TimeSelect.js'
import { PageDataContext } from '../PageDataContext.js'
import DropBox from '../dropbox/DropBox.js'
import Flag from '../flags/Flag.js'
import Task from './Task.js'

import '../css/icons.css'
import '../css/taskPanel.css'

class TasksPanel extends Component {

  constructor(props) {
    super(props)
    this.state = {
      campaignIds: [],
      userIds: [],
      timeInterval: undefined,
    }
    this.container = new Container(this, 'com.optimsys.costra.kordex.task.TasksPanel')
    this.data = new EditData(this, '')
  }

  componentDidMount() {
    this.loadConfig()
  }

  componentWillUnmount() {
    this.container.close()
  }

  tellContainer() {
    this.container.tell({
      _class: 'com.optimsys.costra.kordex.task.TasksPanel$Get',
      campaignIds: this.state.campaignIds,
      userIds: this.state.userIds,
      timeInterval: this.state.timeInterval
    })
  }

  loadConfig() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.LoadPreset',
      name: 'rightPanelConfig',
    }).then((result) => {
      if (result.data) {
        this.setState({
          campaignIds: result.data[0].campaignIds,
          userIds: result.data[0].userIds.length === 0 ? [this.context.currentUser.id.$oid] : result.data[0].userIds,
          timeInterval: result.data[0].timeInterval,
        }, () => this.tellContainer())
      } else {
        // Defaulting - all campaigns, currentUser, all times
        this.setState({
          campaignIds: Object.keys(this.context.accessibleCampaigns ? this.context.accessibleCampaigns : []),
          userIds: [this.context.currentUser.id],
          timeInterval: 'All',
        }, () => this.tellContainer())
      }
    }, () => this.setState({error: 'genericLoadError'}))
  }

  applyConfig() {
    this.setState({items: null}, () => {
      const data = [{
        userIds: this.state.userIds,
        timeInterval: this.state.timeInterval
      }]
      WsContainers.transaction({
        _class: 'com.optimsys.costra.kordex.presets.StorePreset',
        name: 'rightPanelConfig',
        data: data,
      }).then(() => {
        this.tellContainer()
      }, () => {
        this.setState({error: 'genericStoreError'})
      })
    })
  }

  filterTasksForCampaign(item) {
    if (item === "all") {
      this.setState({
        campaignIds: this.state.campaignIds.length === Object.values(this.context.accessibleCampaigns).length ?
        [] :
        Object.keys(this.context.accessibleCampaigns)
      }, () => this.tellContainer())
    }
    else {
      const campaignId = item._id
      this.data.toggleObjectInArray('campaignIds', campaignId, !this.data.isObjectInArray('campaignIds', campaignId))
      this.tellContainer()
    }
  }

  filterTasksForUser(item) {
    if (item === "all") {
      this.setState(
        {
          userIds: this.state.userIds.length === Object.values(this.context.users).filter(user => user.login.indexOf('inactive') === -1).length ? [] :
            this.context.users && Object.values(this.context.users)
            .filter(user => user.login.indexOf('inactive') === -1)
            .map(e => e.id.$oid)
        },
        this.tellContainer
      )
    }
    else {
      const userId = item.id
      this.data.toggleObjectInArray('userIds', userId, !this.data.isObjectInArray('userIds', userId))
      this.tellContainer()
    }
  }

  filterTasksForTime(item) {
    this.data.set('timeInterval', item.value)
    this.tellContainer()
  }

  renderItem(campaignId, item) {
    switch(item.itemType) {
      case 'Task' :
        return <div key={item.className + item.priority}
                    className="task"
                    onClick={(event) => Nav.go(event, '/task/' + campaignId + '/' + item.className.substring(32))}>
          <Task.Icon taskClass={item.className} />
          <div className="name">{Translate.get(item.className)}</div>
          <div className="count">{item.count}</div>
        </div>
      case 'CaseFlag' :
        return <div key={item.className + item.priority}
                    className="flag"
                    onClick={(event) => Nav.go(event, '/flag/case/' + campaignId + '/' + item.className.substring(32))}>
          <Flag.Icon flagClass={item.className} />
          <div className="name">{Translate.get(item.className)}</div>
          <div className="count">{item.count}</div>
        </div>
      case 'ObjectFlag' :
        return <div key={item.className + item.itemType + item.priority}
                    className="flag"
                    onClick={(event) => Nav.go(event, '/flag/object/' + campaignId + '/' + item.className.substring(32))}>
          <Flag.Icon flagClass={item.className} />
          <div className="name">{Translate.get(item.className)}</div>
          <div className="count">{item.count}</div>
        </div>
      case 'DropBox' :
        return <div key={item.className + item.priority}
                    className="flag"
                    onClick={(event) => DropBox.go(event, item.id.$oid)}>
          <div className="icon dropBox" />
          <div className="name">{item.name}</div>
          <div className="count">{item.count}</div>
        </div>
      case 'CommLog' :
        return <div key={item.className + item.priority}
                    className="task"
                    onClick={(event) => Nav.go(event, '/commLog/new')}>
          <div className="icon call" />
          <div className="name">{Translate.get('tasksPanel.commLog')}</div>
          <div className="count">{item.count}</div>
        </div>
      default:
         return <div key={item.className + item.priority}>{item.itemType}</div>
    }
  }

  renderContent() {
    if (!this.state.items) {
      return <LoadingPlaceholder/>
    }
    let sortedCampaigns = Object.entries(this.context.accessibleCampaigns || [])
            .sort(([,a], [,b]) => a.localeCompare(b))
            .map(pair => { return {id: pair[0], displayName: pair[1]}})
    sortedCampaigns.push({id: '000000000000000000000000', displayName: Translate.get('tasksPanel.campaign.noCampaign')})
    return sortedCampaigns.map(campaign =>
      this.state.items[campaign.id] && <div key={campaign.id}>
        <h3>{campaign.displayName}</h3>
        {this.state.items[campaign.id].map(item => this.renderItem(campaign.id, item))}
      </div>
    )
  }

  addTask() {
    let now = +new Date()
    let task = {
      _class : 'com.optimsys.costra.kordex.task.GenericCaseTask',
      userId : this.context.currentUser.id,
      createdAt : now,
      deadlineAt : now,
    }
    return Dialogs.open(
      <TaskEditDialog task={task}
                      users={this.context.users}/>
    )
  }

  render() {
    // TODO -> this need to be done on server via isActive flag
    let sortedUsers = Object.values(this.context.users)
        .filter(user => user.login.indexOf('inactive') === -1)
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    return (
      <div className={'taskPanel' + (this.context ? ' withCallPanel' : '')}>
        <div className="top">
          <UserSelect data={this.data}
                      items={sortedUsers}
                      apply={() => this.applyConfig()}/>
          <TimeSelect data={this.data}
                      apply={() => this.applyConfig()}/>
          <div className="select-with-dropdown">
            <div className="name"
                 onClick={() => this.addTask()}>
              {Translate.get('tasksPanel.button.addTask')}
            </div>
          </div>
        </div>
        <div className="scrollable">
          {this.renderContent()}
        </div>
        <div className="bottom"></div>
      </div>
    )
  }
}
TasksPanel.contextType = PageDataContext

export default TasksPanel