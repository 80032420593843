import React from 'react'
import Translate from '../costra/Translate.js'

class ImportError {

  static Row(props) {
    return <>
      <div className="row">
        <div><div className="icon error"/></div>
        <div>{Translate.get('imports.cannotImportRecord')}</div>
      </div>
      <ul className="list spaced">
        {props.item.errors && props.item.errors.map((error, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon cancel"/></div>
              <div className="size6c">{error.fileName}</div>
              <div className="size2c">{error.position}</div>
              <div className="size4c">{error.title}</div>
              <div className="size4c">{error.value}</div>
              <div className="size4c">{Translate.get(error.code)}</div>
            </div>
          </li>
        )}
      </ul>
    </>
  }
}

export default ImportError