import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import CaseRecord from '../../CaseRecord.js'
import '../../../css/form.css'

function SalCollectionProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection case" />
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="caseNo"
                   title='caserecord.properties.caseNo'
                   validate={Validator.notEmptyString} />
        {props.editOpen && <>
          <Form.Select data={props.data}
                       disabled={!props.editOpen}
                       path="source"
                       title="sal.source"
                       values={CaseRecord.sources}/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.evNum"
                     title="caserecord.sal.evNum"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.documentId"
                     title="caserecord.sal.documentId"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.eventId"
                     title="caserecord.sal.eventId"/>
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="sal.fk"
                     title="caserecord.sal.fk"/>
        </>}

        <Form.DateTime data={props.data}
                        disabled={true}
                        path="processing.created"
                        title='caserecord.processing.created'
                        format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                        disabled={!props.editOpen}
                        path="plannedEnding"
                        title='caserecord.properties.plannedEnding'
                        format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                        disabled={true}
                        path="processing.finished"
                        title='caserecord.processing.finished'
                        format="%d.%m.%yyyy" />
      </div>
    </div>

    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection log" />
        <h3>{Translate.get('caserecord.contract.title')}</h3>
      </div>
      <div className="content">
        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="contract.signatureDate"
                       title='caserecord.properties.signatureDate'
                       format="%d.%m.%yyyy" />
        <Form.DateTime data={props.data}
                       disabled={!props.editOpen}
                       path="contract.endDate"
                       title='caserecord.properties.contractEndDate'
                       format="%d.%m.%yyyy" />
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="contract.paymentAmount"
                   title='caserecord.properties.paymentAmount'/>
        <Form.Text data={props.data}
                   disabled={!props.editOpen}
                   path="contract.payments"
                   title='caserecord.properties.numberOfPayments'/>
      </div>
    </div>

    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default SalCollectionProperties