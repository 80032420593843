import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import TextInputArray from '../utils/TextInputArray.js'
import UsersArray from '../users/UsersArray.js'

import { PageDataContext } from '../PageDataContext.js'
import DropBox from './DropBox.js'

import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen : false,
    }
    this.data = new EditData(this, 'item.')
  }

  create() {
    return WsContainers.action({
      _class: 'com.optimsys.costra.kordex.dropbox.PrepareForCreation',
      campaignId: this.props.campaignId,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({error: 'genericStoreError'})
    })
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.dropbox.FetchDropBox',
      id : this.props.id,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({error: 'genericLoadError' })
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.dropbox.UpdateDropBox',
      item : this.state.item,
    }).then((result) => {
      if (this.props.id === result.item._id.$oid) {
        result.editOpen = false
        this.setState(result)
      }
      else {
        DropBox.go(null, result.item._id.$oid)
      }
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }

  componentDidMount() {
    if (this.props.id === 'new') {
      this.create()
    }
    else {
      this.load()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  toggleSalStorage(event) {
    if (event.target.checked) {
      this.data.set('salStorageConfig', { directory : '', isInbound : false, _class: 'com.optimsys.costra.kordex.dropbox.SalStorageConfig' })
    }
    else {
      this.data.set('salStorageConfig', undefined)
    }
  }

  renderSalStorageToggle() {
    if (!this.state.editOpen) {
      return <div className="formRow">
        <label>{Translate.get('dropbox.properties.sal.useStorage')}</label>
        <div className="inputReplacement formInput"
            title={Translate.get('dropbox.properties.sal.useStorage')}>
          {Translate.get(this.state.item.salStorageConfig ? 'true' : 'false')}
        </div>
      </div>
    }

    return <div className="formRow">
      <label>{Translate.get('dropbox.properties.sal.useStorage')}</label>
      <input type="checkBox"
             className="formInput"
             title={Translate.get('dropbox.properties.sal.useStorage')}
             checked={this.state.item.salStorageConfig && true}
             onChange={(event) => this.toggleSalStorage(event)}/>
    </div>
  }

  render() {
    if (!this.state.item) {
      return <LoadingPlaceholder/>
    }
    return <div className="section spacedTop">
      <div className="buttons">
        <EditButtons component={this}/>
      </div>
      <div className="subsection">
        <div className="header-row">
          <div className="icon iconSection addressBookItem" />
          <h3>{Translate.get('dropbox.properties.title')}</h3>
        </div>

        <div className="content">
          <Form.Text data={this.data}
                     disabled={!this.state.editOpen}
                     path="name"
                     title='dropbox.properties.name'/>
          <Form.Check data={this.data}
                      disabled={!this.state.editOpen}
                      path="allowImports"
                      title='dropbox.properties.allowImports'/>
          {this.renderSalStorageToggle()}
        </div>
        
        {this.state.item.salStorageConfig &&
          <div className='content'>
            <Form.Text data={this.data}
                       disabled={!this.state.editOpen}
                       path="salStorageConfig.directory"
                       title='dropbox.properties.sal.directory'/>
            <Form.Check data={this.data}
                        disabled={!this.state.editOpen}
                        path="salStorageConfig.isInbound"
                        title='dropbox.properties.sal.isInbound'/>
          </div>
        }
      </div> 
      <TextInputArray data={this.data}
                      disabled={!this.state.editOpen}
                      path="notifiedMails"
                      title='dropbox.properties.notifiedMails'
                      icon="email"
                      newValue={() => ''}/>

      <UsersArray data={this.data}
                    disabled={!this.state.editOpen}
                    path="notifiedUsers"
                    title='dropbox.properties.notifiedUsers'
                    icon="user"/>                    
    </div>
  }
}
Properties.contextType = PageDataContext

export default Properties
