import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Translate from '../costra/Translate.js'

import Form from '../utils/Form.js'
import EditButtons from '../utils/EditButtons.js'
import EditConfirmDialog from '../utils/EditConfirmDialog.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import Contacts from '../addressbook/Contacts.js'

import ErlPredProperties from './erl/pred/PredProperties.js'
import SalCollectionProperties from './sal/collection/CollectionProperties.js'
import SalSfProperties from './sal/sf/SfProperties.js'
import SalTelProperties from './sal/telephony/TelProperties.js'
import SalPisProperties from './sal/pis/PisProperties.js'
import SalPisSkProperties from './sal/pis/PisSkProperties.js'
import VwFsProperties from './vw/fs/VwFsProperties.js'
import UcbTelProperties from './ucb/telephony/TelProperties.js'
import CofidisCollectionProperties from './cofidis/collection/CollectionProperties.js'

/*
import ErlTelProperties from './erl/ErlTelProperties.js'
import TelCaseEdit from './lcs/telephony/TelCaseEdit.js'
import KvCaseEdit from './lcs/telephony/KvCaseEdit.js'
import RetCaseEdit from './lcs/telephony/RetCaseEdit.js'
import RupCaseEdit from './lcs/telephony/RupCaseEdit.js'
import WcCaseEdit from './lcs/telephony/WcCaseEdit.js'
import ZamCaseEdit from './lcs/telephony/ZamCaseEdit.js'
import SkTelCaseEdit from './sal/telephony/SkTelCaseEdit.js'
import SkWcCaseEdit from './sal/telephony/SkWcCaseEdit.js'*/

//import DateHelper from '../costra/DateHelper.js'

import '../css/form.css'

class Properties extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      editOpen: false,
      error: null,
    }
    this.data = new EditData(this, 'item.')
    this.addressBookData = new EditData(this, 'addressBook.')
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caserecord.FetchCase',
      id: this.props.id,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'caserecord.propertiesEdit.loadError' })
    })
  }

  save() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.caserecord.UpdateCase',
      item: this.state.item,
    }).then((result) => {
      result.editOpen = false
      this.setState(result)
      return result
    }, () => {
      EditConfirmDialog(this)
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderCommonTimes() {
    return <div>
      <Form.DateTime data={this.data}
        disabled={true}
        path="processing.created"
        title='caserecord.propertiesEdit.form.title.create'
        format="%d.%m.%yyyy %HH:%MM" />
      <Form.DateTime data={this.data}
        disabled={!this.state.editOpen}
        path="plannedEnding"
        title='caserecord.propertiesEdit.form.title.plannedEnding'
        format="%d.%m.%yyyy %HH:%MM" />
      <Form.DateTime data={this.data}
        disabled={true}
        path="processing.finished"
        title='caserecord.propertiesEdit.form.title.finished'
        format="%d.%m.%yyyy %HH:%MM" />
    </div>
  }

  renderContent() {
    switch (this.state.item._class) {

      case 'com.optimsys.costra.kordex.caserecord.erl.pred.PredCase':
        return <ErlPredProperties data={this.data}
                                  item={this.state.item}
                                  editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.sal.sf.SfCase':
        return <SalSfProperties data={this.data}
                                item={this.state.item}
                                editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisCase':
        return <SalPisProperties data={this.data}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.sal.pis.PisSKCase':
        return <SalPisSkProperties data={this.data}
                                   item={this.state.item}
                                   editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.vw.fs.VwFsCase':
        return <VwFsProperties data={this.data}
                               item={this.state.item}
                               editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.sal.telephony.TelCase':
        return <SalTelProperties data={this.data}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.sal.collection.CollectionCase':
        return <SalCollectionProperties data={this.data}
                                        item={this.state.item}
                                        editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.ucb.telephony.TelCase':
        return <UcbTelProperties data={this.data}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.cofidis.collection.CollectionCase':
        return <CofidisCollectionProperties data={this.data}
                                            item={this.state.item}
                                            editOpen={this.state.editOpen}/>

      /*
      case 'com.optimsys.costra.kordex.caserecord.erl.telephony.TelCase':
        return <ErlTelProperties data={this.data}
                                 item={this.state.item}
                                 editOpen={this.state.editOpen}/>

      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.KvCase':
        return <KvCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.RetCase':
        return <RetCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.RupCase':
        return <RupCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.TelCase':
        return <TelCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.WcCase':
        return <WcCaseEdit title={this.props.title}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.lcs.telephony.ZamCase':
        return <ZamCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.sal.telephony.SkWcCase':
        return <SkWcCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      case 'com.optimsys.costra.kordex.caserecord.sal.telephony.SkTelCampaign':
        return <SkTelCaseEdit title={this.props.title}
            renderButtonsForSection={() => this.renderButtonsForSection(this.props)}
            component={this}
            item={this.state.item}
            users={this.state.users.operators}
            editOpen={this.state.editOpen}/>
      */

      default:
        return <div>{this.state.item._class}</div>
    }
  }

  render() {
    if (!this.state.item) {
      return <div className="section spacedTop"><LoadingPlaceholder /></div>
    }
    if (this.state.error) {
      return <div className="section spacedTop">{Translate.get(this.state.error)}</div>
    }
    return <>
      <div className="section spacedTop">
        <div className="buttons">
          <EditButtons component={this}/>
        </div>
        {this.renderContent()}
      </div>

      {this.state.addressBook &&
        <div className="section spacedTop">
          {this.state.addressBook.map((item, itemIndex) =>
            <div key={itemIndex} className="subsection">
              <div className="header-row">
                <div className="icon iconSection  addressBookItem" />
                <h3>{Translate.get('caserecord.addressBookContacts')}: {item.displayName}</h3>
              </div>
              <Contacts data={this.addressBookData}
                        path={itemIndex + '.contacts'}
                        disabled={true}/>
            </div>
          )}
        </div>
      }
    </>
  }
}

export default Properties
