import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'

import ContinuationDialog from './ContinuationDialog.js'
import '../css/dialogs.css';

function Item(props) {
  var clickProc = () => {
    Dialogs.close(null)
    Dialogs.open(
      <ContinuationDialog id={props.objectRef.id}
                          continuationClass="com.optimsys.costra.kordex.caseobject.GetObjectContinuation"/>
    )
  }
  return <div className='nextStep row withHover'
              onClick={clickProc}>
    <div className="flexRest inline">
      <div className='card'>
        <div className='instruction'>{props.objectRef.name}</div>
      </div>
    </div>
  </div>
}

function ObjectContinuationDialog(props) {
  return <div className="dialogsContainer">
    <div className="dialog wide frame">
      <div className="title">
        <span>{Translate.get('caserecord.continuation.title')}</span>
      </div>
      <ul className="list spacedBottom">
        {props.objectsRefs && props.objectsRefs.map((objectRef) =>
          <li key={objectRef.id.$oid}>
            <Item objectRef={objectRef}/>
          </li>
        )}
      </ul>
      <div className="buttons">
        <div className="button"
             title={Translate.get('close')}
             onClick={(event) => Dialogs.close(null)}>
          <div className="icon iconBig cancel" />
        </div>
     </div>
    </div>
  </div>
}

export default ObjectContinuationDialog
 