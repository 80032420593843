import React, { Component } from 'react';
import LoadingPlaceholder from './LoadingPlaceholder.js'

class List extends Component {

  render() {
    if ((this.props.list === null || this.props.list === undefined) && !this.props.notNull) {
      return <LoadingPlaceholder/>
    }
    else if (!this.props.list || this.props.list.length <= 0) {
      return null
    }
    else return <ul className={this.props.extraClass? this.props.extraClass + " list" : "list"}>
      {this.props.list.map((item, index) => {
        var key = index
        if (item.item && item.item._id) {
          key = item.item._id.$oid
        }
        else if (item && item._id) {
          key = item._id.$oid
        }
        return <li key={key}>{this.props.itemFn(item, index)}</li>
      })}
      {this.props.children}
    </ul>
  }
}

export default List