import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import Container from '../costra/Container.js'
import Dialogs from '../costra/Dialogs.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import ContinuationDialog from '../processing/ContinuationDialog.js'

import { PageDataContext } from '../PageDataContext.js'
import AnyItem from './AnyItem.js'
import '../css/icons.css'
import '../css/form.css'

const filters = ['events',  'flags', 'tasks', 'payments', 'files', 'calls', 'mails', 'sms', 'importExport', 'daySeparators']

class Events extends Component {

  constructor(props) {
    super(props)    
    var propsFilters = {}
    filters.forEach(category =>
      propsFilters[category] = true
    )
    this.state = {
      filters: propsFilters,
    }
    this.container = new Container(this, this.props.containerClass)
    this.showContinuationDialog = this.showContinuationDialog.bind(this)
  }

  componentDidMount() {
    if (this.props.id !== 'new') {
      this.container.tell({
        _class: this.props.containerClass + '$Get',
        id: this.props.id,
      })
    }
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.componentDidMount()
    }
  }

  toggleFilterCategory(category) {
    var newFilters = this.state.filters
    newFilters[category] = !this.state.filters[category]
    this.setState({filters: newFilters})
  }

  showContinuationDialog(event) {
    Dialogs.open(<ContinuationDialog id={this.props.id} continuationClass={this.props.continuationClass}/>)
  }

  render() {
    if (!this.state._class) {
      return <LoadingPlaceholder/>
    }
    return <>
      <div className="listHeader spacedTop">
        <div className='events__filter'>
          {filters.map(category =>
            <div key={category}>
              {Translate.get('events.filter.' + category)}
              <input type="checkbox"
                     checked={this.state.filters[category]}
                     onChange={()=> this.toggleFilterCategory(category)}/>
            </div>
          )}
        </div>
        <div>
          <div className="button right"
               onClick={this.showContinuationDialog}>
            <div className="icon left reload"/>
            Pokračovat
          </div>
        </div>
      </div>
      <ul className="list level1">
        <li>
          <div className="row rowHeader ">
            <div><div className="icon case"/></div>
            <div className="flexRest">{Translate.get('events.header.description')}</div>
          </div>
        </li>
        {this.state.items && this.state.items.map((item, i) =>
          <li key={item.item._id ? item.item._id.$oid : i}>
            <AnyItem item={item} filter={this.state.filters}/>
          </li>
        )}
      </ul>
    </>
  }
}

Events.contextType = PageDataContext

export default Events
