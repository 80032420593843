import React from 'react'
import Column from '../costra/Column.js'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'

class Item {

  static go(event, id, newTab) {
    Nav.go(event, '/addressBook/' + id, newTab)
  }

  static create(event) {
    Nav.go(event, '/addressBook/new')
  }

  static legalTypes = () =>[
    {value: 'Unknown', title: 'legalType.unknown' },
    {value: 'FON', title: 'legalType.FON'},
    {value: 'FOP', title: 'legalType.FOP'},
    {value: 'SRO', title: 'legalType.SRO'},
    {value: 'AS', title: 'legalType.AS'},
    {value: 'SE', title: 'legalType.SE'},
    {value: 'KS', title: 'legalType.KS'},
    {value: 'VOS', title: 'legalType.VOS'},
    {value: 'DRU', title: 'legalType.DRU'},
    {value: 'SP', title: 'legalType.SP'},
    {value: 'OPS', title: 'legalType.OPS'},
    {value: 'NAD', title: 'legalType.NAD'},
    {value: 'PRI', title: 'legalType.PRI'},
    {value: 'ROZ', title: 'legalType.ROZ'},
    {value: 'URA', title: 'legalType.URA'},
    {value: 'ZAO', title: 'legalType.ZAO'},
    {value: 'FOR', title: 'legalType.FOR'},
  ]

  static columns = Column.createMap([
    Column.element('icon', <div className="icon addressBookItem"/>, <div className="icon addressBookItem"/>),
    Column.primitive('displayName', 'Jméno'),
  ])

  static defaultLayout = [
    {name: 'icon'},
    {name: 'displayName', extraClass: 'size3c'},
  ]

  static Row(props) {
    var classes = 'row'
    var isActive = props.item.item && props.item.item.extensions && props.item.item.extensions.kordex &&
      (props.item.item.extensions.kordex.activeCampaignIds || props.item.item.extensions.kordex.isReferencedElsewhere)
    if (!isActive) {
      classes += ' disabled'
    }
    return <tr className={classes + ' withHover'}
                onClick={(event) => Item.go(event, props.item.item._id.$oid)}>
      <td/>
      {Column.render(Item.columns, props.layout, props.item.item? props.item.item : props.item)}
      <td>
        <div className="right button"
            key="open"
            title= {Translate.get('openInNewWindow')}
            onClick={(event) => Item.go(event, props.item.item._id.$oid, true)}>
          <div className="icon newWindow"/>
        </div>
      </td>
      
    </tr>
  }
}

export default Item