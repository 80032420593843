import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import ObjectHelper from '../costra/ObjectHelper.js'
import Translate from '../costra/Translate.js'

class filter extends Component {

  dateFilter(name, title, sequence) {
    var range = this.props.filter[name]
    if (!range) {
      return
    }
    switch(range.mode) {
      case 'Range':
        if (range.from || range.to) {
          sequence.push({name: name, title: title})
        }
        break;
      case 'Unset':
        sequence.push({name: name, title: title})
        break;
      default:
        if (range.from) {
          sequence.push({name: name, title: title})
        }
    }
  }

  arrayFilter(name, title, sequence) {
    var list = this.props.filter[name]
    if (!list) {
      return
    }
    if (list.length > 0) {
      sequence.push({name: name, title: title})
    }
  }

  set(path, value) {
    this.setState({filter: ObjectHelper.set(this.state.filter, path, value)})
  }

  removeFilter(event, name) {
    var filter = this.props.filter
    delete filter[name]
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      name: this.props.name,
      data: [filter],
    }).then((result) => {
      this.props.update()
    },  () => {
      this.setState({error: Translate.get('exports.filterdialog.error')})
    })
  }

  render() {
    var sequence = []
    this.dateFilter('time',  'exports.filter.time', sequence)
    this.arrayFilter('campaigns',  'exports.filter.campaign', sequence)
    this.arrayFilter('exportType', 'exports.filter.exportType', sequence)

    if (sequence.length <= 0) {
      return null
    }

    return <div className="listHeader spacedTop">
      <div > 
        {sequence.map(part =>
          <div key={part.name}
              className="block"
              title={Translate.get(part.title)}>
            <div className="icon left filter"/>
            <span className="left">{Translate.get(part.title)}</span>
            <div className="button left"
                title={Translate.get('exports.filter.button.remove')}
                onClick={(event) => this.removeFilter(event, part.name)}>
              <div className="icon left cancel"/>
            </div>
          </div>
        )}
      </div>
    </div>
  }

}

export default filter