import React from 'react'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'

import Nav from '../utils/Nav.js'

class PreparedLines {

  static closeButton(component) {
    return <div className="button right"
         onClick={(event) => component.kill()}>
      <div className="icon left cancel"/>
      {Translate.get('imports.button.close')}
    </div>
  }

  static prepareButton(component) {
    return <div className="button right"
         onClick={(event) => component.prepare()}>
      <div className="icon left ok"/>
      {Translate.get('imports.button.continue')}
    </div>
  }

  static commitButton(component) {
    return <div className="button right"
         onClick={(event) => component.commit()}>
      <div className="icon left ok"/>
      {Translate.get('imports.button.continue')}
    </div>
  }

  static reportProtocolButton(id) {
    return <div className="button right"
         onClick={(event) => Nav.go(event, '/import/' + id.$oid)}>
      <div className="icon left import"/>
      {Translate.get('imports.button.protocol')}
    </div>
  }

  static exportBatchButton(title, id) {
    if (!id) {
      return null
    }
    return <div className="button right"
         onClick={(event) => Nav.go(event, '/export/batch/' + id.$oid)}>
      <div className="icon left export"/>
      {Translate.get(title)}
    </div>
  }

  static reportLine(title, value, icon) {
    if (!icon) {
      icon = 'ok'
    }
    return <li>
      <div className="row">
        <div><div className={'icon ' + icon}/></div>
        <div>{Translate.get(title)}: {value}</div>
      </div>
    </li>
  }

  static previewHeader(component) {
    return <div className="listHeader spacedTop">
      <div>
        <div className="icon iconBig import"/>
        <h2>{Translate.get('imports.preview.title')}</h2>
      </div>
      <div>
        {PreparedLines.commitButton(component)}
        {PreparedLines.closeButton(component)}
      </div>
    </div>
  }

  static notImportedRows(lines) {
    if (!lines) {
      return null
    }
    return lines.map((line, index) => {
      return <li key={index}>
        <div className="row">
          <div><div className="icon cancel"/></div>
          <div>{Translate.get('imports.preview.failedImport')}</div>
        </div>
        {PreparedLines.errorsList(line)}
      </li>
    })
  }

  static errorsList(item) {
    if (!item || !item.errors) {
      return null
    }
    return <div className="spaced">
      <ul className="list">
        {item.errors.map((error, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon cancel"/></div>
              <div className="size5c">{Translate.get(error.code)}</div>
              <div className="size8c">{error.fileName}</div>
              <div className="size2c">{error.position}</div>
              <div>{error.title}: {error.value}</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static caseRecord(item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon case"/></div>
      <div className="size2c">{Translate.get('imports.preview.case')}</div>
      <div className="size8c">{item.item.caseNo}</div>
    </div>
  }

  static standardPreview(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.caseRecords && data.caseRecords.map(caseRecord =>
          <li key={caseRecord.item._id.$oid}>
            {PreparedLines.caseRecord(caseRecord)}
            {PreparedLines.errorsList(caseRecord)}
            {caseRecord.objects && caseRecord.objects.map(caseObject => PreparedLines.errorsList(caseObject))}
            <div className="spaced">
              <ul className="list">
                {caseRecord.paymentPlans && caseRecord.paymentPlans.map(plan =>
                  <li key={plan.item._id.$oid}>{PreparedLines.paymentPlan(plan)}</li>
                )}
                {caseRecord.objects && caseRecord.objects.map(caseObject =>
                  <li key={caseObject.item._id.$oid}>{PreparedLines.caseObject(caseObject)}</li>
                )}
                <li>
                  {PreparedLines.contactDataItem('imports.preview.client', caseRecord.item.client)}
                  {PreparedLines.contactDataItemContacts(caseRecord.item.client)}
                  {PreparedLines.errorsList(caseRecord.client)}
                </li>
              </ul>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static standardPaymentsPreview(component, data) {
    return <div>
      {PreparedLines.previewHeader(component)}
      <ul className="list">
        {PreparedLines.notImportedRows(data.notImported)}
        {data.payments && data.payments.map((payment, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon coins"/></div>
              <div className="size2c">{Translate.get('imports.preview.payment')}</div>
              <div className="size4c">{payment.caseNo}</div>
              <div className="size4c">{payment.received.id}</div>
              <div className="size4c">
                {Formatter.formatAmount(payment.received.amount) + ' ' + payment.received.currency}
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static paymentPlan(item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon money"/></div>
      <div className="size5c">{Translate.get('imports.preview.paymentPlan')}</div>
      <div className="size6c">{item.item.invoices ? item.item.invoices.length : 0} {Translate.get('imports.preview.paymentPlan.rows')}</div>
    </div>
  }

  static contactDataItem(title, item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon addressBook"/></div>
      <div className="size5c">{Translate.get(title)}</div>
      <div className="size6c">{item.displayName}</div>
      {item.salPartnerId &&
        <div className="size4c">{Translate.get('imports.preview.salPartnerId')}: {item.salPartnerId}</div>
      }
    </div>
  }

  static contactDataItemContacts(item) {
    if (!item || !item.contacts) {
      return null
    }
    return <div className="spaced">
      <ul className="list">
        {item.contacts && item.contacts.map((contact, index) => PreparedLines.contact(contact, index))}
      </ul>
    </div>
  }

  static contact(contact, index) {
    switch(contact._class) {

      case "com.optimsys.costra.addressbook.contact.Address":
        return <li key={index}>
          <div className="row">
            <div><div className="icon address"/></div>
            <div className="size1c">{Translate.get('imports.preview.contact.address')}</div>
            <div className="size6c">
              {contact.street + ' ' +
                contact.streetCode + ' ' +
                contact.city + ' ' +
                contact.postCode}
            </div>
          </div>
        </li>

      case "com.optimsys.costra.addressbook.contact.Mail":
        return <li key={index}>
          <div className="row">
            <div><div className="icon email"/></div>
            <div className="size1c">{Translate.get('imports.preview.contact.mail')}</div>
            <div className="size6c">{contact.mailAddress}</div>
          </div>
        </li>

      case "com.optimsys.costra.addressbook.contact.Phone":
        return <li key={index}>
          <div className="row">
            <div><div className="icon phone"/></div>
            <div className="size1c">{Translate.get('imports.preview.contact.phone')}</div>
            <div className="size6c">{contact.phoneNum}</div>
          </div>
        </li>

      default:
        return <li key={index}>{contact._class}</li>
    }
  }

  static caseObject(item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon car"/></div>
      <div className="size5c">{Translate.get('imports.preview.object')}</div>
      <div className="size8c">{item.item.name}</div>
      {item.item.vin &&
        <div className="size4c">{item.item.vin}</div>
      }
      {item.item.licenseNumber &&
        <div className="size4c">{item.item.licenseNumber}</div>
      }
      {item.item.productionYear &&
        <div className="size4c">{item.item.productionYear}</div>
      }
    </div>
  }













  /*
  static importPrepared(component, data) {
    return <div className="header-row">
      <div className="icon iconSection import"/>
      <h2>
        {Translate.get('imports.preparedLines.h2.importPrepared')}
      </h2>
      <div className="buttons">
        <div className="button"
            onClick={(event) => component.commit()}>
          <div className="icon left ok"/>
          {Translate.get('imports.preparedLines.button.continue')}
        </div>
        <div className="button"
            onClick={(event) => component.kill()}>
          <div className="icon left cancel"/>
          {Translate.get('imports.preparedLines.button.cancel')}
        </div>
      </div>
    </div>
  }

  static importDone(component, data) {
    return <div className="header-row">     
      <div className="icon iconSection import"/>
      <h2>
        {Translate.get('imports.preparedLines.h2.importDone')}
      </h2>
      <div className="buttons">
        <div className="button"
            onClick={(event) => Nav.go(event, '/import/' + data.importRecordId.$oid)}>
          <div className="icon left import"/>
          {Translate.get('imports.preparedLines.button.protocol')}
        </div>
        <div className="button"
            onClick={(event) => component.kill()}>
          <div className="icon left cancel"/>
          {Translate.get('imports.preparedLines.button.cancel')}
        </div>
      </div>
    </div>
  }

  static errors(item) {
    if (!item || !item.errors) {
      return null
    }
    return <div className="spaced">
      <ul className="list">
        {item.errors.map((error, index) =>
          <li key={index}>
            <div className="row">
              <div><div className="icon cancel"/></div>
              <div className="size2c">{Translate.get('imports.preparedLines.row.error')}</div>
              <div className="size6c">{error.fileName}</div>
              <div className="size2c">{error.position}</div>
              <div className="size8c">{error.title}: {error.value}</div>
              <div className="size4c">{error.code}</div>
            </div>
          </li>
        )}
      </ul>
    </div>
  }

  static notImported(lines) {
    if (!lines) {
      return null
    }

    const numberOfEmptyLines = lines.filter(line => line.errors.length === line.errors.filter(err => err.value === "Empty line").length).length 
    const linesWithoutEmptyLines = lines.filter(line => line.errors.length === line.errors.filter(err => err.value !== "Empty line").length)

    return <> 
      { linesWithoutEmptyLines.map((line, index) => {
        return <li key={line.fileName + ':' + line.lineNo + "|" + index}>
          <div className="row">
            <div><div className="icon cancel"/></div>
            <div className="size4c">{Translate.get('imports.preparedLines.row.failedImport')}</div>
            <div className="size7c">{line.fileName}:{line.lineNo}</div>
          </div>
          {PreparedLines.errors(line)}
        </li>
      })}  
      { numberOfEmptyLines > 0 &&
        <li>
          <div className="row">
            <div><div className="icon cancel"/></div>
            <div className="size4c">{Translate.get('imports.preparedLines.row.emptyRows')}</div>
            <div className="size7c">{numberOfEmptyLines}</div>
          </div>
        </li>
      } 
    </>
  }
  */

  /*
  static caseObject(item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon car"/></div>
      <div className="size4c">Předmět</div>
      <div className="size4c">{item.item.name}</div>
      <div className="size4c">Rok výroby: {item.item.productionYear}</div>
      <div className="size4c">Použití: {item.item.usage}</div>
      <div className="size4c">VIN/SČ: {item.item.vin}</div>
    </div>
  }
  */
  /*
  static caseLocation(item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon address"/></div>
      <div className="size1c">{Translate.get('imports.preparedLines.row.location')}</div>
      <div className="size8c">
        {item.item.address.street + ' ' +
          item.item.address.streetCode + ' ' +
          item.item.address.city + ' ' +
          item.item.address.postCode}
      </div>
    </div>
  }


  static addressBookItemContacts(item) {
    if (!item || !item.item.contacts) {
      return null
    }
    return <div className="spaced">
      <ul className="list">
        {item.item.contacts && item.item.contacts.map((contact, index) => PreparedLines.contact(contact, index))}
      </ul>
    </div>
  }

  static addressBookItem(title, item) {
    if (!item) {
      return null
    }
    return <div className="row">
      <div><div className="icon addressBook"/></div>
      <div className="size4c">{title}</div>
      <div className="size6c">{item.item.displayName}</div>
      {item.item.extensions.kordex.salPartnerId &&
        <div className="size4c">{Translate.get('imports.preparedLines.row.salId')} {item.item.extensions.kordex.salPartnerId}</div>
      }
    </div>
  }
  */
}

export default PreparedLines
