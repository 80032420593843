import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import '../css/dialogs.css'

class ViewLustrationDialog extends Component {

  renderContent(item) {
    switch (item._class) {

      case "com.optimsys.costra.kordex.lustration.LustrationError":
        return <>
          <div className='dialog__header'>
            <div className='flex' >
              <div className="title">
                <div className="icon iconBig error"/>
                <span>{Translate.get('lustrations.error.title')}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div><div className="icon alert"/></div>
            <div className="flex4c">{Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM', item.time)}</div>
            <div className="flex4c">{item.user}</div>
            <div className="flex4c">{Translate.get('contactData.companyId')}: {item.companyId}</div>
          </div>
          <div className="spaced multiline">
            {item.errorDescription}
          </div>
        </>

      case "com.optimsys.costra.kordex.lustration.ares.AresResult":
        return <>
          <div className='dialog__header'>
            <div className='flex' >
              <div className="title">
                <div className="icon iconBig law"/>
                <span>{Translate.get('lustrations.view.title')}</span>
              </div>
              <div>
                <span className="left">{Translate.get('lustrations.ares.title')}</span>
              </div>
            </div>
          </div>
          <div className='dialog__details'>
            <div>
              <div className="left icon addressBookItem" title={Translate.get('contactData.displayName')}/>
              <div>{this.props.lustrationResult.displayName} ({Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM', this.props.lustrationResult.time)})</div>
            </div>
            <div>
              <div className="left icon address" title={Translate.get('contactData.address.title')}/>
              {this.props.lustrationResult.addresses.map((address , index) =>
                <span key={index}>{Formatter.address(address)}</span>
              )}
            </div>
            <div>
              <div className="left icon company" title={Translate.get('contactData.companyId')}/>
              <span>{this.props.lustrationResult.companyId}</span>
            </div>
          </div>

          <ul className="list spacedBottom">
              <li>
                <div className='row rowHeader level2'>
                  <div className="flex4c">{Translate.get('lustrations.header.statutary')}</div>
                  <div className="flex2c">{Translate.get('lustrations.header.position')}</div>
                  <div className="flex2c">{Translate.get('lustrations.header.bornDate')} </div>
                  <div className="flexRest inline">{Translate.get('lustrations.header.address')}</div>
                </div>
              </li>
            {item.statutaries && item.statutaries.map((item, index) =>
              <li key={'s' + index * 10}>
                <div className="row " > 
                  <div className="flex4c">{item.contactData.displayName}</div>
                  <div className="flex2c">{item.description}</div>
                  <div className="flex2c">{Formatter.formatDateTime('%dd.%mm.%yyyy', item.contactData.bornDate)}</div>
                  <div className="flexRest inline">
                    {item.contactData.contacts && item.contactData.contacts.map((address, index) => {
                        return <div key={address.city + index * 100}>{Formatter.address(address, index)}</div>
                    })}
                  </div>  
                </div>
              </li>
            )}
          </ul>
        </>
         default: return ""
    }
  }

  render() {
    return <div className="dialogsContainer">
      <div className="dialog wide frame">

        {this.renderContent(this.props.lustrationResult)}
        <div className="buttons">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel" />
          </div>
        </div>
      </div>
    </div>
  }
}

export default ViewLustrationDialog