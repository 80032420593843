import WsContainers from './WsContainers.js'
import Id from './Id.js'

class Container {

  constructor(component, className, errorProc) {
    this.id = Id.get()
    this.requests = {}
    this.component = component
    this.className = className
    this.errorProc = errorProc
    WsContainers.containers[this.id] = this
  }

  _onMessage(message) {
    var id = message['_replyId']
    if (id) {
      delete message._replyId
      if (this.requests[id]) {
        if (message['_failure']) {
          console.log('#Container request ended in failure', this.id, id, message)
          this.requests[id].failed(message['_failure'])
        }
        else {
          console.log('#Container request finished', this.id, id, message)
          this.requests[id].success(message)
        }
        delete this.requests[id]
      }
      else {
        console.log('#Container received reply to unknown request', this.id, id, message)
      }
    }
    else if (message._class === 'com.optimsys.costra.ws.container.ContainerActor$Data') {
      console.log('#Container received data', this.id, message.data)
      this.component.setState(message.data)
    }
    else if (message._class === 'com.optimsys.costra.ws.container.ContainerActor$Error') {
      this._onError(message.message)
    }
    else {
      console.log('#Container received unknown message', this.id, message)
    }
  }

  _onError(message) {
    console.log('#Container error', this.id, message)
    if (this.errorProc) {
      this.errorProc(message)
    }
  }

  tell(params) {
    if (!WsContainers.isConnected || !WsContainers.wsConnection) {
      console.log('#Container cannot send tell message while disconnected', this.id, params)
    }
    else {
      console.log('#Container sending tell message', this.id, params)
      params['_containerId'] = this.id
      params['_containerClass'] = this.className
      WsContainers.wsConnection.send(JSON.stringify(params))
    }
  }

  ask(params) {
    return new Promise((success, failed) => {
      if (!WsContainers.isConnected || !WsContainers.wsConnection) {
        console.log('#Container cannot send ask message while disconnected', this.id, params)
        failed('NotConnected')
      }
      else {
        var id = Id.get()
        console.log('#Container sending ask message', this.id, id, params)
        this.requests[id] = {
          success: success,
          failed: failed,
        }
        params['_containerId'] = this.id
        params['_containerClass'] = this.className
        params['_requestId'] = id
        WsContainers.wsConnection.send(JSON.stringify(params))
        setTimeout(() => {
          if (this.requests[id]) {
            console.log('#Container ask message timeout', this.id, id)
            this.requests[id].failed('Timeout')
            delete this.requests[id]
          }
        }, 30000)
      }
    })
  }

  close() {
    console.log('#Container closed', this.id)
    if (WsContainers.isConnected && WsContainers.wsConnection) {
      WsContainers.wsConnection.send(JSON.stringify({
        _class: 'com.optimsys.costra.ws.container.CloseContainer',
        _containerId: this.id,
        _containerClass: this.className,
      }))
    }
    delete WsContainers.containers[this.id]
    return null
  }
}

export default Container