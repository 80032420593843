import React, { Component } from 'react';

class PageSelector extends Component {

  constructor(props) {
    super()
    this.state = {
      pagesList : this.updatePagesList(props),
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.page !== this.props.page || prevProps.pages !== this.props.pages) {
      this.setState({pagesList: this.updatePagesList(this.props)})
    }
  }

  updatePagesList(props) {
    var count = 19
    var pagesList = []
    var i = 0
    var pages = props.pages
    if (!pages) pages = 1
    if (pages < count) {
      while(i < pages) {
        i += 1
        pagesList.push(i)
      }
    }
    else if (props.page < (Math.round(count / 2) + 1)) {
      while(i < (count - 2)) {
        i += 1
        pagesList.push(i)
      }
      pagesList.push('...')
      pagesList.push(pages)
    }
    else if ((pages - props.page) < (Math.round(count / 2) - 1)) {
      pagesList.push(1)
      pagesList.push('...')
      i = pages - (count - 2)
      while(i < pages) {
        i += 1
        pagesList.push(i)
      }
    }
    else {
      pagesList.push(1)
      pagesList.push('...')
      var base = props.page - Math.round((count - 5) / 2) - 1
      while(i < (count - 4)) {
        i += 1
        pagesList.push(i + base)
      }
      pagesList.push('...')
      pagesList.push(pages)
    }
    return pagesList
  }

  renderItem(page, index) {
    if (page === '...') {
      return <div key={index}>...</div>
    }
    else {
      return <div key={index}
                  className={page === this.props.page ? "pageSelector active" : "pageSelector"}
                  onClick={(event) => this.props.onChange(event, page)}>
        {page}
      </div>
    }
  }

  render() {
    return <div>{this.state.pagesList.map((page, index) => this.renderItem(page, index))}</div>
  }
}

export default PageSelector