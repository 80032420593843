import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'
import Dialogs from '../costra/Dialogs.js'
import Router from '../costra/Router.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import ConfirmDialog from '../utils/ConfirmDialog.js'
import Nav from '../utils/Nav.js'

import ImportErrors from './ImportErrors.js'
import ImportedCases from '../caserecord/ImportedCases.js'
import ImportedObjects from '../caseobject/ImportedObjects.js'
import ImportLogs from './ImportLogs.js'

import '../css/icons.css'
import '../css/form.css'

class ImportRecordView extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: null,
      tab: 'files',
    }
    this.data = new EditData(this, 'item.')
  }

  load() {
    return WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.imports.FetchImportRecord',
      id: this.props.id,
    }).then((result) => {
      this.setState(result)
      return result
    }, () => {
      this.setState({ error: 'genericLoadError'})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  removeRecord() {
    Dialogs.open(
      <ConfirmDialog text={Translate.get('imports.remove.prompt')}/>
    ).then((confirmed) => {
      if (confirmed) {
        WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.imports.RemoveImportRecord',
          id: this.props.id,
        })
        Router.go('/import/')
      }
      else{
        Dialogs.close(null)
      }
    })
  }

  downloadImportedFile(event, file, index) {
    Nav.go(event, '/download/importedFile?id=' + this.props.id + '&file=' + index + '&download=1', true)
  }

  renderFiles() {
    return <ul className="list">
      {this.state.item.files && this.state.item.files.map((file, index) =>
        <li key={index}>
          <div className="row withHover"
               onClick={(event) => this.downloadImportedFile(event, file, index)}>
            <div><div className="icon file"/></div>
            <div>{file.info.fileName}</div>
          </div>
        </li>
      )}
    </ul>
  }

  renderTabs() {
    switch (this.state.tab) {
      case 'imported':
        return <ImportLogs id={this.props.id}/>
      case 'errors':
        return <ImportErrors id={this.props.id}/>
      case 'cases':
        return <ImportedCases id={this.props.id}/>
      case 'objects':
        return <ImportedObjects id={this.props.id}/>
      default:
        return this.renderFiles()
    }
  }

  render() {
    return (
      <div className="mainPanel withTabs">
        <div className="header">
          <div className="row">
            <div className="left icon iconBig import" />
            {this.state.item &&
              <h1>
                {Translate.get(this.state.item.displayName) + ' ' +
                  Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM:%SS', this.state.item.time)
                  /* + ' (' + this.state.campaign.displayName + ')'*/
                }
              </h1>
            }
          </div>
          
          <div className="row">
            <div className="tabs">
              <div className={'tab' + (this.state.tab === 'files' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'files'})}>{Translate.get('imports.tabs.files')}</div>
              <div className={'tab' + (this.state.tab === 'imported' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'imported'})}>{Translate.get('imports.tabs.imported')}</div>
              <div className={'tab' + (this.state.tab === 'errors' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'errors'})}>{Translate.get('imports.tabs.errors')}</div>
              <div className={'tab' + (this.state.tab === 'cases' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'cases'})}>{Translate.get('imports.tabs.cases')}</div>
              <div className={'tab' + (this.state.tab === 'objects' ? ' active' : '')}
                  onClick={(event) => this.setState({tab: 'objects'})}>{Translate.get('imports.tabs.objects')}</div>
            </div>
          </div>

          <div className='header__buttons'>
            <button disabled={!this.state.allowRemove}
                    className={this.state.allowRemove ? "" : "disabled"}
                    onClick={() => this.removeRecord()}>
              {Translate.get('imports.button.remove')}
            </button>
          </div>

        </div>
        <div className="scrollable">
          <div className="content">
            {this.state.item ?
              this.renderTabs()
              :
              <LoadingPlaceholder />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ImportRecordView
