import React, { Component } from 'react'
import SimpleListContainer from '../costra/SimpleListContainer.js'

import PageSelector from '../utils/PageSelector.js'
import List from '../utils/List.js'
import Campaign from './Campaign.js'

import '../css/list.css'
import '../css/icons.css'
import Translate from '../costra/Translate.js'
import Nav from '../utils/Nav.js'

class Campaigns extends Component {

  constructor(props) {
    super(props)
    var state = SimpleListContainer.initialState()
    state.itemsPerPage = 30
    this.state = state
    this.container = new SimpleListContainer(this, 'com.optimsys.costra.kordex.campaign.CampaignsList',
      error => this.setState({error: error}))
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  render() {
    return (
      <div className="mainPanel">
        <div className="header">
          <div className="row">
            <div className="left icon iconBig campaigns"/>
            <h1>{Translate.get('campaigns.title')}</h1>
          </div>
        </div>
        <div className="scrollable">
          <div className="content">

            <div className="listHeader spacedTop">
              <PageSelector page={Number(this.props.page ?? 1)}
                            pages={this.state.pages}
                            onChange={(event, page) => {
                              Nav.addUrlParam(event, 'page', page)}
                            }/>

              <div className="button right"
                   title={Translate.get('campaigns.button.newCampaign')}
                   onClick={(event) => Campaign.create(event)}>
                <div className="icon left campaignAdd"/>
                <span className="left">{Translate.get('campaigns.button.newCampaign')}</span>
              </div>
            </div>

            <List list={this.state.items} itemFn={item => <Campaign.Row item={item}/>}/>
          </div>
        </div>
      </div>
    )
  }

}

export default Campaigns