import React, { Component } from 'react'
import Translate from '../costra/Translate.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import ObjectEdit from '../caseobject/ObjectEdit'
import CaseEdit from '../caserecord/CaseEdit'
import ItemEdit from '../addressbook/ItemEdit'
import NavPanel from './NavPanel'
import NavContainer from './NavContainer.js'

import './navPanel.css'

class NavPanelWrapper extends Component {

  constructor(props) {
    super(props)
    this.state = {
      active: "false",
      error: null,
    }
    this.container = new NavContainer(
      this, 'com.optimsys.costra.kordex.nav.NavContainer',
      error => this.setState({error: error})
    )
  }

  load() {
    if (this.props.id !== 'new') {
      this.container.load(this.props.id, this.props.path[0]) 
    }
    else {
      this.setState({ item: null })
    }
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this.container.close()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps && prevProps.id !== this.props.id) {
      this.load()
    }
  }

  isLoaded() {
    switch(this.props.path[0]) {
      case 'case' :
         return this.state.cases && this.state.cases[this.state.caseIndex]
      case 'caseObject' :
        return this.state.cases &&
          this.state.cases[this.state.caseIndex] &&
          this.state.cases[this.state.caseIndex].objects &&
          this.state.cases[this.state.caseIndex].objects[this.state.objectIndex]
      case 'addressBook' :
        return this.state.items && this.state.items[this.state.itemIndex]
      default:
        return false
    }
  }

  render() {
    if (this.state.error) {
      return <>
        <div className="navPanel">
          <div className="navPanel--withPadding"></div>
        </div>
        <div className="mainPanel withTabs mainPanelWithNavPanel">
          <div className="flex__placeholder">
            <div className={'icon alert left '}/>
            <div>{Translate.get('genericLoadError')}</div>
          </div>
        </div>
      </>
    }

    if (this.props.path[1] === "new") {
      switch(this.props.path[0]) {
        case 'case':
          return <CaseEdit id={this.props.path[1]}
                           item={{}}
                           exports={[]}
                           allowEdit={true}
                           allowRemove={false}
                           lustrations={[]}
                           load={this.load}
                           objects={[]}/>
        case 'addressBook':
          return <ItemEdit id={this.props.path[1]}
                           item={{}}
                           allowEdit={true}
                           allowRemove={false}
                           items={[]}
                           load={this.load}
                           lustrations={[]}/>
        case 'caseObject':
          return <ObjectEdit id={this.props.path[1]}
                             parentId={this.props.path[2]}
                             item={{}}
                             exports={[]}
                             cases={[]}
                             allowEdit={true}
                             allowRemove={false}/>
        default:
          return null
      }
    }

    if (!this.isLoaded()) {
      return <>
        <div className="navPanel">
          <div className="navPanel--withPadding"></div>
        </div>
        <div className="mainPanel withTabs mainPanelWithNavPanel">
          <div className="flex__placeholder">
            <LoadingPlaceholder/>
          </div>
        </div>
      </>
    }

    return <>
       <NavPanel items={this.state.items}
                 cases={this.state.cases}
                 clientRating={this.state.clientRating}
                 id={this.props.path[1]}
                 lustrations={this.state.lustrations && this.state.lustrations}
                 path={this.props.path[0]}
                 caseIndex={this.state.caseIndex}
                 objectIndex={this.state.objectIndex}/>
      {this.props.path[0] === 'case' &&
        <CaseEdit id={this.props.path[1]}
                  item={this.state.cases[this.state.caseIndex].caseRecord}
                  exports={this.state.cases[this.state.caseIndex].exports}
                  allowEdit={this.state.cases[this.state.caseIndex].allowEdit}
                  allowRemove={this.state.cases[this.state.caseIndex].allowRemove}
                  lustrations={this.state.lustrations && this.state.lustrations}
                  objects={this.state.cases[this.state.caseIndex].objects || []}
                  load={this.load}/>
      }
      {this.props.path[0] === 'addressBook' &&
         <ItemEdit id={this.props.path[1]}
                   item={this.state.items[this.state.itemIndex].item}
                   allowEdit={this.state.items[this.state.itemIndex].allowEdit}
                   allowRemove={this.state.items[this.state.itemIndex].allowRemove}
                   lustrations={this.state.lustrations && this.state.lustrations}
                   load={this.load}/>
      }
      {this.props.path[0] === 'caseObject' &&
          this.state.cases[this.state.caseIndex].objects[this.state.objectIndex] &&
        <ObjectEdit id={this.props.path[1]}
                    parentId={this.props.path[2]}
                    item={this.state.cases[this.state.caseIndex].objects[this.state.objectIndex].item}
                    exports={this.state.cases[this.state.caseIndex].objects[this.state.objectIndex].exports}
                    allowEdit={this.state.cases[this.state.caseIndex].objects[this.state.objectIndex].allowEdit}
                    allowRemove={this.state.cases[this.state.caseIndex].objects[this.state.objectIndex].allowRemove}
                    load={this.load}/>
      }
    </>
  }
}

export default NavPanelWrapper