import React, { Component } from 'react'
import WsContainers from '../costra/WsContainers.js'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'

class GpsItemsList extends Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  load() {
    WsContainers.transaction({
      _class : 'com.optimsys.costra.kordex.gps.GpsItemsList',
      ownerId : this.props.id,
    }).then((data) => {
      if (!data.items) {
        data['items'] = []
      }
      this.setState(data)
    }, () => {
      this.setState({error: 'genericLoadError'})
    })
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    if (this.container) {
      this.container = this.container.close()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id) {
      this.load()
    }
  }

  renderDistances(item) {
    return <>
      <div className="flexRest">
        {item.gps &&
          <div className="inline withHover"
            onClick={(event) => Nav.openMap(event, item.gps.expectedLat, item.gps.expectedLng)}>
            {item.gps.expectedLat ? (Number(item.gps.expectedLat).toFixed(6).toString()) + ',' : ''}
            {item.gps.expectedLng ? (Number(item.gps.expectedLng).toFixed(6).toString()) : ''}
            {item.gps.expectedLat &&
              <div className="icon-button" title={Translate.get('gps.item.expectedGps')}>
                <div className="icon gps"/>
              </div>
            }
          </div>
        }
      </div>
      <div className="flexRest">
        {item.gps &&
          <div className="inline withHover"
               onClick={(event) => Nav.openMap(event, item.gps.lat, item.gps.lng)}>
            {Number(item.gps.lat).toFixed(6).toString()}, {Number(item.gps.lng).toFixed(6).toString()}
            <div className="icon-button"
                key={'trueGps'}
                title={Translate.get('gps.item.trueGps')}>
              <div className="icon gps"/>
            </div>
          </div>
        }
      </div>
      <div className="flexRest">
        {item.gps && item.gps.deviation &&
          <div className="inline withHover"
               onClick={(event) => Nav.go(event, 'https://www.google.com/maps/dir/' + item.gps.expectedLat + ',+' +
               item.gps.expectedLng + '/' +  item.gps.lat + ',' + item.gps.lng + '/@' + item.gps.lat + ',' + item.gps.lng + ',17z', true)}>
            {item.gps.deviation ? Number(item.gps.deviation).toFixed(2).toString() + 'km' : ''}
            <div className="icon-button"
                key={'deviation'}
                title={Translate.get('gps.item.deviation')}>
              <div className="icon expectedGps"/>
            </div>
          </div>
        }
      </div>
    </>
  }

  renderItem(item, i) {
    return <li key={item._class + i}>
      <div className="row"> 
        <div className="flexRest">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.result ? item.result.time : item.time)}</div>
        <div className="flexRest">{Translate.get(item._class)}</div>
        {this.renderDistances(item.result ? item.result : item)}
        </div>
      </li>
  }

  render() {
    return <ul className="list level1">
      <li>
        <div className="row rowHeader ">
          <div className="flexRest">{Translate.get('gps.header.time')}</div>
          <div className="flexRest">{Translate.get('gps.header.type')}</div>
          <div className="flexRest">{Translate.get('gps.header.expectedGps')}</div>
          <div className="flexRest">{Translate.get('gps.header.trueGps')}</div>
          <div className="flexRest">{Translate.get('gps.header.deviation')}</div>
        </div>
      </li>
      {this.state.items && this.state.items.map((item, i) =>
        this.renderItem(item, i)
      )}
    </ul>
  }

}

export default GpsItemsList