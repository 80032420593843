import React, { Component } from 'react'

import ListContainer from '../utils/ListContainer.js'
import PageSelector from '../utils/PageSelector.js'
import Nav from '../utils/Nav.js'

import ImportRecord from './ImportRecord.js'

import '../css/list.css'
import '../css/icons.css'

class ImportRecords extends Component {

  constructor(props) {
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.page = props.page || 1
    this.state = state
    this.container = new ListContainer(this, 'com.optimsys.costra.kordex.imports.ImportRecords')
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps && prevProps.page !== this.props.page) {
      this.container.reload()
    }
  }

  render() {
    return <>
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => Nav.addUrlParam(event, 'page', page)}/>
      </div>
      <ul className="list">
        <ImportRecord.Header/>
        {this.state.items && this.state.items.map((item, index) => {
          return <li key={(item._id ? item._id.$oid : index)}>
            <ImportRecord.Row item={item}/>
          </li>
        })}
      </ul>
    </>
  }
}

export default ImportRecords
