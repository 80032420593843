import React from 'react'
import Translate from '../../../costra/Translate.js'
import Validator from '../../../costra/Validator.js'

import Form from '../../../utils/Form.js'
import ContactData from '../../../addressbook/ContactData.js'

import CaseRecord from '../../CaseRecord.js'
import '../../../css/form.css'

function SalSfProperties(props) {
  return <>
    <div className="subsection">
      <div className="header-row">
        <div className="icon iconSection case" />
        <h3>{Translate.get('caserecord.properties.title')}</h3>
      </div>
      <div className="content">
        <div className="column-block">
          <Form.Text data={props.data}
                     disabled={!props.editOpen}
                     path="caseNo"
                     title='caserecord.properties.caseNo'
                     validate={Validator.notEmptyString} />
          {props.editOpen && <>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.evNum"
                       title='caserecord.sal.evNum'/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.documentId"
                       title='caserecord.sal.documentId'/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.eventId"
                       title='caserecord.sal.eventId'/>
            <Form.Text data={props.data}
                       disabled={!props.editOpen}
                       path="sal.fk"
                       title='caserecord.sal.fk'/>
            <Form.Select data={props.data}
                         disabled={!props.editOpen}
                         path="source"
                         title="sal.source"
                         values={CaseRecord.sources}/>
          </>}
          <Form.DateTime data={props.data}
                          disabled={true}
                          path="processing.created"
                          title='caserecord.processing.created'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={!props.editOpen}
                          path="plannedEnding"
                          title='caserecord.properties.plannedEnding'
                          format="%d.%m.%yyyy" />
          <Form.DateTime data={props.data}
                          disabled={true}
                          path="processing.finished"
                          title='caserecord.processing.finished'
                          format="%d.%m.%yyyy" />
        </div>
      </div>      
    </div>
    <ContactData data={props.data}
                 path="client"
                 disabled={!props.editOpen}
                 title='contactData.relation.client'/>

    {props.data.get('otherContacts') && props.data.get('otherContacts').map((contactData, index) =>
      <ContactData key={index}
                   data={props.data}
                   path={'otherContacts.' + index}
                   disabled={!props.editOpen}/>
    )}
  </>
}

export default SalSfProperties