import ObjectHelper from './ObjectHelper.js'

class EditData {

  constructor(container, prefix) {
    console.assert(container)
    this.container = container
    this.prefix = prefix
    if (!this.prefix) {
      this.prefix = ''
    }
  }

  get(path) {
    return ObjectHelper.get(this.container.state, this.prefix + path)
  }

  set(path, value, updateProc) {
    var state = ObjectHelper.set(this.container.state, this.prefix + path, value)
    this.container.setState(state, updateProc)
    return state
  }

  unset(path) {
    var state = ObjectHelper.unset(this.container.state, this.prefix + path)
    this.container.setState(state)
    return state
  }

  isIdInArray(path, id) {
    return ObjectHelper.isIdInArray(this.get(path), id)
  }

  toggleIdInArray(path, id, value) {
    return this.set(path, ObjectHelper.toggleIdInArray(this.get(path), id, value))
  }

  isInArray(path, value) {
    var array = this.get(path)
    if (!array) {
      return false
    }
    return (array.indexOf(value) >= 0)
  }

  isObjectInArray(path, value) {
    var array = this.get(path)
    if (!array || !Array.isArray(array)) {
      return false
    }
    return array.findIndex(e => ObjectHelper.isEqual(e, value)) >= 0
  }

  addToArray(path, value) {
    var array = this.get(path)
    if (!array) {
      array = []
    }
    array.push(value)
    return this.set(path, array)
  }

  removeFromArray(path, index) {
    var array = this.get(path)
    if (!array) {
      array = []
    }
    array.splice(index, 1)
    return this.set(path, array)
  }

  toggleObjectInArray(path, value, set, wantedIndex) {
    var array = this.get(path)
    if (!array) {
      array = []
    }
    var index = array.findIndex(e => ObjectHelper.isEqual(e, value))
    if (index < 0) {
      if (set) {
        if(wantedIndex !== undefined) {
          array.splice(wantedIndex, 0, value);
        } else {
          array.push(value)
        }
        return this.set(path, array)
      }
    }
    else {
      if (!set) {
        array.splice(index, 1)
        return this.set(path, array)
      }
    }
  }

  toggleInArray(path, value, set) {
    var array = this.get(path)
    if (!array) {
      array = []
    }
    var index = array.indexOf(value)
    if (index < 0) {
      if (set) {
        array.push(value)
        return this.set(path, array)
      }
    }
    else {
      if (!set) {
        array.splice(index, 1)
        return this.set(path, array)
      }
    }
  }
}

export default EditData