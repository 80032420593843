import React, { Component } from 'react'

import {PageDataContext} from  './../PageDataContext.js'

import CaseObjectsList from '../caseobject/CaseObjectsList.js'
import CaseFilesList from '../files/CaseFilesList.js'
import GpsItemsList from '../gps/GpsItemsList.js'
import EntityChangeLog from '../changelog/EntityChangeLog.js'
import CasePaymentPlan from '../paymentplan/CasePaymentPlan.js'
import SkWcGuide from '../guide/SkWcGuide.js'
import Events from '../events/Events.js'

import Properties from './Properties.js'
import CaseEditHeader from './CaseEditHeader.js'
import CaseEditTabs from './CaseEditTabs.js'

import '../css/icons.css'
import '../css/form.css'

class CaseEdit extends Component {

  static contextType = PageDataContext

  constructor(props) {
    super(props)
    this.state = {
      tab: 'props',
    }
  }

  renderTabs() {
    switch (this.state.tab) {
      case "objects":
        return <CaseObjectsList id={this.props.id}  allowEditCase={this.props.allowEdit} />
      case 'files':
        return <CaseFilesList id={this.props.id} allowEdit={this.props.allowEdit}/>
      case "gps":
        return <GpsItemsList id={this.props.id} />
      case "log":
        return <EntityChangeLog id={this.props.id} item={this.props.item}/>
      case 'payments':
        return <CasePaymentPlan caseId={this.props.id} caseClass={this.props.item._class} />
      case 'guide':
        return <SkWcGuide caseId={this.props.id} item={this.props.item}/>  
      case 'events':
        return <Events id={this.props.id}
                       containerClass="com.optimsys.costra.kordex.caserecord.CaseEvents"
                       continuationClass="com.optimsys.costra.kordex.caserecord.GetCaseContinuation"/>
      default:
        return <Properties id={this.props.id} campaignId={this.props.campaignId} />
    }
  }

  render() {
    return <>
      <div className="mainPanel withTabs mainPanelWithNavPanel">
        <div className="header">
          <div className="row">
            {this.props.item &&
              <CaseEditHeader item={this.props.item}
                              campaigns={this.context.campaigns}
                              exports={this.props.exports}
                              allowEdit={this.props.allowEdit}
                              allowRemove={this.props.allowRemove}/>
            }
          </div>
        </div>
        <div className="scrollable">
          <div className='subMenu'>
            <div className="row">
              {this.props.item &&
                <CaseEditTabs item={this.props.item} component={this} allowEdit={this.props.allowEdit}/>
              }
            </div>
          </div>
          
          <div className="content">
            {this.renderTabs()}
          </div>
        </div>
      </div>
    </>
  }
}

export default CaseEdit
