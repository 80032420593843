import React from 'react'
import PreparedLines from './PreparedLines.js'
import Translate from '../costra/Translate.js'

class Ucb {

  static telCaseResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('ucb.result.productRowsImported', data.productRowsImported)}
        {PreparedLines.reportLine('ucb.result.clientRowsImported', data.clientRowsImported)}
        {PreparedLines.reportLine('ucb.result.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('ucb.result.productRowsSkipped', data.productRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('ucb.result.clientRowsSkipped', data.clientRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('ucb.result.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static telCaseUpdateResult(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('ucb.result.productRowsImported', data.productRowsImported)}
        {PreparedLines.reportLine('ucb.result.productRowsMismatched', data.productRowsMismatched, 'cancel')}
        {PreparedLines.reportLine('ucb.result.productRowsSkipped', data.productRowsSkipped, 'cancel')}
      </ul>
    </div>
  }

}

export default Ucb