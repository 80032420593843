import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'

import CreateTaskDialog from '../tasks/CreateTaskDialog.js'
import Event from '../events/Event.js'
import UploadFileDialog from '../files/UploadFileDialog.js'

import CaseRewardDialog from './CaseRewardDialog.js'
import ObjectContinuationDialog from './ObjectContinuationDialog.js'
import { PageDataContext } from '../PageDataContext.js';
import '../css/dialogs.css';

class ContinuationStep extends Component {

  renderTaskInfo(task) {
    switch(task._class) {

      case 'com.optimsys.costra.kordex.task.CollectionTask':
      case 'com.optimsys.costra.kordex.task.VisitTask':
        return <div className="info">
          <div className="flexRest">{Formatter.address(task.address)}</div>
        </div>

      case 'com.optimsys.costra.kordex.task.PaymentPromiseTask':
        return <div className="info">
          {task.promised && task.promised.map(promised =>
            <div className="flexRest">{Formatter.formatAmount(promised.amount) + promised.currency}</div>
          )}
        </div>

      default:
        return null
    }
  }

  renderStep(step) {
    switch (step._class) {

      case 'com.optimsys.costra.kordex.processing.AddCaseEvent':
        return <div className='card'>
          <div className='instruction'>{Translate.get('addCaseEvent.' + step.event._class)}</div>
        </div>

      case 'com.optimsys.costra.kordex.processing.AddClientsEvent':
        return <div className='card'>
          <div className='instruction'>{Translate.get('addClientsEvent.' + step.event._class)}</div>
        </div>

      case 'com.optimsys.costra.kordex.processing.AddObjectEvent':
        return <div className='card'>
          <div className='instruction'>{Translate.get('addObjectEvent.' + step.event._class)}</div>
        </div>

      case 'com.optimsys.costra.kordex.processing.AddCaseToTask':
        return <div className='card'>
          <div className='instruction'>{Translate.get('addCaseToTask.' + step.task._class)}</div>
          {this.renderTaskInfo(step.task)}
        </div>

      case 'com.optimsys.costra.kordex.processing.AddCaseTask':
      case 'com.optimsys.costra.kordex.processing.AddClientsTask':
      case 'com.optimsys.costra.kordex.processing.ContinueObjectProcessing':
        return <div className='card'>
          <div className='instruction'>{Translate.get(step._class)}</div>
        </div>

      case 'com.optimsys.costra.kordex.processing.UploadFile':
      case 'com.optimsys.costra.kordex.processing.GenerateAndAttachDocument':
        return <div className='card'>
          <div className='instruction'>{Translate.get(step._class + '.' + step.fileType)}</div>
        </div>

      case 'com.optimsys.costra.kordex.processing.MigrateCase':
        return <div className='card'>
          <div className='instruction'>{Translate.get(step._class)}</div>
          <div className="info">
            {step.campaignId &&
              <div className="flexRest">{this.context.campaigns[step.campaignId.$oid]}</div>
            }
          </div>
        </div>

      case 'com.optimsys.costra.kordex.processing.SetCaseReward':
        return <div className='card'>
          <div className='instruction'>{Translate.get(step._class)}</div>
        </div>

      default:
        return <div className='card'>
          <div className='instruction'>{Translate.get(step._class)}</div>
        </div>
    }
  }

  processStep(step) {
    switch (step._class) {

      case 'com.optimsys.costra.kordex.processing.AddCaseEvent':
      case 'com.optimsys.costra.kordex.processing.AddClientsEvent':
      case 'com.optimsys.costra.kordex.processing.AddObjectEvent':
        Dialogs.close(null)
        Event.editEvent(step.event)
        break

      case 'com.optimsys.costra.kordex.processing.AddCaseToTask':
        WsContainers.action({
          _class: 'com.optimsys.costra.kordex.processing.UseNextStep',
          nextStep: {
            _class: 'com.optimsys.costra.kordex.processing.AddCaseToTask$Processing',
            caseId: step.caseId,
            taskId: step.task._id,
          },
        }).then(() => {
          Dialogs.close(null)
        })
        break

      case 'com.optimsys.costra.kordex.processing.AddCaseTask':
        Dialogs.close(null)
        Dialogs.open(<CreateTaskDialog caseIds={[step.caseId]} userId={this.context.currentUser.id}/>)
        break

      case 'com.optimsys.costra.kordex.processing.AddClientsTask':
        Dialogs.close(null)
        Dialogs.open(<CreateTaskDialog caseIds={step.caseIds} userId={this.context.currentUser.id}/>)
        break

      case 'com.optimsys.costra.kordex.processing.ContinueObjectProcessing':
        Dialogs.close(null)
        Dialogs.open(<ObjectContinuationDialog objectsRefs={step.caseObjects}/>)
        break

      case 'com.optimsys.costra.kordex.processing.UploadFile':
        Dialogs.open(
          <UploadFileDialog owner={step.owner.$oid}
                            fileType={step.fileType}/>
        )
        break

      case 'com.optimsys.costra.kordex.processing.GenerateAndAttachDocument':
        WsContainers.action({
          _class: 'com.optimsys.costra.kordex.processing.UseNextStep',
          nextStep: {
            _class: 'com.optimsys.costra.kordex.processing.GenerateAndAttachDocument$Processing',
            owner: step.owner,
            fileType: step.fileType,
            exportToRun: step.exportToRun,
          },
        }).then(() => {
          Dialogs.close(null)
        })
        break

      case 'com.optimsys.costra.kordex.processing.MigrateCase':
        WsContainers.action({
          _class: 'com.optimsys.costra.kordex.processing.UseNextStep',
          nextStep: {
            _class: 'com.optimsys.costra.kordex.processing.MigrateCase$Processing',
            caseId: step.caseId,
            campaignId: step.campaignId,
          },
        }).then(() => {
          Dialogs.close(null)
        })
        break

      case 'com.optimsys.costra.kordex.processing.SetCaseReward':
        Dialogs.close(null)
        Dialogs.open(
          <CaseRewardDialog step={step}/>
        ).then((result) => {
          if (result !== null) {
            return WsContainers.action({
              _class: 'com.optimsys.costra.kordex.processing.UseNextStep',
              nextStep: {
                _class: 'com.optimsys.costra.kordex.processing.SetCaseReward$Processing',
                caseId: step.caseId,
                reward: result.reward,
                currency: result.currency,
              },
            })
          }
        })
        break

      default:
        break
    }
  }

  render() {
    return <div className='nextStep row withHover'
                onClick={() => this.processStep(this.props.step)}>
      <div className="flexRest inline">
        {this.renderStep(this.props.step)}
      </div>
    </div>
  }
}

ContinuationStep.contextType = PageDataContext

export default ContinuationStep
