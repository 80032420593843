import React, { Component } from 'react'
import Translate from '../costra/Translate.js'
import Formatter from '../costra/Formatter.js'
import Dialogs from '../costra/Dialogs.js'

import Metrics from '../utils/Metrics.js'
import Nav from '../utils/Nav.js'

import CallDialDialog from '../comms/CallDialDialog.js'
import SmsSendDialog from '../comms/SmsSendDialog.js'
import Batch from '../exports/Batch.js'
import ImportRecord from '../imports/ImportRecord.js'
import Task from '../tasks/Task.js'
import Flag from '../flags/Flag.js'

import { PageDataContext } from '../PageDataContext.js'
import Event from './Event.js'
import '../css/icons.css'
import '../css/form.css'

class AnyItem extends Component {

  getUser(item) {
    if (!item.userId) {
      return ''
    }
    const userObject = this.context.users[item.userId.$oid]
    if (!userObject) {
      return ''
    }
    return userObject.displayName
  }

  renderFlagWithParts(flag, parts) {
    return <div className="row multiple-columns purpleBorder">
      <div className="row">
        <div><Flag.Icon flag={flag}/></div>
        <div className="flex8c">{Translate.get(flag._class)}</div>
        <div className="flexRest">{flag._object}</div>
      </div>
      {parts &&
        <div className="row">
          <div className="flexRest">
            {parts.map(part => Translate.get(flag._class + '.' + part)).join(', ')}
          </div>
        </div>
      }
    </div>
  }

  dialCall(dst) {
    Dialogs.open(<CallDialDialog dst={dst}/>)
  }

  sendSms(dst) {
    Dialogs.open(<SmsSendDialog dst={dst}/>)
  }

  renderEventButtons(event) {
    if (!this.props.item.allowEdit) return null
    return <>
      <div className="button"
           title={Translate.get('event.button.edit')}
           onClick={() => Event.editEvent(event)}>
        <div className="icon edit"></div>
      </div>
      <div className="button"
           title={Translate.get('event.button.remove')}
           onClick={() => Event.removeEvent(event)}>
        <div className="icon cancel"></div>
      </div>
    </>
  }

  renderTaskHeader(task, extraButtons) {
    return <div className="row">
      <div><Task.Icon task={task}/></div>
      <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", task.deadlineAt)}</div>
      <div className="size3c">{this.getUser(task)}</div>
      <div className="flexRest">
        {Translate.get(task._class) + ' ' +
          Translate.get('task.form.casesCount', null, {count: task.caseIds ? task.caseIds.length : 0})}
      </div>
      {this.props.item.allowClose &&
        <>
          <div className="button"
               title={Translate.get('task.button.close')}
               onClick={() => Task.closeTask(task)}>
            <div className="icon cancel"></div>
          </div>
          <div className="button"
               title={Translate.get('task.button.reschedule')}
               onClick={() => Task.editDialog(task)}>
            <div className="icon edit"></div>
          </div>
        </>
      }
      {this.props.item.allowEdit &&
        <>
          {extraButtons}
          {!this.props.item.allowClose &&
            <div className="button"
                 title={Translate.get('task.button.addNote')}
                 onClick={() => Event.addNote(task.caseIds)}>
              <div className="icon edit"></div>
            </div>
          }
          <div className="button"
               title={Translate.get('task.button.continue')}
               onClick={() => Task.continueDialog(task)}>
            <div className="icon add"></div>
          </div>
          <div className="button"
               title={Translate.get('task.button.newTab')}
               onClick={evt => Nav.go(evt, '/taskinfo/' + task._id.$oid, true)}>
            <div className="icon newWindow"></div>
          </div>
          <div className="button"
               title={Translate.get('task.button.open')}
               onClick={evt => Nav.go(evt, '/taskinfo/' + task._id.$oid)}>
            <div className="icon task"></div>
          </div>
        </>
      }
    </div>
  }

  renderPhoneButtons(remote) {
    return <>
      {this.context.currentUser.canDialCall &&
        <div className="button"
             title={Translate.get('task.button.dial')}
             onClick={() => this.dialCall(remote)}>
          <div className="icon call"></div>
        </div>
      }
      {this.context.currentUser.canSendSms &&
        <div className="button"
             title={Translate.get('task.button.sendSms')}
             onClick={() => this.sendSms(remote)}>
          <div className="icon sms"></div>
        </div>
      }
    </>
  }

  renderNotes(task) {
    return (task.note &&
      <div className="row">
        <div className="flexRest multiline">{task.note}</div>
      </div>
    )
  }

  render() {
    var item = this.props.item.item
    switch (item._class) {

      // Events ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.events.ObjectBackOfficeCheck':
      case 'com.optimsys.costra.kordex.events.ObjectCertification':
      case 'com.optimsys.costra.kordex.events.ObjectChecked':
      case 'com.optimsys.costra.kordex.events.ObjectCollected':
      case 'com.optimsys.costra.kordex.events.ObjectCollectionImpossible':
      case 'com.optimsys.costra.kordex.events.ObjectEnRoute':
      case 'com.optimsys.costra.kordex.events.ObjectInCustomsDepot':
      case 'com.optimsys.costra.kordex.events.ObjectNotFound':
      case 'com.optimsys.costra.kordex.events.ObjectProcessingCancelled':
      case 'com.optimsys.costra.kordex.events.ObjectTpCollected':
      case 'com.optimsys.costra.kordex.events.ObjectTransferred':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{this.props.item._objects && this.props.item._objects.join(', ')}</div>
            <div className="flexRest">{Translate.get(item._class)}</div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.ObjectInDemo':
      case 'com.optimsys.costra.kordex.events.ObjectLent':
      case 'com.optimsys.costra.kordex.events.ObjectServiced':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{this.props.item._objects && this.props.item._objects.join(', ')}</div>
            <div className="flexRest">
              {Translate.get(item._class)}
              {item.returnDate && ' (' + Formatter.formatDateTime("%dd.%mm.%yyyy", item.returnDate) + ')'}
            </div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.ObjectSold':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{this.props.item._objects && this.props.item._objects.join(', ')}</div>
            <div className="flexRest">
              {Translate.get(item._class)}
              {item.sellDate && ' (' + Formatter.formatDateTime("%dd.%mm.%yyyy", item.sellDate) + ')'}
            </div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.ObjectLockdownStateChanged':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{this.props.item._objects && this.props.item._objects.join(', ')}</div>
            <div className="flexRest">{Translate.get(item._class)}</div>
            {this.renderEventButtons(item)}
          </div>
          <div className="row">
            <div className="flexRest">
              {item.methods ?
                item.methods.map(method => Translate.get(item._class + '.' + method)).join(', ') :
                Translate.get(item._class + '.none')
              }
            </div>
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.DocumentSigned':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{this.props.item._objects && this.props.item._objects.join(', ')}</div>
            <div className="flexRest">
              {Translate.get(item._class) + ' (' + Translate.get('signatureState.' + item.signatureState) + ')'}
            </div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.CaseUpdateAcknowledged':
      case 'com.optimsys.costra.kordex.events.CaseUpdated':
      case 'com.optimsys.costra.kordex.events.NoteEvent':
      case 'com.optimsys.costra.kordex.events.PrivateNoteEvent':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="flexRest">
              {Translate.get(item._class)}
              {' ' + Translate.get('event.form.ownersCount', null, {count: item.owner.length})}
            </div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.events.LocationVisited':
        if (!this.props.filter.events) return null
        return <div className="row multiple-columns turquoiseBorder">
          <div className="row">
            <div><Event.Icon event={item}/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="size6c">{item.address.matchStr}</div>
            <div className="flexRest">
              {Translate.get(item._class)}
              {' ' + Translate.get('event.form.ownersCount', null, {count: item.owner.length})}
            </div>
            {this.renderEventButtons(item)}
          </div>
          {this.renderNotes(item)}
        </div>

      // Flags ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.flag.BackOfficeCheckNeeded':
      case 'com.optimsys.costra.kordex.flag.CaseOverdue':
      case 'com.optimsys.costra.kordex.flag.CaseStartDelayed':
      case 'com.optimsys.costra.kordex.flag.ExpediteCaseProcessing':
      case 'com.optimsys.costra.kordex.flag.GpsMissing':
      case 'com.optimsys.costra.kordex.flag.PlannedPastDeadline':
      case 'com.optimsys.costra.kordex.flag.CaseStateNotSet':
      case 'com.optimsys.costra.kordex.flag.ObjectLockedDown':
        if (!this.props.filter.flags) return null
        return <div className="row multiple-columns purpleBorder">
          <div className="row">
            <div><Flag.Icon flag={item}/></div>
            <div className="flex8c">{Translate.get(item._class)}</div>
            <div className="flexRest">{this.props.item._object}</div>
          </div>
        </div>

      case 'com.optimsys.costra.kordex.flag.CaseDocumentsMissing':
        if (!this.props.filter.flags) return null
        return this.renderFlagWithParts(item, item.docs)

      case 'com.optimsys.costra.kordex.flag.CaseEntryInvalid':
        if (!this.props.filter.flags) return null
        return this.renderFlagWithParts(item, item.errors)

      case 'com.optimsys.costra.kordex.flag.CaseExportReady':
        if (!this.props.filter.flags) return null
        return <div className="row multiple-columns purpleBorder">
          <div className="row">
            <div><Flag.Icon flag={item}/></div>
            <div className="flex8c">{Translate.get(item._class + '.' + item.exportType)}</div>
            <div className="flexRest">{this.props.item._object}</div>
          </div>
        </div>

      case 'com.optimsys.costra.kordex.flag.ObjectDocumentsMissing':
        if (!this.props.filter.flags) return null
        return this.renderFlagWithParts(item, item.docs)

      case 'com.optimsys.costra.kordex.flag.ObjectProcessingStopped':
        if (!this.props.filter.flags) return null
        return this.renderFlagWithParts(item, item.errors)

      case 'com.optimsys.costra.kordex.flag.RestoreObject':
        if (!this.props.filter.flags) return null
        return this.renderFlagWithParts(item, item.methods)

      // Tasks ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.task.ContactTask':
      case 'com.optimsys.costra.kordex.task.GenericCaseTask':
        if (!this.props.filter.tasks) return null
        return <div className={'row multiple-columns ' + Metrics.TaskColor(item)}>
          {this.renderTaskHeader(item)}
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.task.CollectionTask':
        if (!this.props.filter.tasks) return null
        return <div className={'row multiple-columns ' + Metrics.TaskColor(item)}>
          {this.renderTaskHeader(item)}
          {item.address.matchStr &&
            <div className="row">
              <div className="flexRest">
                {item.address.matchStr}
              </div>
            </div>
          }
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.task.VisitTask':
        var extraButtons = <>
          <div className="button"
               title={Translate.get('task.button.markVisit')}
               onClick={() => Event.markLocationVisited(item.userId, item.caseIds, item.address)}>
            <div className="icon address"></div>
          </div>
        </>
        if (!this.props.filter.tasks) return null
        return <div className={'row multiple-columns ' + Metrics.TaskColor(item)}>
          {this.renderTaskHeader(item, extraButtons)}
          {item.address.matchStr &&
            <div className="row">
              <div className="flexRest">
                {item.address.matchStr}
              </div>
            </div>
          }
          {this.renderNotes(item)}
        </div>

      case 'com.optimsys.costra.kordex.task.PaymentPromiseTask':
        if (!this.props.filter.tasks) return null
        return <div className={'row multiple-columns ' + Metrics.TaskColor(item)}>
          {this.renderTaskHeader(item)}
          <div className="row">
            {item.promiseProcessing.fulfilment ?
              item.promiseProcessing.fulfilment.map(fulfilment =>
                <div className="size5c" key={fulfilment.currency}>
                  {Formatter.formatAmount(fulfilment.payed) + ' / ' +
                    Formatter.formatAmount(fulfilment.promised) + ' ' + fulfilment.currency}
                </div>
              )
              :
              item.promised && item.promised.map(promised =>
                <div className="size5c" key={promised.currency}>
                  {Formatter.formatAmount(promised.amount) + ' ' + promised.currency}
                </div>
              )
            }
            <div className="flexRest">
              {Translate.get('task.promiseResult.' + item.promiseProcessing.result)}
            </div>
          </div>
          {this.renderNotes(item)}
        </div>

      // Files ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.file.StoredFile':
        if (!this.props.filter.files) return null
        return <div className="row multiple-columns ">
          <div className="row">
            <div><div className="icon file"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c"></div>
            <div className="size6c">{this.props.item._object}</div>
            <div className="flexRest">{Translate.get('files.fileType.' + item.fileType)}</div>
          </div>
        </div>

      // Comms ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.call.db.Call':
        if (!this.props.filter.calls) return null
        return <div className="row multiple-columns pinkBorder">
          <div className="row">
            <div><div className="icon call"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size6c">
              {Formatter.phoneNumber(item.src, '+420') + ' -> ' + Formatter.phoneNumber(item.dst, '+420')}
            </div>
            <div className="size4c">
              {item.connectedTo && (' -> ' + item.connectedTo.map(number =>
                Formatter.phoneNumber(number , '+420')
              ).join(', '))}
            </div>
            <div className="flexRest">
              {item.length.talking > 0 && Formatter.formatSeconds(item.length.talking)}
            </div>
            {this.renderPhoneButtons(item.src.match(/^[0-9]{1,4}$/) ? item.dst : item.src)}
          </div>
        </div>

      case 'com.optimsys.costra.sms.db.Sms':
        if (!this.props.filter.sms) return null
        return <div className="row multiple-columns pinkBorder">
          <div className="row">
            <div><div className="icon sms"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size6c">
              {Formatter.phoneNumber(item.src, '+420') + ' -> ' + Formatter.phoneNumber(item.dst, '+420')}
            </div>
            <div className="flexRest">
              {Translate.get('sms.state.' + item.state)}
            </div>
            {this.renderPhoneButtons(item.state === 'Received' ? item.src : item.dst)}
          </div>
          <div className="row">
            <div className="flexRest"><i className="shiftedMessageContainer">{item.text}</i></div>
          </div>
        </div>

      case 'com.optimsys.costra.mail.db.Mail':
        if (!this.props.filter.mails) return null
        return <div className="row multiple-columns pinkBorder">
          <div className="row">
            <div><div className="icon email"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="flexRest">
              {item.src + ' -> ' + (item.dst ? item.dst.join(', ') : '?')}
            </div>
          </div>
          <div className="row">
            <div className="flexRest"><i>{item.subject}</i></div>
            {item.attachments && item.attachments.map((attachment, index) =>
              <div className="button"
                   key={index}
                   title={Translate.get('commlog.item.button.openAttachment', null, {name: attachment.info.fileName})}
                   onClick={evt => Nav.go(evt, '/download/mailAttachment?id=' + item._id.$oid + '&index=' + index, true)}>
                <div className="icon file"></div>
              </div>
            )}
            <div className="button"
                 title={Translate.get('commlog.item.button.openText')}
                 onClick={evt => Nav.go(evt, '/download/mail?id=' + item._id.$oid, true)}>
              <div className="icon newWindow"></div>
            </div>
          </div>
        </div>

      // Exports ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.exports.erl.pred.MonthlyClosingBatch':
      case 'com.optimsys.costra.kordex.exports.erl.pred.AdHocClosingBatch':
      case 'com.optimsys.costra.kordex.exports.erl.pred.ImportClosingBatch':
      case 'com.optimsys.costra.kordex.exports.erl.pred.ImportOverviewBatch':
      case 'com.optimsys.costra.kordex.exports.sal.sf.SfClosingBatch':
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisClosingBatch':
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisPostBatch':
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisSkClosingBatch':
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisSkPostBatch':
      case 'com.optimsys.costra.kordex.exports.sal.telephony.TelClosingBatch':
      case 'com.optimsys.costra.kordex.exports.vw.fs.VwFsClosingBatch':
        if (!this.props.filter.importExport) return null
        return <div className="row multiple-columns">
          <div className="row">
            <div><div className="icon export"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size7c">{Translate.get(item._class)}</div>
            <div className="flexRest">{item.name}</div>
            <div className="button"
                 title={Translate.get('openInNewWindow')}
                 onClick={evt => Batch.go(evt, item._id.$oid, true)}>
              <div className="icon newWindow"></div>
            </div>
            <div className="button"
                 title={Translate.get('open')}
                 onClick={evt => Batch.go(evt, item._id.$oid)}>
              <div className="icon export"></div>
            </div>
          </div>
        </div>

      // Imports ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.imports.log.FilesImported':
        if (!this.props.filter.importExport) return null
        return <div className="row multiple-columns">
          <div className="row">
            <div><div className="icon import"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy %HH:%MM", item.time)}</div>
            <div className="size3c">{this.getUser(item)}</div>
            <div className="flexRest">{Translate.get(item.displayName)}</div>
            <div className="button"
                 title={Translate.get('openInNewWindow')}
                 onClick={evt => ImportRecord.go(evt, item._id.$oid, true)}>
              <div className="icon newWindow"></div>
            </div>
            <div className="button"
                 title={Translate.get('open')}
                 onClick={evt => ImportRecord.go(evt, item._id.$oid)}>
              <div className="icon export"></div>
            </div>
          </div>
        </div>

      // Payment ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.payment.ReceivedPayment':
        if (!this.props.filter.payments) return null
        return <div className="row multiple-columns goldBorder">
          <div className="row">
            <div><div className="icon coins"/></div>
            <div className="size3c">{Formatter.formatDateTime("%dd.%mm.%yyyy", item.time)}</div>
            <div className="size3c">{Formatter.formatAmount(item.amount) + ' ' + item.currency}</div>
            <div className="flexRest">{Translate.get('com.optimsys.costra.kordex.payment.ReceivedPayment')}</div>
          </div>
        </div>

      // Default ------------------------------------------------------------------------------------------------------
      case 'com.optimsys.costra.kordex.ui.DaysSeparator':
        if (!this.props.filter.daySeparators) return null
        return <div className="spacedTop">
          --- {Formatter.formatDateTime("%dd.%mm.%yyyy", item.time)} ---
        </div>

      // Default ------------------------------------------------------------------------------------------------------
      default:
        return <div className="row multiple-columns">
          <div className="row">
            <div className="flexRest"><i>{item._class}</i></div>
          </div>
        </div>
    }
  }
}

AnyItem.contextType = PageDataContext

export default AnyItem
