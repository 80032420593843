import React from 'react'
import Translate from '../costra/Translate.js'

import PreparedLines from './PreparedLines.js'
import Nav from '../utils/Nav.js'

class Erl {

  static overviewBatchButton(data) {
    return <div className="button right"
                onClick={(event) => Nav.go(event, '/export/batch/' + data.overviewBatchId.$oid)}>
      <div className="icon left export"/>
      {Translate.get('export.erl.pred.overviewBatch')}
    </div>
  }

  static predImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {Erl.overviewBatchButton(data)}
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.clientRowsImported', data.rowsImported)}
        {PreparedLines.reportLine('imports.result.clientRowsSkipped', data.rowsSkipped, 'cancel')}
      </ul>
    </div>
  }

  static predXmlImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {Erl.overviewBatchButton(data)}
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.xmlEntitiesImported', data.entitiesImported)}
        {PreparedLines.reportLine('imports.result.xmlEntitiesSkipped', data.entitiesSkipped, 'cancel')}
      </ul>
    </div>
  }

  static predE6ImportDone(component, data) {
    return <div>
      <div className="listHeader spacedTop">
        <div>
          <div className="icon iconBig import"/>
          <h2>{Translate.get('imports.result.title')}</h2>
        </div>
        <div>
          {Erl.overviewBatchButton(data)}
          {PreparedLines.reportProtocolButton(data.importRecordId)}
          {PreparedLines.closeButton(component)}
        </div>
      </div>
      <ul className="list">
        {PreparedLines.reportLine('imports.result.e6.addressRowsImported', data.addressRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contactRowsImported', data.contactRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.orvRowsImported', data.orvRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsImported', data.partnerRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.objectRowsImported', data.objectRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsImported', data.accessoryRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.contractRowsImported', data.contractRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.eventRowsImported', data.eventRowsImported)}
        {PreparedLines.reportLine('imports.result.e6.addressRowsSkipped', data.addressRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contactRowsSkipped', data.contactRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.orvRowsSkipped', data.orvRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.partnerRowsSkipped', data.partnerRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.objectRowsSkipped', data.objectRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.accessoryRowsSkipped', data.accessoryRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.contractRowsSkipped', data.contractRowsSkipped, 'cancel')}
        {PreparedLines.reportLine('imports.result.e6.eventRowsSkipped', data.eventRowsSkipped, 'cancel')}
      </ul>
    </div>
  }
}

export default Erl