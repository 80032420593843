import React from 'react'

import ContactData from './ContactData.js'

function ContactDataList(props) {
  var list = props.data.get(props.path)
  return list && list.map((contactData, index) =>
    <ContactData key={index}
                 data={props.data}
                 path={props.path + '.' + index}
                 disabled={props.disabled}
                 title={props.title}/>
  )
}

export default ContactDataList