import React from 'react'
import Dialogs from '../costra/Dialogs'
import Formatter from '../costra/Formatter'
import Translate from '../costra/Translate'
import WsContainers from '../costra/WsContainers.js'
import Router from '../costra/Router.js'

import StateIcons from '../utils/StateIcons'
import ConfirmDialog from '../utils/ConfirmDialog.js'

import ExportSelectDialog from '../exports/ExportSelectDialog.js'

import '../css/icons.css'
import '../css/form.css'

function openExportDialog(event, props) {
  event.stopPropagation()
  Dialogs.open(<ExportSelectDialog record={props.item} exports={props.exports}/>)
}

function removeObject(item){
  Dialogs.open(
    <ConfirmDialog text={Translate.get('caseobject.remove.prompt', null, {name: item.name})}/>
  ).then((confirmed) => {
    if (confirmed) {
      WsContainers.transaction({
        _class: 'com.optimsys.costra.kordex.caseobject.RemoveObject',
        itemId: item._id.$oid,
      }).then(() => Router.boBack())
    }
  })
}

function renderTitle(props) {
  switch(props.item._class) {
    /*
    case 'com.optimsys.costra.kordex.caseobject.erl.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.KvCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RetCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.RupCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.WcCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.lcs.telephony.ZamCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkWcCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.SkTelCaseObject':
    */
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.pis.PisCaseObject':
      return <div className='header--flex'>
        <div>
          <div className="left icon iconBig case"/>
          <h1>{props.item.name}</h1>
        </div>
      </div>

    case 'com.optimsys.costra.kordex.caseobject.cofidis.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectVtp':
    case 'com.optimsys.costra.kordex.caseobject.erl.pred.PredCaseObject':
      return <div className='header--flex'>
        <div>
          <div className="left icon iconBig case"/>
          <h1>{props.item.name}</h1>
        </div>
        <div>
          <div className="left icon  address"/>
          <span>
            {props.item.address.matchStr ?
              Formatter.address(props.item.address) :
              Translate.get('caseobject.header.noAddress')
            }
          </span>
        </div>
      </div>

    default:
      return <div className='header--flex'>
        <div>{props.item._class}</div>
      </div>
  }
}

function renderValues(props) {
  switch (props.item._class) {
    case 'com.optimsys.costra.kordex.caseobject.erl.pred.PredCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectCar':
    case 'com.optimsys.costra.kordex.caseobject.sal.sf.SfCaseObjectVtp':
      return <div className="values">
        <div className={'score ' + (props.item.processing && props.item.processing.finished ? 'complete' : '')}>
          {Translate.get('score.' + props.item.processing.score)}
        </div>
        <StateIcons item={props.item} extraClass="iconBig"/>
      </div>

    case 'com.optimsys.costra.kordex.caseobject.vw.fs.VwFsCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.cofidis.collection.CollectionCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.telephony.TelCaseObject':
    case 'com.optimsys.costra.kordex.caseobject.sal.pis.PisCaseObject':
      return <div className="values">
        <StateIcons item={props.item} extraClass="iconBig"/>
      </div>

    default:
      return <div className="values">{props.item._class}</div>
  }
}

function ObjectEditHeader(props) {
  if (!props.item) {
    return null
  }
  return <>
    {renderTitle(props)}
    {renderValues(props)}
    <div className='header__buttons'>
      <button disabled={!props.allowEdit}
              className={!props.allowEdit ? "disabled" : ""}
              onClick={(event) => openExportDialog(event, props)}>
        {Translate.get('caseobject.header.button.export')}
      </button>
      <button disabled={!props.allowRemove}
              className={props.allowRemove ? "" : "disabled"}
              onClick={() => removeObject(props.item)}>
        {Translate.get('caseobject.header.button.remove')}
      </button>
    </div>
  </>
}

export default ObjectEditHeader
