import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'

import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import TaskEditDialog from './TaskEditDialog.js'
import '../css/dialogs.css'
import Task from './Task.js'

class CreateTaskDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      working : false,
    }
  }

  createTask(taskClass) {
    this.setState({working: true})
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.task.BuildTask',
      caseIds: this.props.caseIds,
      userId: this.props.userId,
      taskClass: taskClass,
    }).then(result => {
      Dialogs.close(null)
      Dialogs.open(<TaskEditDialog task={result}/>)
    })
  }

  render() {  
    if(this.state.working) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <LoadingPlaceholder/>
        </div>
      </div>
    }
    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className='title'>
          <div className="icon iconBig task"/>
          <span>{Translate.get('task.create.title')}</span>
        </div>
        <ul className="list spacedBottom">
          {Task.taskClasses.map((taskClass, i) =>
            <li key={i}>
              <div className="row withHover"
                   onClick={event => this.createTask(taskClass)}>
                <div><Task.Icon taskClass={taskClass}/></div>
                <div>{Translate.get(taskClass)}</div>
              </div>
            </li>
          )}
        </ul>
        <div className="buttons spacedTop">
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default CreateTaskDialog
