import React, { Component } from 'react'

import Translate from '../costra/Translate.js'

import ListContainer from '../utils/ListContainer.js'
import PageSelector from '../utils/PageSelector.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'
import Nav from '../utils/Nav.js'

import '../css/list.css'
import '../css/icons.css'

import Task from './Task.js'
import { PageDataContext } from '../PageDataContext.js'

class Container extends ListContainer {

  params() {
    var params = super.params()
    params.campaignId = this.component.props.campaignId
    params.taskClass = 'com.optimsys.costra.kordex.task.' + this.component.props.task
    return params
  }
}

class SelectedTasksList extends Component {

  constructor(props) {    
    super(props)
    var state = ListContainer.initialState()
    state.itemsPerPage = 30
    state.filter = {}
    state.page = this.props.page ?? 1
    state.sortField = 'deadlineAt'
    state.sortOrder = 1
    state.items = null
    this.state = state
    this.container = new Container(this, 'com.optimsys.costra.kordex.task.SelectedTasksList')
  }

  componentDidMount() {
    this.container.reload()
  }

  componentWillUnmount() {
    this.container.close()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.campaignId !== this.props.campaignId ||
        prevProps.task !== this.props.task ||
        prevProps.page !== this.props.page) {
      this.setState({items: null}, () => {
        this.container.reload()
      })
    }
  }

  renderContent(taskClass) {
    if (!this.state.items) {
      return <div className="content"><LoadingPlaceholder /></div>
    }
    return <div className="content">
      <div className="listHeader spacedTop">
        <PageSelector page={this.state.page}
                      pages={this.state.pages}
                      onChange={(event, page) => {Nav.addUrlParam(event, 'page', page)}}/>
      </div>
      <ul className="list">
        <li><Task.Header taskClass={taskClass}/></li>
        {this.state.items && this.state.items.map(item =>
          <li key={item.item._id.$oid}><Task.Row item={item} users={this.context.users}/></li>
        )}
      </ul>
    </div>
  }

  render() {
    let taskClass = 'com.optimsys.costra.kordex.task.' + this.props.task
    return <div className="mainPanel">
      <div className="header">
        <div className="row">
          <Task.Icon taskClass={taskClass} extraClass="iconBig"/>
          <h1>{Translate.get(taskClass)}</h1>
        </div>
      </div>
      <div className="scrollable">
        {this.renderContent(taskClass)}
      </div>
    </div>
  }
}
SelectedTasksList.contextType = PageDataContext

export default SelectedTasksList