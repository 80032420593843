import WsContainers from './WsContainers.js'
import Container from './Container.js'

class Identity extends Container {

  static login(login, password) {
    return WsContainers.action({
      _class: 'com.optimsys.costra.ws.action.Login',
      login: login,
      password: password,
    })
  }

  static logout() {
    return WsContainers.action({
      _class: 'com.optimsys.costra.ws.action.Logout',
    })
  }

  constructor(proc) {
    super()
    this.proc = proc
    this.className = 'com.optimsys.costra.ws.container.SessionIdentityInfo'
  }

  open() {
    this.tell({_class: 'com.optimsys.costra.ws.container.SessionIdentityInfo$Get'})
  }

  _onMessage(message) {
    if (message._class === 'com.optimsys.costra.ws.container.SessionIdentityInfo$Info') {
      this.proc(message['identity'], message['trueIdentity'])
    }
    else {
      super._processMessage(message)
    }
  }
}

export default Identity