import React from 'react'
import Validator from '../../../costra/Validator.js'
import Translate from '../../../costra/Translate.js'

import Input from '../../../utils/Input.js'

import '../../../css/icons.css'
import '../../../css/form.css'
/*
function AddressColumns(props) {

  const [openAddress,setOpenAddress] = useState(false)

  return <>
    <div className='flex'>
      <div className="icon left address" />
      <div >
        {props.data.get(props.path ) ?
          Formatter.address(props.data.get(props.path ))
          :
          Translate.get('caseobject.erl.accessories.sameAddressAsObject')
        }
      </div>
      {!props.disabled &&
        <div className="button" onClick={()=>setOpenAddress(!openAddress)}>
          {Translate.get('caseobject.erl.accessories.button.change')}
        </div>
      }
    </div>
    {openAddress && !props.disabled &&
      <div className="stretched address">
        <div>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.street'}
                      title='contactData.address.street'
                      className="size3c"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.streetCode'}
                      title='contactData.address.streetCode'
                      className="max--size2c short"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path + '.city'}
                      title='contactData.address.city'
                      className="size3c"/>
          <Input.Text data={props.data}
                      showEmpty={true}
                      disabled={props.disabled}
                      path={props.path +  '.postCode'}
                      title='contactData.address.postCode'
                      className="max--size2c medium"/>
        </div>
      </div>
    }
  </>
}
*/
function addAccessory(event, props) {
  var list = props.data.get(props.path)
  if (!list) {
    list = []
  }
  list.push({name: ""})
  props.data.set(props.path, list)
}

function removeAccessory(event, props, index) {
  var list = props.data.get(props.path)
  if (!list) {
    list = []
  }
  list.splice(index, 1)
  props.data.set(props.path, list)
}

function Accessories(props) {
  var list = props.data.get(props.path)
  if (!list && props.disabled) {
    return null
  }
  
  return <div className="subsection">
    <div className="spacedTop">
      <div className="header-row">
        <div className="icon iconSection accessory"/>
        <h3>
          {Translate.get('caseobject.accessories')}
        </h3>
      </div>
      {!props.disabled &&
        <div className="button right"
             title={Translate.get('caseobject.accessories.button.addAccessories')}
             onClick={(event) => addAccessory(event, props)}>
          <div className="icon left add"/>
          {Translate.get('caseobject.accessories.button.addAccessories')}
        </div>
      }
    </div>
    <ul className="list">
      {list && Object.keys(list).map(key =>
        <li key={key}>
          <div className="rowAccessories">
            <div className='flex'>
              <div className= "icon accessory"/>
              <Input.Text data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + key + '.name'}
                          title='caseobject.accessories.name'
                          validate={Validator.notEmpty}/>
              <Input.Text data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + key + '.serialNo'}
                          title='caseobject.accessories.serialNo'
                          className="size6c"/> 
              <Input.Text data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + key + '.productionYear'}
                          title='caseobject.accessories.productionYear'
                          className="size6c"/> 
              <Input.Select data={props.data}
                          disabled={props.disabled}
                          path={props.path + '.' + key + '.checkupState'}
                          title='caseobject.erl.accessories.checkupState'
                          values={props.checkupStates}
                          className="size6c"/> 
              {!props.disabled &&
                <div className="button right"
                    title={Translate.get('caseobject.accessories.button.removeAccessory')}
                    onClick={(event) => removeAccessory(event, props, key)}>
                  <div className="icon left remove"/>
                </div>
              }
            </div>
            {/*
            <div >
             <AddressColumns data={props.data}
                            path={props.path + '.' + key + '.address'}
                            disabled={props.disabled}
                            key={key}/>
            </div>
            */}
          </div>
        </li>
      )}
    </ul>
  </div>
}

export default Accessories
