import React from 'react'
import Dialogs from '../costra/Dialogs.js'
import WsContainers from '../costra/WsContainers.js'

import ConfirmDialog from '../utils/ConfirmDialog.js'
import Nav from '../utils/Nav.js'
import Translate from '../costra/Translate.js'

class MessageTemplate {

  static go(event, id, newTab) {
    Nav.go(event, '/messageTemplate/' + id, newTab)
  }

  static create(event, campaignId) {
    Nav.go(event, '/messageTemplate/new/' + campaignId)
  }

  static remove(event, item) {
    event.stopPropagation()
    return Dialogs.open(<ConfirmDialog text={'Opravdu chcete odstranit šablonu ' + item.displayName + '?'}/>).then((confirmed) => {
      if (confirmed) {
        return WsContainers.transaction({
          _class: 'com.optimsys.costra.kordex.message.RemoveMessageTemplate',
          id: item._id,
        })
      }
    })
  }

  static Row(props) {
    return <div className="row withHover"
                onClick={(event) => MessageTemplate.go(event, props.item._id.$oid)}>
      <div><div className="icon messageTemplate"/></div>
      <div>{props.item.displayName}</div>

      {props.item._allowEdit &&
        <div className="right button"
             title={Translate.get('message.template.button.remove')}
             onClick={(event) => MessageTemplate.remove(event, props.item)}>
          <div className="icon remove"/>
        </div>
      }
      <div className="right button"
           title={Translate.get('message.template.button.openInNewTab')}
           onClick={(event) => MessageTemplate.go(event, props.item._id.$oid, true)}>
        <div className="icon newWindow"/>
      </div>
    </div>
  }

}

export default MessageTemplate