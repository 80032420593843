import React, { Component } from 'react'
import Dialogs from '../costra/Dialogs.js'
import Translate from '../costra/Translate.js'
import WsContainers from '../costra/WsContainers.js'
import EditData from '../costra/EditData.js'

import Input from '../utils/Input.js'
import ErrorPlaceholder from '../utils/ErrorPlaceholder.js'
import LoadingPlaceholder from '../utils/LoadingPlaceholder.js'

import '../css/dialogs.css'

class PresetsDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      presets: null,
      error: null,
      newPreset: null,
    }
    this.data = new EditData(this, 'newPreset.')
  }

  load() {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.LoadNamedPresets',
      name: this.props.name,
    }).then((result) => {
      if (!result.presets) {
        result.presets = []
      }
      this.setState(result)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  componentDidMount() {
    this.load()
  }

  applyPreset(event, preset) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: this.props.validationClass,
      name: this.props.name,
      data: preset.data,
    }).then((result) => {
      Dialogs.close(preset.data[0])
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  createNamedPreset(event) {
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: this.props.validationClass,
      name: this.props.name,
      title: this.state.newPreset.title,
      data: [this.props.filter],
    }).then((result) => {
      Dialogs.close(null)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  overwriteNamedPreset(event, preset) {
    event.stopPropagation()
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.StorePreset',
      validationClass: this.props.validationClass,
      name: this.props.name,
      title: preset.title,
      data: [this.props.filter],
    }).then((result) => {
      Dialogs.close(null)
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  removeNamedPreset(event, preset, index) {
    event.stopPropagation()
    WsContainers.transaction({
      _class: 'com.optimsys.costra.kordex.presets.RemoveNamedPreset',
      name: this.props.name,
      title: preset.title,
    }).then((result) => {
      var presets = this.state.presets
      presets.splice(index, 1)
      this.setState({presets: presets})
    }, () => {
      this.setState({error: 'filter.error.cannotStore'})
    })
  }

  renderList() {
    if (this.state.error) {
      return <ErrorPlaceholder/>
    }
    if (!this.state.presets) {
      return <LoadingPlaceholder/>
    }
    return <ul className="list spacedBottom">
      {this.state.presets.map((preset, index) =>
        <li key={preset._id.$oid}>
          <div className="row withHover"
               onClick={(event) => this.applyPreset(event, preset)}>
            <div><div className="icon filter"/></div>
            <div>{preset.title}</div>
            <div className="button right"
                 title={Translate.get('preset.overwrite.button')}
                 onClick={(event) => this.overwriteNamedPreset(event, preset)}>
              <div className="icon save"/>
            </div>
            <div className="button right"
                 title={Translate.get('preset.remove.button')}
                 onClick={(event) => this.removeNamedPreset(event, preset, index)}>
              <div className="icon cancel"/>
            </div>
          </div>
        </li>
      )}
    </ul>
  }

  render() {
    if (this.state.newPreset) {
      return <div className="dialogsContainer">
        <div className="dialog wide frame">
          <div className="title">
            <div className="icon iconBig filter"/>
            <span>{Translate.get('preset.new.title')}</span>
          </div>
          <div>
            <Input.Text data={this.data}
                        path="title"
                        title='preset.new.title.prompt'
                        className="wide"/>
          </div>
          <div className="buttons">
            <div className="button"
                 title={Translate.get('preset.add.button')}
                 onClick={(event) => this.createNamedPreset(event)}>
              <div className="icon iconBig ok"/>
            </div>
            <div className="button"
                 title={Translate.get('close')}
                 onClick={(event) => Dialogs.close(null)}>
              <div className="icon iconBig cancel"/>
            </div>
          </div>

        </div>
      </div>
    }

    return <div className="dialogsContainer">
      <div className="dialog wide frame">
        <div className="title">
          <div className="icon iconBig filter"/>
          <span>{Translate.get('preset.select.title')}</span>
        </div>
        {this.renderList()}
        <div className="buttons">
          <div className="button"
               title={Translate.get('preset.add.button')}
               onClick={(event) => this.setState({newPreset: {}})}>
            <div className="icon iconBig add"/>
          </div>
          <div className="button"
               title={Translate.get('close')}
               onClick={(event) => Dialogs.close(null)}>
            <div className="icon iconBig cancel"/>
          </div>
        </div>

      </div>
    </div>
  }
}

export default PresetsDialog
