import React from 'react'
import Formatter from '../costra/Formatter.js'
import Translate from '../costra/Translate.js'

import Nav from '../utils/Nav.js'

import {PageDataContext} from '../PageDataContext.js'

class Batch {

  static go(event, id, newTab) {
    Nav.go(event, '/export/batch/' + id, newTab)
  }

  static DetailsText(props) {
    switch(props.item._class) {
      case 'com.optimsys.costra.kordex.exports.erl.pred.MonthlyClosingBatch':
        return Translate.get('sal.source.' + props.item.source) + ' ' +
          props.item.year + '-' + props.item.month + ' ' +
          Translate.get('erl.pred.contractType.' + props.item.contractType)
      case 'com.optimsys.costra.kordex.exports.erl.pred.ImportClosingBatch':
        return props.item.importFileName + ' - ' +
          Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM',props.item.importTime)
      case "com.optimsys.costra.kordex.exports.erl.pred.AdHocClosingBatch":
        return Translate.get('sal.source.' + props.item.source)
      case "com.optimsys.costra.kordex.exports.erl.pred.ImportOverviewBatch":
        return props.item.importFileName
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisClosingBatch':
      case 'com.optimsys.costra.kordex.exports.sal.telephony.TelClosingBatch':
        return Translate.get('sal.source.' + props.item.source)
      default:
        return ''
    }
  }

  static mutableRowColumns(props) {
    switch(props.item._class) {
      case 'com.optimsys.costra.kordex.exports.erl.pred.MonthlyClosingBatch':
        return <>
          <div className="size2c">{Translate.get('sal.source.' + props.item.source)}</div>
          <div className="size3c">{props.item.year}-{props.item.month}</div>
          <div className="size2c">{Translate.get('erl.pred.contractType.' + props.item.contractType)}</div>
        </>
      case 'com.optimsys.costra.kordex.exports.erl.pred.ImportClosingBatch':
        return <>
          <div className="size3c">{Formatter.formatDateTime('%d.%m.%yyyy %HH:%MM',props.item.importTime)}</div>
          <div>{props.item.importFileName}</div>
        </>
      case "com.optimsys.costra.kordex.exports.erl.pred.AdHocClosingBatch":
        return <>
          <div className="size2c">{Translate.get('sal.source.' + props.item.source)}</div>
          <div className="size3c">{props.item.client}</div>
        </>
      case "com.optimsys.costra.kordex.exports.erl.pred.ImportOverviewBatch":
        return <>
          <div>{props.item.importFileName}</div>
        </>
      case 'com.optimsys.costra.kordex.exports.sal.pis.PisClosingBatch':
      case 'com.optimsys.costra.kordex.exports.sal.telephony.TelClosingBatch':
        return <>
          <div className="size2c">{Translate.get('sal.source.' + props.item.source)}</div>
        </>
      default:
        return <></>
    }
  }

  static Row(props) {
    return <PageDataContext.Consumer>
      {pageData =>
        <div className="row withHover"
             onClick={(event) => Batch.go(event, props.item._id.$oid)}>
          <div><div className="icon export"/></div>
          <div className="size3c">{Formatter.formatDateTime('%d.%m. %yyyy %HH:%MM', props.item.time)}</div>
          <div className="size4c">{pageData.campaigns[props.item.campaignId.$oid]}</div>
          <div className="size6c">{Translate.get(props.item._class)}</div>
          {Batch.mutableRowColumns(props)}
          <div className="right button"
               title={Translate.get('openInNewWindow')}
               onClick={(event) => Batch.go(event, props.item._id.$oid, true)}>
            <div className="icon newWindow"/>
          </div>
        </div>
      }
    </PageDataContext.Consumer>
  }
}

export default Batch